import { CircularProgress, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

import AuthRoutes from "./routes/AuthRoutes";
import MainRoutes from "./routes/MainRoutes";
import { setLogin } from "./store/reducer/AuthHelper";
import { GlobalStyles } from "./themes/globalStyles";

import "./App.css";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const theme = useTheme();
  const { isLoggedIn = null } = useSelector((state) => state?.authhelper);
  const dispatch = useDispatch();
  useEffect(() => {
    const isAuth = localStorage.getItem("auth");
    if (isAuth) {
      dispatch(setLogin({ isAuth: true }));
    } else {
      dispatch(setLogin({ isAuth: false }));
    }
  }, [isLoggedIn]);
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const role = queryParams.get("role");
    const token = queryParams.get("auth");
    const designation = queryParams.get("designation");

    if (role && token) {
      localStorage.setItem("auth", token);
      localStorage.setItem("role", role);
      if (role == 3 && designation) {
        localStorage.setItem("designation", designation);
      }
    }
  }, []);
  if (isLoggedIn === null) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Fragment>
      <GlobalStyles theme={theme} />
      {isLoggedIn ? <MainRoutes /> : <AuthRoutes />}
      {/* <MainRoutes /> */}
      <ToastContainer hideProgressBar autoClose={1500} />
    </Fragment>
  );
}

export default App;
