import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Rating from "@mui/material/Rating";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../api";
import clockicon from "../../assets/images/clockicon.png";
import doctorphone from "../../assets/images/doctorphone.png";
import doctorprofile from "../../assets/images/doctorprofile.png";
import location from "../../assets/images/location.png";
import staricon from "../../assets/images/staricon.png";
import { styles } from "../../layouts/Styles";
import formatDate from "../../utils/formatDate";
import RatingModal from "./RatingModal";
import SentSuccessfully from "./SentSuccessfully";
import getStringInitials from "../../utils/getStringInitials";
import moment from "moment";
import { format } from 'date-fns'; // Import format function from date-fns

const ClinicMyBookings = (props) => {
  const data = props?.data || {};
  const { open, handleClose, selectedData } = props;

  const [openRating, setOpenRating] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [done, setDone] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const classes = styles();
  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
  };
  const handleCloseApprove = () => {
    setCancel(false);
    handleClose();
    // getAppointments();
  };
  const handlecloseDone = () => {
    setDone(false);
    handleClose();
  };

  const updateAppointmentStatus = async (status) => {
    try {
      await api.updateBookingStatus(selectedData?._proposal, status);
      setOpenRating(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleOptionChange = (event) => {
    let value = event.target.value;
    setSelectedOption(value);
    if (value == "done") {
      // setDone(true);

      // setOpenRating(true);
      updateAppointmentStatus("completed");
    } else {
      updateAppointmentStatus("cancelled");
      setCancel(true);
    }
  };

  const handleDone = () => {
    setOpenRating(false);
    setDone(true);
  };

  return (
    <>
      {cancel ? (
        <SentSuccessfully
          modal={cancel}
          cancel={true}
          handleCloseApprove={handleCloseApprove}
        />
      ) : // <SentSuccessfully
        //   modal={true}
        //   handleCloseApprove={handleCloseApprove}
        //   value={"payment"}
        // />
        done ? (
          <SentSuccessfully
            modal={true}
            handleCloseApprove={handleCloseApprove}
            value={"payment"}
          />
        ) : openRating ? (
          <RatingModal
            modal={openRating}
            handleCloseApprove={handleDone}
            value={"proposal"}
            data={data}
            doctor={true}
          />
        ) : (
          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle
              sx={{ m: 0, p: 2, fontWeight: 600, fontSize: 20, color: "#F98247" }}
              id="customized-dialog-title"
            >
              {selectedData?.postDetails?.testName || ""}
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "black",
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <Grid container spacing={3} alignItems="center">
                <Grid item className={classes.imageContainer}>
                  <img
                    src={doctorprofile}
                    style={{ width: "84px", height: "84px" }}
                  />
                  <Typography variant="h6" className={classes.textOverlay}>
                    {selectedData?.doctor?.name
                      ? getStringInitials(selectedData?.doctor?.name)
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box display="flex" alignItems="center">
                        <Box display="flex" flexDirection="column">
                          <Box
                            display="flex"
                            sx={{
                              flexDirection: {
                                xs: "column",
                                sm: "column",
                                md: "column",
                                lg: "row",
                                xl: "row",
                              },
                              mr: 1,
                              // ml: 1,
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#004E89",
                                fontSize: "20px",
                                fontWeight: 600,
                              }}
                            >
                              {selectedData?.doctor?.name || ""}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Box
                        display="flex"
                        sx={{
                          flexDirection: {
                            md: "row",
                            lg: "row",
                            xl: "row",
                            sm: "row",
                            xs: "row",
                          },
                        }}
                      >
                        <Rating
                          name="half-rating"
                          readOnly
                          value={selectedData?.doctor?.rating || 0}
                          // onChange={(event, newRating) => handleRatingChange(newRating)}
                          precision={0.5}
                          sx={{ fontSize: 20 }}
                        />
                        <Typography
                          sx={{
                            color: "#1D2343",
                            fontSize: "18.14px",
                            fontWeight: 500,
                            px: 1,
                          }}
                        >
                          {selectedData?.doctor?.rating || 0}
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(10, 10, 10, 0.48)",
                            fontSize: "18.14px",
                            fontWeight: 500,
                          }}
                        >
                          ({selectedData?.doctor?.totalRating || 0})
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container sx={{ mt: "30px" }}>
                <Grid item xs={3} sm={1} md={1} lg={1} xl={1} sx={{ mr: 0 }}>
                  <img
                    src={doctorphone}
                    alt="phone"
                    style={{ width: 40, height: 40 }}
                  />
                </Grid>
                <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "400", color: "#858585" }}
                  >
                    Contact no
                  </Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "500", color: "#303030" }}
                  >
                    {selectedData?.doctor?.phoneNumber || "-"}
                  </Typography>
                </Grid>

                <Grid
                  item
                  xs={3}
                  sm={1}
                  md={1}
                  lg={1}
                  xl={1}
                  sx={{ pl: "2px", mr: 0 }}
                >
                  <img
                    src={location}
                    style={{ width: 40, height: 40 }}
                    alt="loc"
                  />
                </Grid>
                <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "400", color: "#858585" }}
                  >
                    Location
                  </Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "500", color: "#303030" }}
                  >
                    {selectedData?.postDetails?.locationUrl || "-"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={3}
                  sm={1}
                  md={1}
                  lg={1}
                  xl={1}
                  sx={{ pl: "2px", mr: 0 }}
                >
                  <img
                    src={clockicon}
                    style={{ width: 40, height: 40 }}
                    alt="clock"
                  />
                </Grid>
                <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "400", color: "#858585" }}
                  >
                    Date & Time
                  </Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "500", color: "#303030" }}
                  >
                    {selectedData?.postDetails?.date ? format(new Date(selectedData.postDetails.date), 'dd/MM/yyyy') : ""}
                    {selectedData?.postDetails?.startTime || ""} - {selectedData?.postDetails?.endTime || ""}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  lg={3}
                  xl={3}
                  sx={{ mt: "0.5em" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={staricon}
                      style={{ width: "16.67px", height: "16.67px" }}
                    />
                    <Typography
                      sx={{
                        color: "#F98247",
                        fontSize: "16px",
                        fontWeight: 600,
                        ml: 1,
                      }}
                    >
                      {selectedData?.postDetails?.minimumSalary
                        ? `Rs. ${selectedData?.postDetails?.minimumSalary || 0} (Per Hour)`
                        : ""}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={staricon}
                      style={{ width: "16.67px", height: "16.67px" }}
                    />
                    <Typography
                      sx={{
                        color: "#F98247",
                        fontSize: "16px",
                        fontWeight: 600,
                        ml: 1,
                      }}
                    >
                      {selectedData?.postDetails?.specilistIn
                        ? ` ${selectedData?.postDetails?.specilistIn || "N-A"}`
                        : ""}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                container
                flexDirection={"column"}
                spacing={1}
                sx={{ mt: "0.5em", mb: "0.5em" }}
              >
                <Grid item>
                  <Typography variant="h3" sx={{ fontSize: "20px", mt: "0.5em" }}>
                    Job Description
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "rgba(118, 121, 128, 1)",
                      minHeight: "50px",
                      maxHeight: "220px",
                      overflowY: "auto",
                      border: "1px solid #e6e6e6",
                      padding: "4px",
                      borderRadius: "6px",
                    }}
                  >
                    {selectedData?.postDetails?.caseDescription || "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container Spacing={1}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  sx={{ mt: "1em" }}
                >
                  <Typography sx={{ fontSize: "16px", color: "#303030" }}>
                    Order Status
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FormControl sx={{ minWidth: 320 }}>
                    <Select
                      value={selectedOption}
                      onChange={handleOptionChange}
                      sx={{
                        borderRadius: 2,
                        backgroundColor: "white",
                        "& .MuiOutlinedInput-input": {
                          borderRadius: 2,
                          border: "none",
                          outline: "none",
                          height: "15px",
                          marginTop: "-0.2em",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: 2,
                          border: "none",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                      //   inputProps={{ id: "doctor_type" }}
                      //   value={inputData.doctor_type}
                      //   onChange={(e) => { handleInput(e, "doctor_type") }}
                      onFocus={(e) => e.currentTarget.classList.add("focused")}
                      onBlur={(e) => e.currentTarget.classList.remove("focused")}
                      displayEmpty
                      onOpen={handleDropdownOpen}
                      onClose={handleDropdownClose}
                    >
                      <MenuItem value="" style={{ display: "none" }}>
                        <em
                          style={{
                            color: "#C2C2C2",
                            fontStyle: "normal",
                            fontSize: 14,
                            fontWeight: 400,
                          }}
                        >
                          Select Status
                        </em>
                      </MenuItem>

                      <MenuItem value="done">
                        <Typography style={{ color: "green", fontWeight: "400" }}>
                          {selectedData?.postDetails?.requestType == "consultation"
                            ? "Job Done"
                            : "Approve"}
                        </Typography>
                      </MenuItem>
                      <MenuItem value="cancel">
                        <Typography style={{ color: "red", fontWeight: "400" }}>
                          {selectedData?.postDetails?.requestType == "consultation"
                            ? "Appointment Cancel"
                            : "Reject"}
                        </Typography>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  container
                  spacing={1}
                  sx={{ mt: isDropdownOpen ? "4em" : "0em" }}
                >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        )}
    </>
  );
};
export default ClinicMyBookings;
