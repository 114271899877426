import { Box } from "@mui/material";
import React from "react";

const Marketplace = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ height: "80vh" }}
    >
      <h1
        style={{
          color: "#1A202C",
          fontWeight: 600,
          fontSize: 100,
          textAlign: "center",
          margin: "0px",
          padding: "0px",
          lineHeight: "90px",
        }}
      >
        Coming
        <br /> Soon!
      </h1>
    </Box>
  );
};

export default Marketplace;
