import { Visibility, VisibilityOff } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  InputAdornment,
  Link,
  OutlinedInput,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import api from "../../api";
import { setLogout } from "../../store/reducer/AuthHelper";
import PasswordDoneModal from "./PasswordDoneModal";

const PasswordModal = (props) => {
  const dispatch = useDispatch();

  const [done, setDone] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [isDisabled, setIsDisabled] = useState(false);

  const [inputData, setInputData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleInput = (e, id) => {
    e.preventDefault();
    const { value } = e.target;
    setInputData({
      ...inputData,
      [id]: value,
    });
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleDoneModal = () => {
    setDone(true);
  };
  const handleDoneCloseModal = () => {
    setDone(false);
    props.handleClose();
  };
  const validateNewPassword = (password) => {
    const minLength = 8;
    const maxLength = 12;
    const specialCharacters = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
    const capitalLetters = /[A-Z]/;
    const numbers = /[0-9]/;

    if (password.length < minLength) {
      return "Password should contain minimum 8 characters";
    } else if (password.length > maxLength) {
      return "Password should not exceed 12 characters";
    } else if (!specialCharacters.test(password)) {
      return "Password should contain at least one special character";
    } else if (!capitalLetters.test(password)) {
      return "Password should contain at least one capital letter";
    } else if (!numbers.test(password)) {
      return "Password should contain at least one digit";
    } else if (inputData.currentPassword == password) {
      return "Password cannot be same as old password";
    }

    return null;
  };

  const logoutUser = () => {
    setTimeout(() => {
      dispatch(setLogout());
    }, 3000);
  };

  const changePassword = async () => {
    setIsDisabled(true);
    try {
      let payload = { ...inputData };
      delete payload["confirmPassword"];
      const { data } = await api.changePassword(payload);
      if (!data?.success) {
        setIsDisabled(false);
        return;
      }
      setDone(true);
      localStorage.clear();
      logoutUser();
    } catch (err) {
      setIsDisabled(false);
      console.log(err);
    }
  };

  return (
    <>
      {done ? (
        <PasswordDoneModal
          isDisabled={isDisabled}
          open={true}
          handleClose={handleDoneCloseModal}
        />
      ) : (
        <Dialog
          onClose={props.handleClose}
          aria-labelledby="customized-dialog-title"
          open={props.open}
          maxWidth="sm"
          //  fullWidth
        >
          <DialogTitle
            sx={{
              ml: 2,
              p: 2,
              fontWeight: 600,
              fontSize: 20,
              color: "#161616",
            }}
            id="customized-dialog-title"
          >
            Change Password
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#444444",
                    mb: 1,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Old Password
                </Typography>
                <OutlinedInput
                  placeholder="Type Here"
                  inputProps={{ id: "currentPassword", maxLength: 20 }}
                  value={inputData.currentPassword}
                  onChange={(e) => {
                    handleInput(e, "currentPassword");
                  }}
                  sx={{
                    borderRadius: 2,
                    width: "320px",
                    backgroundColor: "#FAFAFA",
                    "& .MuiOutlinedInput-input": {
                      borderRadius: 2,
                      border: "none",
                      outline: "none",
                      height: "15px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      border: "none",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#444444",
                    mb: 1,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  New Password
                </Typography>
                <OutlinedInput
                  placeholder="Type Here"
                  type={showPassword ? "text" : "password"}
                  inputProps={{
                    id: "newPassword",
                    minLength: 8,
                    maxLength: 12,
                  }}
                  value={inputData.newPassword}
                  onChange={(e) => {
                    handleInput(e, "newPassword");
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowPassword}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size="small"
                      >
                        <Box sx={{ fontSize: "4px" }}>
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </Box>
                      </IconButton>
                    </InputAdornment>
                  }
                  sx={{
                    borderRadius: 2,
                    width: "320px",
                    backgroundColor: "#FAFAFA",
                    "& .MuiOutlinedInput-input": {
                      borderRadius: 2,
                      border: "none",
                      outline: "none",
                      height: "15px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      border: "none",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                />
                <FormHelperText
                // sx={{
                //   height: 4,
                // }}
                >
                  {inputData.newPassword &&
                    validateNewPassword(inputData.newPassword)}
                </FormHelperText>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#444444",
                    mb: 1,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Confirm Password
                </Typography>
                <OutlinedInput
                  placeholder="Re-type new password"
                  type={showConfirmPassword ? "text" : "password"}
                  inputProps={{
                    id: "confirmPassword",
                    minLength: 8,
                    maxLength: 12,
                  }}
                  value={inputData.confirmPassword}
                  onChange={(e) => {
                    handleInput(e, "confirmPassword");
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleClickShowConfirmPassword}
                        // onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  sx={{
                    borderRadius: 2,
                    width: "320px",
                    backgroundColor: "#FAFAFA",
                    "& .MuiOutlinedInput-input": {
                      borderRadius: 2,
                      border: "none",
                      outline: "none",
                      height: "15px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      border: "none",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                />
                <FormHelperText
                  sx={{
                    height: 10,
                    visibility:
                      inputData?.confirmPassword?.length > 0 &&
                      inputData?.confirmPassword !== inputData?.newPassword
                        ? "visible"
                        : "hidden",
                  }}
                >
                  Passwords should match
                </FormHelperText>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid item>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#F98247",
                    borderRadius: "30px",
                    color: "white",
                    fontSize: 13,
                    fontWeight: 300,
                    padding: "12px 50px 12px 50px",
                    marginRight: "45px",
                    marginLeft: "7px",
                    "&:hover": { backgroundColor: "#F98247" },
                    maxWidth: "150px",
                  }}
                  onClick={changePassword}
                  disabled={
                    isDisabled ||
                    !inputData.currentPassword ||
                    !inputData.newPassword ||
                    !inputData.confirmPassword ||
                    validateNewPassword(inputData.newPassword) ||
                    inputData.confirmPassword !== inputData.newPassword
                  }
                >
                  Done
                </Button>
              </Grid>

              <Grid item>
                <Link
                  to={
                    props.doctor
                      ? "/doctor/settings"
                      : props.clinic
                      ? "/clinic/settings"
                      : "/settings"
                  }
                  onClick={() => {
                    if (isDisabled) return null;
                    props.handleClose();
                  }}
                  style={{
                    color: "#FF7676",
                    fontWeight: 600,
                    fontSize: 16,
                    cursor: "pointer",
                    textDecoration: "none",
                    marginLeft: "1em",
                  }}
                >
                  Cancel
                </Link>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
export default PasswordModal;
