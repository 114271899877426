import { Box, Grid, LinearProgress, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";

import api from "../../api";
import DateRangeCalendar from "../calendar/DateRangeCalendar";
import ConsultationModal from "../Modals/ConsultationModal";
import ConsultationHistoryDetailsCard from "./ConsultationHistoryDetailsCard";

const ConsultationHistory = () => {
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [consultations, setConsultations] = useState([]);
  const [showDetails, setShowDetails] = useState({});
  const setStartDate = (d) => {
    setSelectedStartDate(d);
  };
  const setEndDate = (d) => {
    setSelectedEndDate(d);
  };

  const getDoctorConsulationHistory = async () => {
    setIsLoading(true);
    try {
      let queryString =
        selectedStartDate && selectedEndDate
          ? `?startDate=${moment(
              new Date(selectedStartDate).toISOString()
            ).format("YYYY-MM-DD")}&endDate=${moment(
              new Date(selectedEndDate).toISOString()
            ).format("YYYY-MM-DD")}`
          : `?`;

      const { data } = await api.getDoctorConsulationHistory(queryString);
      setSelectedStartDate();
      setSelectedEndDate();
      setIsLoading(false);
      if (!data?.success) {
        return;
      }
      setConsultations(data?.data?.bookingHistoryData);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handleClose = () => {
    setShowDetails({});
  };

  useEffect(() => {
    if (selectedStartDate && selectedEndDate) {
      getDoctorConsulationHistory();
    }
  }, [selectedEndDate]);

  useEffect(() => {
    getDoctorConsulationHistory();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: {
            xs: "column",
            sm: "row",
          },
          maxWidth: 1365,
          gap: "10px",
          mt: 1,
        }}
        justifyContent="space-between"
      >
        <Typography variant="h3" sx={{ fontSize: "22px" }}>
          Order History
        </Typography>
        <DateRangeCalendar
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </Box>

      {isLoading && (
        <LinearProgress
          sx={{
            my: 1,
          }}
        />
      )}

      <Grid display={"flex"} flexWrap={"wrap"} gap={1} mt={1}>
        {consultations.length
          ? consultations.map((item, index) => (
              <ConsultationHistoryDetailsCard
                consultation={item}
                setShowDetails={setShowDetails}
                index={index}
              />
            ))
          : !isLoading && (
              <Typography width={"100%"} textAlign={"center"}>
                No records found.
              </Typography>
            )}
      </Grid>

      {showDetails.id && (
        <ConsultationModal
          open={showDetails}
          handleClose={handleClose}
          isHistory={true}
        />
      )}
    </>
  );
};

export default ConsultationHistory;
