import CloseIcon from "@mui/icons-material/Close";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

import api from "../../api";
import cancelIcon from "../../assets/images/cancelicon.png";
import doctoremail from "../../assets/images/doctoremail.png";
import doctorphone from "../../assets/images/doctorphone.png";
import doctorprofile from "../../assets/images/doctorprofile.png";
import experience from "../../assets/images/experience.png";
import fileimage from "../../assets/images/fileimage.png";
import sent from "../../assets/images/sent.png";
import starspecialties from "../../assets/images/starspecialties.png";
import DocumentViewer from "../../components/DocumentViewer/DocView";
import { styles } from "../../layouts/Styles";
import getExperience from "../../utils/getExperience";
import getStringInitials from "../../utils/getStringInitials";

const AdminDoctorsPending = ({
  doctors,
  total,
  currentPage,
  getDoctors,
  totalPages,
  page = "",
  setPage = null,
  setActiveUserID = null,
}) => {
  const [approveModal, setApproveModal] = React.useState(false);
  const [rejectModal, setRejectModal] = React.useState(false);
  const [submitReject, setsubmitReject] = React.useState(false);
  const [clinicOpen, setClinicOpen] = React.useState(false);
  const [rejection_reason, setReason] = React.useState("");
  const [selectedDoctor, setSelectedDoctor] = React.useState(null);

  const [limit, setLimit] = useState(10);

  // const [page, setPage] = useState(1);

  const {
    palette: { grey },
  } = useTheme();

  const classes = styles();

  const documentFileName = "certificate.pdf";

  const sampleDoctors = [
    {
      id: 1,
      doctorname: "John Doe",
      phonenumber: 251111129,
      email: "sample@test.com",
      experience: "2 Years",
      worktype: "Full-Time",
      doctortype: "MDS",
      status: "View",
    },
  ];

  const columnsdoctor = Object.keys(sampleDoctors[0]);

  useEffect(() => {
    getDoctors(page, limit);
  }, [page, limit]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setLimit(event.target.value);
  };

  const changeUserStatus = async (id, status, reason) => {
    const res = await api.changeUserStatus(status, id, reason);
    if (res.data.success) {
      setSelectedDoctor(null);
      if (!reason) {
        handleApproveModal();
      }
    }
  };
  const handleClinicOpen = (obj) => {
    setClinicOpen(true);
    setSelectedDoctor(obj);
  };
  const handleClinicClose = () => {
    setClinicOpen(false);
  };
  const handleCloseApprove = () => {
    setApproveModal(false);
  };
  const handleApproveModal = () => {
    setApproveModal(true);
    handleClinicClose();
  };
  const handleRejectModal = () => {
    setRejectModal(true);

    handleClinicClose();
  };
  const handleCloseReject = () => {
    setRejectModal(false);
    setReason("");
  };
  const handleOpensubmitRejectModal = async () => {
    await changeUserStatus(selectedDoctor.id, "REJECTED", rejection_reason);
    setsubmitReject(true);
    handleCloseReject();
  };
  const handleClosesubmitReject = () => {
    setsubmitReject(false);
  };

  const handleReason = (e) => {
    setReason(e.target.value);
  };
  let specialization = selectedDoctor?.userDetails?.specialistIn?.split(",");
  return (
    <Fragment>
      <Paper sx={{ p: 2, mt: 2, borderRadius: "12px" }}>
        <Typography
          variant="h3"
          sx={{ fontSize: "22px", fontWeight: 500, mb: 1 }}
        >
          Pending Approval&nbsp;
          <span style={{ fontSize: "22px", fontWeight: 600 }}>
            ({total})
          </span>
        </Typography>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columnsdoctor?.map((column) => (
                  <TableCell key={column} className={classes.tableCell}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {doctors?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.tableCellBody}>
                    {row?.id || "-"}
                  </TableCell>
                  <TableCell className={classes.tableCellBodyLink}>
                    <Link style={{textTransform: "capitalize"}} to={`#`} onClick={() => setActiveUserID(row?.id)}>
                      {row?.name?.toLowerCase() || "-"}
                    </Link>
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {row?.phoneNumber || "-"}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {row?.email || "-"}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {getExperience(row?.userDetails?.experienceInMonths)}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {row?.userDetails?.workType || "-"}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {row?.designation || "-"}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    <Button
                      onClick={() => {
                        handleClinicOpen(row);
                      }}
                      variant="contained"
                      size="small"
                      sx={{
                        backgroundColor: "#F98247",
                        borderRadius: 30,
                        width: "80px",
                        fontSize: 13,
                        "&:hover": { backgroundColor: "#F98247" },
                      }}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={total}
          rowsPerPage={limit}
          page={currentPage - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Dialog
        onClose={handleClinicClose}
        open={clinicOpen}
        aria-labelledby="customized-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          sx={{ m: 0, p: 2, fontWeight: 600, fontSize: 20, color: "#303030" }}
          id="customized-dialog-title"
        >
          Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClinicClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid container spacing={3} alignItems="center">
            <Grid item className={classes.imageContainer}>
              <img
                src={doctorprofile}
                style={{ width: "84px", height: "84px" }}
              />
              <Typography variant="h6" className={classes.textOverlay}>
                {getStringInitials(
                  selectedDoctor?.name?.[0].toUpperCase() || "-"
                )}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                sx={{ fontSize: "20px", fontWeight: "600", color: "#1A202C" }}
              >
                {selectedDoctor?.name?.[0].toUpperCase()}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: "40px" }}>
            <Grid item xs={3} sm={1} md={1} lg={1} xl={1} sx={{ mr: 0 }}>
              <img
                src={doctorphone}
                alt="Phone"
                style={{ width: 40, height: 40 }}
              />
            </Grid>
            <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "400", color: "#858585" }}
              >
                Phone Number
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "500", color: "#303030" }}
              >
                {selectedDoctor?.phoneNumber}
              </Typography>
            </Grid>

            <Grid
              item
              xs={3}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              sx={{ pl: "2px", mr: 0 }}
            >
              <img
                src={doctoremail}
                style={{ width: 40, height: 40 }}
                alt="Email"
              />
            </Grid>
            <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "400", color: "#858585" }}
              >
                Email Address
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "500", color: "#303030" }}
              >
                {selectedDoctor?.email}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: "30px" }}>
            <Grid item xs={3} sm={1} md={1} lg={1} xl={1} sx={{ mr: 0 }}>
              <img
                src={experience}
                alt="Exp"
                style={{ width: 40, height: 40 }}
              />
            </Grid>
            <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "400", color: "#858585" }}
              >
                Graduation Year
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "500", color: "#303030" }}
              >
                {selectedDoctor?.userDetails?.passOutYear}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              sx={{ pl: "2px", mr: 0 }}
            >
              <img
                src={experience}
                style={{ width: 40, height: 40 }}
                alt="Exp"
              />
            </Grid>
            <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "400", color: "#858585" }}
              >
                Work Type
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "500", color: "#303030" }}
              >
                {selectedDoctor?.userDetails?.workType}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              sx={{ pl: "2px", mr: 0 }}
            >
              <img
                src={experience}
                style={{ width: 40, height: 40 }}
                alt="Exp"
              />
            </Grid>
            <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "400", color: "#858585" }}
              >
                Experience
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "500", color: "#303030" }}
              >
                {selectedDoctor?.userDetails?.experience}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: "30px" }}>
            <Grid
              item
              xs={3}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              sx={{ pl: "2px", mr: 0 }}
            >
              <img
                src={starspecialties}
                style={{ width: 20, height: 20 }}
                alt="Specialities"
              />
            </Grid>
            <Grid
              item
              xs={9}
              sm={11}
              md={11}
              lg={11}
              xl={11}
              sx={{ ml: "-2em" }}
            >
              <Typography
                sx={{ color: "#858585", fontWeight: "700", fontSize: "16px" }}
              >
                Specialist In:{" "}
                {/* <span style={{ color: "#F98247", fontWeight: "600" }}>
                  {selectedDoctor?.userDetails?.specialistIn}
                </span> */}
                {specialization?.map((specialization, index) => (
                  <Chip
                    key={index}
                    label={specialization}
                    style={{ margin: "4px" }}
                  />
                ))}
              </Typography>
              <Grid
                display={"flex"}
                flexWrap={"wrap"}
                alignItems={"center"}
                gap={1}
                mt={1}
              >
                {selectedDoctor?.userDetails?.certificateUrl ? (
                  <Button
                    variant="outlined"
                    // onClick={handleOpen}
                    size="large"
                    sx={{
                      borderRadius: "25px",
                      border: "1px solid #EDEDED",
                      background: "white",
                      color: "black",
                      fontWeight: 600,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={fileimage}
                      style={{ width: 24, height: 24, marginRight: 4 }}
                    />
                    {documentFileName}
                  </Button>
                ) : (
                  <Typography
                    sx={{ color: "#858585", fontSize: "14px", fontWeight: 500 }}
                  >
                    N/A
                  </Typography>
                )}

                {selectedDoctor?.userDetails?.certificateUrl ? (
                  <Link
                    style={{
                      // backgroundColor: "#F98247",
                      borderRadius: "30px",
                      color: "#000",
                      border: "1px solid #EDEDED",
                      width: "60px",
                      height: "45px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    to={`${selectedDoctor?.userDetails?.certificateUrl || ""}`}
                    target="_blank"
                    download
                  >
                    <DownloadRoundedIcon />
                  </Link>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
          {/* <DocumentViewer file={documentFileUrl} fileName={documentFileName} /> */}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-start" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
                md: "row",
                lg: "row",
                xl: "row",
              },
              gap: "10px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#F98247",
                borderRadius: "30px",
                color: "white",
                fontSize: 16,
                fontWeight: 600,
                padding: "12px 50px 12px 50px",
                marginRight: "45px",
                marginLeft: "7px",
                "&:hover": { backgroundColor: "#F98247" },
                maxWidth: "150px",
              }}
              // onClick={() => handleApproveModal(id)}
              onClick={() => {
                changeUserStatus(selectedDoctor.id, "APPROVED");
              }}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#E91717",
                borderRadius: "30px",
                color: "white",
                fontSize: 16,
                fontWeight: 600,
                padding: "12px 50px 12px 50px",
                "&:hover": { backgroundColor: "#E91717" },
                maxWidth: "150px",
                marginLeft: "7px",
              }}
              // type="submit"
              onClick={handleRejectModal}
            >
              Reject
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={handleCloseApprove}
        aria-labelledby="customized-dialog-title"
        open={approveModal}
        fullWidth={true}
        // maxWidth="xs"
        sx={{
          "& .MuiDialog-paper": {
            // width: "672px",
            padding: "0px",
            borderRadius: "15px",
            height: "400px",
            width: "450px",
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseApprove}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className={classes.dialogContent}>
          <img
            src={sent}
            alt="Sent"
            style={{ width: "134px", height: "134px" }}
            className={classes.image}
          />
          <Typography
            align="center"
            sx={{
              fontSize: "30px",
              fontWeight: "600",
              color: "#F98247",
              fontFamily: "Poppins",
              marginTop: "5px",
              padding: "0px",
            }}
          >
            Success
          </Typography>
          <Typography
            align="center"
            sx={{
              // marginTop: "10px",
              fontSize: "24px",
              fontWeight: "400",
              color: "black",
              fontFamily: "Poppins",
              margin: "0px",
              padding: "0px",
            }}
          >
            The doctor has been approved Successfully.
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={handleCloseReject}
        aria-labelledby="customized-dialog-title"
        open={rejectModal}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, fontWeight: 600, fontSize: 20, color: "#303030" }}
          id="customized-dialog-title"
        >
          Enter the reason
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseReject}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xl={12} md={12} xs={12} sm={12}>
              <Typography variant="caption1">Description</Typography>
            </Grid>

            <Grid item xl={12} md={12} xs={12} sm={12}>
              <TextField
                id="outlined-multiline-flexible"
                label="Type Here"
                multiline
                rows={4}
                variant="outlined"
                className={classes.bgColorTextField}
                fullWidth
                value={rejection_reason}
                onChange={handleReason}
                inputProps={{ maxLength: 200 }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-start" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#F98247",
              borderRadius: "30px",
              color: "white",
              fontSize: 13,
              fontWeight: 300,
              padding: "12px 50px 12px 50px",
              marginTop: "38px",
              marginLeft: "8px",
              "&:hover": { backgroundColor: "#F98247" },
            }}
            onClick={handleOpensubmitRejectModal}
            disabled={!rejection_reason}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={handleClosesubmitReject}
        aria-labelledby="customized-dialog-title"
        open={submitReject}
        fullWidth={true}
        // maxWidth="xs"
        sx={{
          "& .MuiDialog-paper": {
            // width: "672px",
            padding: "0px",
            borderRadius: "15px",
            height: "300px",
            width: "450px",
            margin: "0px",
          },
          "& .MuiDialogContent-root": {
            margin: "0px",
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClosesubmitReject}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{
            height: "350px !important",
            mt: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={cancelIcon}
            alt="Cancelled"
            style={{ width: "134px", height: "134px" }}
            className={classes.image}
          />
          <Typography
            variant="h5"
            align="center"
            style={{ color: "red", fontSize: "40px" }}
          >
            Cancelled
          </Typography>
          <Typography
            align="center"
            style={{
              // marginTop: "32px",
              fontSize: "20px",
              fontWeight: "400",
              color: "black",
            }}
          >
            The Doctor has been Cancelled.
          </Typography>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default AdminDoctorsPending;
