import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, Chip, Divider, Grid, MenuItem, Popover, Typography } from "@mui/material";
import { useState } from "react";

import api from "../../api";
import getExperience from "../../utils/getExperience";
import { getPayType } from "../../utils/getPayType";
import getStringInitials from "../../utils/getStringInitials";

const ProposalDetailsCard = ({ data = {}, index = "" }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleCloseProposal = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = async (option, item) => {
    try {
      setAnchorEl(null);
      await UpdateProposalStatus(item.id, option);
    } catch (error) {
      console.log(error);
    }
  };

  const UpdateProposalStatus = async (id, status) => {
    try {
      await api.updateProposalByProposalId({ id, status });
    } catch (err) {
      console.log(err);
    }
  };

  const handleClickProposal = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      {data?.id && (
        <Grid
          key={index}
          sx={{
            py: 1.5,
            px: 1.5,
            display: "flex",
            gap: 1,
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#fff",
            borderRadius: "8px",
          }}
        >
          <Grid
            display={"flex"}
            alignItems={"center"}
            gap={1}
            flexWrap={"wrap"}
          >
            {data?.postDetails?.imageUrl ? (
              <Box display={"flex"} alignItems={"center"}>
                <img
                  src={data?.postDetails?.imageUrl}
                  style={{
                    height: "46px",
                    width: "46px",
                    borderRadius: "5px",
                    objectFit: "cover",
                  }}
                />
              </Box>
            ) : (
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{
                  height: 46,
                  width: 46,
                  backgroundColor: "#004E89",
                  borderRadius: "5px",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "18px",
                    fontWeight: 600,
                    fontFamily: "Poppins",
                  }}
                >
                  {getStringInitials(data?.doctor?.name)}
                </Typography>
              </Box>
            )}

            <Box>
              <Box>
                <Typography
                  noWrap
                  sx={{
                    color: "#004E89",
                    fontSize: "18px",
                    fontWeight: 600,
                    fontFamily: "Poppins",
                    width: "130px",
                  }}
                  title={data?.doctor?.name || "-"}
                >
                  {data?.doctor?.name || "-"}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  {data?.doctor?.phoneNumber || ""}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                },
                gap: 0.5,
                position: "relative",
              }}
            >
              <Box>
                <Chip
                  sx={{
                    borderRadius: "3.43px",
                    border: "none",
                    backgroundColor: "rgba(248, 248, 248, 1)",
                    // minWidth: "140px",
                    width: "140px",
                  }}
                  title={
                    data?.postDetails?.requestType?.split(",").join(", ") || "-"
                  }
                  label={
                    <Typography
                      noWrap
                      sx={{
                        color: "#F98247",
                        fontSize: "12px",
                        fontWeight: "600",
                        fontFamily: "Poppins",
                      }}
                    >
                      {data?.postDetails?.requestType?.split(",").join(", ") ||
                        "-"}
                    </Typography>
                  }
                />
              </Box>
              <Box>
                <Chip
                  sx={{
                    borderRadius: "3.43px",
                    border: "none",
                    backgroundColor: "rgba(248, 248, 248, 1)",
                    width: "140px",
                  }}
                  label={
                    <Typography
                      noWrap
                      sx={{
                        color: "#F98247",
                        fontSize: "12px",
                        fontWeight: "600",
                        fontFamily: "Poppins",
                      }}
                      title={getExperience(
                        data?.doctorDetails?.experienceInMonths
                      )}
                    >
                      {getExperience(data?.doctorDetails?.experienceInMonths)}
                    </Typography>
                  }
                />
              </Box>
            </Box>
          </Grid>
          <Divider
            color={"#F98247"}
            width={"0.47px"}
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ mx: 1 }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <Box sx={{ position: "relative" }}>
              <Chip
                label={
                  data?.status === "PENDING"
                    ? "Review"
                    : data?.status
                }
                onClick={handleClickProposal}
                startIcon={<ArrowDropDownIcon />}
                sx={{
                  borderRadius: "3.43px",
                  border: "1px solid #F98247",
                  backgroundColor: "#FFFFFF",
                  width: "100px",
                  cursor: "pointer",
                  color: "#F98247",
                  fontWeight: "600",
                  fontSize: "14px",
                }}
              />
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleCloseProposal}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Box sx={{ padding: 0.5 }}>
                  <MenuItem
                    onClick={() => handleOptionClick("SHORTLISTED", data)}
                  >
                    Shortlist
                  </MenuItem>
                  <MenuItem onClick={() => handleOptionClick("ACCEPTED", data)}>
                    Hire
                  </MenuItem>
                  <MenuItem onClick={() => handleOptionClick("REJECTED", data)}>
                    Reject
                  </MenuItem>
                </Box>
              </Popover>
            </Box>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default ProposalDetailsCard;
