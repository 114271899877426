import { Button, Grid } from "@mui/material";
import React, { useState } from "react";

import ClinicConsultationHistory from "../../components/clinichiredoctor/ClinicConsultationHistory";
import ClinicHireDoctor from "../../components/clinichiredoctor/ClinicHireDoctor";
import Posts from "../../components/clinichiredoctor/Posts";
import Proposals from "../../components/clinichiredoctor/Proposals";

const HireDoctor = () => {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <>
      <Grid container gap={1}>
        <Grid item>
          <Button
            onClick={() => setTabIndex(0)}
            variant={tabIndex == 0 ? "contained" : "outlined"}
            size="medium"
            sx={{ borderRadius: 46 }}
          >
            {" "}
            Hire Doctor{" "}
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => setTabIndex(1)}
            variant={tabIndex == 1 ? "contained" : "outlined"}
            size="medium"
            sx={{ borderRadius: 46 }}
          >
            {" "}
            Posts{" "}
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => setTabIndex(2)}
            variant={tabIndex == 2 ? "contained" : "outlined"}
            size="medium"
            sx={{ borderRadius: 46 }}
          >
            {" "}
            Proposals{" "}
          </Button>
        </Grid>
      </Grid>

      {tabIndex === 0 && <ClinicHireDoctor />}
      {tabIndex === 1 && <Posts setTabIndex={setTabIndex} />}
      {tabIndex === 2 && <Proposals />}
      {tabIndex === 3 && <ClinicConsultationHistory />}
    </>
  );
};

export default HireDoctor;
