import CloseIcon from "@mui/icons-material/Close";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import api from "../../api/index";
import cancelIcon from "../../assets/images/cancelicon.png";
import doctoremail from "../../assets/images/doctoremail.png";
import doctorphone from "../../assets/images/doctorphone.png";
import doctorprofile from "../../assets/images/doctorprofile.png";
import experience from "../../assets/images/experience.png";
import fileimage from "../../assets/images/fileimage.png";
import ownericon from "../../assets/images/ownericon.png";
import sent from "../../assets/images/sent.png";
import starspecialties from "../../assets/images/starspecialties.png";
import { styles } from "../../layouts/Styles";
import getExperience from "../../utils/getExperience";
import getStringInitials from "../../utils/getStringInitials";
import WorkExpDetailsCard from "../DoctorDashboard/WorkExpDetailsCard";

const PendingDoctor = (props) => {
  const { page, setPage } = props;
  const classes = styles();
  const [rejection_reason, setReason] = React.useState("");
  const [selectedDoctor, setSelectedDoctor] = React.useState(null);
  const [approveModal, setApproveModal] = React.useState(false);
  const [rejectModal, setRejectModal] = React.useState(false);
  const [submitReject, setsubmitReject] = React.useState(false);
  const [clinicOpen, setClinicOpen] = React.useState(false);
  
  const colTitles = [
    "id",
    "name",
    "contact",
    "email",
    "experience",
    "work type",
    "doctor type",
    "profile Status",
    "status",
  ];

  const changeUserStatus = async (id, status, reason) => {
    const res = await api.changeUserStatus(status, id, reason);
    if (res.data.success) {
      props.getPendingDoctors(page, limit);
      setSelectedDoctor(null);
      if (!reason) {
        handleApproveModal();
      }
    }
  };
  const getColorStyle = (isApproved) => {
    isApproved = isApproved;

    switch (isApproved) {
      case 0:
        return "pending";
      case 1:
        return "credited";
      default:
        return "tableCellBody";
    }
  };
  const handleClinicOpen = (obj) => {
    setClinicOpen(true);
    setSelectedDoctor(obj);
  };
  const handleClinicClose = () => {
    setClinicOpen(false);
  };
  const handleCloseApprove = () => {
    setApproveModal(false);
  };
  const handleApproveModal = () => {
    setApproveModal(true);
    handleClinicClose();
  };
  const handleRejectModal = () => {
    setRejectModal(true);

    handleClinicClose();
  };
  const handleCloseReject = () => {
    setRejectModal(false);
    setReason("");
  };
  const handleOpensubmitRejectModal = async () => {
    await changeUserStatus(selectedDoctor.id, "REJECTED", rejection_reason);
    setsubmitReject(true);
    handleCloseReject();
  };
  const handleClosesubmitReject = () => {
    setsubmitReject(false);
  };

  const handleReason = (e) => {
    setReason(e.target.value);
  };
  let specialization = selectedDoctor?.userDetails?.specialistIn?.split(",");
  const [limit, setLimit] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setLimit(event.target.value);
  };
  useEffect(() => {
    props.getPendingDoctors(page, limit);
  }, [page, limit]);

  return (
    <>
      <Paper sx={{ p: 2, mt: 1.5, borderRadius: "8px" }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {colTitles.map((column) => (
                  <TableCell key={column} className={classes.tableCell}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props?.pendingDoctors?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.tableCellBody}>
                    {row?.id || "-"}
                  </TableCell>
                  <TableCell className={classes.tableCellBodyLink}>
                    <Link
                      to={`#`}
                      onClick={() => props.setActiveUserID(row?.id)}
                      style={{ textTransform: "capitalize" }}
                    >
                      {" "}
                      {row?.name?.toLowerCase() || "-"}
                    </Link>
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {row?.phoneNumber || "-"}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {row?.email || "-"}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {getExperience(row?.userDetails?.experienceInMonths)}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {row?.userDetails?.workType || "-"}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {row?.designation || "-"}
                  </TableCell>
                  <TableCell
                    className={classes[getColorStyle(row["isApproved"])]}
                  >
                    {row?.isApproved == "1" ? "Completed" : "Pending"}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: "13px !important",
                    }}
                    className={classes.tableCellBody}
                  >
                    <Button
                      onClick={() => {
                        handleClinicOpen(row);
                      }}
                      variant="contained"
                      size="small"
                      sx={{
                        fontSize: 13,
                        backgroundColor: "#F98247",
                        borderRadius: 30,
                        px: 2,
                        "&:hover": { backgroundColor: "#F98247" },
                      }}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={props?.total}
          rowsPerPage={limit}
          page={props?.currentPage - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Dialog
        onClose={handleClinicClose}
        open={clinicOpen}
        maxWidth="md"
        fullWidth
        style={{ overflow: "auto" }}
      >
        <DialogTitle
          sx={{ m: 0, p: 1, fontWeight: 600, fontSize: 20, color: "#303030" }}
          id="customized-dialog-title"
        >
          Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClinicClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{ p: 1, display: "flex", flexDirection: "column", gap: 2 }}
        >
          <Grid display={"flex"} alignItems="center" gap={1}>
            {selectedDoctor?.imageUrl ? (
              <Box>
                <img
                  src={selectedDoctor?.imageUrl}
                  style={{ width: 60, height: 60 }}
                  alt="_img"
                />
              </Box>
            ) : (
              <Box
                sx={{
                  width: 60,
                  height: 60,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#004E89",
                  borderRadius: "12px",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: 600,
                    fontSize: 22,
                  }}
                >
                  {getStringInitials(selectedDoctor?.name || "-")}
                </Typography>
              </Box>
            )}
            <Grid item>
              <Typography
                sx={{ fontSize: "20px", fontWeight: "600", color: "#1A202C" }}
              >
                {selectedDoctor?.name}
              </Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid
              xs={12}
              sm={4}
              gap={0.5}
              display={"flex"}
              alignItems={"center"}
              item
            >
              <img
                src={ownericon}
                style={{ width: 30, height: 30 }}
                alt="Email"
              />
              <Grid>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "400", color: "#858585" }}
                >
                  Registration No.
                </Typography>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "500", color: "#303030" }}
                >
                  {selectedDoctor?.stateCouncilNo || "-"}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              xs={12}
              sm={4}
              gap={0.5}
              item
              display={"flex"}
              alignItems={"center"}
            >
              <img
                src={experience}
                style={{ width: 30, height: 30 }}
                alt="Exp"
              />
              <Grid>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "400", color: "#858585" }}
                >
                  Dental Council
                </Typography>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "500", color: "#303030" }}
                >
                  {selectedDoctor?.state || "-"}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              xs={12}
              sm={4}
              gap={0.5}
              display={"flex"}
              alignItems={"center"}
            >
              <img
                src={experience}
                alt="Exp"
                style={{ width: 30, height: 30 }}
              />
              <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "400", color: "#858585" }}
                >
                  Designation
                </Typography>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "500", color: "#303030" }}
                >
                  {selectedDoctor?.designation || "-"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container>
            <Grid
              xs={12}
              sm={4}
              item
              display={"flex"}
              alignItems={"center"}
              gap={0.5}
            >
              <img
                src={experience}
                alt="Exp"
                style={{ width: 30, height: 30 }}
              />
              <Grid>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "400", color: "#858585" }}
                >
                  Graduation Year
                </Typography>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "500", color: "#303030" }}
                >
                  {selectedDoctor?.userDetails?.passOutYear || "-"}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              display={"flex"}
              alignItems={"center"}
              gap={0.5}
            >
              <img
                src={experience}
                style={{ width: 30, height: 30 }}
                alt="Exp"
              />
              <Grid>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "400", color: "#858585" }}
                >
                  College Name
                </Typography>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "500", color: "#303030" }}
                >
                  {selectedDoctor?.userDetails?.collegeName || "-"}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              display={"flex"}
              alignItems={"center"}
              gap={0.5}
            >
              <img
                src={experience}
                style={{ width: 30, height: 30 }}
                alt="Exp"
              />
              <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "400", color: "#858585" }}
                >
                  Experience
                </Typography>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "500", color: "#303030" }}
                >
                  {selectedDoctor?.userDetails?.experience || "-"} Years
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid container>
            <Grid
              item
              xs={12}
              sm={4}
              display={"flex"}
              alignItems={"center"}
              gap={0.5}
            >
              <img
                src={doctorphone}
                alt="Phone"
                style={{ width: 30, height: 30 }}
              />
              <Grid>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "400", color: "#858585" }}
                >
                  Mobile Number
                </Typography>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "500", color: "#303030" }}
                >
                  {selectedDoctor?.phoneNumber || "-"}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              display={"flex"}
              alignItems={"center"}
              gap={0.5}
            >
              <img
                src={doctoremail}
                style={{ width: 30, height: 30 }}
                alt="Email"
              />
              <Grid>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "400", color: "#858585" }}
                >
                  Email
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#303030",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {selectedDoctor?.email || "-"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid>
            <Box display={"flex"} alignItems={"center"} gap={0.5}>
              <img
                src={starspecialties}
                style={{ width: 14, height: 14 }}
                alt="Specialities"
              />
              <Typography sx={{ color: "#858585", fontWeight: "500" }}>
                Preferences:{" "}
                {selectedDoctor?.userDetails?.workType
                  ?.split(",")
                  ?.map((item, index) => (
                    <Chip key={index} label={item} style={{ margin: "4px" }} />
                  ))}
              </Typography>
            </Box>

            <Box display={"flex"} alignItems={"center"} gap={0.5}>
              <img
                src={starspecialties}
                style={{ width: 14, height: 14 }}
                alt="Specialities"
              />

              <Typography sx={{ color: "#858585", fontWeight: "500" }}>
                Skills:{" "}
                {specialization?.map((item, index) => (
                  <Chip key={index} label={item} style={{ margin: "4px" }} />
                ))}
              </Typography>
            </Box>

            <Grid
              display={"flex"}
              flexWrap={"wrap"}
              alignItems={"center"}
              gap={1}
            >
              {/* {selectedDoctor?.userDetails?.workType ? (
                <Button
                  variant="outlined"
                  // onClick={handleOpen}
                  size="large"
                  sx={{
                    borderRadius: "25px",
                    border: "1px solid #EDEDED",
                    background: "white",
                    color: "black",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={fileimage}
                    style={{ width: 24, height: 24, marginRight: 4 }}
                  />
                  {documentFileName}
                </Button>
              ) : (
                <Typography
                  sx={{ color: "#858585", fontSize: "14px", fontWeight: 500 }}
                >
                  No license available
                </Typography>
              )} */}

              {selectedDoctor?.userDetails?.certificateUrl ? (
                <Link
                  style={{
                    // backgroundColor: "#F98247",
                    borderRadius: "30px",
                    color: "#000",
                    border: "1px solid #EDEDED",
                    width: "60px",
                    height: "45px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  to={`${selectedDoctor?.userDetails?.certificateUrl || ""}`}
                  target="_blank"
                  download
                >
                  <DownloadRoundedIcon />
                </Link>
              ) : (
                <Typography
                  sx={{ color: "#858585", fontSize: "14px", fontWeight: 500 }}
                >
                  No license available
                </Typography>
              )}
            </Grid>
          </Grid>

          <Typography
            sx={{
              fontSize: 14,
              fontWeight: "600",
              color: "#1A202C",
            }}
          >
            Work Experience
          </Typography>
          {selectedDoctor?.clinicalExperiences?.map((item, index) => (
            <WorkExpDetailsCard data={item} index={index} />
          ))}
          {selectedDoctor?.clinicalExperiences.length <= 0 && (
            <Typography
              sx={{
                color: "#858585",
                fontSize: "12px",
                fontWeight: 500,
                marginTop: "10px",
              }}
            >
              No Work Experience
            </Typography>
          )}

          {/* <DocumentViewer file={documentFileUrl} fileName={documentFileName} /> */}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-start" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
                md: "row",
                lg: "row",
                xl: "row",
              },
              gap: "10px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#F98247",
                borderRadius: "30px",
                color: "white",
                fontSize: 13,
                fontWeight: 300,
                padding: "12px 50px 12px 50px",
                marginRight: "45px",
                marginLeft: "7px",
                "&:hover": { backgroundColor: "#F98247" },
                maxWidth: "150px",
              }}
              onClick={() => {
                changeUserStatus(selectedDoctor.id, "APPROVED");
              }}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#E91717",
                borderRadius: "30px",
                color: "white",
                fontSize: 13,
                fontWeight: 300,
                padding: "12px 50px 12px 50px",
                "&:hover": { backgroundColor: "#E91717" },
                maxWidth: "150px",
                marginLeft: "7px",
              }}
              // type="submit"
              onClick={handleRejectModal}
            >
              Reject
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={handleCloseApprove}
        aria-labelledby="customized-dialog-title"
        open={approveModal}
        fullWidth={true}
        // maxWidth="xs"
        sx={{
          "& .MuiDialog-paper": {
            // width: "672px",
            padding: "0px",
            borderRadius: "15px",
            height: "400px",
            width: "450px",
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseApprove}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className={classes.dialogContent}>
          <img
            src={sent}
            alt="Sent"
            style={{ width: "134px", height: "134px" }}
            className={classes.image}
          />
          <Typography
            align="center"
            // className={classes.text}
            sx={{
              fontSize: "30px",
              fontWeight: "600",
              color: "#F98247",
              fontFamily: "Poppins",
              marginTop: "5px",
              padding: "0px",
            }}
          >
            Success
          </Typography>
          <Typography
            align="center"
            // className={classes.text}
            sx={{
              // marginTop: "10px",
              fontSize: "24px",
              fontWeight: "400",
              color: "black",
              fontFamily: "Poppins",
              margin: "0px",
              padding: "0px",
            }}
          >
            The Doctor has been added Successfully.
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={handleCloseReject}
        aria-labelledby="customized-dialog-title"
        open={rejectModal}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, fontWeight: 600, fontSize: 20, color: "#303030" }}
          id="customized-dialog-title"
        >
          Enter the reason
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseReject}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xl={12} md={12} xs={12} sm={12}>
              <Typography variant="caption1">Description</Typography>
            </Grid>

            <Grid item xl={12} md={12} xs={12} sm={12}>
              <TextField
                id="outlined-multiline-flexible"
                label="Type Here"
                multiline
                rows={4}
                variant="outlined"
                className={classes.bgColorTextField}
                fullWidth
                value={rejection_reason}
                onChange={handleReason}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-start" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#F98247",
              borderRadius: "30px",
              color: "white",
              fontSize: 13,
              fontWeight: 300,
              padding: "12px 50px 12px 50px",
              marginTop: "38px",
              marginLeft: "8px",
              "&:hover": { backgroundColor: "#F98247" },
            }}
            onClick={handleOpensubmitRejectModal}
            disabled={!rejection_reason}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={handleClosesubmitReject}
        aria-labelledby="customized-dialog-title"
        open={submitReject}
        fullWidth={true}
        // maxWidth="xs"
        sx={{
          "& .MuiDialog-paper": {
            // width: "672px",
            padding: "0px",
            borderRadius: "15px",
            height: "300px",
            width: "450px",
            margin: "0px",
          },
          "& .MuiDialogContent-root": {
            margin: "0px",
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClosesubmitReject}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{
            height: "350px !important",
            mt: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={cancelIcon}
            alt="Cancelled"
            style={{ width: "134px", height: "134px" }}
            className={classes.image}
          />
          <Typography
            variant="h5"
            align="center"
            style={{ color: "red", fontSize: "40px" }}
          >
            Cancelled
          </Typography>
          <Typography
            align="center"
            style={{
              // marginTop: "32px",
              fontSize: "20px",
              fontWeight: "400",
              color: "black",
            }}
          >
            The Doctor has been Cancelled.
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PendingDoctor;
