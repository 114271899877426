import CloseIcon from "@mui/icons-material/Close";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { Button, Chip, Dialog, DialogContent, DialogTitle, FormControl, Grid, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

import api from "../../api/index";
import doctoremail from "../../assets/images/doctoremail.png";
import doctorphone from "../../assets/images/doctorphone.png";
import doctorprofile from "../../assets/images/doctorprofile.png";
import experience from "../../assets/images/experience.png";
import fileimage from "../../assets/images/fileimage.png";
import starspecialties from "../../assets/images/starspecialties.png";
import DocumentViewer from "../../components/DocumentViewer/DocView";
import { styles } from "../../layouts/Styles";
import getExperience from "../../utils/getExperience";
import getStringInitials from "../../utils/getStringInitials";

const AdminDoctorsRejected = (props) => {
  const {
    palette: { grey },
  } = useTheme();

  const classes = styles();

  const [clinicOpen, setClinicOpen] = React.useState(false);
  const [selectedDoctor, setSelectedDoctor] = React.useState(null);
  const [approveModal, setApproveModal] = React.useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isDisabled, setDisabled] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setLimit(event.target.value);
  };
  const handleApproveModal = () => {
    setApproveModal(true);
    handleClinicClose();
  };

  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
  };
  const handleRejectModal = () => {
    setRejectModal(true);

    handleClinicClose();
  };

  const changeUserStatus = async (id, status, reason) => {
    const res = await api.changeUserStatus(status, id, reason);
    if (res.data.success) {
      setSelectedDoctor(null);
      props.getDoctors(page, limit);
      if (!reason) {
        handleApproveModal();
      }
    }
  };

  useEffect(() => {
    props.getDoctors(page, limit);
  }, [page, limit]);

  const handleClinicOpen = (obj) => {
    console.log(obj);
    setSelectedDoctor(obj);
    setClinicOpen(true);
  };
  const handleClinicClose = () => {
    setClinicOpen(false);
  };

  const documentFileUrl =
    "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf";
  const documentFileName = "certificate.pdf";

  const sampleDoctors = [
    {
      id: 1,
      doctorname: "John Doe",
      phonenumber: 251111129,
      email: "sample@test.com",
      experience: "2 Years",
      worktype: "Full-Time",
      doctortype: "MDS",
      status: "View",
    },
  ];
  const columnsdoctor = Object.keys(sampleDoctors[0]);
  let specialization = selectedDoctor?.userDetails?.specialistIn?.split(",");
  return (
    <Fragment>
      <Paper sx={{ p: 2, mt: 2, borderRadius: '12px' }}>
        <Typography
          variant="h3"
          sx={{ fontSize: "22px", fontWeight: 500, mb: 1 }}
        >
          Rejected Doctors&nbsp;
          <span style={{ fontSize: "22px", fontWeight: 600 }}>
            ({props?.total})
          </span>
        </Typography>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columnsdoctor.map((column) => (
                  <TableCell key={column} className={classes.tableCell}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props?.doctors?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.tableCellBody}>
                    {row?.id || "-"}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {/* <Link to={`/doctor/doctorDetails/${row?.id}`}> */}
                    {row?.name || "-"}
                    {/* </Link> */}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {row?.phoneNumber || "-"}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {row?.email || "-"}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {/* {row?.userDetails?.experience || "-"} */}
                    {getExperience(row?.userDetails?.experience)}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {row?.userDetails?.workType || "-"}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {row?.designation || "-"}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    <Button
                      onClick={() => {
                        handleClinicOpen(row);
                      }}
                      variant="contained"
                      size="small"
                      sx={{
                        backgroundColor: "#F98247",
                        borderRadius: 30,
                        fontSize: 13,
                        width: "90px",
                        "&:hover": { backgroundColor: "#F98247" },
                      }}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={props?.total}
          rowsPerPage={limit}
          page={props?.currentPage - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Dialog
        onClose={handleClinicClose}
        aria-labelledby="customized-dialog-title"
        open={clinicOpen}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          sx={{ m: 0, p: 2, fontWeight: 600, fontSize: 20, color: "#303030" }}
          id="customized-dialog-title"
        >
          Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClinicClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <Grid container spacing={1} alignItems="center">
                <Grid item className={classes.imageContainer}>
                  <img
                    src={doctorprofile}
                    style={{ width: "84px", height: "84px" }}
                  />
                  <Typography variant="h6" className={classes.textOverlay}>
                    {getStringInitials(
                      selectedDoctor?.name?.[0].toUpperCase() || "-"
                    )}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      fontWeight: "600",
                      color: "#1A202C",
                    }}
                  >
                    {selectedDoctor?.name?.[0].toUpperCase()}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <FormControl sx={{ minWidth: 220 }}>
                <Select
                  disabled={isDisabled}
                  value={selectedOption}
                  // onChange={handleOptionChange}
                  sx={{
                    borderRadius: 2,
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-input": {
                      borderRadius: 2,
                      border: "none",
                      outline: "none",
                      height: "15px",
                      marginTop: "-0.2em",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      border: "none",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  //   inputProps={{ id: "doctor_type" }}
                  //   value={inputData.doctor_type}
                  //   onChange={(e) => { handleInput(e, "doctor_type") }}
                  onFocus={(e) => e.currentTarget.classList.add("focused")}
                  onBlur={(e) => e.currentTarget.classList.remove("focused")}
                  displayEmpty
                  onOpen={handleDropdownOpen}
                  onClose={handleDropdownClose}
                >
                  {/* <MenuItem value="" style={{ display: "none" }}>
                      <em
                        style={{
                          color: "#C2C2C2",
                          fontStyle: "normal",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        Select Status
                      </em>
                    </MenuItem> */}
                  <MenuItem value="">
                    <Typography style={{ color: "red", fontWeight: "400" }}>
                      Rejected
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    value="approved"
                    onClick={() => {
                      changeUserStatus(selectedDoctor?.id, "APPROVED");
                    }}
                  >
                    <Typography style={{ color: "green", fontWeight: "400" }}>
                      Approve
                    </Typography>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {/* <Grid item>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "500", color: "red" }}
              >
                Rejected
              </Typography>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  changeUserStatus(selectedDoctor.id, "APPROVED");
                }}
                variant="contained"
                size="medium"
                sx={{
                  color: "#00AB11",
                  backgroundColor: "#D9F3DC",
                  borderRadius: 30,
                  fontSize: 14,
                  fontWeight: 500,
                  padding: "10px 60px 10px 60px",
                  "&:hover": { backgroundColor: "#D9F3DC" },
                }}
              >
                Approve
              </Button>
            </Grid> */}
          </Grid>
          <Grid container sx={{ mt: "40px" }}>
            <Grid item xs={3} sm={1} md={1} lg={1} xl={1} sx={{ mr: 0 }}>
              <img
                src={doctorphone}
                alt="Phone"
                style={{ width: 40, height: 40 }}
              />
            </Grid>
            <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "400", color: "#858585" }}
              >
                Phone Number
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "500", color: "#303030" }}
              >
                {selectedDoctor?.phoneNumber}
              </Typography>
            </Grid>

            <Grid
              item
              xs={3}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              sx={{ pl: "2px", mr: 0 }}
            >
              <img
                src={doctoremail}
                style={{ width: 40, height: 40 }}
                alt="Email"
              />
            </Grid>
            <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "400", color: "#858585" }}
              >
                Email Address
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "500", color: "#303030" }}
              >
                {selectedDoctor?.email}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: "30px" }}>
            <Grid item xs={3} sm={1} md={1} lg={1} xl={1} sx={{ mr: 0 }}>
              <img
                src={experience}
                alt="Exp"
                style={{ width: 40, height: 40 }}
              />
            </Grid>
            <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "400", color: "#858585" }}
              >
                Graduation Year
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "500", color: "#303030" }}
              >
                {selectedDoctor?.userDetails?.passOutYear}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              sx={{ pl: "2px", mr: 0 }}
            >
              <img
                src={experience}
                style={{ width: 40, height: 40 }}
                alt="Exp"
              />
            </Grid>
            <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "400", color: "#858585" }}
              >
                Work Type
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "500", color: "#303030" }}
              >
                {selectedDoctor?.userDetails?.workType}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              sx={{ pl: "2px", mr: 0 }}
            >
              <img
                src={experience}
                style={{ width: 40, height: 40 }}
                alt="Exp"
              />
            </Grid>
            <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "400", color: "#858585" }}
              >
                Experience
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "500", color: "#303030" }}
              >
                {selectedDoctor?.userDetails?.experience} Years
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: "30px" }}>
            <Grid
              item
              xs={3}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              sx={{ pl: "2px", mr: 0 }}
            >
              <img
                src={starspecialties}
                style={{ width: 20, height: 20 }}
                alt="Specialities"
              />
            </Grid>
            <Grid
              item
              xs={9}
              sm={11}
              md={11}
              lg={11}
              xl={11}
              sx={{ ml: "-2em" }}
            >
              <Typography sx={{ color: "#858585", fontWeight: "500" }}>
                Specialist In:{" "}
                {/* <span style={{ color: "#F98247", fontWeight: "500" }}>
                {}
                </span> */}
                {specialization?.map((specialization, index) => (
                  <Chip
                    key={index}
                    label={specialization}
                    style={{ margin: "4px" }}
                  />
                ))}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography
                variant="h3"
                sx={{ fontSize: "20px", fontWeight: 600, mt: "2em" }}
              >
                Reason
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 400,
                  color: "rgba(118, 121, 128, 1)",
                  maxHeight: 300,
                  border: "1px solid #000",
                  borderRadius: "8px",
                  p: 1,
                  overflowY: "auto",
                  wordWrap: "break-word",
                }}
              >
                {selectedDoctor?.reason ||
                  " Lorem ipsum dolor sit amet consectetur. Amet arcu aliquam at egestas facilisis id vitae ullamcorper. Habitant susci Case Description dolor sit amet consectetur"}
              </Typography>
            </Grid>
          </Grid>
          {/* <DocumentViewer file={documentFileUrl} fileName={documentFileName} /> */}
          <Grid
            display={"flex"}
            flexWrap={"wrap"}
            alignItems={"center"}
            gap={1}
            mt={1}
          >
            {selectedDoctor?.userDetails?.certificateUrl ? (
              <Button
                variant="outlined"
                // onClick={handleOpen}
                size="large"
                sx={{
                  borderRadius: "25px",
                  border: "1px solid #EDEDED",
                  background: "white",
                  color: "black",
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={fileimage}
                  style={{ width: 24, height: 24, marginRight: 4 }}
                />
                {documentFileName}
              </Button>
            ) : (
              <Typography
                sx={{ color: "#858585", fontSize: "14px", fontWeight: 500 }}
              >
                No certificate available.
              </Typography>
            )}

            {selectedDoctor?.userDetails?.certificateUrl ? (
              <Link
                style={{
                  // backgroundColor: "#F98247",
                  borderRadius: "30px",
                  color: "#000",
                  border: "1px solid #EDEDED",
                  width: "60px",
                  height: "45px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                to={`${selectedDoctor?.userDetails?.certificateUrl || ""}`}
                target="_blank"
                download
              >
                <DownloadRoundedIcon />
              </Link>
            ) : (
              ""
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default AdminDoctorsRejected;
