import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Button, Chip, FormControl, Grid, IconButton, ListItemText, MenuItem, OutlinedInput, Paper, Select, TextField, Typography } from "@mui/material";
import { FormControlLabel, InputAdornment } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import { styled } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { toast } from "react-toastify";

import api from "../../api";
import rocket from "../../assets/images/rocket.png";
import SentSuccessfully from "../Modals/SentSuccessfully";
import RecentPostDetailsCard from "./RecentpostDetailsCars";

import "quill/dist/quill.snow.css";

const ClinicHireDoctor = () => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [personName, setPersonName] = React.useState([]);
  const [holidays, setHolidays] = React.useState([]);
  const [skills, setSkills] = React.useState([]);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [recentPostsData, setRecentPostsData] = useState([]);
  const [openPositions, setOpenPositions] = useState(0);
  const [workingHours, setWorkingHours] = useState(0);
  const [minSalary, setMinSalary] = useState("");
  const [maxSalary, setMaxSalary] = useState("");
  const [experience, setExperience] = useState("");
  const [selectedRequestType, setSelectedRequestType] = useState("Full-Time");
  const [date, setDate] = useState(new Date());
  const [jobTitle, setJobTitle] = useState("");
  const [locationName, setLocationName] = useState("");
  const [locationUrl, setLocationUrl] = useState("");
  const [caseDescription, setCaseDescription] = useState("");
  const [isFlexible, setIsFlexible] = useState(false);
  const [hourlyBasis, setHourlyBasis] = useState("Hourly");
  const [dateForConsultation, setDateForConsultation] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [skillNames, setSkillNames] = useState([]);
  const names = ["MDS", "BDS"];
  const holidayNames = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const resetState = () => {
    setModalOpen(false);
    setPersonName([]);
    setHolidays([]);
    setSkills([]);
    setStartTime("");
    setEndTime("");
    setRecentPostsData([]);
    setOpenPositions(0);
    setWorkingHours(0);
    setMinSalary("");
    setMaxSalary("");
    setExperience("");
    setSelectedRequestType("Full-Time");
    setDate(new Date());
    setJobTitle("");
    setLocationName("");
    setLocationUrl("");
    setCaseDescription("");
    setIsFlexible(false);
    setHourlyBasis("Hourly");
    setDateForConsultation(null);
    setLoading(false);
    setSkillNames([]);
  };
  const workOptions = ["Full-Time", "Part-Time", "Consultation"];
  const hourly = ["Hourly", "Fixed"];
  var modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] },
      ],
      [
        {
          color: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
            "custom-color",
          ],
        },
      ],
    ],
  };

  var formats = [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "color",
    "bullet",
    "indent",
    "link",
    "image",
    "align",
    "size",
  ];
  const handleExperienceChange = (event) => {
    const value = event.target.value;
    const isValid = /^\d*$/.test(value);
    if (isValid) {
      setExperience(value);
    }
  };

  const handleMinSalaryChange = (event) => {
    const value = event.target.value;
    const isValid = /^\d*$/.test(value);
    if (isValid) {
      setMinSalary(value);
    }
  };

  const handleMaxSalaryChange = (event) => {
    const value = event.target.value;
    const isValid = /^\d*$/.test(value);
    if (isValid) {
      setMaxSalary(value);
    }
  };

  const handleIncreasePositions = () => {
    setOpenPositions(openPositions + 1);
  };

  const handleDecreasePositions = () => {
    if (openPositions > 0) {
      setOpenPositions(openPositions - 1);
    }
  };

  const handleIncreaseWorkingHours = () => {
    setWorkingHours(workingHours + 1);
  };

  const handleDecreaseWorkingHours = () => {
    if (workingHours > 0) {
      setWorkingHours(workingHours - 1);
    }
  };

  const handleRequestTypeChange = (event) => {
    const value = event.target.value;
    if (value == "Consultation") {
      var obj = [...personName];
      obj.push("MDS");
      setPersonName(obj);
    }
    setSelectedRequestType(value);
  };

  const handleHourlyChanged = (event) => {
    const value = event.target.value;
    setHourlyBasis(value);
  };

  const handleProcedureContentChange = (content) => {
    setCaseDescription(content);
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const handleJobTitle = (event) => {
    setJobTitle(event.target.value);
  };

  const handleChangeHoliday = (event) => {
    const {
      target: { value },
    } = event;
    setHolidays(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangeSkills = (event) => {
    const {
      target: { value },
    } = event;
    setSkills(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    if (personName.length == 1 && personName[0] == "BDS") {
      setSkillNames([
        "RESTORATIONS",
        "SCALING",
        "RCT",
        "EXTRACTIONS",
        "IMPACTIONS",
        "CD/RPD/FPD",
      ]);
    } else if (personName.length == 1 && personName[0] == "MDS") {
      setSkillNames([
        "ENDODONTICS",
        "PROSTHODONTICS",
        "OMFS",
        "PEDODONTICS",
        "PERIODONTICS",
        "ORTHODONTICS",
        "OMR",
        "ORAL PATHOLOGY",
        "PUBLIC HEALTH DENTISTRY",
      ]);
    } else {
      setSkillNames([
        "ENDODONTICS",
        "PROSTHODONTICS",
        "OMFS",
        "PEDODONTICS",
        "PERIODONTICS",
        "ORTHODONTICS",
        "OMR",
        "ORAL PATHOLOGY",
        "PUBLIC HEALTH DENTISTRY",
        "RESTORATIONS",
        "SCALING",
        "RCT",
        "EXTRACTIONS",
        "IMPACTIONS",
        "CD/RPD/FPD",
      ]);
    }
  }, [personName]);

  const handleDateChange = (date) => {
    setDateForConsultation(date);
  };

  const handleOpenSubmit = () => {
    resetState();
    setModalOpen(true);
  };
  const handleCloseSubmit = () => {
    setModalOpen(false);
  };

  const handleStartTimeChange = (event) => {
    const newStartTime = event.target.value;
    setStartTime(newStartTime);
  };
  const handleEndTimeChange = (event) => {
    const newEndTime = event.target.value;
    // Perform validation if needed
    setEndTime(newEndTime);
  };
  const handleFocus = () => {
    if (startTime === "") {
      setStartTime("12:00 AM");
    }
  };

  const handleLocationUrl = (event) => {
    setLocationUrl(event.target.value);
  };
  const handleLocationName = (event) => {
    setLocationName(event.target.value);
  };

  useEffect(() => {
    setPersonName(["MDS"]);
  }, [selectedRequestType]);

  const checkFormValidity = (data) => {
    let msg;
    if (Number(data.maximumSalary) < Number(data.minimumSalary)) {
      msg = "Maximum salary cannot be less than minimum salary.";
    }
    if (selectedRequestType == "Full-Time") {
      if (Number(data.minimumSalary) < 15000) {
        msg = "Minimum salary must be 15000 or higher.";
      }
    }
    let values = Object.values(data);
    let isEmpty;
    values?.map((item) => {
      if (item === "" || item === null) {
        isEmpty = true;
      }
    });
    if (isEmpty) {
      msg = "All fields are required.";
    }
    if (msg) {
      toast.error(msg);
      return false;
    }
    return true;
  };

  const hireDoctor = async () => {
    try {
      let payload =
        selectedRequestType !== "Consultation"
          ? {
              date: date.toISOString(),
              skills: skills.join(","),
              eligibility: personName.join(","),
              number_of_openings: openPositions,
              workingHour: workingHours,
              workingHourIsFlexible: isFlexible,
              workingHourTimeIn: isFlexible ? null : startTime,
              workingHourTimeOut: isFlexible ? null : endTime,
              salaryPerMonth: 0,
              holidays: holidays.join(","),
              jobDescription: caseDescription,
              requestType: selectedRequestType,
              minimumSalary: minSalary,
              maximumSalary: maxSalary,
              locationUrl: locationUrl,
              locationName: locationName,
              jobTitle: jobTitle,
              experience: experience,
            }
          : {
              requestType: selectedRequestType,
              minimumSalary: minSalary,
              maximumSalary: maxSalary,
              locationUrl: locationUrl,
              locationName: locationName,
              caseDescription: caseDescription,
              caseName: jobTitle,
              startTime: startTime,
              endTime: endTime,
              eligibility: personName.join(","),
              skills: skills.join(","),
              hourly: hourlyBasis ? true : false,
              date: dateForConsultation.toISOString(),
            };

      if (isFlexible == true && selectedRequestType !== "Consultation") {
        delete payload.workingHourTimeIn;
        delete payload.workingHourTimeOut;
      }
      setLoading(true);
      let isValid = checkFormValidity(payload);
      if (!isValid) {
        return;
      }
      const { data } = await api.hireDoctor(payload);
      setLoading(false);
      if (!data?.success) {
        return;
      }
      handleOpenSubmit();
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const RecentPosts = async () => {
    try {
      const { data } = await api.getRecentHirePosts();
      if (!data?.success) {
        toast.error(data?.message || "Something went wrong.");
        return;
      }
      setRecentPostsData(data?.data?.latestTenPosts);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    RecentPosts();
  }, []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: "auto",
        overflowY: "scroll",
        padding: "2px",
      },
    },
    autoFocus: false,
  };

  const CustomizedStaticDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {},
  }));
  const DownArrowIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path d="M7 10l5 5 5-5z" />
    </svg>
  );

  const UpArrowIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path d="M7 14l5-5 5 5z" />
    </svg>
  );
  useEffect(() => {
    if (
      startTime &&
      workingHours.toString() &&
      selectedRequestType != "Consultation"
    ) {
      let eTime = moment(startTime, "HH:mm")
        .add(workingHours, "hours")
        .format("HH:mm");
      setEndTime(eTime);
    }
  }, [startTime, workingHours]);

  return (
    <>
      {modalOpen ? (
        <SentSuccessfully
          modal={modalOpen}
          handleCloseApprove={handleCloseSubmit}
          value={"post"}
          post={true}
        />
      ) : null}
      <Paper sx={{ p: "2em", borderRadius: 1.5, mt: 2 }}>
        <Grid container rowSpacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={2} justifyContent="space-between">
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Box display="flex" flexDirection="column">
                  <Typography
                    sx={{
                      color: "rgba(48, 48, 48, 1)",
                      fontSize: "16px",
                      fontWeight: 400,
                      mb: 1,
                    }}
                  >
                    Request Type
                  </Typography>
                  <FormControl>
                    <Select
                      displayEmpty
                      input={<OutlinedInput />}
                      value={selectedRequestType}
                      onChange={handleRequestTypeChange}
                      renderValue={(selected) => {
                        if (!selected) {
                          return (
                            <em
                              style={{
                                color: "#C2C2C2",
                                fontStyle: "normal",
                                fontSize: 14,
                                fontWeight: 500,
                              }}
                            >
                              Select Request Type
                            </em>
                          );
                        }
                        return selected;
                      }}
                    >
                      {workOptions.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Radio
                            checked={selectedRequestType === name}
                            sx={{
                              color: "#F98247",
                              "&.Mui-checked": { color: "#F98247" },
                            }}
                          />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Box display="flex" flexDirection="column">
                  <Typography
                    sx={{
                      color: "rgba(48, 48, 48, 1)",
                      fontSize: "16px",
                      fontWeight: 400,
                      mb: 1,
                    }}
                  >
                    Eligibility
                  </Typography>
                  <FormControl>
                    <Select
                      placeholder="Eligibility"
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={personName}
                      onChange={handleChange}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                      disabled={selectedRequestType == "Consultation"}
                    >
                      {names.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox
                            sx={{
                              color: "#F98247",
                              "&.Mui-checked": { color: "#F98247" },
                            }}
                            checked={personName.indexOf(name) > -1}
                          />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>

              <Grid item xs={12} md={4} mt={"2px"}>
                <Box display="flex" flexDirection="column">
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#303030",
                      mb: 1,
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                  >
                    {selectedRequestType === "Consultation"
                      ? "Case Title"
                      : "Job Title"}
                  </Typography>
                  <OutlinedInput
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        borderRadius: 2,
                        border: "none",
                        outline: "none",
                        height: "17px",
                      },
                    }}
                    placeholder={
                      selectedRequestType === "Consultation"
                        ? "Case Title"
                        : "Job Title"
                    }
                    value={jobTitle}
                    inputProps={{ id: "price" }}
                    onChange={handleJobTitle}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 0 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <Box display="flex" flexDirection="column">
                  <Typography
                    sx={{
                      color: "rgba(48, 48, 48, 1)",
                      fontSize: "16px",
                      fontWeight: 400,
                      mb: 1,
                    }}
                  >
                    Skills/Expertise
                  </Typography>
                  <FormControl>
                    <Select
                      displayEmpty
                      multiple
                      value={skills}
                      onChange={handleChangeSkills}
                      input={<OutlinedInput label="Tag" />}
                      renderValue={(selected) =>
                        selected.length ? (
                          selected.join(", ")
                        ) : (
                          <em
                            style={{
                              color: "#BEC5CD",
                              fontStyle: "normal",
                              fontSize: 14,
                              fontWeight: 400,
                            }}
                          >
                            Select Skills/Expertise
                          </em>
                        )
                      }
                      MenuProps={MenuProps}
                    >
                      {skillNames.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox
                            sx={{
                              color: "#F98247",
                              "&.Mui-checked": { color: "#F98247" },
                            }}
                            checked={skills.indexOf(name) > -1}
                          />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={12} md={4} mt={"2px"}>
                <Box display="flex" flexDirection="column">
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#303030",
                      mb: 1,
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                  >
                    {selectedRequestType === "Consultation"
                      ? "Pay Range"
                      : "Monthly Salary"}{" "}
                    <span
                      style={{
                        height: "20px",
                        color: "rgba(166, 162, 162, 1)",
                        fontWeight: 500,
                        fontSize: "12px",
                      }}
                    >
                      {" "}
                      {selectedRequestType === "Full-Time" &&
                      personName.includes("BDS")
                        ? "(Min 15,000 or above)"
                        : ""}
                    </span>
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1  
                    }}
                  >
                    <OutlinedInput
                      sx={{
                        "& .MuiOutlinedInput-input": {
                          borderRadius: 2,
                          border: "none",
                          outline: "none",
                          height: "17px",
                          width:
                            selectedRequestType === "Consultation"
                              ? "90px"
                              : "100%",
                        },
                      }}
                      placeholder="Minimum"
                      value={minSalary}
                      onChange={handleMinSalaryChange}
                      inputProps={{ type: "text" }}
                    />

                    <OutlinedInput
                      sx={{
                        "& .MuiOutlinedInput-input": {
                          borderRadius: 2,
                          border: "none",
                          outline: "none",
                          height: "17px",
                          width:
                            selectedRequestType === "Consultation"
                              ? "90px"
                              : "100%",
                        },
                      }}
                      placeholder="Maximum"
                      value={maxSalary}
                      onChange={handleMaxSalaryChange}
                      inputProps={{ type: "text" }}
                    />
                    {selectedRequestType === "Consultation" && (
                      <Box display="flex" flexDirection="column">
                        <FormControl>
                          <Select
                            displayEmpty
                            input={<OutlinedInput />}
                            value={hourlyBasis}
                            onChange={handleHourlyChanged}
                            renderValue={(selected) => {
                              if (!selected) {
                                return (
                                  <em
                                    style={{
                                      color: "#C2C2C2",
                                      fontStyle: "normal",
                                      fontSize: 14,
                                      fontWeight: 500,
                                    }}
                                  >
                                    Select Request Type
                                  </em>
                                );
                              }
                              return selected;
                            }}
                          >
                            {hourly.map((name) => (
                              <MenuItem key={name} value={name}>
                                <Radio
                                  checked={selectedRequestType === name}
                                  sx={{
                                    color: "#F98247",
                                    "&.Mui-checked": { color: "#F98247" },
                                  }}
                                />
                                <ListItemText primary={name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={4} mt={"2px"}>
                {selectedRequestType !== "Consultation" ? (
                  <Box display="flex" flexDirection="column">
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#303030",
                        mb: 1,
                        fontSize: 16,
                        fontWeight: 400,
                      }}
                    >
                      Experience In Years
                    </Typography>
                    <OutlinedInput
                      sx={{
                        "& .MuiOutlinedInput-input": {
                          borderRadius: 2,
                          border: "none",
                          outline: "none",
                          height: "17px",
                        },
                      }}
                      placeholder="Experience"
                      value={experience}
                      onChange={handleExperienceChange}
                      inputProps={{ id: "price", type: "text" }}
                    />
                  </Box>
                ) : (
                  <Box display="flex" flexDirection="column">
                    <Typography
                      sx={{
                        color: "rgba(48, 48, 48, 1)",
                        fontSize: "16px",
                        fontWeight: 400,
                        mb: 1,
                      }}
                    >
                      Select Date
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <CustomizedStaticDatePicker
                        inputProps={{ id: "selectDate" }}
                        value={dateForConsultation}
                        onChange={handleDateChange}
                        label="Select Date"
                        showDaysOutsideCurrentMonth={true}
                        disablePast
                        views={["day"]}
                        format="DD/MM/YYYY"
                        dayOfWeekFormatter={(day) => `${day}`}
                        slots={{
                          openPickerIcon: DownArrowIcon,
                          closePickerIcon: UpArrowIcon,
                        }}
                        slotProps={{
                          textField: { size: "small" },
                          switchViewButton: { sx: { display: "none" } },
                          calendarHeader: {
                            sx: {
                              position: "relative",
                              "& .MuiPickersArrowSwitcher-root": {
                                width: 0,
                              },
                              "& .MuiIconButton-edgeEnd": {
                                position: "absolute",
                                left: 0,
                                top: 0,
                                bottom: 0,
                              },
                              "& .MuiIconButton-edgeStart": {
                                position: "absolute",
                                right: 0,
                                top: 0,
                                bottom: 0,
                              },
                            },
                          },
                        }}
                        sx={{
                          "& .MuiInputBase-input": {
                            height: "8px",
                            padding: "30px 14px 11.5px !important",
                            borderColor: "blue !important",
                            marginTop: "5px",
                          },
                          "& .MuiInputLabel-root": {
                            color: "#C2C2C2 !important ",
                            fontStyle: "normal",
                            fontSize: 14,
                            fontWeight: 500,
                          },

                          "& .MuiOutlinedInput-root": {
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#C2C2C2 !important",
                            },
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                )}
              </Grid>

              {selectedRequestType !== "Consultation" && (
                <>
                  <Grid
                    sx={{ paddingLeft: "20px" }}
                    mt={"32px"}
                    xs={12}
                    sm={12}
                    md={2}
                  >
                    <Typography
                      sx={{
                        color: "rgba(48, 48, 48, 1)",
                        fontSize: "16px",
                        fontWeight: 400,
                        mb: 1,
                      }}
                    >
                      Open Positions
                    </Typography>
                    <TextField
                      type="number"
                      value={openPositions}
                      sx={{
                        "& .MuiInputBase-input": {
                          textAlign: "center",
                          height: 21,
                        },
                      }}
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <RemoveIcon onClick={handleDecreasePositions} />
                            </IconButton>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <AddIcon onClick={handleIncreasePositions} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid
                    sx={{ paddingLeft: "20px" }}
                    mt={"32px"}
                    xs={12}
                    sm={12}
                    md={2}
                  >
                    <Typography
                      sx={{
                        color: "rgba(48, 48, 48, 1)",
                        fontSize: "16px",
                        fontWeight: 400,
                        mb: 1,
                      }}
                    >
                      Working Hours
                    </Typography>
                    <TextField
                      type="number"
                      value={workingHours}
                      sx={{
                        "& .MuiInputBase-input": {
                          textAlign: "center",
                          height: 21,
                        },
                      }}
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <RemoveIcon
                                onClick={handleDecreaseWorkingHours}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton>
                              <AddIcon onClick={handleIncreaseWorkingHours} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </>
              )}
              <Grid
                sx={{ paddingLeft: "20px" }}
                mt={selectedRequestType !== "Consultation" ? "32px" : "15px"}
                xs={12}
                sm={12}
                md={2}
              >
                <Typography
                  sx={{
                    color: "rgba(48, 48, 48, 1)",
                    fontSize: "16px",
                    fontWeight: 400,
                    mb: 1,
                  }}
                >
                  {selectedRequestType === "Consultation"
                    ? "Select Time"
                    : "Working Timings"}
                </Typography>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <TextField
                    type="time"
                    value={startTime}
                    onChange={handleStartTimeChange}
                    onFocus={handleFocus}
                    inputProps={{
                      step: 300,
                    }}
                    disabled={
                      selectedRequestType !== "Consultation" ? isFlexible : null
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        outline: "none",
                        height: "23px",
                        width: "100vw",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {},
                    }}
                  />
                </div>
              </Grid>

              <Grid
                mt={selectedRequestType !== "Consultation" ? "32px" : "37px"}
                sx={{ paddingLeft: "20px" }}
                xs={12}
                sm={12}
                md={2}
              >
                {selectedRequestType !== "Consultation" && (
                  <FormControlLabel
                    sx={{ display: "flex", justifyContent: "flex-end" }}
                    control={
                      <Checkbox
                        size="small"
                        sx={{
                          padding: "0px",
                          color: "#F98247",
                          "&.Mui-checked": { color: "#F98247" },
                        }}
                        value={isFlexible}
                        onClick={() => {
                          setIsFlexible(!isFlexible);
                        }}
                      />
                    }
                    label="Flexible"
                  />
                )}
                <div
                  style={{
                    display: "flex",
                    marginTop: "10px",
                  }}
                >
                  <TextField
                    type="time"
                    value={endTime}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleEndTimeChange}
                    disabled={selectedRequestType != "Consultation"}
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        outline: "none",
                        height: "23px",
                        width: "100vw",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {},
                    }}
                  />
                </div>
              </Grid>

              {selectedRequestType !== "Consultation" && (
                <Grid
                  mt={"16px"}
                  sx={{ paddingLeft: "20px" }}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={4}
                >
                  <Box display="flex" flexDirection="column">
                    <Typography
                      sx={{
                        color: "rgba(48, 48, 48, 1)",
                        fontSize: "16px",
                        fontWeight: 400,
                        mb: 1,
                      }}
                    >
                      Holidays
                    </Typography>
                    <FormControl>
                      <Select
                        displayEmpty
                        placeholder="Eligibility"
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={holidays}
                        onChange={handleChangeHoliday}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) =>
                          selected.length ? (
                            selected.join(", ")
                          ) : (
                            <em
                              style={{
                                color: "#BEC5CD",
                                fontStyle: "normal",
                                fontSize: 14,
                                fontWeight: 400,
                              }}
                            >
                              Select Holidays
                            </em>
                          )
                        }
                        MenuProps={MenuProps}
                      >
                        {holidayNames.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              sx={{
                                color: "#F98247",
                                "&.Mui-checked": { color: "#F98247" },
                              }}
                              checked={holidays.indexOf(name) > -1}
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              )}

              <Grid item xs={12} md={4} mt={"2px"}>
                <Box display="flex" flexDirection="column">
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#303030",
                      mb: 1,
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                  >
                    Location URL
                  </Typography>
                  <OutlinedInput
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        borderRadius: 2,
                        border: "none",
                        outline: "none",
                        height: "17px",
                      },
                    }}
                    placeholder="Location URL"
                    inputProps={{ id: "price" }}
                    value={locationUrl}
                    onChange={handleLocationUrl}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={4} mt={"2px"}>
                <Box display="flex" flexDirection="column">
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#303030",
                      mb: 1,
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                  >
                    Location Name
                  </Typography>
                  <OutlinedInput
                    sx={{
                      "& .MuiOutlinedInput-input": {
                        borderRadius: 2,
                        border: "none",
                        outline: "none",
                        height: "17px",
                      },
                    }}
                    value={locationName}
                    placeholder="Location Name"
                    inputProps={{ id: "price" }}
                    onChange={handleLocationName}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={12} mt={"2px"}>
                <div style={{ display: "grid" }}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#303030",
                      mb: 1,
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                  >
                    {selectedRequestType === "Consultation"
                      ? "Case Description"
                      : "Job Description"}
                  </Typography>
                  <ReactQuill
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    placeholder="Write your description ...."
                    value={caseDescription}
                    onChange={handleProcedureContentChange}
                    style={{ height: "220px", borderRadius: "20px" }}
                  ></ReactQuill>
                </div>
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={hireDoctor}
                  sx={{
                    fontSize: "16px",
                    width: "220px",
                    height: "55px",
                    fontWeight: 600,
                    backgroundColor: "#E78756",
                    borderRadius: "14px",
                    padding: "12px 46px 12px 56px",
                    "&:hover": { backgroundColor: "#E78756" },
                    mt: "2em",
                  }}
                >
                  <IconButton sx={{ ml: "-2em", mr: "1em" }}>
                    <img
                      src={rocket}
                      alt="rocket"
                      style={{ width: "40px", height: "40px" }}
                    />
                  </IconButton>
                  Create Post
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <Typography variant="h3" sx={{ fontSize: "22px", mt: "1em", mb: "1em" }}>
        Recent Post
      </Typography>
      <Grid sx={{ display: "flex", flexWrap: "wrap" }} gap={1}>
        {recentPostsData.length
          ? recentPostsData.map((item, index) => (
              <RecentPostDetailsCard data={item} index={index} />
            ))
          : "No records found."}
      </Grid>
    </>
  );
};

export default ClinicHireDoctor;
