import { ChevronLeftRounded, ChevronRightRounded } from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  Container,
  IconButton,
  Typography,
} from "@mui/material";
import {
  addDays,
  addWeeks,
  format,
  isSameDay,
  lastDayOfWeek,
  startOfWeek,
  subWeeks,
} from "date-fns";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";

const Calendar = ({
  onSelectDate = null,
  setFromDate = null,
  setToDate = null,
}) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  const handleDateClick = (day) => {
    if (!selectedStartDate) {
      setSelectedStartDate(day);
      setFromDate(day);

      setSelectedEndDate(null);
      setToDate(null);
    } else if (!selectedEndDate) {
      if (day >= selectedStartDate) {
        setSelectedEndDate(day);
        setToDate(day);

        onSelectDate({ startDate: selectedStartDate, endDate: day });
      } else {
        setSelectedEndDate(selectedStartDate);
        setToDate(selectedStartDate);

        setSelectedStartDate(day);
        setFromDate(day);
      }
    } else {
      setSelectedStartDate(day);
      setFromDate(day);

      setSelectedEndDate(null);
      setToDate(null);
    }
  };

  const changeWeekHandle = (btnType) => {
    if (btnType === "prev") {
      setCurrentMonth(subWeeks(currentMonth, 1));
    }
    if (btnType === "next") {
      setCurrentMonth(addWeeks(currentMonth, 1));
    }
  };

  const renderCells = () => {
    const startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });
    const endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 1 });
    const dateFormat = "yyyy-MM-dd'T'HH:mm:ss'Z'";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        const isSelected =
          isSameDay(selectedStartDate, day) || isSameDay(selectedEndDate, day);
        const isInRange =
          selectedStartDate &&
          selectedEndDate &&
          day >= selectedStartDate &&
          day <= selectedEndDate;
        days.push(
          <ButtonBase
            key={day}
            onClick={() => handleDateClick(cloneDay)}
            sx={{
              cursor: "pointer",
              backgroundColor: isSelected
                ? "rgba(0, 78, 137, 1)"
                : isInRange
                ? "#ADD8E6"
                : "transparent",
              p: 0.8,
              borderRadius: 1,
              mt: 1,
              mb: 1,
              width: 60,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "5px",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontSize: 12,
                fontWeight: 500,
                letterSpacing: 1,
                color: isSelected ? "#FFFFFF" : isInRange ? "#000000" : "gray",
                lineHeight: "normal",
              }}
            >
              {moment(formattedDate).format("ddd")}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: 12,
                fontWeight: 600,
                letterSpacing: 1,
                color: isSelected
                  ? "#FFFFFF"
                  : isInRange
                  ? "#000000"
                  : "#000000",
                lineHeight: "normal",
              }}
            >
              {moment(formattedDate).format("Do")}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: 12,
                fontWeight: 500,
                letterSpacing: 1,
                color: isSelected ? "#FFFFFF" : isInRange ? "#000000" : "gray",
                lineHeight: "normal",
              }}
            >
              {moment(formattedDate).format("MMM")}
            </Typography>
          </ButtonBase>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div
          key={day}
          style={{ display: "flex", width: "100%", overflowX: "scroll" }}
        >
          {days}
        </div>
      );
      days = [];
    }
    return rows;
  };

  return (
    <Fragment>
      <Container
        sx={{
          padding: "0 !important",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            // width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            disableRipple
            disableFocusRipple
            disableTouchRipple
            onClick={() => {
              changeWeekHandle("prev");
            }}
            sx={{
              borderRadius: 0.5,
              width: 20,
              color:
                new Date() < startOfWeek(currentMonth, { weekStartsOn: 1 })
                  ? "#000000"
                  : "grey",
            }}
          >
            <ChevronLeftRounded />
          </IconButton>
          {renderCells()}
          <IconButton
            disableRipple
            disableFocusRipple
            disableTouchRipple
            disabled={false}
            onClick={() => {
              changeWeekHandle("next");
            }}
            sx={{ borderRadius: 0.5, width: 20, color: "#000000" }}
          >
            <ChevronRightRounded />
          </IconButton>
        </Box>
      </Container>
    </Fragment>
  );
};

Calendar.defaultProps = {
  onSelectDate: () => {},
};

export default Calendar;
