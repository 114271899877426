import { HttpService } from "./http.service";

const getDashboardData = async () => {
  return await HttpService.get(`/api/v1/admin/dashboard/overviews`);
};

const getTotalPendingCountOfDoctorsAndClinics = async () => {
  return await HttpService.get(
    `/api/v1/admin/users/pending/count/doctor-clinic`
  );
};

const createDoctor = async (data) => {
  return await HttpService.post(`/api/v1/user`, data);
};
const createClinic = async (data) => {
  return await HttpService.post(`/api/v1/user`, data);
};
const getUsersByRoleAndStatus = async (_role, status, page, limit) => {
  return await HttpService.get(
    `/api/v1/admin/user-by-role-and-status?_role=${_role}&status=${status}&page=${page}&limit=${limit}`
  );
};
const changeUserStatus = async (status, id, reason) => {
  return await HttpService.put(`/api/v1/change-status/user/${id}`, {
    status,
    reason,
  });
};
const getProfile = async () => {
  return await HttpService.get(`/api/v1/settings/users-details`);
};
const updateProfile = async (details) => {
  return await HttpService.put(`/api/v1/settings/edit-users-details`, details);
};
const getPendingDoctors = async ({ startDate, endDate, page, limit, profileStatus }) => {
  if (
    startDate == null ||
    startDate == undefined ||
    endDate == null ||
    endDate == undefined
  ) {
    return await HttpService.get(
      `/api/v1/admin/users/doctors/status-pending?page=${page}&limit=${limit}&profileStatus=${profileStatus}`
    );
  } else {
    return await HttpService.get(
      `/api/v1/admin/users/doctors/status-pending?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}&profileStatus=${profileStatus}`
    );
  }
};
const getPendingClinics = async ({ startDate, endDate, page, limit, profileStatus }) => {
  if (startDate == null || endDate == null) {
    return await HttpService.get(
      `/api/v1/admin/users/clinics/status-pending?page=${page}&limit=${limit}&profileStatus=${profileStatus}`
    );
  } else {
    return await HttpService.get(
      `/api/v1/admin/users/clinics/status-pending?startDate=${startDate}&endDate=${endDate}&page=${page}&limit=${limit}&profileStatus=${profileStatus}`
    );
  }
};
const adminLogin = async (formData) => {
  return await HttpService.post(`/api/v1/admin/login`, {
    email: formData.email,
    password: formData.password,
  });
};
// Doctor's Module API's

const getDoctorConsulationHistory = async (args) => {
  console.log(args);
  return await HttpService.get(
    `/api/v1/doctor/proposal/consulation-history${args}`
  );
};

const getDoctorOverview = async () => {
  return await HttpService.get(`/api/v1/doctor/dashboard/overviews`);
};

const getOverviewClinicsAdmin = async () => {
  return await HttpService.get(`/api/v1/admin/clinic/overviews`);
};

const getOverviewDoctorsAdmin = async () => {
  return await HttpService.get(`/api/v1/admin/clinic/overviews`);
};

const updateBookingStatus = async (id, status) => {
  return await HttpService.put(`/api/v1/booking/status/${id}`, {
    status,
  });
};

const updateProposalStatus = async (id, status) => {
  return await HttpService.put(
    `/api/v1/clinic/update-proposal-by-proposal/${id}?status=${status}`
  );
};

const deleteAvailabilty = async (ids) => {
  return await HttpService.put(`/api/v1/clinic/cabin/delete-availability`, {
    ids,
  });
};

const UpdateCabin = async (id) => {
  return await HttpService.put(`/api/v1/clinic/edit-cabin-by-id/${id}`);
};

const updateProposalByProposalId = async ({ id, status }) => {
  return await HttpService.put(
    `/api/v1/clinic/update-proposal-by-proposal/${id}`,
    {
      status,
    }
  );
};

const getAllClinicPosts = async () => {
  return await HttpService.get(`/api/v1/clinic/posts`);
};

const getAllClinicPostsById = async (id) => {
  return await HttpService.get(`/api/v1/clinic/proposal-by-post/${id}`);
};

const getUpcomingBookingByRoleAndDate = async (date) => {
  return await HttpService.get(
    `/api/v1/proposal/booking/upcomingRole-date?date=${date}`
  );
};

const getProposalsBySlots = async (status) => {
  return await HttpService.get(
    `/api/v1/doctor/today-upcoming-proposals?slots=${status}`
  );
};

const createProposal = async (_clinic) => {
  return await HttpService.post(`/api/v1/doctor/sent-proposal`, _clinic);
};
const getClinicDashboardData = async () => {
  return await HttpService.get(`/api/v1/clinic/dashboard/overviews`);
};
const getProposalsByStatus = async (status) => {
  return await HttpService.get(
    `/api/v1/clinic/proposal-by-status?status=${status}`
  );
};

const getProposalsByDoctor = async () => {
  return await HttpService.get(`/api/v1/doctor/pending-proposal`);
};
const getClinicBookingByDate = async (args) => {
  return await HttpService.get(
    `/api/v1/proposal/booking/upcoming?_role=2&${args}`
  );
};

const getDoctorBookingByDate = async ({ startDate, endDate }) => {
  return await HttpService.get(
    `/api/v1/proposal/booking/upcoming?_role=3&startDate=${
      startDate || null
    }&endDate=${endDate || null}`
  );
};

const searchProposalsOfClinic = async (query) => {
  const { status, startDate, endDate, searchQuery, type } = query;
  return await HttpService.get(
    `/api/v1/clinic/proposals/search?status=${status}&startDate=${startDate}&endDate=${endDate}&searchInput=${searchQuery}&type=${type}`
  );
};

const hireDoctor = async (args) => {
  return await HttpService.post(`/api/v1/clinic/post`, args);
};

const getHirePosts = async ({
  status = "",
  startDate = "",
  endDate = "",
  type = "",
  searchInput = "",
}) => {
  return await HttpService.get(
    `/api/v1/clinic/posts?status=${status}&startDate=${startDate}&endDate=${endDate}&searchInput=${searchInput}&type=${type}`
  );
};

const getRecentClinicPosts = async ({
  status = "",
  startDate = "",
  endDate = "",
  type = "",
  searchInput = "",
}) => {
  return await HttpService.get(
    `/api/v1/clinic/posts/search?status=${status}&startDate=${startDate}&endDate=${endDate}&searchInput=${searchInput}&type=${type}`
  );
};
const getAllClinicPostsForAdmin = async ({
  status = "",
  startDate = "",
  endDate = "",
  type = "",
  searchInput = "",
}) => {
  return await HttpService.get(
    `/api/v1/admin/clinic/all-posts?status=${status}&startDate=${startDate}&endDate=${endDate}&searchInput=${searchInput}&type=${type}`
  );
};

const getAppointments = async () => {
  return await HttpService.get(`/api/v1/clinic/appointments`);
};

const getProposals = async (args) => {
  return await HttpService.get(`/api/v1/clinic/proposal-by-status${args}`);
};

const createCabinAvailability = async (availiblities) => {
  return await HttpService.post(`/api/v1/clinic/cabin-availability`, {
    availiblities,
  });
};

const searchChair = async (query) => {
  const { date, startPrice, address, endPrice } = query;
  return await HttpService.get(
    `/api/v1/doctor/search-all-available-cabins?date=${date}&startPrice=${startPrice}&endPrice=${endPrice}&address=${address}`
  );
};

const getDoctorBookedCabins = async () => {
  return await HttpService.get(`/api/v1/doctor/doctor-booked-cabins`);
};

const getClinicBookedCabins = async (startDate, endDate, status) => {
  if (startDate && endDate && status)
    return await HttpService.get(
      `/api/v1/clinic/all-booked-slots-by-clinic?status=${status}&startDate=${startDate}&endDate=${endDate}`
    );
  if (!startDate && !endDate && status)
    return await HttpService.get(
      `/api/v1/clinic/all-booked-slots-by-clinic?status=${status}`
    );
  return await HttpService.get(`/api/v1/clinic/all-booked-slots-by-clinic`);
};

const sendOtp = async (email) => {
  return await HttpService.post(`/api/v1/user/forgetPassword/sendOtp`, email);
};

const enterOtp = async (body) => {
  return await HttpService.put(
    `/api/v1/user/otpVarification/forgetPassword`,
    body
  );
};
const getConsultationHistory = async (args) => {
  return await HttpService.get(
    `/api/v1/clinic/proposal/consulation-history${args}`
  );
};

const getClinicConsulationHistory = async (queryString) => {
  return await HttpService.get(
    `/api/v1/clinic/cabin/clinic-history?${queryString}`
  );
};

const rateConsultationExperience = async (args) => {
  return await HttpService.post(`/api/v1/user/rating`, args);
};
const getChairById = async (id) => {
  return await HttpService.get(`/api/v1/doctor/available-cabins/${id}`);
};

const bookChair = async (body) => {
  return await HttpService.put(`/api/v1/doctor/book-available-cabins`, body);
};
const getDoctorCabinBookingHistory = async (query) => {
  const { startDate, endDate } = query;
  return await HttpService.get(
    `/api/v1/doctor/cabin/booking-history?startDate=${startDate}&endDate=${endDate}`
  );
};

const getDoctorDetailsById = async (id) => {
  return await HttpService.get(`/api/v1/admin/profile/users-details/${id}`);
};

const getDoctorOverViewDetails = async (
  id,
  selectedStartDate,
  selectedEndDate
) => {
  if (selectedStartDate && selectedEndDate) {
    return await HttpService.get(
      `/api/v1/admin/doctor/overviews/booked/${id}?startDate=${selectedStartDate}&endDate=${selectedEndDate}`
    );
  } else {
    return await HttpService.get(`/api/v1/admin/doctor/overviews/booked/${id}`);
  }
};

const updateCabinBookingStatusDoctor = async ({ id, status, amount }) => {
  return await HttpService.put(
    `/api/v1/doctors/update-cabins-with-payment/${id}?status=${status}&amount=${amount}`
  );
};

const updateCabinBookingStatusClinic = async ({ id, status }) => {
  return await HttpService.put(
    `/api/v1/clinics/update-cabins/${id}?status=${status}`
  );
};

const updateCabinAvailability = async ({ id, args }) => {
  return await HttpService.put(`/api/v1/clinic/cabin/availiblity/${id}`, args);
};

const getClinicOverViewDetails = async (
  id,
  selectedStartDate,
  selectedEndDate
) => {
  if (selectedStartDate && selectedEndDate) {
    return await HttpService.get(
      `/api/v1/admin/clinic/overviews/booked/${id}?startDate=${selectedStartDate}&endDate=${selectedEndDate}`
    );
  } else {
    return await HttpService.get(`/api/v1/admin/clinic/overviews/booked/${id}`);
  }
};

const getDoctorOverViewDetailsConsultancy = async (
  id,
  selectedStartDate,
  selectedEndDate
) => {
  if (selectedStartDate && selectedEndDate) {
    return await HttpService.get(
      `/api/v1/admin/doctor/overviews/consultations/${id}?startDate=${selectedStartDate}&endDate=${selectedEndDate}`
    );
  } else {
    return await HttpService.get(
      `/api/v1/admin/doctor/overviews/consultations/${id}`
    );
  }
};

const getClinicOverViewDetailsConsultancy = async (
  id,
  selectedStartDate,
  selectedEndDate
) => {
  if (selectedStartDate && selectedEndDate) {
    return await HttpService.get(
      `/api/v1/admin/clinic/overviews/consultations/${id}?startDate=${selectedStartDate}&endDate=${selectedEndDate}`
    );
  } else {
    return await HttpService.get(
      `/api/v1/admin/clinic/overviews/consultations/${id}`
    );
  }
};
const getBookedCabins = async () => {
  return await HttpService.get(`/api/v1/doctor/booked-cabins`);
};

const getTransactions = async (startDate, endDate) => {
  // console.log('Line 327 CALLED')
  if (!startDate && !endDate)
    return await HttpService.get(`/api/v1/clinic/transactions`);
  return await HttpService.get(
    `/api/v1/clinic/transactions?startDate=` + startDate + `&endDate=` + endDate
  );
};

const getTransactionsDoctor = async (startDate, endDate) => {
  // console.log('Line 334 CALLED', startDate + '&%%%' + endDate)
  if (!startDate && !endDate)
    return await HttpService.get(`/api/v1/doctor/transactions`);
  return await HttpService.get(
    `/api/v1/doctor/transactions?startDate=` + startDate + `&endDate=` + endDate
  );
};

const getAvailableSlots = async (startDate, endDate, status) => {
  if (startDate && endDate && status)
    return await HttpService.get(
      `/api/v1/clinic/all-available-cabins?status=${status}&startDate=${startDate}&endDate=${endDate}`
    );
  if (!startDate && !endDate && status)
    return await HttpService.get(
      `/api/v1/clinic/all-available-cabins?status=${status}`
    );
  return await HttpService.get(`/api/v1/clinic/all-available-cabins`);
};

const getRecentHirePosts = async () => {
  return await HttpService.get(`/api/v1/clinic/posts/latest-ten`);
};
const getJobs = async (args) => {
  return await HttpService.get(`/api/v1/clinic/all-jobs${args || ''}`);
};

const updateMyConsultationStatus = async (id, status) => {
  return await HttpService.put(`/api/v1/job-done/by-doctor/${id}`, {
    status,
  });
};

const getAllPosts = async (args) => {
  return await HttpService.get(`/api/v1/clinic/all-posts${args || ''}`);
};

const getUserNotifications = async () => {
  return await HttpService.get(`/api/v1/users/notifications`);
};

const getAdminNotifications = async () => {
  return await HttpService.get(`/api/v1/admin/notifications`);
};

const putUserNotifications = async () => {
  return await HttpService.put(`/api/v1/users/update-notifications`);
};

export default {
  getAdminNotifications,
  putUserNotifications,
  getUserNotifications,
  getTransactionsDoctor,
  getJobs,
  getTransactions,
  getDashboardData,
  createDoctor,
  getUsersByRoleAndStatus,
  createClinic,
  changeUserStatus,
  updateProfile,
  getProfile,
  // getPendingUsers,
  getAllClinicPosts,
  getUpcomingBookingByRoleAndDate,
  createProposal,
  getClinicDashboardData,
  getProposalsByStatus,
  getClinicBookingByDate,
  getDoctorOverview,
  hireDoctor,
  getHirePosts,
  getProposals,
  createCabinAvailability,
  getProposalsBySlots,
  getDoctorConsulationHistory,
  getProposalsByDoctor,
  searchChair,
  getDoctorBookedCabins,
  adminLogin,
  sendOtp,
  enterOtp,
  updateBookingStatus,
  updateProposalByProposalId,
  getDoctorBookingByDate,
  getPendingDoctors,
  getTotalPendingCountOfDoctorsAndClinics,
  getPendingClinics,
  getAppointments,
  getConsultationHistory,
  rateConsultationExperience,
  getOverviewClinicsAdmin,
  getOverviewDoctorsAdmin,
  getChairById,
  bookChair,
  getDoctorCabinBookingHistory,
  getClinicConsulationHistory,
  getDoctorDetailsById,
  getDoctorOverViewDetails,
  getClinicOverViewDetails,
  updateCabinBookingStatusDoctor,
  getDoctorOverViewDetailsConsultancy,
  getClinicOverViewDetailsConsultancy,
  getBookedCabins,
  getAvailableSlots,
  updateMyConsultationStatus,
  getRecentHirePosts,
  updateProposalStatus,
  updateCabinBookingStatusClinic,
  getClinicBookedCabins,
  getAllPosts,
  deleteAvailabilty,
  getAllClinicPostsById,
  searchProposalsOfClinic,
  getRecentClinicPosts,
  updateCabinAvailability,
  getAllClinicPostsForAdmin,
};
