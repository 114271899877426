import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";

import SuccessIcon from "../../assets/images/sent.png";
import { styles } from "../../layouts/Styles";

const SuccessModal = ({
  isOpen = false,
  title = "Success",
  msg = "Completed successfully",
  toggle = null,
}) => {
  const classes = styles();

  return (
    <Dialog
      onClose={toggle}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
      sx={{
        "& .MuiDialog-paper": {
          padding: "0px",
          borderRadius: "15px",
          height: "50%",
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={toggle}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "black",
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent className={classes.dialogContent}>
        <img
          src={SuccessIcon}
          alt="_success"
          style={{ width: "75px", height: "75px" }}
          className={classes.image}
        />
        <Typography
          align="center"
          sx={{
            fontSize: "32px",
            fontWeight: "600",
            color: "#F98247",
            fontFamily: "Poppins",
            marginTop: "5px",
            padding: "0px",
          }}
        >
          {title || "Successful"}
        </Typography>

        <Typography
          align="center"
          className={classes.text}
          sx={{
            fontSize: "18px",
            fontWeight: "400",
            color: "#343434",
            fontFamily: "Poppins",
          }}
        >
          {msg || "Completed successfully"}
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default SuccessModal;
