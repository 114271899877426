import activeclinic from "../assets/images/activeclinic.png";
import activedashboard from "../assets/images/activedashboard.png";
import activedoctor from "../assets/images/activedoctor.png";
import activemarketplace from "../assets/images/activemarketplace.png";
import activesettings from "../assets/images/activesettings.png";
import activeusers from "../assets/images/activeusers.png";
import inactiveclinic from "../assets/images/inactiveclinic.png";
import inactivedashboard from "../assets/images/inactivedashboard.png";
import inactivedoctor from "../assets/images/inactivedoctor.png";
import inactivemarketplace from "../assets/images/inactivemarketplace.png";
import inactivesettings from "../assets/images/inactivesettings.png";
import inactiveusers from "../assets/images/inactiveusers.png";

export const navigation = (role) => {
  let designation = localStorage.getItem("designation");
  if (role === "1") {
    role = "admin";
  }
  if (role === "2") {
    role = "clinic";
  }
  if (role === "3") {
    role = "doctor";
  }
  return [
    {
      _id: 1,
      isActive: ["/admin/dashboard"],
      navigator: "/admin/dashboard",
      name: "Dashboard",
      inactiveicon: inactivedashboard,
      activeicon: activedashboard,
      visible: role === "admin",
    },
    {
      _id: 2,
      isActive: ["/doctor"],
      navigator: "/doctor",
      name: "Doctors",
      inactiveicon: inactivedoctor,
      activeicon: activedoctor,
      visible: role === "admin",
    },
    {
      _id: 3,
      isActive: ["/clinics"],
      navigator: "/clinics",
      name: "Clinics",
      inactiveicon: inactiveclinic,
      activeicon: activeclinic,
      visible: role === "admin",
    },
    {
      _id: 16,
      isActive: ["/admin/clinics/posts"],
      navigator: "/admin/clinics/posts",
      name: "Posts",
      inactiveicon: inactiveclinic,
      activeicon: activeclinic,
      visible: role === "admin",
    },
    {
      _id: 4,
      isActive: ["/marketplace"],
      navigator: "/marketplace",
      name: "Marketplace",
      inactiveicon: inactivemarketplace,
      activeicon: activemarketplace,
      visible: role === "admin",
    },

    {
      _id: 5,
      isActive: ["/settings", "/editSettings"],
      navigator: "/settings",
      name: "Settings",
      inactiveicon: inactivesettings,
      activeicon: activesettings,
      visible: role === "admin",
    },
    {
      _id: 6,
      isActive: ["/doctor/dashboard"],
      navigator: "/doctor/dashboard",
      name: "Dashboard",
      inactiveicon: inactivedashboard,
      activeicon: activedashboard,
      visible: role === "doctor",
    },
    {
      _id: 7,
      isActive: ["/bookchair", "/booknow", "/doctor/payment"],
      navigator: "/bookchair",
      name: "Book a Chair",
      inactiveicon: inactiveclinic,
      activeicon: activeclinic,
      visible: role === "doctor",
    },
    {
      _id: 8,
      isActive: designation == "MDS" ? ["/consultation"] : ["/jobs"],
      navigator: designation == "MDS" ? "/consultation" : "/jobs",
      name: designation == "MDS" ? "Consultations" : "Jobs",
      inactiveicon: inactiveusers,
      activeicon: activeusers,
      visible: role === "doctor",
    },
    {
      _id: 9,
      isActive: ["/doctor/transaction"],
      navigator: "/doctor/transaction",
      name: "Transaction",
      inactiveicon: inactivemarketplace,
      activeicon: activemarketplace,
      visible: role === "doctor",
    },
    {
      _id: 10,
      isActive: ["/doctor/settings", "/editSettingsDoctor"],
      navigator: "/doctor/settings",
      name: "Settings",
      inactiveicon: inactivesettings,
      activeicon: activesettings,
      visible: role === "doctor",
    },
    {
      _id: 11,
      isActive: ["/clinic/dashboard"],
      navigator: "/clinic/dashboard",
      name: "Dashboard",
      inactiveicon: inactivedashboard,
      activeicon: activedashboard,
      visible: role === "clinic",
    },
    {
      _id: 12,
      isActive: [
        "/clinic/managebookings",
        "/clinic/payment",
        "/clinic/editSlot",
      ],
      navigator: "/clinic/managebookings",
      name: "Rent Your Clinic",
      inactiveicon: inactiveclinic,
      activeicon: activeclinic,
      visible: role === "clinic",
    },
    {
      _id: 13,
      isActive: ["/clinic/hiredoctor", "/clinic/payments"],
      navigator: "/clinic/hiredoctor",
      name: "Hire Doctor",
      inactiveicon: inactivedoctor,
      activeicon: activedoctor,
      visible: role === "clinic",
    },

    {
      _id: 14,
      isActive: ["/clinic/transaction"],
      navigator: "/clinic/transaction",
      name: "Transaction",
      inactiveicon: inactivemarketplace,
      activeicon: activemarketplace,
      visible: role === "clinic",
    },
    {
      _id: 15,
      isActive: ["/clinic/settings", "/editSettingsClinic"],
      navigator: "/clinic/settings",
      name: "Settings",
      inactiveicon: inactivesettings,
      activeicon: activesettings,
      visible: role === "clinic",
    },
  ];
};
