import React, { useState } from "react";
import { Grid, Box, Button, Typography, Paper } from "@mui/material";
import { styles } from "../../layouts/Styles";
import SentSuccessfully from "../Modals/SentSuccessfully";
import leftarrowicon from "../../assets/images/leftarrowicon.png";
import { useNavigate } from "react-router-dom";
import RatingModal from "../Modals/RatingModal";
import { useLocation } from "react-router-dom";

const ManageClinicPayment = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [nav, setNav] = useState(false);
  const [exp, setExpModal] = useState(false);
  const Navigate = useNavigate();
  const location = useLocation();
  const { loc } = location.state || {};

  const classes = styles();
  const handleBookModal = () => {
    setModalOpen(true);
  };

  const handleCloseBook = () => {
    setModalOpen(false);
    setExpModal(true);
  };

  const handleChair = () => {
    setNav(true);
  };

  const handlecloseDone = () => {
    setExpModal(false);
    setNav(true);
  };

  return (
    <>
      {nav ? (
        loc == "dashboard" || loc == "clinicdashbord" ? (
          Navigate("/clinic/dashboard")
        ) : loc == "managebookings" ? (
          Navigate("/clinic/managebookings")
        ) : loc == "bookchair" ? (
          Navigate("/bookchair")
        ) : loc == "doctordashboard" ? (
          Navigate("/doctor/dashboard")
        ) : (
          Navigate("/clinic/hiredoctor")
        )
      ) : (
        <>
          <Grid container sx={{ mb: "2em" }}>
            <Grid item>
              <img
                onClick={handleChair}
                src={leftarrowicon}
                style={{ width: "24px", height: "24px" }}
                alt="leftarrow"
              />
            </Grid>
            <Grid item sx={{ mt: "0.1em", ml: "1em" }}>
              <Typography
                variant="body"
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "black",
                }}
              >
                Back
              </Typography>
            </Grid>
          </Grid>

          <Typography variant="h3" sx={{ fontSize: "23px", fontWeight: 600 }}>
            Select Payment Method
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
                md: "row",
                lg: "row",
                xl: "row",
              },
              gap: "10px",
            }}
          >
            <Paper
              sx={{
                width: "80%",
                p: "2em",
                borderRadius: 1.5,
                mt: 3,
                pb: "3em",
                minHeight: "fit-content",
              }}
            >
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={handleBookModal}
                    // disabled={!cabin || !timeslot}
                    sx={{
                      fontSize: "16px",
                      width: "300px",
                      fontWeight: 600,
                      backgroundColor: "#E78756",
                      borderRadius: 30,
                      padding: "12px 46px 12px 46px",
                      "&:hover": { backgroundColor: "#E78756" },
                    }}
                  >
                    Pay Now
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </>
      )}
      {modalOpen ? (
        <SentSuccessfully
          modal={modalOpen}
          handleCloseApprove={handleCloseBook}
          value={"payment"}
        />
      ) : null}
      {exp ? (
        <RatingModal
          modal={exp}
          handleCloseApprove={handlecloseDone}
          value={"proposal"}
          doctor={loc == "bookchair" || loc == "doctordashboard" ? false : true}
        />
      ) : null}
    </>
  );
};

export default ManageClinicPayment;
