import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import ClinicHistory from "../../components/clinicmanagebooking/ClinicHistory";
import ManageClinic from "../../components/clinicmanagebooking/ManageClinic";

const ManageBookings = () => {
  const CustomizedStaticDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
      width: 320,
    },
  }));
  const DownArrowIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path d="M7 10l5 5 5-5z" />
    </svg>
  );

  const UpArrowIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path d="M7 14l5-5 5 5z" />
    </svg>
  );
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <>
      {/* <Grid container spacing={1}></Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
            lg: "row",
            xl: "row",
          },

          gap: "15px",
        }}
        justifyContent="space-between"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
              md: "row",
              lg: "row",
              xl: "row",
            },
            gap: "10px",
          }}
        >
          <Button
            onClick={() => setTabIndex(0)}
            variant={tabIndex == 0 ? "contained" : "outlined"}
            size="large"
            sx={{ borderRadius: 46 }}
          >
            Hire Doctor
          </Button>
          <Button
            onClick={() => setTabIndex(1)}
            variant={tabIndex == 1 ? "contained" : "outlined"}
            size="large"
            sx={{ borderRadius: 46 }}
          >
            Proposals
          </Button>
          <Button
            onClick={() => setTabIndex(2)}
            variant={tabIndex == 2 ? "contained" : "outlined"}
            size="large"
            sx={{ borderRadius: 46 }}
          >
            Appointments
          </Button>

          <Button
            onClick={() => setTabIndex(3)}
            variant={tabIndex == 3 ? "contained" : "outlined"}
            size="large"
            sx={{ borderRadius: 46 }}
          >
            Consultation History
          </Button>
        </Box>
      </Box>
      {tabIndex === 0 && <ClinicHireDoctor />}
      {tabIndex === 0 && <ManageClinic />}
      {tabIndex === 1 && <ClinicHistory />} */}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "80vh" }}
      >
        <h1
          style={{
            color: "#1A202C",
            fontWeight: 600,
            fontSize: 100,
            textAlign: "center",
            margin: "0px",
            padding: "0px",
            lineHeight: "90px",
          }}
        >
          Coming
          <br /> Soon!
        </h1>
      </Box>
    </>
  );
};

export default ManageBookings;
