import * as React from "react";
import { styled } from "@mui/material/styles";
import { styles } from "../../layouts/Styles";
import {
  Grid,
  Typography,
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  useTheme,
  Box,
  TablePagination,
} from "@mui/material";
import DateRangeCalendar from "../../components/calendar/DateRangeCalendar";
import moment from "moment";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function BookedClinic({
  doctorDataOverView,
  setStartDate,
  setEndDate,
  role,
  tableData,
  tabIndex,
}) {
  const {
    palette: { grey },
  } = useTheme();
  const classes = styles();

  let transactiondata = [
    {
      s_No: 1,
      Clinic_Name: "Martin Joy",
      Phone_number: "₹2000",

      Location: "Credited",
      Date: "Aug 20",
      Amount: 2000,
      status: "cancelled",
    },
  ];

  let transactiondataClinic = [
    {
      s_No: 1,
      Doctor_Name: "Martin Joy",
      Phone_number: "₹2000",

      Location: "Credited",
      Date: "Aug 20",
      Amount: 2000,
      status: "cancelled",
    },
  ];
  const getColorStyle = (status) => {
    status = status?.toLowerCase();

    switch (status) {
      case "completed":
        return "credited";
      case "rejected":
        return "debited";
      case "cancelled":
        return "debited";
      default:
        return "tableCellBody";
    }
  };

  const columns = Object.keys(
    role == 3 ? transactiondata[0] : transactiondataClinic[0]
  );

  return (
    <Grid container spacing={2} sx={{ mt: 4 }}>
      <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
        <Item>
          <Box
            sx={{
              display: "flex",
              color: "black",
              gap: "5px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontWeight: 600, fontSize: "18px", color: "#000000" }}
              
            >
              Orders
            </Typography>
            <Box>
              <DateRangeCalendar
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                size="small"
              />
            </Box>
          </Box>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                background: "#f8f8f8",
                height: "50px",
                borderRadius: "10px",
                // justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <Typography
                sx={{
                  marginLeft: "20px",
                  fontSize: "14px",
                  color: "#000000",
                  fontWeight: 500,
                }}
              >
                Total Orders
              </Typography>
              <Typography
                sx={{
                  marginRight: "20px",
                  fontSize: "14px",
                  color: "#000000",
                  fontWeight: 500,
                }}
              >
                {doctorDataOverView?.totalOrders}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                background: "#f8f8f8",
                height: "50px",
                borderRadius: "10px",
                // justifyContent: "center",
                alignItems: "center",
                //   marginTop: "20px",
              }}
            >
              <Typography
                sx={{
                  marginLeft: "20px",
                  fontSize: "14px",
                  color: "#000000",
                  fontWeight: 500,
                }}
              >
                revenue
              </Typography>
              <Typography
                sx={{
                  marginRight: "20px",
                  fontSize: "14px",
                  color: "#000000",
                  fontWeight: 500,
                }}
              >
                {doctorDataOverView?.revenue}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                background: "#f8f8f8",
                height: "50px",
                borderRadius: "10px",
                // justifyContent: "center",
                alignItems: "center",
                //   marginTop: "20px",
              }}
            >
              <Typography
                sx={{
                  marginLeft: "20px",
                  fontSize: "14px",
                  color: "#000000",
                  fontWeight: 500,
                }}
              >
                completed
              </Typography>
              <Typography
                sx={{
                  marginRight: "20px",
                  fontSize: "14px",
                  color: "#000000",
                  fontWeight: 500,
                }}
              >
                {doctorDataOverView?.completed}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                background: "#f8f8f8",
                height: "50px",
                borderRadius: "10px",
                // justifyContent: "center",
                alignItems: "center",
                //   marginTop: "20px",
              }}
            >
              <Typography
                sx={{
                  marginLeft: "20px",
                  fontSize: "14px",
                  color: "#000000",
                  fontWeight: 500,
                }}
              >
                cancel
              </Typography>
              <Typography
                sx={{
                  marginRight: "20px",
                  fontSize: "14px",
                  color: "#000000",
                  fontWeight: 500,
                }}
              >
                {doctorDataOverView?.cancel}
              </Typography>
            </div>
          </div>
        </Item>
      </Grid>
      <Grid item xs={12} sm={6} md={8} lg={8} xl={8}>
        <Item>
          <Paper sx={{ p: "1.5em", mt: 2 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column} className={classes.tableCell}>
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell className={classes.tableCellBody}>
                        {index + 1}
                      </TableCell>
                      <TableCell className={classes.tableCellBody}>
                        {role == 3
                          ? row?.clinicDetails?.clinicName
                          : row?.doctor?.name}
                      </TableCell>
                      <TableCell className={classes.tableCellBody}>
                        {role == 3
                          ? row?.clinic?.phoneNumber
                          : row?.doctor?.phoneNumber}
                      </TableCell>
                      <TableCell className={classes.tableCellBody}>
                        {role == 3
                          ? row?.clinicDetails?.area
                          : row?.doctorDetails?.area}
                      </TableCell>
                      <TableCell className={classes.tableCellBody}>
                        {moment(row?.cabinAvailability?.date).format(
                          "DD-MM-YYYY"
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCellBody}>
                        {tabIndex
                          ? row?.clinicPosts?.price
                          : row?.cabinAvailability?.price}
                      </TableCell>
                      <TableCell
                        className={classes[getColorStyle(row["status"])]}
                      >
                        {row?.status}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {tableData?.length <= 0 && (
                <>
                  <div style={{ marginTop: "20px", fontSize: "20px" }}>
                    No Data Found
                  </div>
                </>
              )}
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5]}
              component="div"
              count={10}
              rowsPerPage={5}
              page={0}
              onPageChange={""}
              onRowsPerPageChange={""}
            />
          </Paper>
        </Item>
      </Grid>
    </Grid>
  );
}
