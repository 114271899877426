import { Grid, LinearProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import api from "../../api/index";
import SentSuccessfully from "../Modals/SentSuccessfully";
import SentProposalDetailsCard from "./SentProposalDetailsCard";

const SentProposals = () => {
  const [openModal, setModalOpen] = useState(false);
  const [consultations, setConsultations] = useState([]);

  const [isLoading, setLoading] = useState(false);

  const [isUpdateLoading, setUpdateLoading] = useState('');

  const handleCloseApprove = () => {
    setModalOpen(false);
  };

  const updateProposalByProposalId = async (consultationId, status, index) => {
    try {
      setUpdateLoading(index);
      const res = await api.updateProposalByProposalId({
        id: consultationId,
        status: status,
      });
      setUpdateLoading("");
      if (res?.data?.success) {
        setModalOpen(true);
        getProposalsByDoctor();
      }
    } catch (err) {
      setUpdateLoading("");
      console.log(err);
    }
  };

  const getProposalsByDoctor = async () => {
    setLoading(true);
    await api
      .getProposalsByDoctor()
      .then((response) => {
        setLoading(false);
        if (response?.data?.success) {
          setConsultations(response?.data?.data?.proposal);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getProposalsByDoctor();
  }, []);

  return (
    <>
      {openModal ? (
        <SentSuccessfully
          modal={openModal}
          withdraw={true}
          handleCloseApprove={handleCloseApprove}
        />
      ) : null}
      <Typography variant="h3" sx={{ fontSize: "22px", mt: 1, mb: 0.5 }}>
        Sent Proposals
      </Typography>
      {isLoading && (
        <LinearProgress
          sx={{
            mb: 1,
          }}
        />
      )}
      <Grid display="flex" flexWrap="wrap" gap={1}>
        {consultations?.map((consultation, index) => (
          <SentProposalDetailsCard
            index={index}
            consultation={consultation}
            updateProposalByProposalId={updateProposalByProposalId}
            isUpdateLoading={isUpdateLoading}
          />
        ))}
      </Grid>
    </>
  );
};

export default SentProposals;
