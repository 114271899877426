import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import Posts from "../../components/clinichiredoctor/Posts";
import Proposals from "../../components/clinichiredoctor/Proposals";

const ClinicPosts = () => {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <>
      <Grid container gap={1}>
        <Grid item>
          <Button
            onClick={() => setTabIndex(0)}
            variant={tabIndex == 0 ? "contained" : "outlined"}
            size="medium"
            sx={{ borderRadius: 46 }}
          >
            Posts
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => setTabIndex(1)}
            variant={tabIndex == 1 ? "contained" : "outlined"}
            size="medium"
            sx={{ borderRadius: 46 }}
          >
            Proposals
          </Button>
        </Grid>
      </Grid>

      {tabIndex === 0 && <Posts setTabIndex={setTabIndex} isAdmin={true} />}
      {tabIndex === 1 && <Proposals />}
    </>
  );
};

export default ClinicPosts;
