import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Link,
  OutlinedInput,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import api from "../../api";
import PasswordModal from "../../components/Modals/PasswordModal";

const Settings = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { loc } = location.state || {};
  const [tabIndex, setTabIndex] = useState(loc == "admin" ? 1 : 0);
  const Navigate = useNavigate();
  const [formData, setFormData] = useState({
    userId: "",
    name: "",
    phoneNumber: "",
    email: "",
    imageUrl: "",
  });
  const { palette } = useTheme();
  const addFormData = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handlePasswordOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getProfile = async () => {
    await api
      .getProfile()
      .then((res) => {
        if (!res?.data?.success) {
          return;
        }
        if (res.data.success) {
          setFormData({
            userId: res?.data?.data?.user?.id || "",
            name: res?.data?.data?.user?.name || "",
            phoneNumber: res?.data?.data?.user?.phoneNumber || "",
            email: res?.data?.data?.user?.email || "",
            imageUrl: res?.data?.data?.user?.imageUrl || "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  useEffect(() => {
    getProfile();
  }, []);
  return (
    <>
      {open ? <PasswordModal open={open} handleClose={handleClose} /> : null}
      <Grid container spacing={1} sx={{ mt: 2 }}>
        <Grid item xs={4} sm={2} md={2} lg={1.5} xl={1}>
          {" "}
          <Button
            onClick={() => setTabIndex(0)}
            variant={tabIndex == 0 ? "contained" : "outlined"}
            size="large"
            sx={{ borderRadius: 46, mr: 2 }}
          >
            Profile
          </Button>
        </Grid>
        {/* <Grid item xs={4} sm={2} md={2} lg={2} xl={1}>
          <Button
            onClick={() => setTabIndex(1)}
            variant={tabIndex == 1 ? "contained" : "outlined"}
            size="large"
            sx={{ borderRadius: 46, mr: 2 }}
          >
            Notifications
          </Button>
        </Grid> */}
      </Grid>
      {tabIndex === 0 && (
        <Paper sx={{ p: "1.8em", borderRadius: 2, mt: 3, maxWidth: "1090px" }}>
          <Box
            sx={{
              mb: 3,
              width: "100px",
              height: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "100%",
              overflow: "hidden",
            }}
          >
            {/* <img
              src={formData.imageUrl || profileicon}
              style={{ width: 100, height: "inherit", objectFit: "cover" }}
            /> */}
            <Avatar
              sx={{
                width: 100,
                height: "inherit",
                objectFit: "cover",
                fontSize: "30px",
              }}
              src={formData?.imageUrl || "./image.png"}
              alt={formData?.name}
            />

            {/* <img
              src={editicon}
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: 34,
                height: 34,
              }}
            /> */}
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid
                  item
                  display="flex"
                  flexDirection="column"
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={5.5}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#303030",
                      mb: 1,
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                  >
                    User ID
                  </Typography>
                  <OutlinedInput
                    name={"userId"}
                    // onChange={addFormData}
                    readOnly
                    value={formData?.userId}
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "white",
                      "& .MuiOutlinedInput-input": {
                        borderRadius: 2,
                        // border: "none",
                        outline: "none",
                        height: "15px",
                        fontSize: "16px",
                        fontWeight: "600px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        transition: "ease-in 0.2s",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${palette?.primary?.main}`,
                        transition: "ease-in 0.2s",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: `2px solid ${palette?.primary?.light}`,
                        transition: "ease-in 0.2s",
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  display="flex"
                  flexDirection="column"
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={5.5}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#303030",
                      mb: 1,
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                  >
                    Name
                  </Typography>
                  <OutlinedInput
                    name={"name"}
                    // onChange={addFormData}
                    readOnly
                    value={formData?.name}
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "white",
                      "& .MuiOutlinedInput-input": {
                        borderRadius: 2,
                        // border: "none",
                        outline: "none",
                        height: "15px",
                        fontSize: "16px",
                        fontWeight: "600px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        transition: "ease-in 0.2s",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${palette?.primary?.main}`,
                        transition: "ease-in 0.2s",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: `2px solid ${palette?.primary?.light}`,
                        transition: "ease-in 0.2s",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid
                  item
                  display="flex"
                  flexDirection="column"
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={5.5}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#303030",
                      mb: 1,
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                  >
                    Email
                  </Typography>
                  <OutlinedInput
                    name={"email"}
                    type="email"
                    // onChange={addFormData}
                    readOnly
                    value={formData?.email}
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "white",
                      "& .MuiOutlinedInput-input": {
                        borderRadius: 2,
                        // border: "none",
                        outline: "none",
                        height: "15px",
                        fontSize: "16px",
                        fontWeight: "600px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        transition: "ease-in 0.2s",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${palette?.primary?.main}`,
                        transition: "ease-in 0.2s",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: `2px solid ${palette?.primary?.light}`,
                        transition: "ease-in 0.2s",
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  display="flex"
                  flexDirection="column"
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={5.5}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#303030",
                      mb: 1,
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                  >
                    Phone Number
                  </Typography>
                  <OutlinedInput
                    name={"phoneNumber"}
                    value={formData?.phoneNumber}
                    // onChange={addFormData}
                    readOnly
                    type="number"
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "white",
                      "& .MuiOutlinedInput-input": {
                        borderRadius: 2,
                        // border: "none",
                        outline: "none",
                        height: "15px",
                        fontSize: "16px",
                        fontWeight: "600px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        transition: "ease-in 0.2s",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${palette?.primary?.main}`,
                        transition: "ease-in 0.2s",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: `2px solid ${palette?.primary?.light}`,
                        transition: "ease-in 0.2s",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid
                  item
                  display="flex"
                  flexDirection="column"
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  xl={1}
                >
                  <Button
                    variant="contained"
                    size="small"
                    fullWidth={false}
                    onClick={() => {
                      Navigate("/editSettings");
                    }}
                    sx={{
                      width: 140,
                      height: 50,
                      borderRadius: 2,
                      padding: "18px 30px 18px 30px",
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                  >
                    Edit
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={5.5}
                  style={{ alignItems: "center", display: "flex" }}
                >
                  <Link
                    sx={{
                      color: "#004E89",
                      fontWeight: 600,
                      fontSize: 16,
                      cursor: "pointer",
                      fontFamily: "poppins",
                    }}
                    onClick={handlePasswordOpen}
                  >
                    Change Password
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}
      {tabIndex === 1 && (
        <Paper sx={{ p: "1em", borderRadius: 1.5, mt: 3, maxWidth: "860px" }}>
          <Box
            sx={{
              backgroundColor: "#F8F8F8",
              padding: 2,
              borderRadius: 1.5,
              mb: 0.5,
            }}
          >
            <Grid container flexDirection="column">
              <Grid item sx={{ mb: 1 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  flexDirection="row"
                >
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#303030",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      Doctor is added
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#303030 63%",
                        fontSize: "10px",
                        fontWeight: 500,
                      }}
                    >
                      10.00AM
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography
                  sx={{ color: "#222222", fontWeight: 300, fontSize: "13px" }}
                >
                  Lorem ipsum dolor sit amet consectetur. Aliquet scelerisque
                  nunc dignissim ornare tincidunt ultrices id non. Blandit et
                  nisi scelerisque lectus. Morbi platea eu ac tortor sed
                  consectetur
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Divider sx={{ mb: 1 }} />
          <Box
            sx={{
              backgroundColor: "#F8F8F8",
              padding: 2,
              borderRadius: 1.5,
              mb: 0.5,
            }}
          >
            <Grid container flexDirection="column">
              <Grid item sx={{ mb: 1 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  flexDirection="row"
                >
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#303030",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      Doctor is added
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#303030 63%",
                        fontSize: "10px",
                        fontWeight: 500,
                      }}
                    >
                      10.00AM
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography
                  sx={{ color: "#222222", fontWeight: 300, fontSize: "13px" }}
                >
                  Lorem ipsum dolor sit amet consectetur. Aliquet scelerisque
                  nunc dignissim ornare tincidunt ultrices id non. Blandit et
                  nisi scelerisque lectus. Morbi platea eu ac tortor sed
                  consectetur
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Divider sx={{ mb: 1 }} />
          <Box
            sx={{
              backgroundColor: "#F8F8F8",
              padding: 2,
              borderRadius: 1.5,
              mb: 0.5,
            }}
          >
            <Grid container flexDirection="column">
              <Grid item sx={{ mb: 1 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  flexDirection="row"
                >
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#303030",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      Doctor is added
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#303030 63%",
                        fontSize: "10px",
                        fontWeight: 500,
                      }}
                    >
                      10.00AM
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography
                  sx={{ color: "#222222", fontWeight: 300, fontSize: "13px" }}
                >
                  Lorem ipsum dolor sit amet consectetur. Aliquet scelerisque
                  nunc dignissim ornare tincidunt ultrices id non. Blandit et
                  nisi scelerisque lectus. Morbi platea eu ac tortor sed
                  consectetur
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Divider sx={{ mb: 1 }} />
        </Paper>
      )}
    </>
  );
};

export default Settings;
