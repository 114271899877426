import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { Box, Grid, Popover, Typography } from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect, useState } from "react";

import { styles } from "../../layouts/Styles";

const DateRangeCalendar = (props) => {
  const [onRange, setRange] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);

  const classes = styles();
  const handleRange = (event) => {
    setRange(event.currentTarget);
  };
  const formatDate = (date) => {
    return date ? new Intl.DateTimeFormat("en-US").format(date) : "";
  };

  useEffect(() => {
    if (props?.resetDate) {
      console.log("reset date: ", props.resetDate);
      setSelectedStartDate(null);
      setSelectedEndDate(null);
    }
    if (props?.setResetDate) {
      props?.setResetDate(false);
    }
  }, [props.resetDate]);

  return (
    <>
      <Grid
        item
        xs={6}
        md={3}
        onClick={(event) => {
          handleRange(event);
        }}
      >
        <Box
          className={
            props?.size == "small"
              ? classes?.OverviewMenuSmall
              : classes?.OverviewMenu
          }
          sx={props?.style || {}}
        >
          <Typography
            variant="subtitle2"
            sx={{
              color: selectedStartDate && selectedEndDate ? "black" : "gray",
              fontWeight: 500,
              lineHeight: "16px",
            }}
          >
            {selectedStartDate && selectedEndDate
              ? `${formatDate(selectedStartDate)} - ${formatDate(
                  selectedEndDate
                )}`
              : "Select Date Range"}
          </Typography>
          <KeyboardArrowDownRoundedIcon
            sx={{ width: "20px", height: "20px", color: "grey" }}
          />
        </Box>
      </Grid>

      <Popover
        open={Boolean(onRange)}
        anchorEl={onRange}
        onClose={() => setRange(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{
          ".css-1q04gal-MuiDateCalendar-root": {
            borderRadius: "8px",
            width: "240px",
          },
        }}
      >
        <Box sx={{ p: 0.8, width: "100%" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              views={["day"]}
              dayOfWeekFormatter={(day) => `${day}`}
              onChange={(date) => {
                if (!selectedStartDate) {
                  setSelectedStartDate(date);
                  if (!props?.resetDate) {
                    props.setStartDate(date);
                  }
                } else if (
                  !selectedEndDate ||
                  date.isSame(selectedStartDate, "day")
                ) {
                  setSelectedEndDate(date);
                  if (!props?.resetDate) {
                    props.setEndDate(date);
                  }
                  setRange(null);
                } else {
                  setSelectedStartDate(date);
                  props.setStartDate(date);
                  setSelectedEndDate(null);
                  if (!props?.resetDate) {
                    props.setEndDate(null);
                  }
                }
              }}
              slotProps={{
                textField: { size: "small" },
                switchViewButton: { sx: { display: "none" } },
                calendarHeader: {
                  sx: {
                    position: "relative",
                    "& .MuiPickersArrowSwitcher-root": {
                      width: 0,
                    },
                    "& .MuiIconButton-edgeEnd": {
                      position: "absolute",
                      left: 0,
                      top: 0,
                      bottom: 0,
                    },
                    "& .MuiIconButton-edgeStart": {
                      position: "absolute",
                      right: 0,
                      top: 0,
                      bottom: 0,
                    },
                  },
                },
              }}
              // disableFuture={true}
              // disablePast={props.disablePast}
              showDaysOutsideCurrentMonth={true}
              renderLoading={() => (
                <span data-mui-test="loading-progress">...</span>
              )}
            />
          </LocalizationProvider>
        </Box>
      </Popover>
    </>
  );
};

export default DateRangeCalendar;
