import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputAdornment, MenuItem, OutlinedInput, Select, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";

import api from "../../api";
import sent from "../../assets/images/sent.png";
import uploadfileIcon from "../../assets/images/uploadfile.png";
import AdminDoctors from "../../components/Table/AdminDoctors";
import AdminDoctorsPending from "../../components/Table/AdminDoctorsPending";
import AdminDoctorsRejected from "../../components/Table/AdminDoctorsRejected";
import { BDSSpecialities, MDSSpecialities, workTypeOptions } from "../../constants";
import { styles } from "../../layouts/Styles";
import DoctorDetails from "./DoctorDetails";

const Doctor = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [submitModal, setSubmitModal] = React.useState(false);
  const [doctors, setDoctors] = useState(null);
  const [emailAlreadyExists, setEmailExists] = useState(false);
  const [overViewData, setOverViewData] = useState({});

  const [specialities, setSpecialities] = useState([]);

  const [selectedSpecialities, setSelectedSpecialities] = useState([]);

  const [showWarning, setShowWarning] = useState(false);

  const [page, setPage] = useState(1);
  const [activeUserID, setActiveUserID] = useState("");

  const [clinicalexp, setClinicalexp] = useState({
    years: "",
    months: "",
  });
  const [inputData, setInputData] = useState({
    doctorType: "",
    collegeName: "",
    _role: 3,
    name: "",
    passOutYear: "",
    phoneNumber: "",
    workType: "",
    email: "",
    certificateUrl: null,
    experience: "",
    specialistIn: "",
  });
  const isReset = () => {
    setInputData((prev) => ({
      ...prev,
      doctorType: "",
      collegeName: "",
      _role: 3,
      name: "",
      passOutYear: "",
      phoneNumber: "",
      workType: "",
      email: "",
      certificateUrl: null,
      experience: "",
      specialistIn: "",
    }));
    setClinicalexp((prev) => ({
      ...prev,
      years: "",
      months: "",
    }));
  };

  const options = ["BDS", "MDS"];

  const classes = styles();

  const getDoctors = async (page, limit) => {
    try {
      const _role = 3;
      const status =
        tabIndex == 0 ? "APPROVED" : tabIndex == 1 ? "PENDING" : "REJECTED";
      const res = await api.getUsersByRoleAndStatus(_role, status, page, limit);
      if (res.data.success) {
        setDoctors(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const overviewDoctors = async () => {
    try {
      const res = await api.getOverviewDoctorsAdmin();
      if (res.data.success) {
        setOverViewData(res?.data?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    overviewDoctors();
  }, [tabIndex]);

  useEffect(() => {
    getDoctors(1, 10);
  }, [tabIndex]);

  const handleInput = (e, id) => {
    e.preventDefault();
    if (id == "certificateUrl") {
      setInputData({
        ...inputData,
        [id]: e.target.files[0],
      });
    } else {
      const { value } = e.target;
      setInputData({
        ...inputData,
        [id]: value,
      });
    }
  };
  const handleInputExp = (e, id) => {
    e.preventDefault();
    const { value } = e.target;
    setClinicalexp({
      ...clinicalexp,
      [id]: value,
    });
  };

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const fileInputRef = React.createRef();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    isReset();
    setSelectedSpecialities([]);
    setShowWarning(false);
  };
  const handleCloseSubmit = () => {
    setSubmitModal(false);
  };

  const isFormInvalid = () => {
    return (
      !inputData?.doctorType ||
      !inputData?.collegeName ||
      !inputData?.name ||
      !inputData?.passOutYear ||
      !inputData?.workType ||
      !inputData?.phoneNumber ||
      inputData?.phoneNumber?.length !== 10 ||
      !inputData?.email ||
      !RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i).test(
        inputData?.email
      ) ||
      !clinicalexp?.years ||
      !clinicalexp?.months ||
      !selectedSpecialities?.length
    );
  };

  const handleSubmitModal = async () => {
    setShowWarning(true);

    //check form validity
    if (isFormInvalid()) {
      return;
    }

    let specialitiesStr = selectedSpecialities.join(",");

    let allInputs = {
      ...inputData,
      experience: clinicalexp.years + "." + clinicalexp.months,
      specialistIn: specialitiesStr,
    };

    const res = await api.createDoctor(allInputs);
    if (res.data.success) {
      setSubmitModal(true);
      getDoctors(1, 10);
      handleClose();
    }
  };

  const generateYears = () => {
    const yearsList = [];
    const currentYear = new Date().getFullYear();
    for (let year = currentYear - 50; year <= currentYear; year++) {
      yearsList.push(year);
    }
    return yearsList;
  };

  const yearsList = generateYears();

  const uploadFile = async (e) => {
    let fileName = e.target.files[0].name;
    let file = e.target.files[0];

    let awsSUrl = `https://godenty-backend-docs.s3.ap-south-2.amazonaws.com/docs/${fileName}`;

    let response = await fetch(awsSUrl, {
      method: "PUT",
      body: file,
    });

    if (response.ok) {
      setInputData({ ...inputData, certificateUrl: response?.url });
    }
  };

  const handleChange = ({ target: { value } }) => {
    setSelectedSpecialities(
      typeof value === "string" ? value.split(",") : value
    );
  };

  useEffect(() => {
    if (inputData.email === "sample@gmail.com") {
      setEmailExists(true);
    } else {
      setEmailExists(false);
    }
  }, [inputData.email]);

  return (
    <>
      {!activeUserID ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                lg: "row",
              },
              gap: "15px",
              mt: 2,
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
                gap: "10px",
              }}
            >
              <Button
                variant={tabIndex == 0 ? "contained" : "outlined"}
                size="medium"
                sx={{ borderRadius: 46 }}
                onClick={() => {
                  setTabIndex(0);
                  setPage(1);
                }}
              >
                Doctors
              </Button>
              <Button
                variant={tabIndex == 1 ? "contained" : "outlined"}
                size="medium"
                sx={{ borderRadius: 46 }}
                onClick={() => {
                  setTabIndex(1);
                  setPage(1);
                }}
              >
                Pending Approval
              </Button>
              <Button
                variant={tabIndex == 2 ? "contained" : "outlined"}
                size="medium"
                sx={{ borderRadius: 46 }}
                onClick={() => {
                  setTabIndex(2);
                  setPage(1);
                }}
              >
                Rejected
              </Button>
            </Box>
            <Box
              sx={{
                maxWidth: { xs: "100%", sm: 200, md: 200, lg: 200, xl: 200 },
              }}
            >
              <Button
                variant="contained"
                size="medium"
                fullWidth
                sx={{ borderRadius: 46 }}
                startIcon={<AddIcon />}
                onClick={handleClickOpen}
              >
                Create Doctor
              </Button>
            </Box>
          </Box>

          {tabIndex === 0 && (
            <Box>
              <Typography variant="h3" sx={{ fontSize: "22px", mt: 2, mb: 1 }}>
                Overview
              </Typography>
              <AdminDoctors
                doctors={doctors?.users}
                getDoctors={getDoctors}
                total={doctors?.total}
                currentPage={doctors?.currentPage}
                totalPages={doctors?.totalPages}
                overViewData={overViewData}
                setActiveUserID={setActiveUserID}
                page={page}
                setPage={setPage}
              />
            </Box>
          )}
          {tabIndex === 1 && (
            <AdminDoctorsPending
              doctors={doctors?.users}
              total={doctors?.total}
              currentPage={doctors?.currentPage}
              totalPages={doctors?.totalPages}
              getDoctors={getDoctors}
              setActiveUserID={setActiveUserID}
              page={page}
              setPage={setPage}
            />
          )}
          {tabIndex === 2 && (
            <AdminDoctorsRejected
              doctors={doctors?.users}
              total={doctors?.total}
              currentPage={doctors?.currentPage}
              totalPages={doctors?.totalPages}
              getDoctors={getDoctors}
            />
          )}

          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle
              sx={{
                m: 0,
                p: 2,
                fontWeight: 600,
                fontSize: 24,
                color: "#303030",
              }}
              id="customized-dialog-title"
            >
              Create Doctor
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "black",
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <Grid container spacing={4} sx={{ pr: 2 }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid container spacing={1} justifyContent="space-between">
                    <Grid
                      item
                      display="flex"
                      flexDirection="column"
                      xs={12}
                      sm={5.5}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#303030",
                          mb: 1,
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        Doctor Type
                      </Typography>
                      <FormControl sx={{ minWidth: 120 }}>
                        <Select
                          sx={{
                            borderRadius: 2,
                            backgroundColor: "white",
                            "& .MuiOutlinedInput-input": {
                              borderRadius: 2,
                              border: "none",
                              outline: "none",
                              height: "15px",
                              marginTop: "-0.2em",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderRadius: 2,
                              border: "none",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                          inputProps={{ id: "doctorType" }}
                          value={inputData.doctorType}
                          onChange={(e) => {
                            handleInput(e, "doctorType");
                            setSpecialities(
                              e.target.value == "MDS"
                                ? MDSSpecialities
                                : BDSSpecialities
                            );
                            setSelectedSpecialities([]);
                          }}
                          onFocus={(e) =>
                            e.currentTarget.classList.add("focused")
                          }
                          onBlur={(e) =>
                            e.currentTarget.classList.remove("focused")
                          }
                          displayEmpty
                        >
                          {inputData.doctorType === "" && (
                            <MenuItem value="" style={{ display: "none" }}>
                              <em
                                style={{
                                  color: "#C2C2C2",
                                  fontStyle: "normal",
                                  fontSize: 14,
                                  fontWeight: 400,
                                }}
                              >
                                Select Doctor Type
                              </em>
                            </MenuItem>
                          )}

                          {options.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                        <FormHelperText
                          sx={{
                            height: 10,
                            visibility:
                              showWarning && !inputData.doctorType
                                ? "visible"
                                : "hidden",
                          }}
                        >
                          Doctor type is required
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      display="flex"
                      flexDirection="column"
                      xs={12}
                      sm={5.5}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#303030",
                          mb: 1,
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        College Name
                      </Typography>
                      <OutlinedInput
                        placeholder="Type Here"
                        inputProps={{ id: "collegeName", maxLength: 100 }}
                        value={inputData.collegeName}
                        onChange={(e) => {
                          handleInput(e, "collegeName");
                        }}
                        sx={{
                          borderRadius: 2,
                          backgroundColor: "#FAFAFA",
                          "& .MuiOutlinedInput-input": {
                            borderRadius: 2,
                            border: "none",
                            outline: "none",
                            height: "15px",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: 2,
                            border: "none",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                      />
                      <FormHelperText
                        sx={{
                          height: 10,
                          visibility:
                            showWarning && !inputData.collegeName
                              ? "visible"
                              : "hidden",
                        }}
                      >
                        College name is required
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid container spacing={1} justifyContent="space-between">
                    <Grid
                      item
                      display="flex"
                      flexDirection="column"
                      xs={12}
                      sm={5.5}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#303030",
                          mb: 1,
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        Doctor Name
                      </Typography>
                      <OutlinedInput
                        placeholder="Type Here"
                        inputProps={{ id: "name", maxLength: 40 }}
                        value={inputData.name}
                        onChange={(e) => {
                          handleInput(e, "name");
                        }}
                        sx={{
                          borderRadius: 2,
                          backgroundColor: "white",
                          "& .MuiOutlinedInput-input": {
                            borderRadius: 2,
                            border: "none",
                            outline: "none",
                            height: "15px",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: 2,
                            border: "none",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                      />
                      <FormHelperText
                        sx={{
                          height: 10,
                          visibility:
                            showWarning && !inputData.name
                              ? "visible"
                              : "hidden",
                        }}
                      >
                        Doctor name is required
                      </FormHelperText>
                    </Grid>
                    <Grid
                      item
                      display="flex"
                      flexDirection="column"
                      xs={12}
                      sm={5.5}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#303030",
                          mb: 1,
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        Year of Graduation
                      </Typography>
                      <FormControl sx={{ minWidth: 120 }}>
                        <Select
                          inputProps={{ id: "passOutYear" }}
                          value={inputData.passOutYear}
                          onChange={(e) => {
                            handleInput(e, "passOutYear");
                          }}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                                width: 250,
                                overflowY: "auto",
                              },
                            },
                          }}
                          sx={{
                            borderRadius: 2,
                            backgroundColor: "white",
                            "& .MuiOutlinedInput-input": {
                              borderRadius: 2,
                              border: "none",
                              outline: "none",
                              height: "15px",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderRadius: 2,
                              border: "none",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                          onFocus={(e) =>
                            e.currentTarget.classList.add("focused")
                          }
                          onBlur={(e) =>
                            e.currentTarget.classList.remove("focused")
                          }
                          displayEmpty
                        >
                          {inputData.passOutYear === "" && (
                            <MenuItem value="" style={{ display: "none" }}>
                              <em
                                style={{
                                  color: "#C2C2C2",
                                  fontStyle: "normal",
                                  fontSize: 14,
                                  fontWeight: 400,
                                }}
                              >
                                Select Year
                              </em>
                            </MenuItem>
                          )}
                          {yearsList.map((year) => (
                            <MenuItem key={year} value={year}>
                              {year}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormHelperText
                        sx={{
                          height: 10,
                          visibility:
                            showWarning && !inputData.passOutYear
                              ? "visible"
                              : "hidden",
                        }}
                      >
                        Year of graduation is required
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid container spacing={1} justifyContent="space-between">
                    <Grid
                      item
                      display="flex"
                      flexDirection="column"
                      xs={12}
                      sm={5.5}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#303030",
                          mb: 1,
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        Phone Number
                      </Typography>
                      <OutlinedInput
                        placeholder="Type Here"
                        inputProps={{
                          id: "phoneNumber",
                          maxLength: 10,
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                        }}
                        value={inputData.phoneNumber}
                        onChange={(e) => {
                          handleInput(e, "phoneNumber");
                        }}
                        onInput={(e) => {
                          const target = e?.target;
                          target.value = e?.target?.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                        sx={{
                          borderRadius: 2,
                          backgroundColor: "white",
                          "& .MuiOutlinedInput-input": {
                            borderRadius: 2,
                            border: "none",
                            outline: "none",
                            height: "15px",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: 2,
                            border: "none",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                      />
                      <FormHelperText
                        sx={{
                          height: 10,
                          visibility:
                            showWarning && inputData?.phoneNumber?.length != 10
                              ? "visible"
                              : "hidden",
                        }}
                      >
                        Phone number must be 10 digits
                      </FormHelperText>
                    </Grid>
                    <Grid
                      item
                      display="flex"
                      flexDirection="column"
                      xs={12}
                      sm={5.5}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#303030",
                          mb: 1,
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        Work Type
                      </Typography>
                      <FormControl sx={{ minWidth: 120 }}>
                        <Select
                          inputProps={{ id: "workType" }}
                          value={inputData.workType}
                          onChange={(e) => {
                            handleInput(e, "workType");
                          }}
                          sx={{
                            borderRadius: 2,
                            backgroundColor: "white",
                            "& .MuiOutlinedInput-input": {
                              borderRadius: 2,
                              border: "none",
                              outline: "none",
                              height: "15px",
                              marginTop: "-0.2em",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderRadius: 2,
                              border: "none",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                          onFocus={(e) =>
                            e.currentTarget.classList.add("focused")
                          }
                          onBlur={(e) =>
                            e.currentTarget.classList.remove("focused")
                          }
                          displayEmpty
                        >
                          {inputData.workType === "" && (
                            <MenuItem value="" style={{ display: "none" }}>
                              <em
                                style={{
                                  color: "#C2C2C2",
                                  fontStyle: "normal",
                                  fontSize: 14,
                                  fontWeight: 400,
                                }}
                              >
                                Select Work Type
                              </em>
                            </MenuItem>
                          )}
                          {workTypeOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <FormHelperText
                        sx={{
                          height: 10,
                          visibility:
                            showWarning && !inputData.workType
                              ? "visible"
                              : "hidden",
                        }}
                      >
                        Work type is required
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid container spacing={1} justifyContent="space-between">
                    <Grid
                      item
                      display="flex"
                      flexDirection="column"
                      xs={12}
                      sm={5.5}
                      md={5.5}
                      lg={5.5}
                      xl={5.5}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#303030",
                          mb: 1,
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        Email
                      </Typography>
                      <OutlinedInput
                        placeholder="Type Here"
                        inputProps={{ id: "email" }}
                        value={inputData.email}
                        type="email"
                        onChange={(e) => {
                          handleInput(e, "email");
                        }}
                        sx={{
                          borderRadius: 2,
                          backgroundColor: "white",
                          "& .MuiOutlinedInput-input": {
                            borderRadius: 2,
                            border: "none",
                            outline: "none",
                            height: "15px",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: 2,
                            border: "none",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                        onInput={(event) => {
                          const inputData = event.target.value;
                          if (
                            inputData !== "" &&
                            !/^[a-zA-Z!@#$%^&*()]+$/?.test(inputData)
                          ) {
                            event.target.value = inputData.replace(/\s/g, "");
                          }
                        }}
                      />
                      <FormHelperText
                        sx={{
                          height: 10,
                          visibility:
                            showWarning && !inputData?.email
                              ? "visible"
                              : showWarning &&
                                !RegExp(
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
                                ).test(inputData?.email)
                              ? "visible"
                              : "hidden",
                        }}
                      >
                        {emailAlreadyExists
                          ? "Email Already Exists"
                          : "Enter valid email"}
                      </FormHelperText>
                    </Grid>
                    <Grid
                      item
                      display="flex"
                      flexDirection="column"
                      xs={12}
                      sm={5.5}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#303030",
                          mb: 1,
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        State Council REG Certificate
                      </Typography>
                      <OutlinedInput
                        inputProps={{ id: "certificateUrl" }}
                        value={
                          inputData.certificateUrl
                            ? inputData.certificateUrl
                            : ""
                        }
                        sx={{
                          borderRadius: 2,
                          backgroundColor: "#FAFAFA",
                          "& .MuiOutlinedInput-input": {
                            borderRadius: 2,
                            border: "none",
                            outline: "none",
                            height: "15px",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: 2,
                            border: "none",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                        placeholder="Upload Document"
                        endAdornment={
                          <InputAdornment position="end">
                            <img
                              src={uploadfileIcon}
                              alt="Upload Image"
                              style={{ cursor: "pointer" }}
                              onClick={handleUploadButtonClick}
                            />
                            <input
                              type="file"
                              style={{ display: "none" }}
                              onChange={uploadFile}
                              ref={fileInputRef}
                              placeholder="Upload File"
                            />
                          </InputAdornment>
                        }
                      />
                      <FormHelperText
                        sx={{
                          height: 10,
                          visibility:
                            showWarning && !inputData.certificateUrl
                              ? "visible"
                              : "hidden",
                        }}
                      >
                        Certificate is required
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid container spacing={1} justifyContent="space-between">
                    <Grid
                      item
                      display="flex"
                      flexDirection="column"
                      xs={12}
                      sm={5.5}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <Typography
                            variant="h6"
                            sx={{
                              color: "#303030",
                              mb: 1,
                              fontSize: 14,
                              fontWeight: 400,
                            }}
                          >
                            Clinical Experience
                          </Typography>
                          <OutlinedInput
                            placeholder="Years"
                            value={clinicalexp.years}
                            onChange={(e) => {
                              handleInputExp(e, "years");
                            }}
                            onInput={(e) => {
                              const target = e?.target;
                              target.value = e?.target?.value
                                ?.replace(/\D/g, "")
                                .slice(0, 2);
                            }}
                            sx={{
                              borderRadius: 2,
                              backgroundColor: "white",
                              "& .MuiOutlinedInput-input": {
                                borderRadius: 2,
                                border: "none",
                                outline: "none",
                                height: "15px",
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: 2,
                                border: "none",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "none",
                                },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                            }}
                          />
                          <FormHelperText
                            sx={{
                              height: 10,
                              visibility:
                                showWarning && !clinicalexp.years
                                  ? "visible"
                                  : "hidden",
                            }}
                          >
                            Minimum 0 is required
                          </FormHelperText>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ mt: "2em" }}>
                          <OutlinedInput
                            placeholder="Months"
                            onInput={(e) => {
                              const target = e?.target;
                              target.value = e?.target?.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                              const intValue = parseInt(target.value);
                              if (intValue < 0 || intValue > 11) {
                                e.preventDefault();
                              } else {
                                handleInputExp(e, "months");
                              }
                            }}
                            value={clinicalexp.months}
                            sx={{
                              borderRadius: 2,
                              backgroundColor: "white",
                              "& .MuiOutlinedInput-input": {
                                borderRadius: 2,
                                border: "none",
                                outline: "none",
                                height: "15px",
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderRadius: 2,
                                border: "none",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "none",
                                },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                border: "none",
                              },
                            }}
                          />
                          <FormHelperText
                            sx={{
                              height: 10,
                              visibility:
                                showWarning && !clinicalexp.months
                                  ? "visible"
                                  : "hidden",
                            }}
                          >
                            Minimum 0 is required
                          </FormHelperText>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      display="flex"
                      flexDirection="column"
                      xs={12}
                      sm={5.5}
                      md={5.5}
                      lg={5.5}
                      xl={5.5}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#303030",
                          mb: 1,
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        Expertise
                      </Typography>
                      <FormControl sx={{ minWidth: 120 }}>
                        <Select
                          multiple
                          inputProps={{ id: "specialistIn" }}
                          value={selectedSpecialities}
                          onChange={handleChange}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                maxHeight: 200,
                                width: 250,
                                overflowY: "auto",
                              },
                            },
                          }}
                          sx={{
                            borderRadius: 2,
                            backgroundColor: "white",
                            "& .MuiOutlinedInput-input": {
                              borderRadius: 2,
                              border: "none",
                              outline: "none",
                              height: "15px",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderRadius: 2,
                              border: "none",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                          }}
                          onFocus={(e) =>
                            e.currentTarget.classList.add("focused")
                          }
                          onBlur={(e) =>
                            e.currentTarget.classList.remove("focused")
                          }
                          displayEmpty
                          placeholder="Select Expertise"
                          renderValue={(selected) => {
                            if (selected.length === 0) {
                              return (
                                <p
                                  style={{
                                    color: "rgb(194, 194, 194)",
                                    fontStyle: "normal",
                                    fontSize: "14px",
                                    fontWeight: 400,
                                  }}
                                >
                                  Select Expertise
                                </p>
                              );
                            }

                            return selected.join(", ");
                          }}
                        >
                          {specialities.map((item) => (
                            <MenuItem key={item} value={item}>
                              {item}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>

                      <FormHelperText
                        sx={{
                          height: 10,
                          visibility:
                            showWarning && !selectedSpecialities.length
                              ? "visible"
                              : "hidden",
                        }}
                      >
                        Expertise is required
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ padding: 0 }}>
              <Grid
                container
                spacing={2}
                justifyContent={"center"}
                sx={{ mt: 1, mb: 1 }}
              >
                <Grid item xs={10} sm={6} md={6} lg={6} xl={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    size="medium"
                    sx={{
                      borderRadius: 24,
                    }}
                    onClick={handleSubmitModal}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
          <Dialog
            onClose={handleCloseSubmit}
            aria-labelledby="customized-dialog-title"
            open={submitModal}
            fullWidth={true}
            sx={{
              "& .MuiDialog-paper": {
                padding: "0px",
                borderRadius: "15px",
                height: "400px",
                width: "450px",
              },
            }}
          >
            <IconButton
              aria-label="close"
              onClick={handleCloseSubmit}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "black",
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent className={classes.dialogContent}>
              <img
                src={sent}
                alt="Sent"
                style={{ width: "134px", height: "134px" }}
                className={classes.image}
              />
              <Typography
                align="center"
                sx={{
                  fontSize: "30px",
                  fontWeight: "600",
                  color: "#F98247",
                  fontFamily: "Poppins",
                  marginTop: "5px",
                  padding: "0px",
                }}
              >
                Success
              </Typography>
              <Typography
                align="center"
                sx={{
                  fontSize: "24px",
                  fontWeight: "400",
                  color: "black",
                  fontFamily: "Poppins",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                The doctor has been added Successfully.
              </Typography>
            </DialogContent>
          </Dialog>
        </>
      ) : (
        <DoctorDetails
          role={3}
          activeUserID={activeUserID}
          setActiveUserID={setActiveUserID}
        />
      )}
    </>
  );
};

export default Doctor;
