import CloseIcon from "@mui/icons-material/Close";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

import api from "../../api";
import cancelIcon from "../../assets/images/cancelicon.png";
import doctoremail from "../../assets/images/doctoremail.png";
import doctorphone from "../../assets/images/doctorphone.png";
import doctorprofile from "../../assets/images/doctorprofile.png";
import fileimage from "../../assets/images/fileimage.png";
import location from "../../assets/images/location.png";
import ownericon from "../../assets/images/ownericon.png";
import sent from "../../assets/images/sent.png";
import website from "../../assets/images/website.png";
import { styles } from "../../layouts/Styles";

const AdminClinicsPending = ({
  clinics,
  total,
  currentPage,
  getClinics,
  totalPages,
  page = '',
  setPage = null,
  setActiveUserID = null
}) => {
  const {
    palette: { grey },
  } = useTheme();

  const classes = styles();

  const [approveModal, setApproveModal] = React.useState(false);
  const [rejectModal, setRejectModal] = React.useState(false);
  const [submitReject, setsubmitReject] = React.useState(false);
  const [clinicOpen, setClinicOpen] = React.useState(false);
  const [rejection_reason, setReason] = React.useState("");
  const [selectedClinic, setSelectedClinic] = React.useState(null);

  const changeUserStatus = async (id, status, reason) => {
    const res = await api.changeUserStatus(status, id, reason);
    if (res.data.success) {
      setSelectedClinic(null);
      if (!reason) {
        handleApproveModal();
      }
    }
  };

  const [limit, setLimit] = useState(10);

  useEffect(() => {
    getClinics(page, limit);
  }, [page, limit]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setLimit(event.target.value);
  };

  const handleClinicOpen = (obj) => {
    setClinicOpen(true);
    setSelectedClinic(obj);
  };
  const handleClinicClose = () => {
    setClinicOpen(false);
  };
  const handleCloseApprove = () => {
    setApproveModal(false);
  };
  const handleApproveModal = () => {
    setApproveModal(true);
    handleClinicClose();
  };
  const handleRejectModal = () => {
    setRejectModal(true);

    handleClinicClose();
  };
  const handleCloseReject = () => {
    setRejectModal(false);
    setReason("");
  };
  const handleOpensubmitRejectModal = async () => {
    await changeUserStatus(selectedClinic.id, "REJECTED", rejection_reason);
    setsubmitReject(true);
    handleCloseReject();
  };
  const handleClosesubmitReject = () => {
    setsubmitReject(false);
  };

  const handleReason = (e) => {
    setReason(e.target.value);
  };
  const documentFileName = "License.pdf";

  let clinicsdata = [
    {
      id: 1,
      "clinic name": "John Doe",
      "phone number": 251111129,
      email: "sample@test.com",
      website: "Clinic Website",
      address: "Madhapur",
      status: "View",
    },
  ];

  const columnsclinic = Object.keys(clinicsdata[0]);
  return (
    <Fragment>
      <Paper sx={{ p: "2em", mt: 6, borderRadius: 2 }}>
        <Typography
          variant="h3"
          sx={{ fontSize: "22px", fontWeight: 500, mt: 1, mb: 2 }}
        >
          Clinic List{" "}
          <span style={{ fontSize: "22px", fontWeight: 700, mt: 1, mb: 2 }}>
            ({total})
          </span>
        </Typography>

        <TableContainer>
          <Table style={{ width: "1200px" }}>
            <TableHead>
              <TableRow>
                {columnsclinic?.map((column) => (
                  <TableCell key={column} className={classes.tableCell}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {clinics?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.tableCellBody}>
                    {row?.id || "-"}
                  </TableCell>
                  <TableCell className={classes.tableCellBodyLink}>
                    {/* <Link to={`/clinic/clinicDetails/${row?.id}`}>
                      {row?.userDetails?.clinicName || "-"}
                    </Link> */}
                    <Link to={`#`} onClick={() => setActiveUserID(row?.id)}>
                      {row?.userDetails?.clinicName || "-"}
                    </Link>
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {row?.phoneNumber || "-"}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {row?.email || "-"}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {row?.userDetails?.website || "-"}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {row?.userDetails?.address || "-"}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    <Button
                      onClick={() => {
                        handleClinicOpen(row);
                      }}
                      variant="contained"
                      size="medium"
                      sx={{
                        backgroundColor: "#F98247",
                        borderRadius: 30,
                        padding: "8px 46px 8px 46px",
                        "&:hover": { backgroundColor: "#F98247" },
                      }}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={total}
          rowsPerPage={limit}
          page={currentPage - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <Dialog
        onClose={handleClinicClose}
        aria-labelledby="customized-dialog-title"
        open={clinicOpen}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          sx={{ m: 0, p: 2, fontWeight: 600, fontSize: 20, color: "#303030" }}
          id="customized-dialog-title"
        >
          Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClinicClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid container spacing={3} alignItems="center">
            <Grid item className={classes.imageContainer}>
              <img
                src={doctorprofile}
                style={{ width: "84px", height: "84px" }}
              />
              <Typography variant="h6" className={classes.textOverlay}>
                {selectedClinic?.userDetails?.clinicName &&
                  selectedClinic?.userDetails?.clinicName[0]?.toUpperCase()}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                sx={{ fontSize: "20px", fontWeight: "600", color: "#1A202C" }}
              >
                {selectedClinic?.userDetails?.clinicName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: "40px" }}>
            <Grid item xs={3} sm={1} md={1} lg={1} xl={1} sx={{ mr: 0 }}>
              <img
                src={ownericon}
                alt="owner"
                style={{ width: 40, height: 40 }}
              />
            </Grid>
            <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "400", color: "#858585" }}
              >
                Owner Name
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "500", color: "#303030" }}
              >
                {selectedClinic?.name}
              </Typography>
            </Grid>

            <Grid
              item
              xs={3}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              sx={{ pl: "2px", mr: 0 }}
            >
              <img
                src={doctorphone}
                style={{ width: 40, height: 40 }}
                alt="Phone"
              />
            </Grid>
            <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "400", color: "#858585" }}
              >
                Phone Number
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "500", color: "#303030" }}
              >
                {selectedClinic?.phoneNumber}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              sx={{ pl: "2px", mr: 0 }}
            >
              <img
                src={doctoremail}
                style={{ width: 40, height: 40 }}
                alt="Email"
              />
            </Grid>
            <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "400", color: "#858585" }}
              >
                Email Address
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "500", color: "#303030" }}
              >
                {selectedClinic?.email}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: "30px" }}>
            <Grid item xs={3} sm={1} md={1} lg={1} xl={1} sx={{ mr: 0 }}>
              <img src={website} alt="Web" style={{ width: 40, height: 40 }} />
            </Grid>
            <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "400", color: "#858585" }}
              >
                Website Name
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "500", color: "#303030" }}
              >
                {selectedClinic?.userDetails?.website}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              sx={{ pl: "2px", mr: 0 }}
            >
              <img src={location} style={{ width: 40, height: 40 }} alt="Loc" />
            </Grid>
            <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "400", color: "#858585" }}
              >
                Address
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "500", color: "#303030" }}
              >
                {selectedClinic?.userDetails?.address}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              sx={{ pl: "2px", mr: 0 }}
            >
              <img src={location} style={{ width: 40, height: 40 }} alt="Loc" />
            </Grid>
            <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "400", color: "#858585" }}
              >
                Google Map Location
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "500", color: "#303030" }}
              >
                {selectedClinic?.userDetails?.googleMapLocation}
              </Typography>
            </Grid>
          </Grid>

          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "600",
              color: "#858585",
              mt: 1,
            }}
          >
            License
          </Typography>
          <Grid
            display={"flex"}
            flexWrap={"wrap"}
            alignItems={"center"}
            gap={1}
            mt={1}
          >
            {selectedClinic?.userDetails?.licenseUrl ? (
              <Button
                variant="outlined"
                // onClick={handleOpen}
                size="large"
                sx={{
                  borderRadius: "25px",
                  border: "1px solid #EDEDED",
                  background: "white",
                  color: "black",
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={fileimage}
                  style={{ width: 24, height: 24, marginRight: 4 }}
                />
                {documentFileName}
              </Button>
            ) : (
              <Typography
                sx={{ color: "#858585", fontSize: "14px", fontWeight: 500 }}
              >
                No license available
              </Typography>
            )}

            {selectedClinic?.userDetails?.licenseUrl ? (
              <Link
                style={{
                  // backgroundColor: "#F98247",
                  borderRadius: "30px",
                  color: "#000",
                  border: "1px solid #EDEDED",
                  width: "60px",
                  height: "45px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                to={`${selectedClinic?.userDetails?.licenseUrl || ""}`}
                target="_blank"
                download
              >
                <DownloadRoundedIcon />
              </Link>
            ) : (
              ""
            )}
          </Grid>
          {/* <DocumentViewer file={documentFileUrl} fileName={documentFileName} /> */}
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-start", mt: "2em" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
                md: "row",
                lg: "row",
                xl: "row",
              },
              gap: "10px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#F98247",
                borderRadius: "30px",
                color: "white",
                fontSize: 13,
                fontWeight: 300,
                padding: "12px 50px 12px 50px",
                marginRight: "45px",
                marginLeft: "7px",
                "&:hover": { backgroundColor: "#F98247" },
                maxWidth: "150px",
              }}
              onClick={() => {
                changeUserStatus(selectedClinic.id, "APPROVED");
              }}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#E91717",
                borderRadius: "30px",
                color: "white",
                fontSize: 13,
                fontWeight: 300,
                padding: "12px 50px 12px 50px",
                "&:hover": { backgroundColor: "#E91717" },
                maxWidth: "150px",
                marginLeft: "7px",
              }}
              // type="submit"
              onClick={handleRejectModal}
            >
              Reject
            </Button>
          </Box>
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={handleCloseApprove}
        aria-labelledby="customized-dialog-title"
        open={approveModal}
        fullWidth={true}
        // maxWidth="xs"
        sx={{
          "& .MuiDialog-paper": {
            // width: "672px",
            padding: "0px",
            borderRadius: "15px",
            height: "400px",
            width: "450px",
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseApprove}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className={classes.dialogContent}>
          <img
            src={sent}
            alt="Sent"
            style={{ width: "134px", height: "134px" }}
            className={classes.image}
          />
          <Typography
            align="center"
            sx={{
              fontSize: "30px",
              fontWeight: "600",
              color: "#F98247",
              fontFamily: "Poppins",
              marginTop: "5px",
              padding: "0px",
            }}
          >
            Success
          </Typography>
          <Typography
            align="center"
            sx={{
              // marginTop: "10px",
              fontSize: "24px",
              fontWeight: "400",
              color: "black",
              fontFamily: "Poppins",
              margin: "0px",
              padding: "0px",
            }}
          >
            The Clinic has been approved Successfully.
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={handleCloseReject}
        aria-labelledby="customized-dialog-title"
        open={rejectModal}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, fontWeight: 600, fontSize: 20, color: "#303030" }}
          id="customized-dialog-title"
        >
          Enter the Rejection Reason
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseReject}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xl={12} md={12} xs={12} sm={12}>
              <Typography variant="caption1">Description</Typography>
            </Grid>

            <Grid item xl={12} md={12} xs={12} sm={12}>
              <TextField
                id="outlined-multiline-flexible"
                label="Type Here"
                multiline
                rows={4}
                variant="outlined"
                className={classes.bgColorTextField}
                fullWidth
                value={rejection_reason}
                onChange={handleReason}
                inputProps={{ maxLength: 200 }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-start" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#F98247",
              borderRadius: "30px",
              color: "white",
              fontSize: 13,
              fontWeight: 300,
              padding: "12px 50px 12px 50px",
              marginTop: "38px",
              marginLeft: "8px",
              "&:hover": { backgroundColor: "#F98247" },
            }}
            onClick={handleOpensubmitRejectModal}
            disabled={!rejection_reason}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={handleClosesubmitReject}
        aria-labelledby="customized-dialog-title"
        open={submitReject}
        fullWidth={true}
        // maxWidth="xs"
        sx={{
          "& .MuiDialog-paper": {
            // width: "672px",
            padding: "0px",
            borderRadius: "15px",
            height: "300px",
            width: "450px",
            margin: "0px",
          },
          "& .MuiDialogContent-root": {
            margin: "0px",
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClosesubmitReject}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{
            height: "350px !important",
            mt: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={cancelIcon}
            alt="Cancelled"
            style={{ width: "134px", height: "134px" }}
            className={classes.image}
          />
          <Typography
            variant="h5"
            align="center"
            style={{ color: "red", fontSize: "40px" }}
          >
            Cancelled
          </Typography>
          <Typography
            align="center"
            style={{
              // marginTop: "32px",
              fontSize: "20px",
              fontWeight: "400",
              color: "black",
            }}
          >
            The Clinic has been Cancelled.
          </Typography>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default AdminClinicsPending;
