const getStringInitials = (text) => {
  let textArr = text?.trim().split(" ");
  let initials = "";
  textArr?.map((item) => {
    initials = initials + item?.charAt(0);
  });

  return initials.toUpperCase();
};

export default getStringInitials;
