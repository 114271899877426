import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Chip, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useState } from "react";
import HTMLRenderer from "react-html-renderer";

import api from "../../api";
import clockicon from "../../assets/images/clockicon.png";
import expirience from "../../assets/images/experience.png";
import location from "../../assets/images/location.png";
import staricon from "../../assets/images/staricon.png";
import StarIcon from "../../assets/images/staricon.png";
import { styles } from "../../layouts/Styles";
import { getPayType } from "../../utils/getPayType";
import getStringInitials from "../../utils/getStringInitials";

const JobDetailsModal = ({
  handleClose,
  open,
  clinicInfo = {},
  setOpenModal = null,
}) => {
  const [isLoading, setLoading] = useState(false);
  const classes = styles();
  const sendProposal = async () => {
    try {
      setLoading(true);
      const { data } = await api.createProposal({
        _post: open?.id,
      });
      setLoading(false);
      if (!data?.success) {
        return;
      }
      setOpenModal(true);
      handleClose();
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const renderChips = (skills) => {
    let arr = skills?.split(",") || [];
    return arr.map((item, index) => (
      <Box
        key={index}
        sx={{
          p: "4px 6px",
          backgroundColor: "#F8F8F8",
          color: "#004E89",
          fontWeight: 500,
          fontSize: 13,
          borderRadius: "4px",
        }}
      >
        {item}
      </Box>
    ));
  };
  return (
    <>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open?.id}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          sx={{ m: 0, p: 1, fontWeight: 600, fontSize: 20, color: "#F98247" }}
          id="customized-dialog-title"
        >
          {open?.jobTitle || "Job Title"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogContent
          sx={{
            p: {
              xs: 0,
              md: 1,
            },
            display: "flex",
            flexDirection: "column",
            gap: 1.5,
          }}
        >
          <Grid display={"flex"} alignItems="center" gap={1}>
            <Grid item className={classes.imageContainer}>
              {!open?.clinicOwner?.imageUrl ? (
                <img
                  src={open?.clinicOwner?.imageUrl || "-"}
                  style={{ width: 60, height: 60, borderRadius: "12px" }}
                />
              ) : (
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{
                    height: 60,
                    width: 60,
                    backgroundColor: "#004E89",
                    borderRadius: "12px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: 22,
                      fontWeight: 500,
                    }}
                  >
                    {getStringInitials(open?.clinicDetails?.clinicName)}
                  </Typography>
                </Box>
              )}
              <Typography variant="h6" className={classes.textOverlay}>
                {clinicInfo?.name
                  ? getStringInitials(clinicInfo?.name || "")
                  : ""}
              </Typography>
            </Grid>
            <Grid>
              <Typography
                sx={{
                  color: "#004E89",
                  fontSize: "20px",
                  fontWeight: 600,
                }}
              >
                {open?.clinicDetails?.clinicName || "Clinic Name"}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            display={"flex"}
            justifyContent={"space-between"}
            flexWrap={"wrap"}
            rowGap={1}
            columnGap={2}
          >
            <Grid display={"flex"} alignItems={"center"} gap={1}>
              <Grid item>
                <img
                  src={clockicon}
                  style={{ width: 30, height: 30 }}
                  alt="_clock"
                />
              </Grid>
              <Grid>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "400", color: "#858585" }}
                >
                  Work Hours{" "}
                </Typography>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "500", color: "#303030" }}
                >
                  {open?.workingHour || "-"}
                </Typography>
              </Grid>
            </Grid>

            <Grid display={"flex"} alignItems={"center"} gap={1}>
              <Grid>
                <img
                  src={location}
                  style={{ width: 30, height: 30 }}
                  alt="_loc"
                />
              </Grid>
              <Grid item>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "400", color: "#858585" }}
                >
                  Location
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: "500",
                    color: "#303030",
                    cursor: "pointer",
                  }}
                  onClick={() => window.open(open?.locationUrl, "_blank")}
                >
                  {open?.locationName || "-"}
                </Typography>
              </Grid>
            </Grid>

            <Grid display={"flex"} alignItems={"center"} gap={1}>
              <Grid>
                <img
                  src={expirience}
                  style={{ width: 30, height: 30 }}
                  alt="_clock"
                />
              </Grid>
              <Grid item>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "400", color: "#858585" }}
                >
                  Eligibility
                </Typography>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "500", color: "#303030" }}
                >
                  {open?.eligibility || "-"}
                </Typography>
              </Grid>
            </Grid>

            <Grid display={"flex"} alignItems={"center"} gap={1}>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
                sx={{
                  backgroundColor: "#004E89",
                  width: 30,
                  height: 30,
                  borderRadius: "100%",
                }}
              >
                <AttachMoneyOutlinedIcon style={{ color: "#fff" }} />
              </Box>
              <Grid item>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "400", color: "#858585" }}
                >
                  Salary
                </Typography>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "500", color: "#303030" }}
                >
                  {getPayType({
                    requestType: open?.requestType,
                    minSalary: open?.minimumSalary,
                    maxSalary: open?.maximumSalary,
                    hourly: open?.hourly,
                  })}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid display={"flex"} alignItems={"center"} gap={0.5}>
            <img src={StarIcon} height={14} alt="_icon" />
            <Typography
              sx={{
                color: "#E78756",
                fontWeight: 500,
                mt: "2px",
                fontSize: 14,
              }}
            >
              {open?.requestType || "-"}
            </Typography>
          </Grid>

          <Grid>
            {open?.postDetails?.requestType ? (
              <Grid item>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={staricon}
                    style={{ width: "16.67px", height: "16.67px" }}
                  />
                  <Typography
                    sx={{
                      color: "#F98247",
                      fontSize: "16px",
                      fontWeight: 600,
                      ml: 1,
                    }}
                  >
                    {open?.requestType}
                  </Typography>
                </Box>
              </Grid>
            ) : (
              ""
            )}

            {open?.price ? (
              <Grid item>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={staricon}
                    style={{ width: "16.67px", height: "16.67px" }}
                  />
                  <Typography
                    sx={{
                      color: "#F98247",
                      fontSize: "16px",
                      fontWeight: 600,
                      ml: 1,
                    }}
                  >
                    Rs.{open?.postDetails?.price} (Per Hour)
                  </Typography>
                </Box>
              </Grid>
            ) : (
              ""
            )}

            {open?.postDetails?.specilistIn ? (
              <Grid item>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Grid container sx={{ mt: "-0.5em" }}>
                    <Grid item sx={{ mt: "0.7em" }}>
                      <img
                        src={staricon}
                        style={{ width: "16.67px", height: "16.67px" }}
                      />
                    </Grid>
                    <Grid item>
                      <Chip
                        label={open?.postDetails?.specilistIn}
                        style={{ margin: "4px" }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ) : (
              ""
            )}
          </Grid>

          <Grid>
            <Typography variant="h3" sx={{ fontSize: 20 }}>
              Skills
            </Typography>
            <Grid display={"flex"} mt={0.5} gap={1} flexWrap={"wrap"}>
              {open?.skills ? renderChips(open?.skills) : "-"}
            </Grid>
          </Grid>

          <Grid>
            <Typography variant="h3" sx={{ fontSize: 20 }}>
              Holidays
            </Typography>
            <Grid mt={0.5} display={"flex"} gap={1} flexWrap={"wrap"}>
              {open?.holidays ? renderChips(open?.holidays) : "-"}
            </Grid>
          </Grid>

          <Grid display={"flex"} flexDirection="column">
            <Grid item>
              <Typography variant="h3" sx={{ fontSize: 20 }}>
                Job Description
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                sx={{
                  fontSize: 14,
                  color: "rgba(118, 121, 128, 1)",
                  minHeight: "80px",
                  maxHeight: "150px",
                  overflowY: "auto",
                }}
              >
                {open?.jobDescription ? (
                  <HTMLRenderer html={open?.jobDescription || "<p>...</p>"} />
                ) : (
                  "-"
                )}
              </Typography>
            </Grid>
          </Grid>

          <Grid>
            <Button
              disabled={isLoading}
              variant="contained"
              sx={{
                backgroundColor: "#F98247",
                borderRadius: "40px",
                color: "white",
                fontSize: 13,
                fontWeight: 300,
                "&:hover": { backgroundColor: "#F98247" },
                width: "180px",
              }}
              onClick={sendProposal}
            >
              {isLoading ? <CircularProgress size={20} /> : "Send Proposal"}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default JobDetailsModal;

{
  /* {!isHistory && (
  <Grid container Spacing={1}>
    <Grid item sx={{ mt: "1.5em" }}>
      <Typography sx={{ fontSize: "16px", color: "#303030" }}>
        Order Status
      </Typography>
    </Grid>
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <FormControl sx={{ minWidth: 320 }}>
        <Select
          disabled={isDisabled}
          value={selectedOption}
          onChange={handleOptionChange}
          sx={{
            borderRadius: 2,
            backgroundColor: "white",
            "& .MuiOutlinedInput-input": {
              borderRadius: 2,
              border: "none",
              outline: "none",
              height: "15px",
              marginTop: "-0.2em",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: 2,
              border: "none",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
          onFocus={(e) => e.currentTarget.classList.add("focused")}
          onBlur={(e) => e.currentTarget.classList.remove("focused")}
          displayEmpty
          onOpen={handleDropdownOpen}
          onClose={handleDropdownClose}
        >
          <MenuItem value="" style={{ display: "none" }}>
            <em
              style={{
                color: "#C2C2C2",
                fontStyle: "normal",
                fontSize: 14,
                fontWeight: 400,
              }}
            >
              Select Status
            </em>
          </MenuItem>

          <MenuItem value="done">
            <Typography style={{ color: "green", fontWeight: "400" }}>
              Job Done
            </Typography>
          </MenuItem>
          <MenuItem value="cancel">
            <Typography style={{ color: "red", fontWeight: "400" }}>
              Appointment Cancel
            </Typography>
          </MenuItem>
        </Select>
      </FormControl>
    </Grid>
    <Grid container spacing={1} sx={{ mt: "3.1em" }}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
    </Grid>
  </Grid>
)} */
}
