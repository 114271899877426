import { Box, Button, Divider, Grid, LinearProgress, Paper, Typography } from "@mui/material";
import Rating from "@mui/material/Rating";
import moment from "moment";
import React, { useEffect, useState } from "react";

import api from "../../api";
import clinicavatar from "../../assets/images/clinicavatar.png";
import clockicon from "../../assets/images/clockicon.png";
import doctorphone from "../../assets/images/doctorphone.png";
import overviewicon from "../../assets/images/overviewicon.png";
import Calendar from "../../components/calendar/Calendar";
import ClinicDashModal from "../../components/Modals/ClinicDashModal";
import ClinicMyBookings from "../../components/Modals/ClinicMyBookings";
import ProposalDetailsCard from "./ProposalDetailsCard";

const ClinicDashboard = () => {
  const [open, setOpen] = React.useState(false);
  const [openView, setOpenView] = React.useState(false);
  const [dashboardData, setDashboardData] = useState({});
  const [upcomingBookings, setUpcomingBookings] = useState([]);
  const [selectedProposal, setSelectedProposal] = useState({});
  const [selectedData, setSelectedData] = useState({});
  const [pendingProposals, setPendingProposals] = useState([]);

  const [isLoading, setLoading] = useState(false);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const getBookingByDate = async () => {
    try {
      let queryString =
        fromDate && toDate
          ? `startDate=${moment(new Date(fromDate).toISOString()).format(
              "YYYY-MM-DD"
            )}&endDate=${moment(new Date(toDate).toISOString()).format(
              "YYYY-MM-DD"
            )}`
          : "";
      const { data } = await api.getClinicBookingByDate(queryString);
      if (data?.success) {
        setUpcomingBookings(data?.data?.upcomingBookingDetails);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1 and pad with '0' if needed
      const day = String(currentDate.getDate()).padStart(2, "0"); // Pad with '0' if needed
      const formattedDate = `${year}-${month}-${day}`;
      const res = await api.getClinicDashboardData();
      const res1 = await api.getProposalsByStatus("PENDING");
      const res2 = await api.getClinicBookingByDate(formattedDate);
      setLoading(false);
      if (res.data.success) {
        setDashboardData(res.data.data);
      }
      if (res1.data.success) {
        setPendingProposals(res1.data.data.proposalData);
      }
      if (res2.data.success) {
        setUpcomingBookings(res2.data.data.upcomingBookingDetails);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpenView = () => {
    setOpenView(true);
  };
  const handleCloseView = () => {
    setOpenView(false);
  };

  useEffect(() => {
    if (localStorage.getItem("auth")) {
      getData();
    }
  }, []);

  useEffect(() => {
    if (toDate) {
      getBookingByDate();
    }
  }, [toDate]);

  return (
    <>
      {open ? (
        <ClinicDashModal
          open={open}
          selectedProposal={selectedProposal}
          handleClose={handleClose}
          setOpen={setOpen}
        />
      ) : null}
      {openView ? (
        <ClinicMyBookings
          open={openView}
          selectedData={selectedData}
          handleClose={handleCloseView}
        />
      ) : null}
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={7} lg={7.5} xl={8}>
          <Typography
            sx={{
              fontSize: "22px",
              my: 1,
              lineHeight: "36px",
              fontWeight: 600,
            }}
          >
            Overview
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={12} lg={4}>
              <Paper sx={{ p: 2, borderRadius: "12px" }}>
                <Box sx={{ mb: "0.5em" }}>
                  <img src={overviewicon} style={{ width: 44, height: 44 }} />
                </Box>
                <Typography
                  variant="h6"
                  sx={{ fontSize: "14px", fontWeight: 500 }}
                >
                  Total MDS
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    fontSize: 30,
                    lineHeight: "30px",
                  }}
                >
                  {dashboardData?.totalMDS || 0}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4} md={12} lg={4}>
              <Paper sx={{ p: 2, borderRadius: "12px" }}>
                <Box sx={{ mb: "0.5em" }}>
                  <img src={overviewicon} style={{ width: 44, height: 44 }} />
                </Box>
                <Typography
                  variant="h6"
                  sx={{ fontSize: "14px", fontWeight: 500 }}
                >
                  Total BDS
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    fontSize: 30,
                    lineHeight: "30px",
                  }}
                >
                  {dashboardData?.totalBDS || 0}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4} md={12} lg={4}>
              <Paper sx={{ p: 2, borderRadius: "12px" }}>
                <Box sx={{ mb: "0.5em" }}>
                  <img src={overviewicon} style={{ width: 44, height: 44 }} />
                </Box>
                <Typography
                  variant="h6"
                  sx={{ fontSize: "14px", fontWeight: 500 }}
                >
                  Total Revenue
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    fontSize: 30,
                    lineHeight: "30px",
                  }}
                >
                  {dashboardData?.totalRevenue || 0}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Typography
            svariant="h3"
            sx={{ fontSize: "22px", mt: 2, fontWeight: 600 }}
          >
            Proposals
          </Typography>

          {isLoading && <LinearProgress sx={{ my: 1 }} />}

          <Grid display={"flex"} flexDirection={"column"} gap={1} mt={1}>
            {pendingProposals.length > 0 ? (
              pendingProposals?.map((proposal, index) => (
                <ProposalDetailsCard
                  proposal={proposal}
                  index={index}
                  setSelectedProposal={setSelectedProposal}
                  handleOpen={handleOpen}
                  key={index}
                />
              ))
            ) : !isLoading ? (
              <Typography
                sx={{
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "30px",
                }}
              >
                No data available
              </Typography>
            ) : (
              ""
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={5} lg={4.5} xl={4}>
          <Paper sx={{ p: "1em", mt: 5, ml: 1 }}>
            <Box sx={{ mb: 2 }}>
              <Typography
                sx={{
                  fontSize: "18px",
                  // mt: "0.5em",
                  mb: "0.5em",
                  fontWeight: 600,
                }}
              >
                Upcoming Bookings
              </Typography>
              <Divider sx={{ mt: "0.5em" }} />
              <Calendar setFromDate={setFromDate} setToDate={setToDate} />
            </Box>
            {upcomingBookings.map((booking, index) => (
              <Grid
                sx={{
                  position: "relative",
                  borderBottom: "2px dashed #B3B3B3",
                  paddingBottom: "12px",
                  marginBottom: "12px",
                }}
                key={index}
              >
                <Grid>
                  <Grid display={"flex"}>
                    <Grid>
                      <img
                        width={50}
                        src={booking?.doctor?.imageUrl || clinicavatar}
                        alt="_profile"
                      />
                    </Grid>

                    <Grid display={"flex"} flexWrap={"wrap"}>
                      <Grid>
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: 600,
                            ml: 1,
                            color: "#004E89",
                          }}
                        >
                          {booking?.doctor?.name || "-"}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#7E8299",
                            fontSize: "13px",
                            fontWeight: 500,
                            ml: 1,
                            maxWidth: "90%",
                          }}
                          noWrap
                        >
                          {booking?.doctorDetails?.specialistIn || "-"}
                        </Typography>
                      </Grid>
                      <Grid>
                        <div
                          style={{
                            display: "flex",
                            height: "fit-content",
                            alignItems: "center",
                            marginLeft: "6px",
                          }}
                        >
                          <Rating
                            name="half-rating"
                            readOnly
                            value={booking?.doctor?.rating || 0}
                            precision={0.5}
                            sx={{ fontSize: 15 }}
                          />
                          <Typography
                            sx={{
                              color: "#1D2343",
                              fontSize: "12px",
                              fontWeight: 500,
                              // px: 1,
                            }}
                          >
                            {booking?.doctor?.rating || 0}
                          </Typography>
                          <Typography
                            sx={{
                              color: "rgba(10, 10, 10, 0.48)",
                              fontSize: "12px",
                              fontWeight: 500,
                            }}
                          >
                            ({booking?.doctor?.totalRating || 0})
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    sx={{
                      position: "absolute",
                      right: 0,
                      top: "50%",
                      transform: "translate(0%, -50%)",
                    }}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      sx={{
                        fontSize: "14px",
                        backgroundColor: "#E78756",
                        borderRadius: 30,
                        "&:hover": { backgroundColor: "#E78756" },
                        padding: "0px 4px",
                        height: "30px",
                      }}
                      onClick={() => {
                        setSelectedData(booking);
                        handleOpenView();
                      }}
                    >
                      View
                    </Button>
                  </Grid>
                </Grid>
                <Grid display={"flex"} gap={1} mt={1} flexWrap={"wrap"}>
                  <Typography
                    mr={3}
                    sx={{
                      color: "#767980",
                      fontSize: "13px",
                      fontWeight: 500,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={clockicon}
                      style={{ width: "22px", marginRight: "4px" }}
                    />
                    {booking?.postDetails?.timeSlots || "-"}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#767980",
                      fontSize: "13px",
                      fontWeight: 500,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={doctorphone}
                      style={{ width: "22px", marginRight: "4px" }}
                    />
                    {booking?.doctor?.phoneNumber || "-"}
                  </Typography>
                </Grid>
              </Grid>
            ))}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ClinicDashboard;
