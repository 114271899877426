import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import Rating from "@mui/material/Rating";
import HTMLRenderer from "react-html-renderer";

import doctoremail from "../../assets/images/doctoremail.png";
import doctorphone from "../../assets/images/doctorphone.png";
import location from "../../assets/images/location.png";
import staricon from "../../assets/images/staricon.png";
import getExperience from "../../utils/getExperience";
import getStringInitials from "../../utils/getStringInitials";

const ProposalDetailsCard = ({
  proposal = {},
  index = "",
  setSelectedProposal = null,
  handleOpen = null,
}) => {
  return (
    <Paper key={index} sx={{ p: 1.5, borderRadius: "12px" }}>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Grid display={"flex"} flexDirection={"column"} gap={1}>
          <Grid display={"flex"} alignItems={"center"} gap={1}>
            {proposal?.doctor?.imageUrl ? (
              <Box
                sx={{
                  height: 45,
                  width: 45,
                  borderRadius: "8px",
                  overflow: "hidden",
                }}
              >
                <img
                  src={proposal?.doctor?.imageUrl}
                  style={{ width: "100%", objectFit: "cover" }}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  height: 45,
                  width: 45,
                  borderRadius: "8px",
                  overflow: "hidden",
                  backgroundColor: "#004E89",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: 24,
                    fontWeight: 500,
                    color: "#fff",
                  }}
                >
                  {getStringInitials(proposal?.doctor?.name) || "-"}
                </Typography>
              </Box>
            )}

            <Box display="flex" flexDirection={"column"}>
              <Typography
                sx={{
                  color: "#004E89",
                  fontSize: "18px",
                  fontWeight: 600,
                  textTransform: "capitalize",
                }}
              >
                {proposal?.doctor?.name?.toLowerCase() || "-"}
              </Typography>

              <Box display="flex" alignItems={"center"}>
                <Rating
                  name="half-rating"
                  readOnly
                  value={proposal?.doctor?.rating || 0}
                  precision={0.5}
                  sx={{ fontSize: 13 }}
                />
                <Typography
                  sx={{
                    color: "#1D2343",
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                >
                  {proposal?.doctor?.rating || 0}
                </Typography>
                <Typography
                  sx={{
                    color: "rgba(10, 10, 10, 0.48)",
                    fontSize: 13,
                    fontWeight: 500,
                  }}
                >
                  ({proposal?.doctor?.totalRatings || 0})
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid display={"flex"} columnGap={3} rowGap={0.5} flexWrap={"wrap"}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <img
                src={doctorphone}
                style={{ width: "18px", height: "18px" }}
              />
              <Typography
                sx={{
                  color: "#1A202C",
                  fontSize: 13,
                  fontWeight: 500,
                }}
              >
                {proposal?.doctor?.phoneNumber}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <img
                src={doctoremail}
                style={{ width: "18px", height: "18px" }}
              />
              <Typography
                title={proposal?.doctor?.email}
                noWrap
                sx={{
                  color: "#1A202C",
                  fontSize: 13,
                  fontWeight: 500,
                  width: "150px",
                }}
              >
                {proposal?.doctor?.email}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <img src={location} style={{ width: "18px", height: "18px" }} />
              <Typography
                sx={{
                  color: "#1A202C",
                  fontSize: "13px",
                  fontWeight: 500,
                }}
              >
                <HTMLRenderer
                  html={proposal?.post?.requestType || "<p>...</p>"}
                />
              </Typography>
            </Box>
          </Grid>

          <Grid
            display={"flex"}
            alignItems={"baseline"}
            flexWrap={"wrap"}
            columnGap={3}
            rowGap={1}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <img src={staricon} style={{ width: 13, height: 13 }} />
              <Typography
                title={getExperience(
                  proposal?.doctorDetails?.experienceInMonths
                )}
                noWrap
                sx={{
                  color: "#F98247",
                  fontSize: 13,
                  fontWeight: 600,
                  mt: "2px",
                  width: "130px",
                }}
              >
                {getExperience(proposal?.doctorDetails?.experienceInMonths)}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "baseline",
                gap: 1,
              }}
            >
              {/* <Box display={"flex"} alignItems={"center"} gap={0.5}>
                <img
                  src={staricon}
                  style={{
                    width: 13,
                    height: 13,
                  }}
                />
                <Typography
                  noWrap
                  sx={{
                    color: "#858585",
                    fontSize: 13,
                    fontWeight: 600,
                    mt: "2px",
                  }}
                >
                  Specialist In:
                </Typography>
              </Box>

              <Grid display={'flex'} flexWrap={'wrap'} gap={0.5}>
                {proposal?.doctorDetails?.specialistIn &&
                  proposal?.doctorDetails?.specialistIn
                    ?.split(",")
                    .map((specialization, index) => (
                      <Chip
                        key={index}
                        label={specialization}
                        sx={{
                          fontSize: 13,
                          fontWeight: 600,
                        }}
                      />
                    ))}
              </Grid> */}
              {proposal?.doctorDetails?.specialistIn && (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 0.5,
                  }}
                >
                  <img src={staricon} style={{ width: 13, height: 13 }} />
                  <Typography
                    title={proposal?.doctorDetails?.specialistIn
                      ?.split(",")
                      .join(", ")
                      .toLowerCase()}
                    sx={{
                      color: "#F98247",
                      fontSize: "13px",
                      fontWeight: 600,
                      textTransform: "capitalize",
                    }}
                  >
                    {proposal?.doctorDetails?.specialistIn
                      ?.split(",")
                      .join(", ")
                      .toLowerCase()}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>

        <Grid>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              setSelectedProposal(proposal);
              handleOpen();
            }}
            sx={{
              px: 3,
              fontSize: "14px",
              fontWeight: 600,
              backgroundColor: "#E78756",
              borderRadius: 30,
              "&:hover": { backgroundColor: "#E78756" },
            }}
          >
            View
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ProposalDetailsCard;
