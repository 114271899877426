import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, OutlinedInput, Paper, Select, TextField, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { CSVDownload, CSVLink } from "react-csv";
import { FaFileExport } from "react-icons/fa";
import { MdOutlineFileUpload } from "react-icons/md";

import api from "../../api/index";
import overviewicon from "../../assets/images/overviewicon.png";
import sent from "../../assets/images/sent.png";
import DateRangeCalendar from "../../components/calendar/DateRangeCalendar";
import { styles } from "../../layouts/Styles";
import DoctorDetails from "./DoctorDetails";
import PendingClinics from "./PendingClinics";
import PendingDoctor from "./PendingDoctor";

const AdminDashboard = () => {
  const classes = styles();
  const [pendingDoctors, setPendingDoctors] = useState();
  const [pendingClinics, setPendingClinics] = useState();
  const [approveModal, setApproveModal] = React.useState(false);
  const [rejectModal, setRejectModal] = React.useState(false);
  const [submitReject, setsubmitReject] = React.useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [dashboardData, setDashboardData] = useState();
  const [rejection_reason, setReason] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [profileFilterStatus, setProfileFilterStatus] = useState("All");

  const [page, setPage] = useState(1);
  const [activeUserID, setActiveUserID] = useState("");

  const setStartDate = (d) => {
    setSelectedStartDate(d);
  };
  const setEndDate = (d) => {
    setSelectedEndDate(d);
  };
  const handleCloseApprove = () => {
    setApproveModal(false);
  };
  const handleRejection = (e) => {
    setReason(e.target.value);
  };

  const handleCloseReject = () => {
    setRejectModal(false);
    setReason("");
  };
  const handleOpensubmitRejectModal = () => {
    setsubmitReject(true);
    handleCloseReject();
  };
  const handleClosesubmitReject = () => {
    setsubmitReject(false);
  };

  const getPendingDoctors = async (page, limit) => {
    setPendingDoctors({
      users: [],
      total: 0,
      currentPage: 1,
      totalPages: 0,
      profileStatus: profileFilterStatus,
    });
    const res = await api.getPendingDoctors(
      !selectedStartDate && !selectedEndDate
        ? {
            startDate: null,
            endDate: null,
            page,
            limit,
            profileStatus: profileFilterStatus.toUpperCase(),
          }
        : {
            startDate: moment(selectedStartDate?.$d).format("YYYY-MM-DD"),
            endDate: moment(selectedEndDate?.$d).format("YYYY-MM-DD"),
            page,
            limit,
            profileStatus: profileFilterStatus.toUpperCase(),
          }
    );
    if (res?.data?.success) {
      setPendingDoctors(res?.data?.data);
    }
  };
  const getPendingClinics = async (page, limit) => {
    setPendingClinics({
      users: [],
      total: 0,
      currentPage: 1,
      totalPages: 0,
    });

    const res = await api.getPendingClinics(
      selectedStartDate && selectedEndDate
        ? {
            startDate: moment(selectedStartDate?.$d).format("YYYY-MM-DD"),
            endDate: moment(selectedEndDate?.$d).format("YYYY-MM-DD"),
            page,
            limit,
            profileStatus: profileFilterStatus.toUpperCase(),
          }
        : {
            startDate: null,
            endDate: null,
            page,
            limit,
            profileStatus: profileFilterStatus.toUpperCase(),
          }
    );
    if (res?.data?.success) {
      setPendingClinics(res?.data?.data);
    }
  };

  const getDashboardData = async () => {
    await api
      .getDashboardData()
      .then((response) => {
        if (response?.data?.success) {
          setDashboardData(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  const formatCsvData = () => {
    let doctorData = pendingDoctors?.users?.map((item) => ({
      ["Name"]: item?.name || "",
      ["Image"]: item?.imageUrl || "",
      ["Email"]: item?.email || "",
      ["Phone Number"]: item?.phoneNumber || "",
      ["Designation"]: item?.designation || "",
      ["State"]: item?.state || "",
      ["State Council Registration"]: item?.stateCouncilNo || "",
      ["Approval Status"]: item?.approvalStatus || "",
    }));

    let clinicData = pendingClinics?.users?.map((item) => ({
      ["Clinic Name"]: item?.userDetails?.clinicName || "",
      ["Owner Name"]: item?.name || "",
      ["Image"]: item?.imageUrl || "",
      ["Email"]: item?.email || "",
      ["Phone Number"]: item?.phoneNumber || "",
      ["Website"]: item?.userDetails?.website || "",
      ["State"]: item?.state || "",
      ["State Council Registration"]: item?.stateCouncilNo || "",
      ["Approval Status"]: item?.approvalStatus || "",
      ["License"]: item?.userDetails?.licenseUrl || "",
    }));
    return tabIndex === 0 ? doctorData || [] : clinicData || [];
  };

  const getFilteredProfile = ({ target: { value } }) => {
    setProfileFilterStatus(value);
  };

  useEffect(() => {
    getDashboardData();
  }, []);

  useEffect(() => {
    getPendingDoctors(1, 10);
  }, [selectedEndDate, selectedStartDate]);

  useEffect(() => {
    getPendingClinics(1, 10);
  }, [selectedEndDate, selectedStartDate]);

  useEffect(() => {
    if (tabIndex === 0) {
      getPendingDoctors(1, 10);
      return;
    }
    if (tabIndex === 1) {
      getPendingClinics(1, 10);
      return;
    }
  }, [profileFilterStatus]);

  return (
    <>
      {!activeUserID ? (
        <Fragment>
          <Typography
            sx={{
              fontSize: "22px",
              mt: 1,
              mb: 1,
              fontWeight: 600,
              color: "#004E89",
            }}
          >
            Overview
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={2.5}>
              <Paper sx={{ borderRadius: "8px", p: 2 }}>
                <Box sx={{ mb: "0.5em" }}>
                  <img src={overviewicon} style={{ width: 44, height: 44 }} />
                </Box>
                <Typography
                  variant="h6"
                  sx={{ fontSize: "14px", fontWeight: 500 }}
                >
                  Total Clinic
                </Typography>
                <Typography
                  variant="h2"
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    fontSize: 30,
                    lineHeight: "30px",
                  }}
                >
                  {dashboardData?.totalClinic || "0"}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4.1} lg={2.5}>
              <Paper sx={{ borderRadius: "8px", p: 2 }}>
                <Box sx={{ mb: "0.5em" }}>
                  <img src={overviewicon} style={{ width: 44, height: 44 }} />
                </Box>
                <Typography
                  variant="h6"
                  sx={{ fontSize: "14px", fontWeight: 500 }}
                >
                  Total MDS
                </Typography>
                <Typography
                  variant="h2"
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    fontSize: 30,
                    lineHeight: "30px",
                  }}
                >
                  {dashboardData?.totalMDS || "0"}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2.5}>
              <Paper sx={{ borderRadius: "8px", p: 2 }}>
                <Box sx={{ mb: "0.5em" }}>
                  <img src={overviewicon} style={{ width: 44, height: 44 }} />
                </Box>
                <Typography
                  variant="h6"
                  sx={{ fontSize: "14px", fontWeight: 500 }}
                >
                  Total BDS
                </Typography>
                <Typography
                  variant="h2"
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    fontSize: 30,
                    lineHeight: "30px",
                  }}
                >
                  {dashboardData?.totalBDS || "0"}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4.1} lg={2.5}>
              <Paper sx={{ borderRadius: "8px", p: 2 }}>
                <Box sx={{ mb: "0.5em" }}>
                  <img src={overviewicon} style={{ width: 44, height: 44 }} />
                </Box>
                <Typography
                  variant="h6"
                  sx={{ fontSize: "14px", fontWeight: 500 }}
                >
                  Total Revenue
                </Typography>
                <Typography
                  variant="h2"
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    fontSize: 30,
                    lineHeight: "30px",
                  }}
                >
                  0
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Typography
            variant="h3"
            sx={{
              mt: 3,
              mb: 1,
              fontSize: "22px",
              fontWeight: 600,
              color: "#004E89",
            }}
          >
            Pending Approval
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row",
              },
              alignItems: {
                md: "start",
                lg: "center",
              },
              gap: "10px",
            }}
            justifyContent="space-between"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Button
                onClick={() => {
                  setProfileFilterStatus("All");
                  setTabIndex(0);
                  setPage(1);
                }}
                variant={tabIndex == 0 ? "contained" : "outlined"}
                size="medium"
                sx={{
                  borderRadius: 46,
                  minWidth: "120px",
                  px: 3,
                  fontSize: "16px",
                  fontWeight: 500,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 0.5,
                }}
              >
                Doctors
                <span
                  style={{ fontSize: 14, fontWeight: 500, marginTop: "2px" }}
                >
                  ({pendingDoctors?.total})
                </span>
              </Button>
              <Button
                onClick={() => {
                  setProfileFilterStatus("All");
                  setTabIndex(1);
                  setPage(1);
                }}
                variant={tabIndex == 1 ? "contained" : "outlined"}
                size="medium"
                sx={{
                  borderRadius: 46,
                  minWidth: "120px",
                  px: 3,
                  fontSize: "16px",
                  fontWeight: 500,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 0.5,
                }}
              >
                Clinics{" "}
                <span
                  style={{ fontSize: 14, fontWeight: 500, marginTop: "2px" }}
                >
                  {" "}
                  ({pendingClinics?.total})
                </span>
              </Button>
            </Box>
            <Box display={"flex"} alignItems={"center"} gap={1}>
              <DateRangeCalendar
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                style={{
                  height: "40px !important",
                  width: "auto !important",
                }}
              />
              <Select
                displayEmpty
                value={profileFilterStatus}
                onChange={getFilteredProfile}
                input={<OutlinedInput />}
                sx={{ width: "130px", height: "40px" }}
              >
                {["All", "Completed", "Pending"]?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item}
                    sx={{
                      "& .MuiTypography-root": {
                        color: "black",
                      },
                      m: 0.5,
                    }}
                  >
                    {item}
                  </MenuItem>
                ))}
              </Select>
              <CSVLink
                title="Export to CSV"
                style={{
                  height: 40,
                  width: 40,
                  border: "1px solid #C2C2C2",
                  borderRadius: "12px",
                  p: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                filename="Pending-Approvals"
                data={formatCsvData() || []}
              >
                <FaFileExport size={22} />
              </CSVLink>
            </Box>
          </Box>
          {tabIndex === 0 && (
            <PendingDoctor
              pendingDoctors={pendingDoctors?.users}
              total={pendingDoctors?.total}
              currentPage={pendingDoctors?.currentPage}
              totalPages={pendingDoctors?.totalPages}
              approveModal={approveModal}
              rejectModal={rejectModal}
              setApproveModal={setApproveModal}
              setRejectModal={setRejectModal}
              getPendingDoctors={getPendingDoctors}
              setActiveUserID={setActiveUserID}
              page={page}
              setPage={setPage}
            />
          )}
          {tabIndex === 1 && (
            <PendingClinics
              pendingClinics={pendingClinics?.users}
              total={pendingClinics?.total}
              currentPage={pendingClinics?.currentPage}
              totalPages={pendingClinics?.totalPages}
              approveModal={approveModal}
              rejectModal={rejectModal}
              setApproveModal={setApproveModal}
              setRejectModal={setRejectModal}
              getPendingClinics={getPendingClinics}
              setActiveUserID={setActiveUserID}
              page={page}
              setPage={setPage}
            />
          )}

          <Dialog
            onClose={handleCloseApprove}
            aria-labelledby="customized-dialog-title"
            open={approveModal}
            fullWidth={true}
            // maxWidth="xs"
            sx={{
              "& .MuiDialog-paper": {
                // width: "672px",
                padding: "0px",
                borderRadius: "15px",
                height: "400px",
                width: "450px",
              },
            }}
          >
            <IconButton
              aria-label="close"
              onClick={handleCloseApprove}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "black",
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent className={classes.dialogContent}>
              <img
                src={sent}
                alt="Sent"
                style={{ width: "134px", height: "134px" }}
                className={classes.image}
              />
              <Typography
                align="center"
                className={classes.text}
                sx={{ fontSize: "40px", fontWeight: "600", color: "#F98247" }}
              >
                Success
              </Typography>
              <Typography
                align="center"
                className={classes.text}
                sx={{
                  marginTop: "10px",
                  fontSize: "20px",
                  fontWeight: "400",
                  color: "black",
                }}
              >
                {tabIndex == 1
                  ? "The Clinic has been added Successfully"
                  : "The Doctor has been added Successfully"}
              </Typography>
            </DialogContent>
          </Dialog>

          <Dialog
            onClose={handleCloseReject}
            aria-labelledby="customized-dialog-title"
            open={rejectModal}
          >
            <DialogTitle
              sx={{
                m: 0,
                p: 2,
                fontWeight: 600,
                fontSize: 20,
                color: "#303030",
              }}
              id="customized-dialog-title"
            >
              {tabIndex == 1
                ? "Enter the Rejection Reason"
                : "Enter the reason"}
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseReject}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "black",
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <Grid container spacing={1}>
                <Grid item xl={12} md={12} xs={12} sm={12}>
                  <Typography variant="caption1">Description</Typography>
                </Grid>

                <Grid item xl={12} md={12} xs={12} sm={12}>
                  <TextField
                    id="outlined-multiline-flexible"
                    label="Type Here"
                    multiline
                    rows={4}
                    value={rejection_reason}
                    onChange={handleRejection}
                    variant="outlined"
                    className={classes.bgColorTextField}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "flex-start" }}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#F98247",
                  borderRadius: "30px",
                  color: "white",
                  fontSize: 13,
                  fontWeight: 300,
                  padding: "12px 50px 12px 50px",
                  marginTop: "38px",
                  marginLeft: "8px",
                  "&:hover": { backgroundColor: "#F98247" },
                }}
                disabled={!rejection_reason}
                onClick={handleOpensubmitRejectModal}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            onClose={handleClosesubmitReject}
            aria-labelledby="customized-dialog-title"
            open={submitReject}
            fullWidth={true}
            maxWidth="xs"
            sx={{
              "& .MuiDialog-paper": {
                width: "30%",
                maxWidth: "none",
                maxHeight: "40vh",
                overflowY: "auto",
              },
            }}
          >
            <IconButton
              aria-label="close"
              onClick={handleClosesubmitReject}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "black",
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent sx={{ height: "350px !important", mt: "100px" }}>
              <Typography
                variant="h5"
                align="center"
                style={{ color: "red", fontSize: "40px" }}
              >
                Cancel
              </Typography>
              {tabIndex == 1 ? (
                <Typography
                  align="center"
                  style={{
                    marginTop: "32px",
                    fontSize: "20px",
                    fontWeight: "400",
                    color: "black",
                  }}
                >
                  The Clinic has been Cancelled
                </Typography>
              ) : (
                <Typography
                  align="center"
                  style={{
                    marginTop: "32px",
                    fontSize: "20px",
                    fontWeight: "400",
                    color: "black",
                  }}
                >
                  The Doctor has been Cancelled
                </Typography>
              )}
            </DialogContent>
          </Dialog>
        </Fragment>
      ) : (
        <DoctorDetails
          role={3}
          activeUserID={activeUserID}
          setActiveUserID={setActiveUserID}
        />
      )}
    </>
  );
};

export default AdminDashboard;
