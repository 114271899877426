import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Box, Button, Divider, Grid, Link, OutlinedInput, Paper, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import api from "../../api";
import profileicon from "../../assets/images/avatar-placeholder.png";
import DocumentViewer from "../../components/DocumentViewer/DocView";
import PasswordModal from "../../components/Modals/PasswordModal";
import WorkExpDetailsCard from "./WorkExpDetailsCard";

const DoctorSettings = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { loc } = location.state || {};
  const [tabIndex, setTabIndex] = useState(loc == "doctor" ? 1 : 0);
  const [certificatePdf, setCertificatePdf] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    imageUrl: "",
    stateCouncilNo: "",
    userDetails: {
      experienceInYears: "",
      experienceInMonths: "",
      collegeName: "",
      passOutYear: "",
      workType: "",
      specialistIn: "",
      clinicalExpriences: [],
    },
  });

  const { palette } = useTheme();
  const Navigate = useNavigate();
  const addFormData = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handlePasswordOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const getProfileData = async () => {
    try {
      const { data } = await api.getProfile();
      if (!data?.success) {
        return;
      }
      setCertificatePdf(data?.data?.user?.userDetails?.certificateUrl);

      let info = data?.data?.user || {};

      setFormData({
        ...formData,
        name: info?.name || "",
        email: info?.email || "",
        phoneNumber: info?.phoneNumber || "",
        imageUrl: info?.imageUrl || "",
        stateCouncilNo: info?.stateCouncilNo || "",

        userDetails: {
          experienceInYears: info?.userDetails?.experienceInMonths
            ? parseInt(info?.userDetails?.experienceInMonths / 12)
            : 0,
          experienceInMonths: info?.userDetails?.experienceInMonths
            ? parseInt(info?.userDetails?.experienceInMonths % 12)
            : 0,
          collegeName: info?.userDetails?.collegeName || "",
          passOutYear: info?.userDetails?.passOutYear || "",
          workType: info?.userDetails?.workType || "",
          specialistIn: info?.userDetails?.specialistIn || "",
          clinicalExpriences: info?.userDetails?.clinicalExpriences || [],
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const documentFileUrl = certificatePdf;

  let documentFileName = "certificate.pdf";

  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <>
      {open ? (
        <PasswordModal open={open} handleClose={handleClose} doctor={true} />
      ) : null}
      <Grid container gap={1} sx={{ mt: 2 }}>
        <Button
          onClick={() => setTabIndex(0)}
          variant={tabIndex == 0 ? "contained" : "outlined"}
          size="medium"
          sx={{ borderRadius: 46 }}
        >
          Profile
        </Button>
        {/* <Button
          onClick={() => setTabIndex(1)}
          variant={tabIndex == 1 ? "contained" : "outlined"}
          size="medium"
          sx={{ borderRadius: 46 }}
        >
          Notifications
        </Button> */}
      </Grid>

      {tabIndex === 0 && (
        <Paper sx={{ p: "1.8em", borderRadius: 2, mt: 3, maxWidth: "1090px" }}>
          <Box
            sx={{
              mb: 3,
              position: "relative",
              width: "100px",
              height: "100px",
              display: "flex",
              alignItems: "center",
              borderRadius: "100%",
              overflow: "hidden",
            }}
          >
            <img
              src={formData.imageUrl || profileicon}
              style={{ width: 100, height: "inherit", objectFit: "cover" }}
            />
          </Box>
          <Typography variant="h3" sx={{ fontSize: "24px", mb: "1em" }}>
            Personal Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid
                  item
                  display="flex"
                  flexDirection="column"
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={5.5}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#303030",
                      mb: 1,
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Name
                  </Typography>
                  <OutlinedInput
                    name={"name"}
                    onChange={addFormData}
                    value={formData?.name}
                    readOnly
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "white",
                      "& .MuiOutlinedInput-input": {
                        borderRadius: 2,
                        outline: "none",
                        height: "15px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        transition: "ease-in 0.2s",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${palette?.primary?.main}`,
                        transition: "ease-in 0.2s",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: `2px solid ${palette?.primary?.light}`,
                        transition: "ease-in 0.2s",
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  display="flex"
                  flexDirection="column"
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={5.5}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#303030",
                      mb: 1,
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Emails
                  </Typography>
                  <OutlinedInput
                    readOnly
                    name={"email"}
                    type="email"
                    onChange={addFormData}
                    value={formData?.email}
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "white",
                      "& .MuiOutlinedInput-input": {
                        borderRadius: 2,
                        outline: "none",
                        height: "15px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        transition: "ease-in 0.2s",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${palette?.primary?.main}`,
                        transition: "ease-in 0.2s",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: `2px solid ${palette?.primary?.light}`,
                        transition: "ease-in 0.2s",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid
                  item
                  display="flex"
                  flexDirection="column"
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={5.5}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#303030",
                      mb: 1,
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Phone Number
                  </Typography>
                  <OutlinedInput
                    readOnly
                    name={"phoneNumber"}
                    value={formData?.phoneNumber}
                    onChange={addFormData}
                    type="number"
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "white",
                      "& .MuiOutlinedInput-input": {
                        borderRadius: 2,
                        // border: "none",
                        outline: "none",
                        height: "15px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        transition: "ease-in 0.2s",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${palette?.primary?.main}`,
                        transition: "ease-in 0.2s",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: `2px solid ${palette?.primary?.light}`,
                        transition: "ease-in 0.2s",
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  display="flex"
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={5.5}
                  gap={1}
                  justifyContent={"space-between"}
                >
                  {/* <Grid xs={6}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#303030",
                        mb: 1,
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Experience In Years
                    </Typography>
                    <OutlinedInput
                      readOnly
                      name={"experience"}
                      value={formData?.userDetails?.experienceInYears}
                      onChange={addFormData}
                      fullWidth
                      sx={{
                        borderRadius: 2,
                        backgroundColor: "white",
                        "& .MuiOutlinedInput-input": {
                          borderRadius: 2,
                          outline: "none",
                          height: "15px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: 2,
                          transition: "ease-in 0.2s",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: `1px solid ${palette?.primary?.main}`,
                          transition: "ease-in 0.2s",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: `2px solid ${palette?.primary?.light}`,
                          transition: "ease-in 0.2s",
                        },
                      }}
                    />
                  </Grid>
                  <Grid xs={6}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#303030",
                        mb: 1,
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Experience In Months
                    </Typography>
                    <OutlinedInput
                      readOnly
                      name={"experience"}
                      value={formData?.userDetails?.experienceInMonths}
                      onChange={addFormData}
                      fullWidth
                      sx={{
                        borderRadius: 2,
                        backgroundColor: "white",
                        "& .MuiOutlinedInput-input": {
                          borderRadius: 2,
                          // border: "none",
                          outline: "none",
                          height: "15px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: 2,
                          transition: "ease-in 0.2s",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: `1px solid ${palette?.primary?.main}`,
                          transition: "ease-in 0.2s",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: `2px solid ${palette?.primary?.light}`,
                          transition: "ease-in 0.2s",
                        },
                      }}
                    />
                  </Grid> */}
                  <Grid item display="flex" flexDirection="column" xs={12}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#303030",
                        mb: 1,
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Registration Number
                    </Typography>
                    <OutlinedInput
                      fullWidth
                      readOnly
                      name={"phoneNumber"}
                      value={formData?.stateCouncilNo}
                      onChange={addFormData}
                      sx={{
                        borderRadius: 2,
                        backgroundColor: "white",
                        "& .MuiOutlinedInput-input": {
                          borderRadius: 2,
                          outline: "none",
                          height: "15px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: 2,
                          transition: "ease-in 0.2s",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: `1px solid ${palette?.primary?.main}`,
                          transition: "ease-in 0.2s",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: `2px solid ${palette?.primary?.light}`,
                          transition: "ease-in 0.2s",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Typography
            variant="h3"
            sx={{ fontSize: "24px", mb: "1em", mt: "1em" }}
          >
            Education
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid
                  item
                  display="flex"
                  flexDirection="column"
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={5.5}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#303030",
                      mb: 1,
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    College Name
                  </Typography>
                  <OutlinedInput
                    readOnly
                    name={"collegeName"}
                    value={formData.userDetails.collegeName}
                    onChange={addFormData}
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "white",
                      "& .MuiOutlinedInput-input": {
                        borderRadius: 2,
                        // border: "none",
                        outline: "none",
                        height: "15px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        transition: "ease-in 0.2s",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${palette?.primary?.main}`,
                        transition: "ease-in 0.2s",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: `2px solid ${palette?.primary?.light}`,
                        transition: "ease-in 0.2s",
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  display="flex"
                  flexDirection="column"
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={5.5}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#303030",
                      mb: 1,
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Year of Graduation
                  </Typography>
                  <OutlinedInput
                    readOnly
                    name={"passedOutYear"}
                    value={formData.userDetails.passOutYear}
                    onChange={addFormData}
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "white",
                      "& .MuiOutlinedInput-input": {
                        borderRadius: 2,
                        outline: "none",
                        height: "15px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        transition: "ease-in 0.2s",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${palette?.primary?.main}`,
                        transition: "ease-in 0.2s",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: `2px solid ${palette?.primary?.light}`,
                        transition: "ease-in 0.2s",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid
                  item
                  display="flex"
                  flexDirection="column"
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={5.5}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#303030",
                      mb: 1,
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    State Council Reg Certificate
                  </Typography>
                  {documentFileUrl ? (
                    <a
                      href={documentFileUrl}
                      download={documentFileName}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <DocumentViewer
                        file={documentFileUrl}
                        fileName={documentFileName}
                      />
                    </a>
                  ) : (
                    <>
                      <label
                        htmlFor="cert-inp"
                        style={{
                          border: "2px solid #004E89",
                          borderRadius: "8px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "4px",
                          width: "50%",
                          padding: "4px 8px",
                          // cursor: "pointer",
                          color: "#004E89",
                          fontWeight: 500,
                          cursor: "not-allowed",
                        }}
                      >
                        Choose File <FileUploadOutlinedIcon fontSize="small" />
                      </label>
                      <input
                      readOnly
                      disabled
                        id="cert-inp"
                        style={{
                          display: "none",
                        }}
                        type="file"
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Typography
            variant="h3"
            sx={{ fontSize: "24px", mb: "1em", mt: "1em" }}
          >
            Preferences
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid
                  item
                  display="flex"
                  flexDirection="column"
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={5.5}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#303030",
                      mb: 1,
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Work Type
                  </Typography>
                  <OutlinedInput
                    name={"expertiseIn"}
                    readOnly
                    value={
                      formData?.userDetails?.workType
                        ? formData?.userDetails?.workType.split(",").join(", ")
                        : "-"
                    }
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "white",
                      "& .MuiOutlinedInput-input": {
                        borderRadius: 2,
                        // border: "none",
                        outline: "none",
                        height: "15px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        transition: "ease-in 0.2s",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${palette?.primary?.main}`,
                        transition: "ease-in 0.2s",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: `2px solid ${palette?.primary?.light}`,
                        transition: "ease-in 0.2s",
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  display="flex"
                  flexDirection="column"
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={5.5}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#303030",
                      mb: 1,
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Expertise in
                  </Typography>
                  <OutlinedInput
                    name={"expertiseIn"}
                    readOnly
                    onChange={addFormData}
                    value={
                      formData?.userDetails?.specialistIn
                        ? formData?.userDetails?.specialistIn
                            .split(",")
                            .join(", ")
                        : "-"
                    }
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "white",
                      "& .MuiOutlinedInput-input": {
                        borderRadius: 2,
                        outline: "none",
                        height: "15px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        transition: "ease-in 0.2s",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${palette?.primary?.main}`,
                        transition: "ease-in 0.2s",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: `2px solid ${palette?.primary?.light}`,
                        transition: "ease-in 0.2s",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item sx={{ width: "100%" }}>
              <Typography
                variant="h3"
                sx={{ fontSize: "24px", mb: "1em", mt: "1em" }}
              >
                Work Experience
              </Typography>

              <Grid >
                {formData?.userDetails?.clinicalExpriences?.map(
                  (item, index) => (
                    <WorkExpDetailsCard data={item} index={index} />
                  )
                )}
              </Grid>
            </Grid>

            <Grid item container spacing={1} justifyContent="space-between">
              <Grid item display="flex" flexDirection="column" xs={3}>
                <Button
                  variant="contained"
                  size="medium"
                  fullWidth={false}
                  onClick={() => {
                    Navigate("/editSettingsDoctor");
                  }}
                  sx={{
                    borderRadius: 24,
                    fontSize: 16,
                    fontWeight: 500,
                    // width: "50%"
                  }}
                >
                  Edit
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={5.5}>
                <Link
                  sx={{
                    color: "#004E89",
                    fontWeight: 600,
                    fontSize: 16,
                    cursor: "pointer",
                  }}
                  onClick={handlePasswordOpen}
                >
                  Change Password
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}
      {tabIndex === 1 && (
        <Paper sx={{ p: "1em", borderRadius: 1.5, mt: 3, maxWidth: "860px" }}>
          <Box
            sx={{
              backgroundColor: "#F8F8F8",
              padding: 2,
              borderRadius: 1.5,
              mb: 0.5,
            }}
          >
            <Grid container flexDirection="column">
              <Grid item sx={{ mb: 1 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  flexDirection="row"
                >
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#303030",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      Doctor is added
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#303030 63%",
                        fontSize: "10px",
                        fontWeight: 500,
                      }}
                    >
                      10.00AM
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Divider sx={{ mb: 1 }} />
          <Box
            sx={{
              backgroundColor: "#F8F8F8",
              padding: 2,
              borderRadius: 1.5,
              mb: 0.5,
            }}
          >
            <Grid container flexDirection="column">
              <Grid item sx={{ mb: 1 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  flexDirection="row"
                >
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#303030",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      Doctor is added
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#303030 63%",
                        fontSize: "10px",
                        fontWeight: 500,
                      }}
                    >
                      10.00AM
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Divider sx={{ mb: 1 }} />
          <Box
            sx={{
              backgroundColor: "#F8F8F8",
              padding: 2,
              borderRadius: 1.5,
              mb: 0.5,
            }}
          >
            <Grid container flexDirection="column">
              <Grid item sx={{ mb: 1 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  flexDirection="row"
                >
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#303030",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      Doctor is added
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#303030 63%",
                        fontSize: "10px",
                        fontWeight: 500,
                      }}
                    >
                      10.00AM
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Divider sx={{ mb: 1 }} />
        </Paper>
      )}
    </>
  );
};

export default DoctorSettings;
