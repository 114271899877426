import CloseIcon from "@mui/icons-material/Close";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import api from "../../api/index";
import cancelIcon from "../../assets/images/cancelicon.png";
import doctoremail from "../../assets/images/doctoremail.png";
import doctorphone from "../../assets/images/doctorphone.png";
import fileimage from "../../assets/images/fileimage.png";
import leftarrowicon from "../../assets/images/leftarrowicon.png";
import location from "../../assets/images/location.png";
import ownericon from "../../assets/images/ownericon.png";
import passingyear from "../../assets/images/passingyear.png";
import BookedClinic from "../../components/doctorDetails/BookedClinic";
import CancelModal from "../../components/Modals/CancalModal";
import SuccessModal from "../../components/Modals/SuccessModal";
import { styles } from "../../layouts/Styles";
import getStringInitials from "../../utils/getStringInitials";

const DoctorDetails = ({
  role = "",
  activeUserID = "",
  setActiveUserID = null,
}) => {
  const classes = styles();
  const navigate = useNavigate();

  const [tabIndex, setTabIndex] = useState(0);
  const [nav, setNav] = useState(false);
  const [doctorData, setDoctorData] = useState({});
  const [doctorDataOverView, setDoctorDataOverView] = useState({});
  const [doctorDataOverViewTable, setDoctorDataOverViewTable] = useState([]);

  const [activeStatus, setActiveStatus] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [rejectModal, setRejectModal] = useState(false);
  const [submitReject, setsubmitReject] = useState(false);
  const [rejection_reason, setReason] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [clinicOpen, setClinicOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleSuccessModal = () => {
    setShowSuccessModal(false);
    // navigate("/doctor");
    setActiveUserID("");
  };

  const [showCancelModal, setShowCancelModal] = useState(false);
  const toggleCancelModal = () => {
    setShowCancelModal(false);
    // navigate("/doctor");
    setActiveUserID("");
  };

  const setStartDate = (d) => {
    setSelectedStartDate(d);
  };
  const setEndDate = (d) => {
    setSelectedEndDate(d);
  };

  // let params = useParams();

  let params = { id: activeUserID };

  const handleClinicClose = () => {
    setClinicOpen(false);
  };
  const handleCloseReject = () => {
    setRejectModal(false);
    setReason("");
  };
  const handleOpensubmitRejectModal = async () => {
    await changeUserStatus(params?.id, "REJECTED", rejection_reason);
    setsubmitReject(true);
    handleCloseReject();
  };
  const handleClosesubmitReject = () => {
    setsubmitReject(false);
    // navigate("/doctor");
    setActiveUserID("");
  };

  const handleReason = (e) => {
    setReason(e.target.value);
  };

  const documentFileUrl =
    "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf";
  let documentFileName = role == 3 ? "certificate.pdf" : "Licence.pdf";

  const handleBooking = () => {
    setNav(true);
  };
  const handleOptionChange = ({ target: { value } }) => {
    //  if (value == "") {
    //   setSelectedOption("approved");
    // } else {
    //   setSelectedOption("Rejected");
    // }

    if (value == "Approve") {
      changeUserStatus(params?.id, "APPROVED", "");
    }
    if (value == "Reject") {
      handleRejectModal();
      // setSelectedOption(value);
    }
  };

  const handleRejectModal = () => {
    setRejectModal(true);
    handleClinicClose();
  };

  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
  };

  const changeUserStatus = async (id, status, reason) => {
    setIsLoading(true);
    try {
      const res = await api.changeUserStatus(status, id, reason);
      setIsLoading(false);
      if (res.data.success) {
        getDoctorDetailsById(params?.id);
        if (status == "REJECTED") {
          // setSelectedOption("Reject");
        }
        if (status == "APPROVED") {
          // setSelectedOption("Approve");
          setShowSuccessModal(true);
        }
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getDoctorDetailsById(params?.id);
    if (role == 3 && tabIndex == 1) {
      getDoctorOverViewDetailsConsultancy(params?.id);
    } else if (role == 3) {
      getDoctorOverViewDetails(params?.id);
    } else if (tabIndex == 1) {
      getClinicOverViewDetailsConsultancy(params?.id);
    } else {
      getClinicOverViewDetails(params?.id);
    }
  }, [activeUserID]);

  let convertMonthsToYear = (months) => {
    let years = months / 12;
    years = years.toFixed(1);
    return years;
  };

  const getDoctorDetailsById = async (id) => {
    try {
      let res = await api.getDoctorDetailsById(id);
      if (res?.data?.success) {
        setDoctorData(res?.data?.data);
        setActiveStatus(res?.data?.data?.user?.approvalStatus);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getDoctorOverViewDetails = async (id) => {
    try {
      let res = "";

      if (selectedStartDate && selectedEndDate) {
        let start = moment(selectedStartDate?.$d).format("YYYY-MM-DD");
        let end = moment(selectedEndDate?.$d).format("YYYY-MM-DD");
        console.log(start, end);
        res = await api.getDoctorOverViewDetails(id, start, end);
      } else {
        res = await api.getDoctorOverViewDetails(id);
      }
      if (res?.data?.success) {
        setDoctorDataOverViewTable(res?.data?.data?.bookedClinic);

        setDoctorDataOverView(res?.data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getDoctorOverViewDetailsConsultancy = async (id) => {
    try {
      let res = "";

      if (selectedStartDate && selectedEndDate) {
        let start = moment(selectedStartDate?.$d).format("YYYY-MM-DD");
        let end = moment(selectedEndDate?.$d).format("YYYY-MM-DD");
        console.log(start, end);
        res = await api.getDoctorOverViewDetailsConsultancy(id, start, end);
      } else {
        res = await api.getDoctorOverViewDetailsConsultancy(id);
      }

      if (res?.data?.success) {
        setDoctorDataOverViewTable(res?.data?.data?.consultations);
        setDoctorDataOverView(res?.data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getClinicOverViewDetails = async (id) => {
    try {
      let res = "";
      if (selectedStartDate && selectedEndDate) {
        let start = moment(selectedStartDate?.$d).format("YYYY-MM-DD");
        let end = moment(selectedEndDate?.$d).format("YYYY-MM-DD");
        res = await api.getClinicOverViewDetails(id, start, end);
      } else {
        res = await api.getClinicOverViewDetails(id);
      }
      if (res?.data?.success) {
        setDoctorDataOverViewTable(res?.data?.data?.bookedClinic);

        setDoctorDataOverView(res?.data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getClinicOverViewDetailsConsultancy = async (id) => {
    try {
      let res = "";
      if (selectedStartDate && selectedEndDate) {
        let start = moment(selectedStartDate?.$d).format("YYYY-MM-DD");
        let end = moment(selectedEndDate?.$d).format("YYYY-MM-DD");
        res = await api.getClinicOverViewDetailsConsultancy(id, start, end);
      } else {
        res = await api.getClinicOverViewDetailsConsultancy(id);
      }
      if (res?.data?.success) {
        setDoctorDataOverViewTable(res?.data?.data?.consultations);

        setDoctorDataOverView(res?.data?.data);
      }
    } catch (e) {
      console.log(e);
    }
  };

  let btn1 = "";
  let btn2 = "";

  switch (doctorData?.user?.designation) {
    case "BDS":
      btn1 = "Jobs";
      btn2 = "Bookings";
      break;
    case "MDS":
      btn1 = "Bookings";
      btn2 = "Consultation";
      break;

    default:
      btn1 = "Posts";
      btn2 = "Availability";
  }
  return (
    <>
      {nav ? (
        navigate("/admin/dashboard")
      ) : (
        <>
          <Grid
            display={"flex"}
            alignItems={"center"}
            gap={0.5}
            mt={0.5}
            sx={{
              cursor: "pointer",
            }}
            onClick={() => setActiveUserID("")}
          >
            <img
              // onClick={handleBooking}
              src={leftarrowicon}
              style={{ width: "24px", height: "24px" }}
              alt="leftarrow"
            />
            <Typography
              variant="body"
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: "black",
              }}
            >
              Back
            </Typography>
          </Grid>
          <Paper sx={{ borderRadius: 1.5, mt: 3, px: 4, py: 5 }}>
            <Box
              display="flex"
              alignItems="center"
              sx={{
                justifyContent: {
                  xs: "start",
                  sm: "left",
                  md: "space-between",
                },
                flexDirection: { xs: "column", sm: "row" },
              }}
              gap="20px"
            >
              <Grid
                container
                gap="30px"
                alignItems="center"
                display="flex"
                flexDirection="row"
              >
                <Grid item>
                  {doctorData?.user?.imageUrl ? (
                    <img
                      src={doctorData?.user?.imageUrl}
                      style={{
                        width: "70px",
                        height: "70px",
                        borderRadius: "5px",
                      }}
                    />
                  ) : (
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      sx={{
                        height: 70,
                        width: 70,
                        backgroundColor: "#004E89",
                        borderRadius: "5px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#fff",
                          fontWeight: 500,
                          fontSize: "26px",
                        }}
                      >
                        {doctorData?.user?.name
                          ? getStringInitials(doctorData?.user?.name)
                          : "-"}
                      </Typography>
                    </Box>
                  )}
                </Grid>
                <Grid item>
                  <Box display="flex" flexDirection="column">
                    <Typography
                      sx={{
                        color: "#004E89",
                        fontSize: "20px",
                        fontWeight: 600,
                        // ml: 2,
                        mr: 2,
                      }}
                    >
                      {role == 3
                        ? doctorData?.user?.name
                        : doctorData?.userDetails?.clinicName}
                    </Typography>

                    {role == 3 && (
                      <Box
                        display="flex"
                        alignItems={"center"}
                        flexDirection="row"
                      >
                        {/* <Rating
                          name="half-rating"
                          readOnly
                          value={doctorData?.user?.rating || 0}
                          precision={0.5}
                          sx={{ fontSize: 20 }}
                        />
                        <Typography
                          sx={{
                            color: "#1D2343",
                            fontSize: "18.14px",
                            fontWeight: 500,
                            px: 1,
                          }}
                        >
                          {doctorData?.user?.rating || ""}
                        </Typography>
                        <Typography
                          sx={{
                            color: "rgba(10, 10, 10, 0.48)",
                            fontSize: "18.14px",
                            fontWeight: 500,
                          }}
                        >
                          ({doctorData?.user?.totalRatings || "0"})
                        </Typography> */}
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
              <Grid>
                <Select
                  displayEmpty
                  disabled={isLoading}
                  value={selectedOption}
                  placeholder="Status"
                  onChange={handleOptionChange}
                  sx={{
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-input": {
                      border: "none",
                      outline: "none",
                      height: "15px",
                      marginTop: "-0.2em",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    minWidth: 120,
                  }}
                  renderValue={(selected) => {
                    if (!selected) {
                      return (
                        <em
                          style={{
                            color: "#A2A2A2",
                            fontStyle: "normal",
                            fontSize: 13,
                            textTransform: "capitalize",
                          }}
                        >
                          {isLoading ? (
                            <CircularProgress size={20} />
                          ) : activeStatus ? (
                            activeStatus?.toLowerCase()
                          ) : (
                            "Select Status"
                          )}
                        </em>
                      );
                    }
                    return selected;
                  }}
                >
                  {!activeStatus ||
                    (activeStatus == "REJECTED" && (
                      <MenuItem sx={{ m: 0.5 }} value="Approve">
                        <Typography
                          style={{ color: "green", fontWeight: "400" }}
                        >
                          Approve
                        </Typography>
                      </MenuItem>
                    ))}
                  {!activeStatus ||
                    (activeStatus == "APPROVED" && (
                      <MenuItem sx={{ m: 0.5 }} value="Reject">
                        <Typography style={{ color: "red", fontWeight: "400" }}>
                          Reject
                        </Typography>
                      </MenuItem>
                    ))}
                  {activeStatus == "PENDING" ? (
                    <MenuItem sx={{ m: 0.5 }} value="Approve">
                      <Typography style={{ color: "green", fontWeight: "400" }}>
                        Approve
                      </Typography>
                    </MenuItem>
                  ) : (
                    // <MenuItem sx={{ m: 0.5 }} value="Reject">
                    //   <Typography style={{ color: "red", fontWeight: "400" }}>
                    //     Reject
                    //   </Typography>
                    // </MenuItem>

                    ""
                  )}
                  {activeStatus == "PENDING" ? (
                    <MenuItem sx={{ m: 0.5 }} value="Reject">
                      <Typography style={{ color: "red", fontWeight: "400" }}>
                        Reject
                      </Typography>
                    </MenuItem>
                  ) : (
                    ""
                  )}
                </Select>
              </Grid>
            </Box>

            <Grid container sx={{ mt: 3 }}>
              <Grid item xs={12} sm={6} md={6} xl={2.5}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <img
                    src={ownericon}
                    style={{ width: "40px", height: "40px" }}
                  />
                  <Box display="flex" flexDirection="column" alignItems="start">
                    <Typography
                      sx={{
                        color: "rgba(48, 48, 48, 0.48)",
                        fontSize: "16px",
                        fontWeight: 400,
                        ml: 2,
                      }}
                    >
                      Member Since
                    </Typography>

                    <Typography
                      sx={{
                        color: "rgba(48, 48, 48, 1)",
                        fontSize: "16px",
                        fontWeight: 500,
                        ml: 2,
                      }}
                    >
                      {moment(doctorData?.user?.createdAt).format("MMM YYYY") ||
                        ""}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={2.5}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <img
                    src={doctorphone}
                    style={{ width: "40px", height: "40px" }}
                  />
                  <Box display="flex" flexDirection="column" alignItems="start">
                    <Typography
                      sx={{
                        color: "rgba(48, 48, 48, 0.48)",
                        fontSize: "16px",
                        fontWeight: 400,
                        ml: 2,
                      }}
                    >
                      Phone Number
                    </Typography>

                    <Typography
                      sx={{
                        color: "rgba(48, 48, 48, 1)",
                        fontSize: "16px",
                        fontWeight: 500,
                        ml: 2,
                      }}
                    >
                      {doctorData?.user?.phoneNumber || ""}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={4}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <img
                    src={doctoremail}
                    style={{ width: "40px", height: "40px" }}
                  />
                  <Box display="flex" flexDirection="column" alignItems="start">
                    <Typography
                      sx={{
                        color: "rgba(48, 48, 48, 0.48)",
                        fontSize: "16px",
                        fontWeight: 400,
                        ml: 2,
                      }}
                    >
                      Email Address
                    </Typography>

                    <Typography
                      sx={{
                        color: "rgba(48, 48, 48, 1)",
                        fontSize: "16px",
                        fontWeight: 500,
                        ml: 2,
                      }}
                    >
                      {doctorData?.user?.email || "-"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              {role == 3 ? (
                <Grid item xs={12} sm={6} md={6} xl={3}>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <img
                      src={passingyear}
                      style={{ width: "40px", height: "40px" }}
                    />
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="start"
                    >
                      <Typography
                        sx={{
                          color: "rgba(48, 48, 48, 0.48)",
                          fontSize: "16px",
                          fontWeight: 400,
                          ml: 2,
                        }}
                      >
                        Experience
                      </Typography>

                      <Typography
                        sx={{
                          color: "rgba(48, 48, 48, 1)",
                          fontSize: "16px",
                          fontWeight: 500,
                          ml: 2,
                        }}
                      >
                        {convertMonthsToYear(
                          doctorData?.userDetails?.experienceInMonths
                        ) + " years " || ""}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ) : (
                <Grid item xs={12} sm={6} md={6} xl={3}>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <img
                      src={location}
                      style={{ width: "40px", height: "40px" }}
                    />
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="start"
                    >
                      <Typography
                        sx={{
                          color: "rgba(48, 48, 48, 0.48)",
                          fontSize: "16px",
                          fontWeight: 400,
                          ml: 2,
                        }}
                      >
                        Address
                      </Typography>

                      <Typography
                        sx={{
                          color: "rgba(48, 48, 48, 1)",
                          fontSize: "16px",
                          fontWeight: 500,
                          ml: 2,
                        }}
                      >
                        {doctorData?.userDetails?.address || "-"}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
            <Grid container sx={{ mt: 3 }}>
              <Grid item xs={12} sm={6} md={6} xl={2.5}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <img
                    src={passingyear}
                    style={{ width: "40px", height: "40px" }}
                  />
                  <Box display="flex" flexDirection="column" alignItems="start">
                    <Typography
                      sx={{
                        color: "rgba(48, 48, 48, 0.48)",
                        fontSize: "16px",
                        fontWeight: 400,
                        ml: 2,
                      }}
                    >
                      Designation
                    </Typography>

                    <Typography
                      sx={{
                        color: "rgba(48, 48, 48, 1)",
                        fontSize: "16px",
                        fontWeight: 500,
                        ml: 2,
                      }}
                    >
                      {doctorData?.user?.designation || "-"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={6} xl={2.5}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <img
                    src={passingyear}
                    style={{ width: "40px", height: "40px" }}
                  />
                  <Box display="flex" flexDirection="column" alignItems="start">
                    <Typography
                      sx={{
                        color: "rgba(48, 48, 48, 0.48)",
                        fontSize: "16px",
                        fontWeight: 400,
                        ml: 2,
                      }}
                    >
                      Work Type
                    </Typography>

                    <Typography
                      sx={{
                        color: "rgba(48, 48, 48, 1)",
                        fontSize: "16px",
                        fontWeight: 500,
                        ml: 2,
                      }}
                    >
                      {doctorData?.userDetails?.workType || "-"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              {role == 3 ? (
                <Grid item xs={12} sm={6} md={6} xl={4}>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <img
                      src={passingyear}
                      style={{ width: "40px", height: "40px" }}
                    />
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="start"
                    >
                      <Typography
                        sx={{
                          color: "rgba(48, 48, 48, 0.48)",
                          fontSize: "16px",
                          fontWeight: 400,
                          ml: 2,
                        }}
                      >
                        Expertise In
                      </Typography>

                      <Typography
                        sx={{
                          color: "rgba(48, 48, 48, 1)",
                          fontSize: "16px",
                          fontWeight: 500,
                          ml: 2,
                        }}
                      >
                        {doctorData?.userDetails?.specialistIn || "-"}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              ) : (
                <Grid item xs={12} sm={6} md={6} xl={3}>
                  <Box display="flex" flexDirection="row" alignItems="center">
                    <img
                      src={passingyear}
                      style={{ width: "40px", height: "40px" }}
                    />
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="start"
                    >
                      <Typography
                        sx={{
                          color: "rgba(48, 48, 48, 0.48)",
                          fontSize: "16px",
                          fontWeight: 400,
                          ml: 2,
                        }}
                      >
                        Website
                      </Typography>

                      <Typography
                        sx={{
                          color: "rgba(48, 48, 48, 1)",
                          fontSize: "16px",
                          fontWeight: 500,
                          ml: 2,
                        }}
                      >
                        {doctorData?.userDetails?.website || "-"}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              )}
            </Grid>
            <Grid
              container
              sx={{ mt: 3 }}
              display={"flex"}
              alignItems={"center"}
              gap={1}
              mt={1}
            >
              {doctorData?.userDetails?.certificateUrl ? (
                <Button
                  variant="outlined"
                  // onClick={handleOpen}
                  size="large"
                  sx={{
                    borderRadius: "25px",
                    border: "1px solid #EDEDED",
                    background: "white",
                    color: "black",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={fileimage}
                    style={{ width: 24, height: 24, marginRight: 4 }}
                  />
                  {documentFileName}
                </Button>
              ) : (
                <Typography
                  sx={{
                    color: "#858585",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  No license available
                </Typography>
              )}

              {doctorData?.userDetails?.certificateUrl ? (
                <Link
                  style={{
                    // backgroundColor: "#F98247",
                    borderRadius: "30px",
                    color: "#000",
                    border: "1px solid #EDEDED",
                    width: "60px",
                    height: "45px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                  to={`${doctorData?.userDetails?.certificateUrl || ""}`}
                  target="_blank"
                  download
                >
                  <DownloadRoundedIcon />
                </Link>
              ) : (
                ""
              )}
            </Grid>
          </Paper>

          {/* <Typography
            variant="h3"
            sx={{
              fontSize: "24px",
              mt: "1em",
              mb: "1em",
              fontSize: "24px",
              fontWeight: 600,
              color: "#004E89",
            }}
          >
            Overview
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
                xl: "row",
              },

              gap: "10px",
            }}
            justifyContent="space-between"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                // justifyContent: "space-between",
              }}
            >
              <Button
                onClick={() => setTabIndex(0)}
                variant={tabIndex == 0 ? "contained" : "outlined"}
                size="large"
                sx={{
                  borderRadius: 46,
                  mr: 2,
                  minWidth: "120px",
                  p: "20px 36px",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                {btn1}
              </Button>
              <Button
                onClick={() => setTabIndex(1)}
                variant={tabIndex == 1 ? "contained" : "outlined"}
                size="large"
                sx={{
                  borderRadius: 46,
                  mr: 2,
                  minWidth: "120px",
                  p: "17px 36px",
                  fontSize: "16px",
                  fontWeight: 600,
                }}
              >
                {btn2}
              </Button>
            </Box>
          </Box> */}
          {/* <BookedClinic
            tableData={doctorDataOverViewTable}
            doctorDataOverView={doctorDataOverView}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            role={role}
            tabIndex={tabIndex}
          /> */}
        </>
      )}

      <Dialog
        onClose={handleCloseReject}
        aria-labelledby="customized-dialog-title"
        open={rejectModal}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, fontWeight: 600, fontSize: 20, color: "#303030" }}
          id="customized-dialog-title"
        >
          Enter the reason
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseReject}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xl={12} md={12} xs={12} sm={12}>
              <Typography variant="caption1">Description</Typography>
            </Grid>

            <Grid item xl={12} md={12} xs={12} sm={12}>
              <TextField
                id="outlined-multiline-flexible"
                label="Type Here"
                multiline
                rows={4}
                variant="outlined"
                className={classes.bgColorTextField}
                fullWidth
                value={rejection_reason}
                onChange={handleReason}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-start" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#F98247",
              borderRadius: "30px",
              color: "white",
              fontSize: 13,
              fontWeight: 300,
              padding: "12px 50px 12px 50px",
              marginTop: "38px",
              marginLeft: "8px",
              "&:hover": { backgroundColor: "#F98247" },
            }}
            onClick={handleOpensubmitRejectModal}
            disabled={!rejection_reason}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={handleClosesubmitReject}
        aria-labelledby="customized-dialog-title"
        open={submitReject}
        fullWidth={true}
        // maxWidth="xs"
        sx={{
          "& .MuiDialog-paper": {
            // width: "672px",
            padding: "0px",
            borderRadius: "15px",
            height: "300px",
            width: "450px",
            margin: "0px",
          },
          "& .MuiDialogContent-root": {
            margin: "0px",
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClosesubmitReject}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{
            height: "350px !important",
            mt: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={cancelIcon}
            alt="Cancelled"
            style={{ width: "134px", height: "134px" }}
            className={classes.image}
          />
          <Typography
            variant="h5"
            align="center"
            style={{ color: "red", fontSize: "40px" }}
          >
            Cancelled
          </Typography>
          <Typography
            align="center"
            style={{
              // marginTop: "32px",
              fontSize: "20px",
              fontWeight: "400",
              color: "black",
            }}
          >
            {role == 3
              ? "The Doctor has been Cancelled."
              : "The Clinic has been Cancelled."}
          </Typography>
        </DialogContent>
      </Dialog>
      <Dialog
        onClose={handleCloseReject}
        aria-labelledby="customized-dialog-title"
        open={rejectModal}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, fontWeight: 600, fontSize: 20, color: "#303030" }}
          id="customized-dialog-title"
        >
          Enter the reason
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseReject}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xl={12} md={12} xs={12} sm={12}>
              <Typography variant="caption1">Description</Typography>
            </Grid>

            <Grid item xl={12} md={12} xs={12} sm={12}>
              <TextField
                id="outlined-multiline-flexible"
                // label="Type Here"
                placeholder="Type here"
                multiline
                rows={4}
                variant="outlined"
                className={classes.bgColorTextField}
                fullWidth
                value={rejection_reason}
                onChange={handleReason}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-start" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#F98247",
              borderRadius: "30px",
              color: "white",
              fontSize: 13,
              fontWeight: 300,
              padding: "12px 50px 12px 50px",
              marginTop: "38px",
              marginLeft: "8px",
              "&:hover": { backgroundColor: "#F98247" },
            }}
            onClick={handleOpensubmitRejectModal}
            disabled={!rejection_reason}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <CancelModal
        isOpen={showCancelModal}
        toggle={toggleCancelModal}
        title="Cancelled"
        msg="Profile cancelled successfully"
      />

      <SuccessModal
        isOpen={showSuccessModal}
        toggle={toggleSuccessModal}
        title="Successful"
        msg="Profile approved successfully"
      />
    </>
  );
};

export default DoctorDetails;

const pendingProposals = [{}];
