import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import SearchBar from "../../components/searchbar";
import { styles } from "../../layouts/Styles";
import getExperience from "../../utils/getExperience";

const AdminDoctors = (props) => {
  const [searchInput, setSearchInput] = useState("");
  const [filteredDoctors, setFilteredDoctors] = useState([]);

  const { page, setPage } = props;

  const {
    palette: { grey },
  } = useTheme();
  const classes = styles();
  const sampleDoctors = [
    {
      id: 1,
      "doctor name": "John Doe",
      "phone number": 251111129,
      email: "sample@test.com",
      experience: "2 Years",
      "work type": "Full-Time",
      "doctor type": "MDS",
      status: "View",
    },
  ];
  const columnsdoctor = Object.keys(sampleDoctors[0]);
  const [limit, setLimit] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setLimit(event.target.value);
  };
  useEffect(() => {
    props.getDoctors(page, limit);
  }, [page, limit]);

  useEffect(() => {
    const filtered = props?.doctors?.filter((doctor) =>
      Object.values(doctor).some((value) => {
        // Check if value is not null before calling toString
        if (value !== null && value !== undefined) {
          return value
            .toString()
            .toLowerCase()
            .includes(searchInput.toLowerCase());
        }
        return false;
      })
    );
    setFilteredDoctors(filtered);
  }, [searchInput, props.doctors]);

  return (
    <>
      <Grid container gap={1} flexWrap={"wrap"}>
        <Grid
          item
          sx={{
            minWidth: "200px",
          }}
        >
          <Paper sx={{ p: "1.5em", borderRadius: "12px" }}>
            <Typography
              variant="h6"
              sx={{
                color: "#161616",
                fontWeight: "500",
                fontSize: "14px",
                mb: 1.5,
              }}
            >
              Total Bookings
            </Typography>
            <Typography
              variant="h2"
              sx={{ color: "#161616", fontWeight: 600, fontSize: "24px" }}
            >
              {props?.overViewData?.totalBooking || 0}
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          sx={{
            minWidth: "200px",
          }}
        >
          <Paper sx={{ p: "1.5em", borderRadius: "12px" }}>
            <Typography
              variant="h6"
              sx={{
                color: "#161616",
                fontWeight: "500",
                fontSize: "14px",
                mb: 1.5,
              }}
            >
              Total Jobs
            </Typography>
            <Typography
              variant="h2"
              sx={{ color: "#161616", fontWeight: 600, fontSize: "24px" }}
            >
              {/* {props?.overViewData?.totalBooking || 0} */}0
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          sx={{
            minWidth: "200px",
          }}
        >
          <Paper sx={{ p: "1.5em", borderRadius: "12px" }}>
            <Typography
              variant="h6"
              sx={{
                color: "#161616",
                fontWeight: "500",
                fontSize: "14px",
                mb: 1.5,
              }}
            >
              Total Applications
            </Typography>
            <Typography
              variant="h2"
              sx={{ color: "#161616", fontWeight: 600, fontSize: "24px" }}
            >
              {/* {props?.overViewData?.totalBooking || 0} */}0
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          sx={{
            minWidth: "200px",
          }}
        >
          <Paper sx={{ p: "1.5em", borderRadius: "12px" }}>
            <Typography
              variant="h6"
              sx={{
                color: "#161616",
                fontWeight: "500",
                fontSize: "14px",
                mb: 1.5,
              }}
            >
              Total Consultations
            </Typography>
            <Typography
              variant="h2"
              sx={{ color: "#161616", fontWeight: 600, fontSize: "24px" }}
            >
              {props?.overViewData?.totalConsulting || 0}
            </Typography>
          </Paper>
        </Grid>
        <Grid
          item
          sx={{
            minWidth: "200px",
          }}
        >
          <Paper sx={{ p: "1.5em", borderRadius: "12px" }}>
            <Typography
              variant="h6"
              sx={{
                color: "#161616",
                fontWeight: "500",
                fontSize: "14px",
                mb: 1.5,
              }}
            >
              Total Revenue
            </Typography>
            <Typography
              variant="h2"
              sx={{ color: "#161616", fontWeight: 600, fontSize: "24px" }}
            >
              {props?.overViewData?.totalRevenue || 0}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Paper sx={{ py: 2, px: 2, mt: 2, borderRadius: "12px" }}>
        <Grid
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mb={1}
        >
          <Typography sx={{ fontSize: "22px", fontWeight: 500 }}>
            Doctors{" "}
            <span style={{ fontSize: "22px", fontWeight: 600 }}>
              ({props?.total})
            </span>
          </Typography>
          <Grid item xs={12} sm={3}>
            <SearchBar
              searchInput={searchInput}
              setSearchInput={setSearchInput}
            />
          </Grid>
        </Grid>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columnsdoctor.map((column) => (
                  <TableCell key={column} className={classes.tableCell}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {searchInput !== "" ? (
                filteredDoctors.length > 0 ? (
                  filteredDoctors.map((row, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell className={classes.tableCellBody}>
                          {row?.id || "-"}
                        </TableCell>
                        <TableCell className={classes.tableCellBodyLink}>
                          <Link
                            to={`#`}
                            onClick={() => props.setActiveUserID(row.id)}
                          >
                            {" "}
                            {row?.name || "-"}
                          </Link>
                        </TableCell>
                        <TableCell className={classes.tableCellBody}>
                          {row?.phoneNumber || "-"}
                        </TableCell>
                        <TableCell className={classes.tableCellBody}>
                          {row?.email || "-"}
                        </TableCell>
                        <TableCell className={classes.tableCellBody}>
                          {(row?.userDetails?.experienceInMonths / 12).toFixed(
                            0
                          )}{" "}
                          years {row?.userDetails?.experienceInMonths % 12}{" "}
                          months
                        </TableCell>
                        <TableCell className={classes.tableCellBody}>
                          {row?.userDetails?.workType || "-"}
                        </TableCell>
                        <TableCell className={classes.tableCellBody}>
                          {row?.designation || "-"}
                        </TableCell>
                        <TableCell className={classes.tableCellBody}>
                          <Button
                            // onClick={handleClickOpen}
                            variant="contained"
                            size="medium"
                            sx={{
                              color: "#00AB11",
                              backgroundColor: "#D9F3DC",
                              borderRadius: 30,
                              fontSize: 14,
                              fontWeight: 500,
                              padding: "10px 60px 10px 60px",
                              "&:hover": { backgroundColor: "#D9F3DC" },
                              cursor: "default",
                            }}
                          >
                            Active
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={8} style={{ textAlign: "center" }}>
                      <div style={{ width: "100%" }}>No data found</div>
                    </TableCell>
                  </TableRow>
                )
              ) : (
                props?.doctors?.length ? props?.doctors?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.tableCellBody}>
                      {row?.id || "-"}
                    </TableCell>
                    <TableCell className={classes.tableCellBodyLink}>
                      {/* <Link to={`/doctor/doctorDetails/${row?.id}`}>
                        {" "}
                        {row?.name || "-"}
                      </Link> */}
                      <Link
                        to={`#`}
                        onClick={() => props.setActiveUserID(row.id)}
                      >
                        {" "}
                        {row?.name || "-"}
                      </Link>
                    </TableCell>
                    <TableCell className={classes.tableCellBody}>
                      {row?.phoneNumber || "-"}
                    </TableCell>
                    <TableCell className={classes.tableCellBody}>
                      {row?.email || "-"}
                    </TableCell>
                    <TableCell className={classes.tableCellBody}>
                      {/* {row?.userDetails?.experience || "-"} */}
                      {/* {(row?.userDetails?.experienceInMonths / 12).toFixed(
                        0
                      )}{" "}
                      years {row?.userDetails?.experienceInMonths % 12} months */}
                      {getExperience(row?.userDetails?.experienceInMonths)}
                    </TableCell>
                    <TableCell className={classes.tableCellBody}>
                      {row?.userDetails?.workType || "-"}
                    </TableCell>
                    <TableCell className={classes.tableCellBody}>
                      {row?.designation || "-"}
                    </TableCell>
                    <TableCell className={classes.tableCellBody}>
                      <Button
                        // onClick={handleClickOpen}
                        variant="contained"
                        size="small"
                        sx={{
                          color: "#00AB11",
                          backgroundColor: "#D9F3DC",
                          borderRadius: 30,
                          fontSize: 14,
                          fontWeight: 500,
                          width: "90px",
                          "&:hover": { backgroundColor: "#D9F3DC" },
                          cursor: "default",
                        }}
                      >
                        Active
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              : "No records found")}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={props?.total}
          rowsPerPage={limit}
          page={props?.currentPage - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default AdminDoctors;
