import { Box, Button, FormControl, Grid, ListItemText, MenuItem, Paper, Select, Typography } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import Radio from "@mui/material/Radio";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import api from "../../api/index";
import leftarrowicon from "../../assets/images/leftarrowicon.png";
import { styles } from "../../layouts/Styles";
import SentSuccessfully from "../Modals/SentSuccessfully";

const BookNow = () => {
  const Navigate = useNavigate();

  const { availableSlots } = useSelector((store) => store?.doctor || []);

  if (!availableSlots.length) {
    Navigate("/bookchair");
  }

  const [activeIndex, setActiveIndex] = useState("");

  const [timeslots, setTimeSlots] = useState();
  const [cabins, setCabins] = useState([]);
  const [cabinData, setCabinData] = useState(null);

  const [timeslot, setTimeSlot] = useState("");
  const [cabin, setCabin] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [nav, setNav] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const classes = styles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        overflowY: "scroll",
      },
    },
    autoFocus: false,
  };

  const handleChangeCabin = (e) => {
    setCabin(e.target.value);
  };

  const handleChangeTime = (e) => {
    setTimeSlot(e.target.value);
  };

  const handleBookModal = async () => {
    let numberofcabins = +cabin?.split(" ")[0] || 0;
    const body = {
      noOfCabinsBooked: numberofcabins,
      cabincAvailabilityId: availableSlots[activeIndex]?.id || "",
    };

    try {
      let response = await api.bookChair(body);
      if (response?.data?.success) {
        setModalOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCloseBook = () => {
    setModalOpen(false);
    setNav(true);
  };

  const handleChair = () => {
    setNav(true);
  };

  const formatTimeSlots = () => {
    let slotsArr = availableSlots.map((item) => {
      if (!item.startTime || !item.endTime) {
        return "-";
      }
      let str = `${item.startTime} : ${item.endTime}`;
      return str;
    });
    setTimeSlots(slotsArr);
  };

  useEffect(() => {
    if (availableSlots.length) {
      formatTimeSlots();
    }
  }, [availableSlots]);

  useEffect(() => {
    let num =
      availableSlots[activeIndex]?.noOfCabins -
        availableSlots[activeIndex]?.bookedCabins || "";
    let array = [];
    for (let i = 1; i <= num; i++) {
      if (i === 1) {
        array.push(i + " cabin");
      } else {
        array.push(i + " cabins");
      }
    }
    setCabins(array);
  }, [activeIndex]);

  return (
    <>
      {nav ? (
        Navigate("/bookchair")
      ) : (
        <>
          <Grid container sx={{ mb: "2em" }}>
            <Grid item>
              <img
                onClick={handleChair}
                src={leftarrowicon}
                style={{ width: "24px", height: "24px" }}
                alt="leftarrow"
              />
            </Grid>
            <Grid item sx={{ mt: "0.1em", ml: "1em" }}>
              <Typography
                variant="body"
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "black",
                }}
              >
                Back
              </Typography>
            </Grid>
          </Grid>

          <Typography variant="h3" sx={{ fontSize: "23px", fontWeight: 600 }}>
            Details
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
                md: "row",
                lg: "row",
                xl: "row",
              },
              gap: "10px",
            }}
          >
            <Paper
              sx={{
                width: {
                  xs: "100%",
                  md: "80%",
                },
                p: "2em",
                borderRadius: 1.5,
                mt: 3,
                pb: "3em",
                minHeight: "fit-content",
              }}
            >
              <Grid
                container
                spacing={1}
                sx={{
                  justifyContent: {
                    xs: "center",
                    md: "space-between",
                  },
                }}
              >
                <Grid item xs={12} md={6}>
                  <Box display="flex" flexDirection="column">
                    <Typography
                      sx={{
                        color: "rgba(48, 48, 48, 1)",
                        fontSize: "16px",
                        fontWeight: 400,
                        mb: 1,
                      }}
                    >
                      Select Time Slots
                    </Typography>
                    <FormControl>
                      <Select
                        displayEmpty
                        value={timeslot}
                        onChange={handleChangeTime}
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return (
                              <em
                                style={{
                                  color: "#C2C2C2",
                                  fontStyle: "normal",
                                  fontSize: 14,
                                  fontWeight: 500,
                                }}
                              >
                                Select Time Slots
                              </em>
                            );
                          }
                          return selected;
                        }}
                        MenuProps={MenuProps}
                      >
                        {timeslots?.map((item, index) => (
                          <MenuItem
                            key={index}
                            value={item}
                            className={classes.selectedMenuItem}
                            onClick={() => setActiveIndex(index)}
                            sx={{
                              "& .MuiTypography-root": {
                                color: "black",
                              },
                              m: 0.5
                            }}
                          >
                            <Radio
                              checked={timeslot === item}
                              sx={{
                                color: "#C2C2C2",
                                "&.Mui-checked": { color: "#F98247" },
                              }}
                            />
                            <ListItemText primary={item} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box display="flex" flexDirection="column">
                    <Typography
                      sx={{
                        color: "rgba(48, 48, 48, 1)",
                        fontSize: "16px",
                        fontWeight: 400,
                        mb: 1,
                      }}
                    >
                      No of Cabins available
                    </Typography>
                    <FormControl>
                      <Select
                        displayEmpty
                        value={cabin}
                        onChange={handleChangeCabin}
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return (
                              <em
                                style={{
                                  color: "#C2C2C2",
                                  fontStyle: "normal",
                                  fontSize: 14,
                                  fontWeight: 500,
                                }}
                              >
                                Select cabins
                              </em>
                            );
                          }
                          return selected;
                        }}
                        MenuProps={MenuProps}
                      >
                        {cabins?.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            className={classes.selectedMenuItem}
                            sx={{
                              "& .MuiTypography-root": {
                                color: "black",
                              },
                            }}
                          >
                            <Radio
                              checked={cabin === name}
                              sx={{
                                color: "#C2C2C2",
                                "&.Mui-checked": { color: "#F98247" },
                              }}
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>

              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{ mt: isDropdownOpen ? 25 : 5 }} // Adjust the margin based on dropdown state
                >
                  <Button
                    variant="contained"
                    size="large"
                    onClick={handleBookModal}
                    disabled={!cabin || !timeslot}
                    sx={{
                      fontSize: "16px",
                      width: "40%",
                      fontWeight: 600,
                      backgroundColor: "#E78756",
                      borderRadius: 30,
                      padding: "12px 46px 12px 46px",
                      "&:hover": { backgroundColor: "#E78756" },
                      textWrap: "nowrap",
                    }}
                  >
                    Book Now
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Box>
        </>
      )}
      {modalOpen ? (
        <SentSuccessfully
          modal={modalOpen}
          handleCloseApprove={handleCloseBook}
          value={"clinic"}
          book={true}
        />
      ) : null}
    </>
  );
};

export default BookNow;
