import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useState } from "react";
import HTMLRenderer from "react-html-renderer";

import api from "../../api/index";
import clockicon from "../../assets/images/clockicon.png";
import doctorphone from "../../assets/images/doctorphone.png";
import experience from "../../assets/images/experience.png";
import location from "../../assets/images/location.png";
import staricon from "../../assets/images/staricon.png";
import { styles } from "../../layouts/Styles";
import getStringInitials from "../../utils/getStringInitials";
import SentSuccessfully from "./SentSuccessfully";

const DoctorConsultationReq = ({ handleClose, open }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const classes = styles();
  const handleCloseApprove = () => {
    setModalOpen(false);
    handleClose();
  };

  const handleSendProposal = async () => {
    setLoading(true);
    try {
      const res = await api.createProposal({ _post: open?.id });
      setLoading(false);
      if (res?.data?.success) {
        setModalOpen(true);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const getDate = (dateString) => {
    let dateObj = new Date(dateString);
    let day = dateObj.getDate();
    const monthName = dateObj.toLocaleString("en-US", { month: "short" });
    return `${day} ${monthName}`;
  };

  const renderChips = (skills) => {
    let arr = skills?.split(",") || [];
    return arr.map((item, index) => (
      <Box
        key={index}
        sx={{
          p: "4px 6px",
          backgroundColor: "#F8F8F8",
          color: "#004E89",
          fontWeight: 500,
          borderRadius: "4px",
        }}
      >
        {item}
      </Box>
    ));
  };

  return (
    <>
      {modalOpen ? (
        <SentSuccessfully
          modal={modalOpen}
          handleCloseApprove={handleCloseApprove}
          value={"proposal"}
        />
      ) : (
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle
            sx={{ m: 0, p: 2, fontWeight: 600, fontSize: 20, color: "#F98247" }}
            id="customized-dialog-title"
          >
            {open?.requestType == "CONSULTATION"
              ? open?.caseName
              : open?.jobTitle}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{p: 1, display: 'flex', flexDirection: 'column', rowGap: 2}}>
            <Grid container gap={1} alignItems="center">
              <Grid item className={classes.imageContainer}>
                {open?.clinicOwner?.imageUrl ? (
                  <img
                    src={open?.clinicOwner?.imageUrl || ""}
                    style={{
                      width: 60,
                      height: 60,
                      borderRadius: "12px",
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      width: 60,
                      height: 60,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#004E89",
                      borderRadius: "12px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: 20,
                        color: "#fff",
                      }}
                    >
                      {getStringInitials(
                        open?.clinicDetails?.clinicName || "-"
                      )}
                    </Typography>
                  </Box>
                )}
              </Grid>

              <Box
                display="flex"
                sx={{
                  flexDirection: {
                    xs: "column",
                    lg: "row",
                  },
                }}
              >
                <Typography
                  sx={{
                    color: "#004E89",
                    fontSize: "20px",
                    fontWeight: 600,
                  }}
                >
                  {open?.clinicDetails?.clinicName || "-"}
                </Typography>
              </Box>
            </Grid>
            {open?.requestType == "CONSULTATION" ? (
              <Grid
                container
                alignItems={"center"}
                justifyContent={"space-between"}
                rowGap={1}
              >
                <Grid display={"flex"} alignItems={"center"} gap={1}>
                  <img
                    src={clockicon}
                    style={{ width: 30, height: 30 }}
                    alt="clock"
                  />
                  <Grid>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: "400",
                        color: "#858585",
                      }}
                    >
                      Date & Time
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: "500",
                        color: "#303030",
                      }}
                    >
                      {getDate(open?.date)}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid display={"flex"} alignItems={"center"} gap={1}>
                  <img
                    src={location}
                    style={{ width: 30, height: 30 }}
                    alt="loc"
                  />
                  <Grid>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: "400",
                        color: "#858585",
                      }}
                    >
                      Location
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: "500",
                        color: "#303030",
                      }}
                      onClick={() => {
                        if (open?.clinicDetails?.googleMapLocation) {
                          window.open(
                            open?.clinicDetails?.googleMapLocation,
                            "_blank"
                          );
                        }
                      }}
                    >
                      {open?.clinicDetails?.address}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid display={"flex"} alignItems={"center"} gap={1}>
                  <img
                    src={doctorphone}
                    alt="phone"
                    style={{ width: 30, height: 30 }}
                  />
                  <Grid>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: "400",
                        color: "#858585",
                      }}
                    >
                      Pay Range ({open?.hourly ? "hourly" : "fixed"})
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: "500",
                        color: "#303030",
                      }}
                    >
                      {open?.clinicOwner?.phoneNumber}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                sx={{ mt: "40px" }}
                display={"flex"}
                justifyContent={"space-between"}
              >
                <Grid md={3} display={"flex"} alignItems={"center"} gap={1}>
                  <Grid item>
                    <img
                      src={clockicon}
                      style={{ width: 40, height: 40 }}
                      alt="clock"
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#858585",
                      }}
                    >
                      Work Hours{" "}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#303030",
                      }}
                    >
                      {open?.workingHour || "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid md={3} display={"flex"} alignItems={"center"} gap={1}>
                  <Grid item sx={{ pl: "2px", mr: 0 }}>
                    <img
                      src={location}
                      style={{ width: 40, height: 40 }}
                      alt="loc"
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#858585",
                      }}
                    >
                      Location
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#303030",
                        cursor: "pointer",
                      }}
                      onClick={() => window.open(open?.locationUrl, "_blank")}
                    >
                      {open?.locationName || "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid md={3} display={"flex"} alignItems={"center"} gap={1}>
                  <Grid item sx={{ pl: "2px", mr: 0 }}>
                    <img
                      src={experience}
                      style={{ width: 40, height: 40 }}
                      alt="clock"
                    />
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#858585",
                      }}
                    >
                      Eligibility
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#303030",
                      }}
                    >
                      {open?.eligibility || "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid md={3} display={"flex"} alignItems={"center"} gap={1}>
                  <Grid item sx={{ pl: "2px", mr: 0 }}>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      sx={{
                        backgroundColor: "#004E89",
                        width: 40,
                        height: 40,
                        borderRadius: "100%",
                      }}
                    >
                      <AttachMoneyOutlinedIcon style={{ color: "#fff" }} />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "#858585",
                      }}
                    >
                      Salary
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "#303030",
                      }}
                    >
                      INR{" "}
                      {open?.minimumSalary ? parseInt(open?.minimumSalary) : ""}
                      {open?.minimumSalary && open?.maximumSalary
                        ? `-${parseInt(open?.maximumSalary)}`
                        : open?.maximumSalary
                        ? parseInt(open?.maximumSalary)
                        : ""}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Grid container spacing={2}>
              <Grid item>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img src={staricon} style={{ width: 14, height: 14 }} />
                  <Typography
                    sx={{
                      color: "#F98247",
                      fontSize: 14,
                      fontWeight: 600,
                      ml: 1,
                    }}
                  >
                    {open?.requestType}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h3" sx={{ fontSize: "20px", mt: "0.5em" }}>
                  Skills
                </Typography>
              </Grid>
              <Grid item display={"flex"} gap={1} flexWrap={"wrap"}>
                {open?.skills ? renderChips(open?.skills) : "-"}
              </Grid>
            </Grid>

            {open?.requestType != "CONSULTATION" ? (
              <Grid container spacing={1} sx={{ mt: "0.5em" }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Typography
                    variant="h3"
                    sx={{ fontSize: "20px", mt: "0.5em" }}
                  >
                    Holidays
                  </Typography>
                </Grid>
                <Grid item display={"flex"} gap={1} flexWrap={"wrap"}>
                  {open?.holidays ? renderChips(open?.holidays) : "-"}
                </Grid>
              </Grid>
            ) : (
              ""
            )}

            <Grid
              container
              spacing={1}
              flexDirection="column"
            >
              <Grid item>
                <Typography variant="h3" sx={{ fontSize: "20px", mt: "0.5em" }}>
                  {open?.requestType == "CONSULTATION" ? "Case" : "Job"}{" "}
                  Description
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: "rgba(118, 121, 128, 1)",
                    minHeight: "50px",
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                >
                  {open?.caseDescription ? (
                    <HTMLRenderer
                      html={open?.caseDescription || "<p>...</p>"}
                    />
                  ) : open?.jobDescription ? (
                    <HTMLRenderer html={open?.jobDescription || "<p>...</p>"} />
                  ) : (
                    "-"
                  )}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ justifyContent: {
            xs: 'center',
            md: 'flex-start'
          } }}>
            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "#F98247",
                borderRadius: "30px",
                color: "white",
                fontSize: 13,
                fontWeight: 300,
                "&:hover": { backgroundColor: "#F98247" },
                minWidth: "160px",
              }}
              disabled={isLoading}
              onClick={handleSendProposal}
            >
              {isLoading ? <CircularProgress size={15} /> : "Send Proposal"}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
export default DoctorConsultationReq;
