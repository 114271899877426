import { Box, Button, Chip, Divider, Grid, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";

import markerPin from "../assets/images/markerPin.png";
import { getPayType } from "../utils/getPayType";
import getStringInitials from "../utils/getStringInitials";
import DoctorConsultationReq from "./Modals/DoctorConsultationReq";

const CardDetails = ({ consultation = {}, index = "" }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = (obj) => {
    setOpen(obj);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div key={index}>
        <DoctorConsultationReq open={open} handleClose={handleClose} />
        <Grid
          sx={{
            py: 1.5,
            px: 1.5,
            display: "flex",
            gap: 1,
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#fff",
            borderRadius: "8px",
          }}
        >
          <Grid
            display={"flex"}
            alignItems={"center"}
            gap={1}
            flexWrap={"wrap"}
          >
            {consultation?.clinicOwner?.imageUrl ? (
              <Box display={"flex"} alignItems={"center"}>
                <img
                  src={consultation?.clinicOwner?.imageUrl}
                  style={{
                    height: "46px",
                    width: "46px",
                    borderRadius: "5px",
                    objectFit: "cover",
                  }}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  width: 46,
                  height: 46,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#004E89",
                  borderRadius: "5px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 20,
                    color: "#fff",
                  }}
                >
                  {getStringInitials(
                    consultation?.requestType == "CONSULTATION"
                      ? consultation?.caseName
                      : consultation?.jobTitle
                  ) || "-"}
                </Typography>
              </Box>
            )}
            <Box>
              <Box>
                <Typography
                  noWrap
                  sx={{
                    color: "#004E89",
                    fontSize: "18px",
                    fontWeight: 600,
                    fontFamily: "Poppins",
                    width: "130px",
                    textTransform: "capitalize",
                  }}
                  title={
                    consultation?.requestType == "CONSULTATION"
                      ? consultation?.caseName || "-"
                      : consultation?.jobTitle || "-"
                  }
                >
                  {consultation?.requestType == "CONSULTATION"
                    ? consultation?.caseName?.toLowerCase() || "-"
                    : consultation?.jobTitle?.toLowerCase() || "-"}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "21.885px",
                    fontSize: "9.708px",
                    fontWeight: 400,
                  }}
                >
                  {moment(consultation?.date).format("DD MMM, YYYY")}
                </Typography>
              </Box>
              <Box
                onClick={() => {
                  if (consultation?.locationUrl) {
                    window.open(consultation?.locationUrl, "_blank");
                  }
                }}
              >
                <Typography
                  sx={{
                    color: "#1A202C",
                    fontSize: "13px",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                    color: "#E78756",
                    cursor: "pointer",
                  }}
                >
                  <img src={markerPin} height={15} />
                  Direction
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                },
                gap: 0.5,
                position: "relative",
              }}
            >
              <Box>
                <Chip
                  sx={{
                    borderRadius: "3.43px",
                    border: "none",
                    backgroundColor: "rgba(248, 248, 248, 1)",
                    minWidth: "140px",
                  }}
                  label={
                    <Typography
                      sx={{
                        color: "#F98247",
                        fontSize: "12px",
                        fontWeight: "600",
                        fontFamily: "Poppins",
                      }}
                    >
                      {consultation?.requestType}
                    </Typography>
                  }
                />
              </Box>
              <Box>
                <Chip
                  sx={{
                    borderRadius: "3.43px",
                    border: "none",
                    backgroundColor: "rgba(248, 248, 248, 1)",
                    width: "140px",
                  }}
                  label={
                    <Typography
                      title={getPayType({
                        requestType: consultation?.requestType,
                        minSalary: consultation?.minimumSalary,
                        maxSalary: consultation?.maximumSalary,
                        hourly: consultation?.hourly,
                      })}
                      noWrap
                      sx={{
                        color: "#F98247",
                        fontSize: "12px",
                        fontWeight: "600",
                        fontFamily: "Poppins",
                      }}
                    >
                      {getPayType({
                        requestType: consultation?.requestType,
                        minSalary: consultation?.minimumSalary,
                        maxSalary: consultation?.maximumSalary,
                        hourly: consultation?.hourly,
                      })}
                    </Typography>
                  }
                />
              </Box>
            </Box>
          </Grid>
          <Divider
            color={"#F98247"}
            width={"0.47px"}
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ mx: 1 }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <Button
              variant="contained"
              size="small"
              sx={{
                fontSize: "13px",
                fontWeight: 600,
                backgroundColor: "#E78756",
                width: "100px",
                borderRadius: "11.79px",
                "&:hover": { backgroundColor: "#E78756" },
              }}
              onClick={() => {
                handleOpen(consultation);
              }}
            >
              View
            </Button>
          </Box>
        </Grid>
      </div>
    </>
  );
};

export default CardDetails;
