import React, { Fragment } from "react";
import { InputAdornment, OutlinedInput, useTheme } from "@mui/material";
import searchicon from "../../assets/images/searchmd.svg";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const SearchBar = ({ searchInput, setSearchInput = () => {} }) => {
  const { palette } = useTheme();

  const onChange = (event) => {
    setSearchInput(event);
  };

  return (
    <Fragment>
      <OutlinedInput
        value={searchInput}
        onChange={(text) => {
          onChange(text?.target?.value);
        }}
        placeholder="Search..."
        size={"small"}
        fullWidth
        startAdornment={
          <InputAdornment position="start">
            <img src={searchicon} style={{ height: "20px" }} />
          </InputAdornment>
        }
        endAdornment={
          <InputAdornment
            onClick={() => {
              onChange("");
            }}
            position="end"
            sx={{ ...(!searchInput && { display: "none" }), cursor: "pointer" }}
          >
            <CloseRoundedIcon />
          </InputAdornment>
        }
        sx={{
          backgroundColor: "#EDEDED",
          "& .MuiOutlinedInput-input": {
            backgroundColor: "#EDEDED",
            borderRadius: "2px",
            border: "none",
            outline: "none",
            height: "15px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: `none`,
            transition: "ease-in 0.2s",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${palette?.primary?.main}`,
            transition: "ease-in 0.2s",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${palette?.primary?.light}`,
            transition: "ease-in 0.2s",
          },
        }}
      />
    </Fragment>
  );
};

export default SearchBar;
