import React, { Fragment, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import BookNow from "../components/bookachair/BookNow";
import Navigation from "../components/navigation/Navigation";
import ManageClinicPayment from "../components/PaymentPage/ManageClinicPayment";
import {
  AdminDashboard,
  Clinics,
  Doctor,
  Marketplace,
  Settings,
} from "../pages/AdminDashboard";
import DoctorDetails from "../pages/AdminDashboard/DoctorDetails";
import EditSettings from "../pages/AdminDashboard/EditSettings";
import {
  ClinicDashboard,
  ClinicSettings,
  ClinicTransaction,
  HireDoctor,
  ManageBookings,
} from "../pages/ClinicDashboard";
import EditSettingsClinic from "../pages/ClinicDashboard/EditSettingsClinic";
import BookChair from "../pages/DoctorDashboard/BookChair";
import Consultation from "../pages/DoctorDashboard/Consultation";
import DoctorDashboard from "../pages/DoctorDashboard/DoctorDashboard";
import DoctorSettings from "../pages/DoctorDashboard/DoctorSettings";
import DoctorTransaction from "../pages/DoctorDashboard/DoctorTransaction";
import EditSettingsDoctor from "../pages/DoctorDashboard/EditSettingsDoctor";
import Jobs from "../pages/DoctorDashboard/Jobs";
import EditSlot from "../pages/ClinicDashboard/EditSlot";
import ClinicPosts from "../pages/AdminDashboard/ClinicPosts";
const MainRoutes = () => {
  let role = localStorage.getItem("role");
  let designation = localStorage.getItem("designation");
  return (
    <Fragment>
      <Navigation role={role} designation={designation}>
        <Routes>
          {role == 1 && (
            <Route path="/admin/dashboard" element={<AdminDashboard />} />
          )}
          {role == 1 && <Route path="/clinics" element={<Clinics />} />}
          {role == 1 && <Route path="/doctor" element={<Doctor />} />}
          {role == 1 && <Route path="/marketplace" element={<Marketplace />} />}
          {role == 1 && <Route path="/settings" element={<Settings />} />}
          {role == 1 && (
            <Route path="/editSettings" element={<EditSettings />} />
          )}
          {role == 1 && (
            <Route
              path="/doctor/doctorDetails/:id"
              element={<DoctorDetails role={3} />}
            />
          )}
          {role == 1 && (
            <Route path="/admin/clinics/posts" element={<ClinicPosts />} />
          )}
          {role == 1 && (
            <Route
              path="/clinic/clinicDetails/:id"
              element={<DoctorDetails role={2} />}
            />
          )}

          {/* Clinic's Module */}
          {role == 2 && (
            <Route path="/clinic/dashboard" element={<ClinicDashboard />} />
          )}
          {role == 2 && (
            <Route path="/clinic/managebookings" element={<ManageBookings />} />
          )}
          {role == 2 && (
            <Route path="/clinic/editSlot" element={<EditSlot />} />
          )}
          {role == 2 && (
            <Route path="/clinic/hiredoctor" element={<HireDoctor />} />
          )}

          {role == 2 && (
            <Route path="/clinic/transaction" element={<ClinicTransaction />} />
          )}
          {role == 2 && (
            <Route path="/clinic/settings" element={<ClinicSettings />} />
          )}
          {role == 2 && (
            <Route
              path="/EditSettingsClinic"
              element={<EditSettingsClinic />}
            />
          )}

          {role == 2 && (
            <Route path="/clinic/payment" element={<ManageClinicPayment />} />
          )}
          {role == 2 && (
            <Route path="/clinic/payments" element={<ManageClinicPayment />} />
          )}

          {/* Doctor's Module */}

          {role == 3 && (
            <Route path="/doctor/dashboard" element={<DoctorDashboard />} />
          )}
          {role == 3 && (
            <Route path="/doctor/payment" element={<ManageClinicPayment />} />
          )}
          {role == 3 && <Route path="/bookchair/" element={<BookChair />} />}
          {role == 3 && (
            <Route path="/booknow/:clinicId" element={<BookNow />} />
          )}
          {role == 3 && (
            <Route path="/consultation" element={<Consultation />} />
          )}
          {role == 3 && <Route path="/jobs" element={<Jobs />} />}
          {role == 3 && (
            <Route path="/doctor/transaction" element={<DoctorTransaction />} />
          )}
          {role == 3 && (
            <Route path="/doctor/settings" element={<DoctorSettings />} />
          )}
          {role == 3 && (
            <Route
              path="/EditSettingsDoctor"
              element={<EditSettingsDoctor />}
            />
          )}

          <Route
            path="/"
            element={
              <Navigate
                to={
                  role == 1
                    ? "/admin/dashboard"
                    : role == 3
                    ? "/doctor/dashboard"
                    : role == 2
                    ? "/clinic/dashboard"
                    : "/"
                }
                replace={true}
              />
            }
          />

          <Route
            path="*"
            element={
              <Navigate
                to={
                  role == 1
                    ? "/admin/dashboard"
                    : role == 3
                    ? "/doctor/dashboard"
                    : role == 2
                    ? "/clinic/dashboard"
                    : "/"
                }
                replace={true}
              />
            }
          />
        </Routes>
      </Navigation>
    </Fragment>
  );
};

export default MainRoutes;
