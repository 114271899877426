import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import React, { useEffect, useState } from "react";
import api from "../../api";
import clinicpic from "../../assets/images/clinicpic.png";
import clockicon from "../../assets/images/clockicon.png";
import doctorphone from "../../assets/images/doctorphone.png";
import location from "../../assets/images/location.png";
import staricon from "../../assets/images/staricon.png";
import DateRangeCalendar from "../calendar/DateRangeCalendar";

const ClinicConsultationHistory = () => {
  const [statusColor, setStatusColor] = useState("Completed");
  const [isLoading, setIsLoading] = useState(false);
  const [consultations, setConsultations] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [selectedEndDate, setSelectedEndDate] = useState();
  const setStartDate = (d) => {
    setSelectedStartDate(d);
  };
  const setEndDate = (d) => {
    setSelectedEndDate(d);
  };

  const getConsultationHistory = async () => {
    setIsLoading(true);
    try {
      let queryString =
        selectedStartDate && selectedEndDate
          ? `?startDate=${moment(
              new Date(selectedStartDate).toISOString()
            ).format("YYYY-MM-DD")}&endDate=${moment(
              new Date(selectedEndDate).toISOString()
            ).format("YYYY-MM-DD")}`
          : "";

      const { data } = await api.getConsultationHistory(queryString);

      setIsLoading(false);

      if (!data?.success) {
        return;
      }

      setConsultations(data?.data?.bookingHistoryData || []);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const getDate = (dateString) => {
    let dateObj = new Date(dateString);
    let day = dateObj.getDate();
    const monthName = dateObj.toLocaleString("en-US", { month: "short" });
    return `${day} ${monthName}`;
  };

  useEffect(() => {
    getConsultationHistory();
  }, [selectedEndDate]);

  return (
    <>
      <Grid Container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{
              flexDirection: {
                xs: "column",
                sm: "row",
                md: "row",
                lg: "row",
                xl: "row",
              },
              alignItems: {
                xs: "start",
                sm: "center",
                md: "center",
                lg: "center",
                xl: "center",
              },
            }}
          >
            <Box>
              <Typography
                variant="h3"
                sx={{ fontSize: "22px", mt: "1em", mb: "1em" }}
              >
                Order History
              </Typography>
            </Box>
            <Box>
              {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <CustomizedStaticDatePicker
                  label="Select date range"
                  showDaysOutsideCurrentMonth={true}
                  views={["day"]}
                  format="DD/MM/YYYY"
                  dayOfWeekFormatter={(day) => `${day}`}
                  slots={{
                    openPickerIcon: DownArrowIcon,
                    closePickerIcon: UpArrowIcon,
                  }}
                  slotProps={{
                    textField: { size: "small" }, //, placeholder:'Select date range'
                    switchViewButton: { sx: { display: "none" } },
                    // labelContainer: {sx: { ml:'9em' }},
                    // leftArrowIcon	:{sx: { marginLeft:'-15.6em' }},
                    // rightArrowIcon	:{sx: { marginLeft:'-0.5em' }},
                    // leftArrowIcon: {
                    //   sx: { color: "#000", fontSize: "2rem" },
                    // },
                    // rightArrowIcon: {
                    //   sx: { color: "#000", fontSize: "2rem" },
                    // },
                    calendarHeader: {
                      sx: {
                        position: "relative",
                        "& .MuiPickersArrowSwitcher-root": {
                          width: 0,
                        },
                        "& .MuiIconButton-edgeEnd": {
                          position: "absolute",
                          left: 0,
                          top: 0,
                          bottom: 0,
                        },
                        "& .MuiIconButton-edgeStart": {
                          position: "absolute",
                          right: 0,
                          top: 0,
                          bottom: 0,
                        },
                      },
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      height: "8px",
                    },
                  }}
                />
              </LocalizationProvider> */}
              <DateRangeCalendar
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {isLoading ? (
            <Box mt={2} justifyContent={"center"} sx={{ display: "flex" }}>
              <CircularProgress size={35} />
            </Box>
          ) : consultations.length ? (
            consultations.map((item, index) => (
              <Paper key={index} sx={{ p: "1.5em", mt: 2 }}>
                <Grid
                  display={"flex"}
                  alignItems={"center"}
                  gap={1}
                  justifyContent={"space-between"}
                >
                  <Grid
                    xs={10}
                    display={"flex"}
                    alignItems={"center"}
                    gap={2}
                    flexWrap={"wrap"}
                  >
                    <Grid item xs={12} md={3}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        <img src={clinicpic} style={{ width: "45px" }} />
                        <Box display="flex" flexDirection="column" ml={1}>
                          <Typography
                            sx={{
                              color: "#004E89",
                              fontSize: "16px",
                              fontWeight: 600,
                            }}
                          >
                            {item?.doctor?.name}
                          </Typography>
                          <Box>
                            <Box
                              display="flex"
                              alignItems={"center"}
                              sx={{
                                flexDirection: {
                                  md: "row",
                                  lg: "row",
                                  xl: "row",
                                  sm: "row",
                                  xs: "row",
                                },
                              }}
                            >
                              <Rating
                                name="half-rating"
                                readOnly
                                value={item?.doctor?.rating || 0}
                                // onChange={(event, newRating) => handleRatingChange(newRating)}
                                precision={0.5}
                                sx={{ fontSize: 14 }}
                              />
                              <Typography
                                sx={{
                                  color: "#1D2343",
                                  fontSize: "14px",
                                  fontWeight: 500,
                                }}
                              >
                                {item?.doctor?.rating || 0}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "rgba(10, 10, 10, 0.48)",
                                  fontSize: "14px",
                                  fontWeight: 500,
                                }}
                              >
                                ({item?.doctor?.totalRating || 0})
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={6}
                      display={"flex"}
                      gap={1}
                      flexWrap={"wrap"}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={doctorphone}
                          style={{ width: "18px", height: "18px" }}
                        />
                        <Typography
                          sx={{
                            color: "#1A202C",
                            fontSize: "14px",
                            fontWeight: 500,
                            ml: 1,
                          }}
                        >
                          {item?.doctor?.phoneNumber || "-"}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={location}
                          style={{ width: "18px", height: "18px" }}
                        />
                        <Typography
                          sx={{
                            color: "#1A202C",
                            fontSize: "14px",
                            fontWeight: 500,
                            ml: 1,
                          }}
                        >
                          {item?.doctor?.email || "-"}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={clockicon}
                          style={{ width: "18px", height: "18px" }}
                        />
                        <Typography
                          noWrap
                          sx={{
                            color: "#1A202C",
                            fontSize: "14px",
                            fontWeight: 500,
                            ml: 1,
                          }}
                        >
                          {item?.date ? getDate(item.date) : "-"}&nbsp;
                          {item?.postDetails?.timeSlots || "-"}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={3}
                      display={"flex"}
                      flexWrap={"wrap"}
                      gap={1}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={staricon}
                          style={{ width: "16.67px", height: "16.67px" }}
                        />
                        <Typography
                          sx={{
                            color: "#F98247",
                            fontSize: "14px",
                            fontWeight: 600,
                            ml: 1,
                          }}
                        >
                          {item?.postDetails?.price
                            ? `Rs. ${item?.postDetails?.price}`
                            : "-"}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={staricon}
                          style={{ width: "16.67px", height: "16.67px" }}
                        />

                        {item?.postDetails?.specilistIn ? (
                          <Chip
                            label={item?.postDetails?.specilistIn}
                            style={{ margin: "4px" }}
                          />
                        ) : (
                          ""
                        )}
                      </Box>
                    </Grid>
                  </Grid>

                  <Grid item xs={2}>
                    <Typography
                      variant="body"
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        color: statusColor === "Completed" ? "#00A01B" : "red",
                      }}
                    >
                      {statusColor}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            ))
          ) : (
            <Typography mt={2} textAlign={"center"}>
              No records found.
            </Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ClinicConsultationHistory;
