import { Box, Button, Chip, Divider, Grid, Typography } from "@mui/material";
import React from "react";

import markerPin from "../../assets/images/marker-pin.png";
import { getPayType } from "../../utils/getPayType";
import getStringInitials from "../../utils/getStringInitials";

const JobDetailsCard = ({ job = [], setActiveJob = null }) => {
  return (
    <>
      <Grid
        sx={{
          py: 1.5,
          px: 1.5,
          display: "flex",
          gap: 1,
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#fff",
          borderRadius: "8px",
        }}
      >
        <Grid display={"flex"} alignItems={"center"} gap={1} flexWrap={"wrap"}>
          {job?.clinicOwner?.imageUrl ? (
            <Box display={"flex"} alignItems={"center"}>
              <img
                src={job?.clinicOwner?.imageUrl}
                style={{
                  height: "46px",
                  width: "46px",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
              />
            </Box>
          ) : (
            <Box
              display={"flex"}
              alignItems={"center"}
              sx={{
                height: 46,
                width: 46,
                backgroundColor: "#004E89",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
              }}
            >
              <Typography
                sx={{
                  color: "#fff",
                  fontWeight: 600,
                  fontSize: 14,
                }}
              >
                {getStringInitials(job?.caseName) || "-"}
              </Typography>
            </Box>
          )}
          <Box>
            <Box>
              <Typography
                noWrap
                sx={{
                  color: "#004E89",
                  fontSize: "18px",
                  fontWeight: 600,
                  fontFamily: "Poppins",
                  width: "130px",
                }}
                title={job?.caseName || "-"}
              >
                {job?.caseName || "-"}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "21.885px",
                  fontSize: "9.708px",
                  fontWeight: 400,
                }}
              >
                Open Position({job?.number_of_openings || "-"})
              </Typography>
            </Box>

            <Box
              onClick={() => {
                if (job?.locationUrl) {
                  window.open(job?.locationUrl, "_blank");
                }
              }}
            >
              <Typography
                sx={{
                  color: "#1A202C",
                  fontSize: "13px",
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                  color: "#E78756",
                  cursor: "pointer",
                }}
              >
                <img src={markerPin} height={15} />
                Direction
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
              },
              gap: 0.5,
              position: "relative",
            }}
          >
            <Box>
              <Chip
                sx={{
                  borderRadius: "3.43px",
                  border: "none",
                  backgroundColor: "rgba(248, 248, 248, 1)",
                  minWidth: "140px",
                }}
                label={
                  <Typography
                    sx={{
                      color: "#F98247",
                      fontSize: "12px",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    {job?.requestType || ""}
                  </Typography>
                }
              />
            </Box>
            <Box>
              <Chip
                sx={{
                  borderRadius: "3.43px",
                  border: "none",
                  backgroundColor: "rgba(248, 248, 248, 1)",
                  width: "140px",
                }}
                label={
                  <Typography
                    sx={{
                      color: "#F98247",
                      fontSize: "12px",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    {getPayType({
                      requestType: job?.requestType,
                      minSalary: job?.minimumSalary,
                      maxSalary: job?.maximumSalary,
                      hourly: job?.hourly,
                    })}
                  </Typography>
                }
              />
            </Box>
          </Box>
        </Grid>
        <Divider
          color={"#F98247"}
          width={"0.47px"}
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ mx: 1 }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Button
            variant="outlined"
            size="small"
            sx={{
              backgroundColor: "#F98247",
              color: "#fff",
              borderRadius: "3.648px",
              fontSize: "13px",
              fontWeight: 600,
              borderColor: "#F98247",
              width: "100px",
            }}
            onClick={() => setActiveJob(job)}
          >
            View
          </Button>
        </Box>
      </Grid>
      {/* )} */}
    </>
  );
};

export default JobDetailsCard;
