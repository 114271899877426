import { Grid, LinearProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import api from "../../api/index";
import ConsultationModal from "../Modals/ConsultationModal";
import MyProposalDetailsCard from "./MyConsultationDetailsCard";

const MyConsultations = () => {
  const [open, setOpen] = React.useState(false);
  const [todaySlots, setTodaySlots] = useState([]);
  const [upcoming, setUpcoming] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = (obj) => {
    setOpen(obj);
  };
  const handleClose = () => {
    setOpen(false);
    getProposalsBySlotsToday();
    getProposalsBySlotsUpcoming();
  };

  //Consultations
  const getProposalsBySlotsToday = async () => {
    setIsLoading(true);
    await api
      .getProposalsBySlots("TODAY")
      .then((response) => {
        setIsLoading(false);
        if (response?.data?.success) {
          setTodaySlots(response?.data?.data);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getProposalsBySlotsUpcoming = async () => {
    setIsLoading(true);
    const res2 = await api
      .getProposalsBySlots("OTHERS")
      .then((response) => {
        setIsLoading(false);
        if (response?.data?.success) {
          setUpcoming(response?.data?.data?.bookings || []);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const getProposalsBySlotsToday = async () => {
      setIsLoading(true);
      const res1 = await api
        .getProposalsBySlots("TODAY")
        .then((response) => {
          setIsLoading(false);
          if (response?.data?.success) {
            setTodaySlots(response?.data?.data.bookings);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    const getProposalsBySlotsUpcoming = async () => {
      setIsLoading(true);
      const res2 = await api
        .getProposalsBySlots("OTHERS")
        .then((response) => {
          setIsLoading(false);
          if (response?.data?.success) {
            setUpcoming(response?.data?.data.bookings);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    };
    getProposalsBySlotsToday();
    getProposalsBySlotsUpcoming();
  }, []);

  return (
    <>
      {open ? (
        <ConsultationModal open={open} handleClose={handleClose} />
      ) : null}
      <Typography variant="h3" sx={{ fontSize: "20px", my: 1 }}>
        Today's Slots
      </Typography>
      {isLoading && <LinearProgress />}
      <Grid display={"flex"} flexWrap={"wrap"} gap={1}>
        {todaySlots && todaySlots.length > 0 ? (
          todaySlots.map((consultation, index) => (
            <MyProposalDetailsCard
              consultation={consultation}
              handleOpen={handleOpen}
              index={index}
            />
          ))
        ) : !isLoading ? (
          <Typography width={'100%'} textAlign={'center'}>No records found.</Typography>
        ) : (
          ""
        )}
      </Grid>

      <Typography variant="h3" sx={{ fontSize: "20px", my: 1 }}>
        Upcoming Slots
      </Typography>
      {isLoading && <LinearProgress />}
      <Grid display={"flex"} flexWrap={"wrap"} gap={1}>
        {upcoming && upcoming.length > 0 ? (
          upcoming.map((consultation, index) => (
            <MyProposalDetailsCard
              consultation={consultation}
              handleOpen={handleOpen}
            />
          ))
        ) : !isLoading ? (
          <Typography width={'100%'} textAlign={'center'}>No records found.</Typography>
        ) : (
          ""
        )}
      </Grid>
    </>
  );
};

export default MyConsultations;
