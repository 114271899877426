import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { Box, Button, CircularProgress, FormControl, Grid, MenuItem, OutlinedInput, Paper, Select, TextField, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import api from "../../api";
import profileicon from "../../assets/images/avatar-placeholder.png";
import editicon from "../../assets/images/editicon.png";
import DocumentViewer from "../../components/DocumentViewer/DocView";
import { BDSSpecialities, MDSSpecialities, workTypeOptions } from "../../constants";
import WorkExpDetailsCard from "./WorkExpDetailsCard";

const DoctorSettings = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    imageUrl: "",
    experienceInYears: "",
    experienceInMonths: "",
    collegeName: "",
    passOutYear: "",
    workType: "",
    specialistIn: "",
    stateCouncilNo: "",
    clinicalExpriences: [],
    certificateUrl: "",
  });
  const { palette } = useTheme();
  const Navigate = useNavigate();
  const addFormData = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const designation = localStorage.getItem("designation");
  const expertiseOptions =
    designation == "MDS" ? MDSSpecialities : BDSSpecialities;

  let documentFileName = "certificate.pdf";

  const [documentFileUrl, setDocFileUrl] = useState("");

  const [selectedWorkTypes, setSelectedWorkTypes] = useState([]);
  const [selectedExpertiseTypes, setSelectedExpertiseTypes] = useState([]);

  const [clinicalExpriences, setClinicalExpriences] = useState([
    {
      clinicName: "",
      jobTitle: "",
      startFrom: "",
      upto: "",
    },
  ]);

  const [isLoading, setLoading] = useState(false);

  const uploadFile = async (e, type = "profile") => {
    let fileName = e.target.files[0].name;
    let file = e.target.files[0];
    let awsSUrl = `https://godenty-backend-docs.s3.ap-south-2.amazonaws.com/docs/${fileName}`;
    let response = await fetch(awsSUrl, {
      method: "PUT",
      body: file,
    });

    if (response.ok) {
      if (type == "certificate") {
        setFormData({ ...formData, certificateUrl: response?.url });
        setDocFileUrl(response?.url);
        return;
      }
      setFormData({ ...formData, imageUrl: response?.url });
    }
  };

  const handleWorkTypeChange = ({ target: { value } }) => {
    setSelectedWorkTypes(typeof value === "string" ? value.split(",") : value);
  };

  const handleExpertiseTypeChange = ({ target: { value } }) => {
    setSelectedExpertiseTypes(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const updateProfile = async () => {
    setLoading(true);
    try {
      let payload = {
        name: formData.name,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        imageUrl: formData.imageUrl,
        experienceInMonths:
          Number(formData.experienceInYears * 12) +
          Number(formData.experienceInMonths),
        workType: formData.workType,
        collegeName: formData.collegeName,
        passOutYear: formData.passOutYear,
        workType: selectedWorkTypes.join(","),
        specialistIn: selectedExpertiseTypes.join(","),
        stateCouncilNo: formData.stateCouncilNo,
        clinicalExperiences: clinicalExpriences,
        certificateUrl: formData.certificateUrl,
      };
      const { data } = await api.updateProfile(payload);
      setLoading(false);
      if (!data?.success) {
        return;
      } else {
        toast.success(data?.message, {
          toastId: "edit-success-toast",
        });
      }
      Navigate("/doctor/settings");
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const populateWorkTypes = (data) => {
    let filteredList = workTypeOptions.filter((item) => data.includes(item));
    setSelectedWorkTypes(filteredList);
  };

  const populateExpertiseTypes = (data) => {
    let filteredList = expertiseOptions.filter((item) => data.includes(item));
    setSelectedExpertiseTypes(filteredList);
  };

  const getProfileData = async () => {
    try {
      const { data } = await api.getProfile();
      if (!data?.success) {
        return;
      }
      let info = data?.data?.user || {};
      setFormData({
        ...formData,
        name: info?.name || "",
        email: info?.email || "",
        phoneNumber: info?.phoneNumber || "",
        imageUrl: info?.imageUrl || "",
        experienceInYears: info?.userDetails?.experienceInMonths
          ? parseInt(info?.userDetails?.experienceInMonths / 12)
          : 0,
        experienceInMonths: info?.userDetails?.experienceInMonths
          ? parseInt(info?.userDetails?.experienceInMonths % 12)
          : 0,
        collegeName: info?.userDetails?.collegeName || "",
        passOutYear: info?.userDetails?.passOutYear || "",
        specialistIn: info?.userDetails?.specialistIn || "",
        stateCouncilNo: info?.stateCouncilNo || "",
        // clinicalExpriences: info?.userDetails?.clinicalExpriences || [],
      });
      setClinicalExpriences(info?.userDetails?.clinicalExpriences || []);

      setDocFileUrl(info?.userDetails?.certificateUrl || "");

      if (info?.userDetails?.workType) {
        populateWorkTypes(info?.userDetails?.workType);
      }
      if (info?.userDetails?.specialistIn) {
        populateExpertiseTypes(info?.userDetails?.specialistIn);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleExpChange = (name, value, index) => {
    let arr = clinicalExpriences.map((item, i) =>
      i == index
        ? {
            ...item,
            [name]: value,
          }
        : item
    );
    setClinicalExpriences(arr);
  };

  const addExpRow = () => {
    setClinicalExpriences([
      ...clinicalExpriences,
      {
        id: null,
        clinicName: "",
        jobTitle: "",
        startFrom: "",
        upto: "",
      },
    ]);
  };

  const deleteExpRow = (index) => {
    let arr = clinicalExpriences.map((item, i) =>
      i == index
        ? {
            ...item,
            isDeleted: true,
          }
        : item
    );
    setClinicalExpriences(arr);
  };

  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <>
      <Paper sx={{ p: "1.8em", borderRadius: 2, mt: 3, maxWidth: "1090px" }}>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: 600,
            color: "#303030",
            mb: "1em",
          }}
        >
          Profile
        </Typography>
        <Box sx={{ width: "100px", position: "relative" }}>
          <Box
            sx={{
              mb: 3,
              width: "100px",
              height: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "100%",
              overflow: "hidden",
            }}
          >
            <img
              src={formData.imageUrl || profileicon}
              style={{ width: 100, height: "inherit", objectFit: "cover" }}
            />
          </Box>
          <Box>
            <label id="avatar-inp-label" htmlFor="avatar-inp">
              <img
                src={editicon}
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  width: 34,
                  height: 34,
                }}
              />
            </label>
            <TextField
              sx={{ display: "none" }}
              id="avatar-inp"
              type="file"
              onChange={uploadFile}
            />
          </Box>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={1} justifyContent="space-between">
              <Grid item display="flex" flexDirection="column" xs={12}>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#303030",
                    mb: 1,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  First Name
                </Typography>
                <OutlinedInput
                  name={"name"}
                  onChange={addFormData}
                  value={formData?.name}
                  sx={{
                    borderRadius: 2,
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-input": {
                      borderRadius: 2,
                      outline: "none",
                      height: "15px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      transition: "ease-in 0.2s",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: `1px solid ${palette?.primary?.main}`,
                      transition: "ease-in 0.2s",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: `2px solid ${palette?.primary?.light}`,
                      transition: "ease-in 0.2s",
                    },
                  }}
                />
              </Grid>
              <Grid item display="flex" flexDirection="column" xs={12} sm={6}>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#303030",
                    mb: 1,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Email
                </Typography>
                <OutlinedInput
                  name={"email"}
                  type="email"
                  onChange={addFormData}
                  value={formData?.email}
                  sx={{
                    borderRadius: 2,
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-input": {
                      borderRadius: 2,
                      outline: "none",
                      height: "15px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      transition: "ease-in 0.2s",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: `1px solid ${palette?.primary?.main}`,
                      transition: "ease-in 0.2s",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: `2px solid ${palette?.primary?.light}`,
                      transition: "ease-in 0.2s",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={1} justifyContent="space-between">
              <Grid item display="flex" flexDirection="column" xs={12} sm={6}>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#303030",
                    mb: 1,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Phone Number
                </Typography>
                <OutlinedInput
                  inputProps={{
                    maxLength: 10,
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                  name={"phoneNumber"}
                  value={formData?.phoneNumber}
                  onChange={addFormData}
                  onInput={(e) => {
                    const target = e?.target;
                    target.value = e?.target?.value.replace(/[^0-9]/g, "");
                  }}
                  sx={{
                    borderRadius: 2,
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-input": {
                      borderRadius: 2,
                      outline: "none",
                      height: "15px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      transition: "ease-in 0.2s",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: `1px solid ${palette?.primary?.main}`,
                      transition: "ease-in 0.2s",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: `2px solid ${palette?.primary?.light}`,
                      transition: "ease-in 0.2s",
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                display="flex"
                justifyContent={"space-between"}
                gap={1}
                xs={12}
                sm={6}
              >
                <Grid item display="flex" flexDirection="column" xs={12}>
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#303030",
                      mb: 1,
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Registration Number
                  </Typography>
                  <OutlinedInput
                    fullWidth
                    name={"stateCouncilNo"}
                    value={formData?.stateCouncilNo}
                    onChange={addFormData}
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "white",
                      "& .MuiOutlinedInput-input": {
                        borderRadius: 2,
                        outline: "none",
                        height: "15px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        transition: "ease-in 0.2s",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${palette?.primary?.main}`,
                        transition: "ease-in 0.2s",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: `2px solid ${palette?.primary?.light}`,
                        transition: "ease-in 0.2s",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: "0.5em" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={1} justifyContent="space-between">
              <Grid item display="flex" flexDirection="column" xs={12} sm={6}>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#303030",
                    mb: 1,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  College Name
                </Typography>
                <OutlinedInput
                  name={"collegeName"}
                  value={formData?.collegeName}
                  onChange={addFormData}
                  sx={{
                    borderRadius: 2,
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-input": {
                      borderRadius: 2,
                      outline: "none",
                      height: "15px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      transition: "ease-in 0.2s",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: `1px solid ${palette?.primary?.main}`,
                      transition: "ease-in 0.2s",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: `2px solid ${palette?.primary?.light}`,
                      transition: "ease-in 0.2s",
                    },
                  }}
                />
              </Grid>
              <Grid item display="flex" flexDirection="column" xs={12} sm={6}>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#303030",
                    mb: 1,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Year of Graduation{" "}
                </Typography>
                <OutlinedInput
                  name={"passOutYear"}
                  value={formData?.passOutYear}
                  onChange={addFormData}
                  sx={{
                    borderRadius: 2,
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-input": {
                      borderRadius: 2,
                      outline: "none",
                      height: "15px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      transition: "ease-in 0.2s",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: `1px solid ${palette?.primary?.main}`,
                      transition: "ease-in 0.2s",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: `2px solid ${palette?.primary?.light}`,
                      transition: "ease-in 0.2s",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={1} justifyContent="space-between">
              <Grid
                item
                display="flex"
                flexDirection="column"
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={5.5}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#303030",
                    mb: 1,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  State Council Reg Certificate
                </Typography>
                {documentFileUrl ? (
                  <a
                    href={documentFileUrl}
                    download={documentFileName}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <DocumentViewer
                      file={documentFileUrl}
                      fileName={documentFileName}
                    />
                  </a>
                ) : (
                  <>
                    <label
                      htmlFor="cert-inp"
                      style={{
                        border: "2px solid #004E89",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "4px",
                        width: "50%",
                        padding: "4px 8px",
                        cursor: "pointer",
                        color: "#004E89",
                        fontWeight: 500,
                      }}
                    >
                      Choose File <FileUploadOutlinedIcon fontSize="small" />
                    </label>
                    <input
                      id="cert-inp"
                      style={{
                        display: "none",
                      }}
                      type="file"
                      onChange={(e) => uploadFile(e, "certificate")}
                    />
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: "0.5em" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={1} justifyContent="space-between">
              <Grid
                item
                display="flex"
                flexDirection="column"
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={5.5}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#303030",
                    mb: 1,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Work Type
                </Typography>
                <FormControl>
                  <Select
                    multiple
                    name={"workType"}
                    value={selectedWorkTypes}
                    onChange={handleWorkTypeChange}
                    sx={{
                      borderRadius: 2,
                      "& .MuiOutlinedInput-input": {
                        borderRadius: 2,
                        outline: "none",
                        height: "15px",
                        marginTop: "-0.2em",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                      },
                    }}
                    onFocus={(e) => e.currentTarget.classList.add("focused")}
                    onBlur={(e) => e.currentTarget.classList.remove("focused")}
                  >
                    {workTypeOptions.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item display="flex" flexDirection="column" xs={12} sm={6}>
                <Typography
                  variant="h6"
                  sx={{
                    color: "#303030",
                    mb: 1,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Expertise in
                </Typography>

                <Select
                  multiple
                  name={"expertiseType"}
                  value={selectedExpertiseTypes}
                  onChange={handleExpertiseTypeChange}
                  sx={{
                    borderRadius: 2,
                    "& .MuiOutlinedInput-input": {
                      borderRadius: 2,
                      outline: "none",
                      height: "15px",
                      marginTop: "-0.2em",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                    },
                  }}
                  onFocus={(e) => e.currentTarget.classList.add("focused")}
                  onBlur={(e) => e.currentTarget.classList.remove("focused")}
                >
                  {expertiseOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            sx={{
              width: "100%",
            }}
          >
            <Typography
              variant="h3"
              sx={{ fontSize: "24px", mb: "1em", mt: "1em" }}
            >
              Work Experience
            </Typography>

            {clinicalExpriences
              ?.filter((item) => !item?.isDeleted)
              .map((item, index) => (
                <WorkExpDetailsCard
                  data={item}
                  index={index}
                  isEdit={true}
                  handleExpChange={handleExpChange}
                  deleteExpRow={deleteExpRow}
                />
              ))}
            <Box textAlign={"center"}>
              <Button variant="contained" size="small" onClick={addExpRow}>
                {/* <AddCircleOutlineOutlinedIcon /> */}
                Add
              </Button>
            </Box>
          </Grid>

          <Grid item xs={12} display={"flex"} alignItems={"center"}>
            <Button
              disabled={isLoading}
              variant="contained"
              size="medium"
              fullWidth={false}
              onClick={updateProfile}
              sx={{
                borderRadius: 24,
                width: "20%",
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {isLoading ? <CircularProgress size={15} /> : "Save"}
            </Button>
            <Link
              to="/doctor/settings"
              style={{
                color: "#FF7676",
                fontWeight: 600,
                fontSize: 16,
                cursor: "pointer",
                marginLeft: "1em",
              }}
            >
              {" "}
              Cancel{" "}
            </Link>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default DoctorSettings;
