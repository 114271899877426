import { Box, Button, Chip, Divider, Grid, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { BsFilePost } from "react-icons/bs";

import markerPin from "../../assets/images/marker-pin.png";
import { getPayType } from "../../utils/getPayType";

const PostDetailsCard = ({
  data = {},
  showPostProposals = null,
  index = "",
}) => {
  return (
    <>
      {data?.id && (
        <Grid
          key={index}
          sx={{
            py: 1.5,
            px: 1.5,
            display: "flex",
            gap: 1,
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#fff",
            borderRadius: "8px",
          }}
        >
          <Grid
            display={"flex"}
            alignItems={"center"}
            gap={1}
            flexWrap={"wrap"}
          >
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                height: 46,
                width: 46,
                backgroundColor: "#004E89",
                borderRadius: "5px",
              }}
            >
              <BsFilePost color="#fff" size={26} />
            </Box>
            <Box>
              <Box>
                <Typography
                  noWrap
                  sx={{
                    color: "#004E89",
                    fontSize: "18px",
                    fontWeight: 600,
                    fontFamily: "Poppins",
                    width: "130px",
                  }}
                  title={data?.caseName || data?.jobTitle || "-"}
                >
                  {data.requestType == "CONSULTATION"
                    ? data?.caseName
                    : data?.jobTitle}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "21.885px",
                    fontSize: "9.708px",
                    fontWeight: 400,
                  }}
                >
                  {data?.date ? moment(data?.date).format("DD MMM, YYYY") : ""}
                </Typography>
              </Box>
              <Box
                onClick={() => window.open(data?.locationUrl || "-", "_blank")}
              >
                <Typography
                  sx={{
                    color: "#1A202C",
                    fontSize: "13px",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                    color: "#E78756",
                    cursor: "pointer",
                  }}
                >
                  <img src={markerPin} height={15} />
                  Direction
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                },
                gap: 0.5,
                position: "relative",
              }}
            >
              <Box>
                <Chip
                  sx={{
                    borderRadius: "3.43px",
                    border: "none",
                    backgroundColor: "rgba(248, 248, 248, 1)",
                    minWidth: "140px",
                  }}
                  label={
                    <Typography
                      sx={{
                        color: "#F98247",
                        fontSize: "12px",
                        fontWeight: "600",
                        fontFamily: "Poppins",
                      }}
                    >
                      {data?.requestType}
                    </Typography>
                  }
                />
              </Box>
              <Box>
                <Chip
                  sx={{
                    borderRadius: "3.43px",
                    border: "none",
                    backgroundColor: "rgba(248, 248, 248, 1)",
                    width: "140px",
                  }}
                  label={
                    <Typography
                      noWrap
                      sx={{
                        color: "#F98247",
                        fontSize: "12px",
                        fontWeight: "600",
                        fontFamily: "Poppins",
                      }}
                      title={getPayType({
                        requestType: data?.requestType,
                        minSalary: data?.minimumSalary,
                        maxSalary: data?.maximumSalary,
                        hourly: data?.hourly,
                      })}
                    >
                      {getPayType({
                        requestType: data?.requestType,
                        minSalary: data?.minimumSalary,
                        maxSalary: data?.maximumSalary,
                        hourly: data?.hourly,
                      })}
                    </Typography>
                  }
                />
              </Box>
            </Box>
          </Grid>
          <Divider
            color={"#F98247"}
            width={"0.47px"}
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ mx: 1 }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <Button
              size="small"
              variant="outlined"
              sx={{
                fontSize: "13px",
                fontWeight: 600,
                color: "#004E89",
                backgroundColor: "transparent",
                borderRadius: "4px",
              }}
              onClick={() => {
                showPostProposals(data);
              }}
            >
              Proposals
            </Button>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default PostDetailsCard;
