import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TableContainer,
  TableCell,
  Table,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  useTheme,
  Box,
} from "@mui/material";
import { styles } from "../../layouts/Styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import DateRangeCalendar from "../../components/calendar/DateRangeCalendar";
import api from "../../api";
import moment from "moment";

const DoctorTransaction = () => {
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const setStartDate = (d) => {
    setSelectedStartDate(
      moment(d?.$d, "ddd MMM DD YYYY HH:mm:ss [GMT]Z").format("YYYY-MM-DD")
    );
  };
  const setEndDate = (d) => {
    setSelectedEndDate(
      moment(d?.$d, "ddd MMM DD YYYY HH:mm:ss [GMT]Z").format("YYYY-MM-DD")
    );
  };
  const {
    palette: { grey },
  } = useTheme();
  const classes = styles();

  let transactiondata = [
    {
      id: 1,
      Name: "Martin Joy",
      Amount: "₹2000",
      "Date & Time": "3Jan, 10:00AM",
      status: "Credited",
    },
    {
      id: 1,
      Name: "Martin Joy",
      Amount: "₹2000",
      "Date & Time": "3Jan, 10:00AM",
      status: "Debited",
    },
  ];
  const getColorStyle = (status) => {
    status = status?.toLowerCase();

    switch (status) {
      case "credited":
        return "credited";
      case "debited":
        return "debited";
      default:
        return "tableCellBody";
    }
  };

  const columns = Object.keys(transactiondata[0]);

  const CustomizedStaticDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
      width: 320,
    },
  }));
  const [data, setData] = useState([]); // Data from API
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(5); // Rows per page
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    // console.log('Line 44 CALLED')
    getTransactions();
  }, [selectedEndDate]);

  const getTransactions = async () => {
    await api
      .getTransactionsDoctor(selectedStartDate, selectedEndDate)
      .then((res) => {
        if (res?.data?.success) {
          // setDoctorCount(res?.data?.data?.totalPendingDoctors);
          console.log("Line 87", res?.data?.data?.transactions);
          setTransactions(res?.data?.data?.transactions);
        }
      });
  };

  useEffect(() => {
    // Fetch data from your API based on page and rowsPerPage
    // Update the 'data' state with the fetched data
    // Example: Fetch data using axios
    // axios.get(`/api/data?page=${page + 1}&perPage=${rowsPerPage}`)
    //   .then(response => setData(response.data))
    //   .catch(error => console.error(error));
    // Mock data for demonstration
    //   const mockData = Array.from({ length: 50 }, (_, index) => ({
    //     id: index + 1,
    //     name: `Item ${index + 1}`,
    //   }));
    //   setData(
    //     mockData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    //   );
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset page to 0 when changing rowsPerPage
  };
  return (
    <>
      <Grid Container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{
              flexDirection: {
                xs: "column",
                sm: "row",
                md: "row",
                lg: "row",
                xl: "row",
              },
              alignItems: {
                xs: "start",
                sm: "center",
                md: "center",
                lg: "center",
                xl: "center",
              },
            }}
          >
            <Box>
              <Typography
                variant="h3"
                sx={{ fontSize: "22px", mt: "1em", mb: "1em" }}
              >
                {" "}
                Transaction{" "}
              </Typography>
            </Box>
            <Box>
              <DateRangeCalendar
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper sx={{ mt: 2 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column} className={classes.tableCell}>
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions?.length ? (
                    transactions?.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell className={classes.tableCellBody}>
                          {row?.id}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBody}
                          style={{ color: "#004E89" }}
                        >
                          {row?.userDetails?.name?.charAt(0).toUpperCase() +
                            row?.userDetails?.name?.slice(1)}
                        </TableCell>
                        <TableCell className={classes.tableCellBody}>
                          ₹ {row?.amount}
                        </TableCell>
                        <TableCell className={classes.tableCellBody}>
                          {moment(row?.proposalDetails?.date).format(
                            "DD MMM, h:mmA"
                          )}
                        </TableCell>
                        <TableCell
                          className={classes[getColorStyle(row["status"])]}
                          style={{
                            color:
                              localStorage.getItem("userId") ===
                              row?.userDetails?._receiver
                                ? "#00A01B"
                                : "#ED3838",
                          }}
                        >
                          {localStorage.getItem("userId") ===
                          row?.userDetails?._receiver
                            ? "Credited"
                            : "Debited"}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        <Typography variant="h6" sx={{ fontSize: "22px" }}>
                          No Transactions Found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={50} 
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default DoctorTransaction;
