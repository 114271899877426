import React, { Fragment } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import AdminLogin from "../pages/AdminDashboard/AdminLogin";

const AuthRoutes = () => {
  return (
    <Fragment>
      <Routes>
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route
          path="/"
          index={true}
          element={<Navigate to={"/admin/login"} replace={true} index={true} />}
        />
        <Route
          path="*"
          index={true}
          element={<Navigate to={"/admin/login"} replace={true} index={true} />}
        />
      </Routes>
    </Fragment>
  );
};

export default AuthRoutes;
