// import { ErrorRounded } from "@mui/icons-material";
import axios from "axios";
// import createAuthRefreshInterceptor from "axios-auth-refresh";
import { toast } from "react-toastify";

export const HttpService = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
});

HttpService.interceptors.request.use((config) => {
  let auth = localStorage.getItem("auth");
  if (auth) {
    auth = JSON.parse(auth);
    config.headers.Authorization = "Bearer " + auth?.accessToken;
    // config.headers.userid = auth.userID;
  }
  return config;
});

HttpService.interceptors.response.use(
  function (response) {
    if (response.data.success) {
      if (response.data.message) {
        toast.success(response.data.message);
      }
    } else {
      toast.error(response?.data?.message || "Something went wrong.");
    }
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.reload();
      return Promise.reject(error);
    }
  }
);
// const refreshAuthLogic = async (failedRequest) => {
//   let authObj;
//   const authStr = localStorage.getItem("auth");
//   if (authStr) {
//     authObj =
//       typeof localStorage.getItem("auth") === "string"
//         ? JSON.parse(authStr)
//         : authObj;
//     return HttpService.post(`/api/v1/refresh-token`, {
//       refreshToken: authObj.refreshToken,
//     })
//       .then((response) => {
//         if (response.data.success) {
//           localStorage.setItem(
//             "auth",
//             JSON.stringify(response.data.data.tokens)
//           );
//           failedRequest.response.config.headers.Authorization =
//             "Bearer " + response.data.data.tokens.accessToken;
//           return Promise.resolve();
//         } else {
//           localStorage.removeItem("auth");
//           window.location.reload();
//           return Promise.reject();
//         }
//       })
//       .catch((e) => {
//         localStorage.removeItem("auth");
//         window.location.reload();
//         return Promise.reject();
//       });
//   } else {
//     localStorage.removeItem("auth");
//     return Promise.reject();
//   }
// };
// createAuthRefreshInterceptor(HttpService, refreshAuthLogic);
