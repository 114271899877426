import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activePost: '',
};

export const Clinic = createSlice({
  name: "Clinic",
  initialState,
  reducers: {
    setActivePost: (state, { payload }) => {
      state.activePost = payload;
    },
  },
});

export const { setActivePost } = Clinic.actions;

export default Clinic.reducer;
