const getExperience = (data) => {
  let exp = data;
  let yy = parseInt(exp / 12);
  let mm = exp % 12;

  let years = "";
  let months = "";

  if (yy) {
    if (yy <= 1) {
      years = " year ";
    } else {
      years = " years ";
    }
  }

  if (mm) {
    if (mm <= 1) {
      months = " month";
    } else {
      months = " months";
    }
  }

  return yy || mm
    ? (yy > 0 ? yy : "") + years + (mm > 0 ? mm : "") + months
    : "0 year";
};

export default getExperience;
