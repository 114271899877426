import { Box, Button, Divider, Grid, Link, OutlinedInput, Paper, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import api from "../../api";
import AvatarIcon from "../../assets/images/avatar-placeholder.png";
import fileimage from "../../assets/images/fileimage.png";
import DocumentViewer from "../../components/DocumentViewer/DocView";
import PasswordModal from "../../components/Modals/PasswordModal";

const ClinicSettings = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { loc } = location.state || {};
  const [tabIndex, setTabIndex] = useState(loc == "clinic" ? 1 : 0);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    clinicName: "",
    phoneNumber: "",
    website: "",
    address: "",
    googleMapLocation: "",
    stateCouncilNo: ""
  });

  const [profileURL, setProfileURL] = useState("");

  const { palette } = useTheme();
  const Navigate = useNavigate();

  const addFormData = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handlePasswordOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const documentFileUrl =
    "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf";
  let documentFileName = "Certificate.pdf";

  const getProfileData = async () => {
    try {
      const { data } = await api.getProfile();
      if (!data?.success) {
        return;
      }

      let info = data?.data?.user || {};

      setFormData({
        ...formData,
        name: info?.name || "",
        email: info?.email || "",
        clinicName: info?.userDetails?.clinicName || "",
        phoneNumber: info?.phoneNumber || "",
        website: info?.userDetails?.website || "",
        address: info?.userDetails?.address || "",
        googleMapLocation: info?.userDetails?.googleMapLocation || "",
        stateCouncilNo: info?.stateCouncilNo || "",
        certificateUrl: info?.userDetails?.certificateUrl || "",
      });
      setProfileURL(info?.imageUrl);
    } catch (err) {
      console.log(err);
    }
  };
  console.log("formData: ", formData);

  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <>
      {open ? (
        <PasswordModal open={open} handleClose={handleClose} clinic={true} />
      ) : null}
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={4} sm={2} md={2} lg={2} xl={1}>
          {" "}
          <Button
            onClick={() => setTabIndex(0)}
            variant={tabIndex == 0 ? "contained" : "outlined"}
            size="medium"
            sx={{ borderRadius: 46 }}
          >
            Profile
          </Button>
        </Grid>
      </Grid>
      {tabIndex === 0 && (
        <Paper sx={{ p: "1.8em", borderRadius: 2, mt: 3, maxWidth: "1090px" }}>
          <Box
            sx={{
              mb: 3,
              position: "relative",
              width: "100px",
              height: "100px",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              flexWrap: "wrap",
              borderRadius: "100%",
              overflow: "hidden",
              border: "2px solid #004E89",
            }}
          >
            <img
              src={profileURL || AvatarIcon}
              width="100%"
              style={{
                height: "fit-content",
              }}
              alt="_profile"
            />
          </Box>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid
                  item
                  display="flex"
                  flexDirection="column"
                  xs={12}
                  sm={6}
                  md={6}
                  lg={6}
                  xl={5.5}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#303030",
                      mb: 1,
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Owner Name
                  </Typography>
                  <OutlinedInput
                    readOnly
                    name={"ownerName"}
                    onChange={addFormData}
                    value={formData?.name}
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "white",
                      "& .MuiOutlinedInput-input": {
                        borderRadius: 2,
                        outline: "none",
                        height: "15px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        transition: "ease-in 0.2s",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${palette?.primary?.main}`,
                        transition: "ease-in 0.2s",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: `2px solid ${palette?.primary?.light}`,
                        transition: "ease-in 0.2s",
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  display="flex"
                  flexDirection="column"
                  xs={12}
                  sm={6}
                  xl={5.5}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#303030",
                      mb: 1,
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                    name="email"
                    value={formData.email}
                    onChange={addFormData}
                  >
                    Email Id
                  </Typography>
                  <OutlinedInput
                    readOnly
                    name={"email"}
                    type="email"
                    onChange={addFormData}
                    value={formData?.email}
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "white",
                      "& .MuiOutlinedInput-input": {
                        borderRadius: 2,
                        outline: "none",
                        height: "15px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        transition: "ease-in 0.2s",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${palette?.primary?.main}`,
                        transition: "ease-in 0.2s",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: `2px solid ${palette?.primary?.light}`,
                        transition: "ease-in 0.2s",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid
                  item
                  display="flex"
                  flexDirection="column"
                  xs={12}
                  sm={6}
                  xl={5.5}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#303030",
                      mb: 1,
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Clinic Name
                  </Typography>
                  <OutlinedInput
                    readOnly
                    name={"clinicName"}
                    onChange={addFormData}
                    value={formData?.clinicName}
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "white",
                      "& .MuiOutlinedInput-input": {
                        borderRadius: 2,
                        outline: "none",
                        height: "15px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        transition: "ease-in 0.2s",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${palette?.primary?.main}`,
                        transition: "ease-in 0.2s",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: `2px solid ${palette?.primary?.light}`,
                        transition: "ease-in 0.2s",
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  display="flex"
                  flexDirection="column"
                  xs={12}
                  sm={6}
                  xl={5.5}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#303030",
                      mb: 1,
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Phone Number
                  </Typography>
                  <OutlinedInput
                    readOnly
                    name={"phoneNumber"}
                    value={formData?.phoneNumber}
                    onChange={addFormData}
                    type="number"
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "white",
                      "& .MuiOutlinedInput-input": {
                        borderRadius: 2,
                        outline: "none",
                        height: "15px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        transition: "ease-in 0.2s",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${palette?.primary?.main}`,
                        transition: "ease-in 0.2s",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: `2px solid ${palette?.primary?.light}`,
                        transition: "ease-in 0.2s",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid
                  item
                  display="flex"
                  flexDirection="column"
                  xs={12}
                  sm={6}
                  xl={5.5}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#303030",
                      mb: 1,
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Website
                  </Typography>
                  <OutlinedInput
                    readOnly
                    name={"website"}
                    value={formData?.website}
                    onChange={addFormData}
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "white",
                      "& .MuiOutlinedInput-input": {
                        borderRadius: 2,
                        outline: "none",
                        height: "15px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        transition: "ease-in 0.2s",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${palette?.primary?.main}`,
                        transition: "ease-in 0.2s",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: `2px solid ${palette?.primary?.light}`,
                        transition: "ease-in 0.2s",
                      },
                    }}
                  />
                </Grid>
                <Grid
                  item
                  display="flex"
                  flexDirection="column"
                  xs={12}
                  sm={6}
                  xl={5.5}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#303030",
                      mb: 1,
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Address
                  </Typography>
                  <OutlinedInput
                    readOnly
                    name={"address"}
                    value={formData?.address}
                    onChange={addFormData}
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "white",
                      "& .MuiOutlinedInput-input": {
                        borderRadius: 2,
                        outline: "none",
                        height: "15px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        transition: "ease-in 0.2s",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${palette?.primary?.main}`,
                        transition: "ease-in 0.2s",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: `2px solid ${palette?.primary?.light}`,
                        transition: "ease-in 0.2s",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid
                  item
                  display="flex"
                  justifyContent={"space-between"}
                  xs={12}
                  sm={6}
                  xl={5.5}
                >
                  <Grid>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#303030",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Licence/Rental Agreement
                    </Typography>
                    {/* <DocumentViewer
                      file={formData?.certificateUrl}
                      fileName={documentFileName}
                    /> */}
                    <Button
                      variant="outlined"
                      onClick={() =>
                        window.open(formData?.certificateUrl, "_blank")
                      }
                      size="large"
                      sx={{
                        borderRadius: "25px",
                        border: "1px solid #EDEDED",
                        background: "white",
                        mt: 1,
                        color: "black",
                        fontWeight: 600,
                        height: 50
                      }}
                    >
                      <img
                        src={fileimage}
                        style={{ width: 24, height: 24, marginRight: 15 }}
                      />
                      Certificate.pdf
                    </Button>
                  </Grid>
                  <Grid>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#303030",
                        mb: 1,
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      State Council Registration Number
                    </Typography>
                    <OutlinedInput
                      readOnly
                      fullWidth
                      name={"address"}
                      value={formData?.stateCouncilNo}
                      onChange={addFormData}
                      sx={{
                        borderRadius: 2,
                        backgroundColor: "white",
                        "& .MuiOutlinedInput-input": {
                          borderRadius: 2,
                          outline: "none",
                          height: "15px",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: 2,
                          transition: "ease-in 0.2s",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: `1px solid ${palette?.primary?.main}`,
                          transition: "ease-in 0.2s",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          border: `2px solid ${palette?.primary?.light}`,
                          transition: "ease-in 0.2s",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  display="flex"
                  flexDirection="column"
                  xs={12}
                  sm={6}
                  xl={5.5}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#303030",
                      mb: 1,
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Google Map Location
                  </Typography>
                  <OutlinedInput
                    readOnly
                    name={"googleMapLocation"}
                    onChange={addFormData}
                    value={formData?.googleMapLocation}
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "white",
                      "& .MuiOutlinedInput-input": {
                        borderRadius: 2,
                        // border: "none",
                        outline: "none",
                        height: "15px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        transition: "ease-in 0.2s",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${palette?.primary?.main}`,
                        transition: "ease-in 0.2s",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: `2px solid ${palette?.primary?.light}`,
                        transition: "ease-in 0.2s",
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} justifyContent="space-between">
                <Grid item display="flex" flexDirection="column" xs={1}>
                  <Button
                    variant="contained"
                    size="small"
                    fullWidth={false}
                    onClick={() => {
                      Navigate("/editSettingsClinic");
                    }}
                    sx={{
                      width: 120,
                      height: 40,
                      borderRadius: 24,
                      // padding: "18px 30px 18px 30px",
                      fontSize: 16,
                      fontWeight: 500,
                    }}
                  >
                    Edit
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={5.5}>
                  <Link
                    sx={{
                      color: "#004E89",
                      fontWeight: 600,
                      fontSize: 16,
                      cursor: "pointer",
                    }}
                    onClick={handlePasswordOpen}
                  >
                    Change Password
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      )}
      {tabIndex === 1 && (
        <Paper sx={{ p: "1em", borderRadius: 1.5, mt: 3, maxWidth: "860px" }}>
          <Box
            sx={{
              backgroundColor: "#F8F8F8",
              padding: 2,
              borderRadius: 1.5,
              mb: 0.5,
            }}
          >
            <Grid container flexDirection="column">
              <Grid item sx={{ mb: 1 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  flexDirection="row"
                >
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#303030",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      Doctor is added
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#303030 63%",
                        fontSize: "10px",
                        fontWeight: 500,
                      }}
                    >
                      10.00AM
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography
                  sx={{ color: "#222222", fontWeight: 300, fontSize: "13px" }}
                >
                  Lorem ipsum dolor sit amet consectetur. Aliquet scelerisque
                  nunc dignissim ornare tincidunt ultrices id non. Blandit et
                  nisi scelerisque lectus. Morbi platea eu ac tortor sed
                  consectetur
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Divider sx={{ mb: 1 }} />
          <Box
            sx={{
              backgroundColor: "#F8F8F8",
              padding: 2,
              borderRadius: 1.5,
              mb: 0.5,
            }}
          >
            <Grid container flexDirection="column">
              <Grid item sx={{ mb: 1 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  flexDirection="row"
                >
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#303030",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      Doctor is added
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#303030 63%",
                        fontSize: "10px",
                        fontWeight: 500,
                      }}
                    >
                      10.00AM
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography
                  sx={{ color: "#222222", fontWeight: 300, fontSize: "13px" }}
                >
                  Lorem ipsum dolor sit amet consectetur. Aliquet scelerisque
                  nunc dignissim ornare tincidunt ultrices id non. Blandit et
                  nisi scelerisque lectus. Morbi platea eu ac tortor sed
                  consectetur
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Divider sx={{ mb: 1 }} />
          <Box
            sx={{
              backgroundColor: "#F8F8F8",
              padding: 2,
              borderRadius: 1.5,
              mb: 0.5,
            }}
          >
            <Grid container flexDirection="column">
              <Grid item sx={{ mb: 1 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  flexDirection="row"
                >
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#303030",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                    >
                      Doctor is added
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        color: "#303030 63%",
                        fontSize: "10px",
                        fontWeight: 500,
                      }}
                    >
                      10.00AM
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Typography
                  sx={{ color: "#222222", fontWeight: 300, fontSize: "13px" }}
                >
                  Lorem ipsum dolor sit amet consectetur. Aliquet scelerisque
                  nunc dignissim ornare tincidunt ultrices id non. Blandit et
                  nisi scelerisque lectus. Morbi platea eu ac tortor sed
                  consectetur
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Divider sx={{ mb: 1 }} />
        </Paper>
      )}
    </>
  );
};

export default ClinicSettings;
