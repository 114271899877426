import { Grid, LinearProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import api from "../../api";
import DoctorConsultationReq from "../Modals/DoctorConsultationReq";
import AppliedJobDetailsCard from "./AppliedJobDetailsCard";

const AppliedJobs = () => {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setLoading] = useState(false);
  const [appliedJobs, setAppliedJobs] = useState([]);

  const [isWithdraw, setIsWithdraw] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const updateProposalByProposalId = async (consultationId, status, index) => {
    setIsWithdraw(index);
    try {
      const res = await api.updateProposalByProposalId({
        id: consultationId,
        status: status,
      });
      setIsWithdraw("");
      if (res?.data?.success) {
        getAppliedJobs();
      }
    } catch (err) {
      setIsWithdraw("");
      console.log(err);
    }
  };

  const getAppliedJobs = async () => {
    try {
      setLoading(true);
      const { data } = await api.getProposalsByDoctor();
      setLoading(false);
      setAppliedJobs(data?.data?.proposal || []);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getAppliedJobs();
  }, []);

  return (
    <>
      <Typography variant="h3" mt={1} mb={0.5} sx={{ fontSize: "22px" }}>
        Applied Jobs
      </Typography>

      {isLoading && (
        <LinearProgress
          sx={{
            mb: 1,
          }}
        />
      )}

      <DoctorConsultationReq open={open} handleClose={handleClose} />
      <Grid display="flex" flexWrap="wrap" gap={1} mt={1}>
        {!isLoading ? (
          appliedJobs.length ? (
            appliedJobs.map((job, index) => (
              <AppliedJobDetailsCard
                job={job}
                index={index}
                updateProposalByProposalId={updateProposalByProposalId}
                isWithdraw={isWithdraw}
              />
            ))
          ) : (
            <Typography sx={{ width: "100%" }} textAlign={"center"}>
              No records found.
            </Typography>
          )
        ) : (
          ""
        )}
      </Grid>
    </>
  );
};

export default AppliedJobs;
