import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Rating from "@mui/material/Rating";
import React, { useState } from "react";
import api from "../../api";
import SentSuccessfully from "./SentSuccessfully";

const RatingModal = (props) => {
  const [success, sentSuccess] = useState(false);
  const [rating, setRating] = useState(null);

  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const handleAproveModal = () => {
    rateConsultationExperience();
  };

  const handleCloseModal = () => {
    sentSuccess(false);
    props.handleCloseApprove();
  };

  const rateConsultationExperience = async () => {
    try {
      let payload = {
        rating,
        _user: props?.isDoctor
          ? props?.data?.clinic?.id
          : props?.data?.doctor?.id,
      };
      const { data } = await api.rateConsultationExperience(payload);
      if (!data?.success) {
        return;
      }

      sentSuccess(true);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {success ? (
        <SentSuccessfully
          modal={success}
          handleCloseApprove={handleCloseModal}
          value={"response"}
        />
      ) : (
        <Dialog
          onClose={props.handleCloseApprove}
          aria-labelledby="customized-dialog-title"
          open={props.modal}
          fullWidth={true}
          // maxWidth="xs"
          sx={{
            "& .MuiDialog-paper": {
              // width: "672px",
              padding: "0px",
              borderRadius: "15px",
              height: "250px",
              width: "500px",
            },
          }}
        >
          <IconButton
            aria-label="close"
            onClick={props.handleCloseApprove}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Grid
              container
              spacing={3}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item xs={12}>
                <Typography
                  align="center"
                  // className={classes.text}
                  sx={{
                    marginTop: "10px",
                    fontSize: "20px",
                    fontWeight: "400",
                    color: "black",
                  }}
                >
                  How is your experience with the{" "}
                  {props.doctor ? "doctor" : "clinic"}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <Stack spacing={1} sx={{ textAlign: 'center' }}> */}
                <Rating
                  name="half-rating"
                  value={rating}
                  onChange={(event, newRating) => handleRatingChange(newRating)}
                  precision={0.5}
                  sx={{
                    "& .MuiGrid-item": {
                      paddingTop: "10px",
                    },
                    fontSize: 36,
                  }}
                />
                {/* </Stack> */}
              </Grid>
            </Grid>
            <DialogActions sx={{ justifyContent: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "#F98247",
                    borderRadius: "30px",
                    color: "white",
                    fontSize: 13,
                    fontWeight: 300,
                    padding: "12px 50px 12px 50px",
                    "&:hover": { backgroundColor: "#F98247" },
                    maxWidth: "300px",
                    marginTop: "10px",
                  }}
                  onClick={handleAproveModal}
                  disabled={rating == null}
                >
                  Submit
                </Button>
              </Box>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default RatingModal;
