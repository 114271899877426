import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Chip,
  Grid,
  InputAdornment,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import api from "../../api";
import { setActivePost } from "../../store/reducer/Clinic";
import PostDetailsCard from "./PostDetailsCard";
import ProposalFilter from "./ProposalFilter";

const Posts = ({ setTabIndex = null, isAdmin }) => {
  const [isLoading, setLoading] = useState(false);
  const [recentPostsData, setRecentPostsData] = useState([]);
  const [showProposalsPopup, setShowProposalsPopup] = useState(false);
  const [proposalListData, setProposalListData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState({
    status: "",
    startDate: "",
    endDate: "",
    type: "",
  });

  const showPostProposals = async (item) => {
    dispatch(setActivePost(item));
    if (isAdmin) {
      setTabIndex(1);
    } else {
      setTabIndex(2);
    }
  };

  const getRecentPosts = async () => {
    setLoading(true);
    try {
      let payload = { ...filterData, searchInput: searchQuery };
      if (isAdmin) {
        const { data } = await api.getAllClinicPostsForAdmin(payload);
        if (!data?.success) {
          toast.error(data?.message || "Something went wrong.");
          return;
        }
        setRecentPostsData(data?.data?.posts);
        setLoading(false);
      } else {
        const { data } = await api.getRecentClinicPosts(payload);
        if (!data?.success) {
          toast.error(data?.message || "Something went wrong.");
          return;
        }
        setRecentPostsData(data?.data?.posts);
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  //delay api call on search key change
  useEffect(() => {
    const getData = setTimeout(() => {
      getRecentPosts();
    }, 1500);

    return () => clearTimeout(getData);
  }, [searchQuery]);

  useEffect(() => {
    getRecentPosts();
  }, [
    // filterData.startDate,
    filterData.endDate,
    filterData.status,
    filterData.type,
  ]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "1em",
          mt: 1,
        }}
      >
        <Typography variant="h3" sx={{ fontSize: "22px" }}>
          Posts
        </Typography>
        <Grid display={"flex"} alignItems={"center"}>
          <TextField
            id="search"
            placeholder="Case Title"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={({ target: { value } }) => setSearchQuery(value)}
            sx={{
              width: "200px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            style={{ color: "#F98247" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ marginRight: "8px" }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <ProposalFilter setFilterData={setFilterData} />
        </Grid>
      </Box>

      {isLoading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}

      <Grid sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
        <>
          {recentPostsData.length
            ? recentPostsData.map((item, index) => (
                <PostDetailsCard
                  data={item}
                  index={index}
                  showPostProposals={showPostProposals}
                />
              ))
            : !isLoading
            ? "No records found."
            : ""}
        </>
      </Grid>
      <Dialog
        open={showProposalsPopup}
        onClose={() => setShowProposalsPopup(false)}
      >
        <IconButton
          aria-label="close"
          onClick={() => setShowProposalsPopup(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {proposalListData.map((item, index) => (
            <Grid key={index} item xs={6} sx={{ padding: 1 }}>
              <Box
                sx={{
                  backgroundColor: "white",
                  display: "flex",
                  padding: 2,
                  borderRadius: "11.79px",
                  display: "flex",
                  justifyContent: "space-around",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                <Box>
                  <img
                    src={
                      item?.clinicDetails?.imageUrl ||
                      require("../../assets/images/Hospital.png")
                    }
                    style={{
                      width: "46px",
                      borderRadius: "5px",
                    }}
                  />
                </Box>
                <Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#004E89",
                        fontSize: "18px",
                        fontWeight: 600,
                        fontFamily: "Poppins",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        width: "100px",
                      }}
                    >
                      {item?.doctor?.name}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#1A202C",
                        fontSize: "14px",
                        fontWeight: 400,
                        textWrap: "wrap",
                      }}
                    >
                      {item?.date
                        ? moment(item?.date).format("DD MMM, YYYY")
                        : ""}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        cursor: "grab",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#F98247",
                          fontSize: "13px",
                          fontWeight: 400,
                          marginLeft: "5px",
                        }}
                      >
                        {item?.doctor?.phoneNumber}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column",
                    },
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      my: "2px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Chip
                      sx={{
                        borderRadius: "3.43px",
                        border: "1px solid #F98247",
                        backgroundColor: "#FFFFFF",
                        width: "130px",
                        marginBottom: "2px",
                      }}
                      label={
                        <Typography
                          sx={{
                            color: "#F98247",
                            fontSize: "14px",
                            fontWeight: "600",
                            fontFamily: "Poppins",
                          }}
                        >
                          {item?.doctorDetails?.workType || "N-A"}
                        </Typography>
                      }
                    />
                    <Chip
                      sx={{
                        borderRadius: "3.43px",
                        border: "1px solid #F98247",
                        backgroundColor: "#FFFFFF",
                        width: "130px",
                        marginTop: "2px",
                      }}
                      label={
                        <Typography
                          sx={{
                            color: "#F98247",
                            fontSize: "14px",
                            fontWeight: "600",
                            fontFamily: "Poppins",
                          }}
                        >
                          {item?.doctorDetails?.experienceInMonths +
                            " Months" || "N-A"}
                        </Typography>
                      }
                    />
                  </Box>
                </Box>
                <Divider
                  color={"#F98247"}
                  width={"0.47px"}
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{ mx: 1 }}
                />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                  }}
                >
                  <Chip
                    sx={{
                      borderRadius: "3.43px",
                      border: "1px solid #F98247",
                      backgroundColor: "#FFFFFF",
                      width: "130px",
                    }}
                    label={
                      <Typography
                        sx={{
                          color: "#F98247",
                          fontSize: "18px",
                          fontWeight: "600",
                          fontFamily: "Poppins",
                        }}
                      >
                        {item?.bookingStatus}
                      </Typography>
                    }
                  />
                </Box>
              </Box>
            </Grid>
          ))}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Posts;
