export const getPayType = ({
  requestType = "",
  minSalary = "",
  maxSalary = "",
  hourly = "",
}) => {
  let amt;
  let operator;
  let term;

  if (minSalary) {
    operator = ">";
    amt = minSalary;
  } else if (maxSalary) {
    operator = "<";
    amt = maxSalary;
  }

  if (hourly) {
    if (requestType == "CONSULTATION") {
      term = "Hour";
    } else {
      term = "Fixed";
    }
  } else {
    if (requestType == "CONSULTATION") {
      term = "Fixed";
    } else {
      term = "Month";
    }
  }

  return `${operator} INR ${parseInt(amt)}/${term}`;
};
