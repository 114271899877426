import { Box, Button, Chip, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import React from "react";

import markerPin from "../../assets/images/marker-pin.png";
import getStringInitials from "../../utils/getStringInitials";

const AppliedJobDetailsCard = ({
  job = {},
  index = "",
  updateProposalByProposalId = null,
  isWithdraw = "",
}) => {
  const checkDisabled = () => {
    let statusList = ["ACCEPTED", "SHORTLISTED", "REJECTED", "WITHDRAWN"];
    if (statusList.includes(job?.status)) {
      return true;
    }
    return false;
  };

  let btnConfig = {
    PENDING: {
      title: "Withdraw",
      style: {
        color: "#fff",
        backgroundColor: "#004E89",
      },
    },
    ACCEPTED: {
      title: "Accepted",
      style: {
        color: "green",
        backgroundColor: "#fff",
        border: "1px solid green",
      },
    },
    SHORTLISTED: {
      title: "Shortlisted",
      style: {
        color: "yellow",
        backgroundColor: "#fff",
        border: "1px solid yellow",
      },
    },
    REJECTED: {
      title: "Rejected",
      style: {
        color: "red",
        border: "1px solid red",
        backgroundColor: "#fff",
      },
    },
    WITHDRAWN: {
      title: "Withdrawn",
      style: {
        color: "orange",
        border: "1px solid orange",
        backgroundColor: "#fff",
      },
    },
  };

  return (
    <>
      <Grid
        key={index}
        sx={{
          py: 1.5,
          px: 1.5,
          display: "flex",
          gap: 1,
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#fff",
          borderRadius: "8px",
        }}
      >
        <Grid display={"flex"} alignItems={"center"} gap={1} flexWrap={"wrap"}>
          {job?.clinic?.imageUrl ? (
            <Box display={"flex"} alignItems={"center"}>
              <img
                src={job?.clinic?.imageUrl}
                style={{
                  height: "46px",
                  width: "46px",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
              />
            </Box>
          ) : (
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              sx={{
                height: 46,
                width: 46,
                backgroundColor: "#004E89",
                borderRadius: "8px",
              }}
            >
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "24px",
                  fontWeight: 500,
                }}
              >
                {getStringInitials(job?.clinicDetails?.clinicName)}
              </Typography>
            </Box>
          )}
          <Box>
            <Box>
              <Typography
                noWrap
                sx={{
                  color: "#004E89",
                  fontSize: "18px",
                  fontWeight: 600,
                  fontFamily: "Poppins",
                  width: "130px",
                }}
                title={job?.clinicDetails?.clinicName || "-"}
              >
                {job?.clinicDetails?.clinicName || ""}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "21.885px",
                  fontSize: "9.708px",
                  fontWeight: 400,
                }}
              >
                Open Position({job?.postDetails?.number_of_openings || "-"})
              </Typography>
            </Box>

            <Box
              onClick={() => {
                if (job?.clinicDetails?.googleMapLocation) {
                  window.open(job?.clinicDetails?.googleMapLocation, "_blank");
                }
              }}
            >
              <Typography
                sx={{
                  color: "#1A202C",
                  fontSize: "13px",
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                  color: "#E78756",
                  cursor: "pointer",
                }}
              >
                <img src={markerPin} height={15} />
                Direction
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
              },
              gap: 0.5,
              position: "relative",
            }}
          >
            <Box>
              <Chip
                sx={{
                  borderRadius: "3.43px",
                  border: "none",
                  backgroundColor: "rgba(248, 248, 248, 1)",
                  minWidth: "140px",
                }}
                label={
                  <Typography
                    sx={{
                      color: "#F98247",
                      fontSize: "12px",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    {job?.postDetails?.requestType || ""}
                  </Typography>
                }
              />
            </Box>
            <Box>
              <Chip
                sx={{
                  borderRadius: "3.43px",
                  border: "none",
                  backgroundColor: "rgba(248, 248, 248, 1)",
                  width: "140px",
                }}
                label={
                  <Typography
                    sx={{
                      color: "#F98247",
                      fontSize: "12px",
                      fontWeight: "600",
                      fontFamily: "Poppins",
                    }}
                  >
                    {job?.postDetails?.minimumSalary
                      ? `>INR ${parseInt(
                          job?.postDetails?.minimumSalary
                        )}/Month`
                      : job?.postDetails?.maximumSalary
                      ? `<INR ${parseInt(
                          job?.postDetails?.minimumSalary
                        )}/Month`
                      : ""}
                  </Typography>
                }
              />
            </Box>
          </Box>
        </Grid>

        <Divider
          color={"#F98247"}
          width={"0.47px"}
          orientation="vertical"
          variant="middle"
          flexItem
          sx={{ mx: 1 }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Button
            variant={job?.bookingStatus == "PENDING" ? "contained" : "outlined"}
            size="small"
            sx={{
              borderRadius: "3.648px",
              fontSize: "13px",
              fontWeight: 600,
              width: "100px",
              ...btnConfig[`${job?.status}`].style,
              "&:hover": {
                backgroundColor: "transparent",
                border: "inherit",
                color: "inherit",
              },
            }}
            onClick={() => {
              if (checkDisabled()) {
                return;
              }
              updateProposalByProposalId(job?.id, "WITHDRAWN", index);
            }}
          >
            {isWithdraw === index ? (
              <CircularProgress sx={{ color: "#fff" }} size={20} />
            ) : (
              btnConfig[`${job?.status}`].title
            )}
          </Button>
        </Box>
      </Grid>
    </>
  );
};

export default AppliedJobDetailsCard;
