import { Box, Button } from "@mui/material";
import React, { useState } from "react";

import ConsultationHistory from "../../components/consultation/ConsultationHistory";
import MyConsultations from "../../components/consultation/MyConsultations";
import AppliedJobs from "../../components/Jobs/AppliedJobs";
import Jobs from "../../components/Jobs/Jobs";

const JobsMain = () => {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },

          gap: "15px",
        }}
        justifyContent="space-between"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            gap: "10px",
          }}
        >
          <Button
            onClick={() => setTabIndex(0)}
            variant={tabIndex == 0 ? "contained" : "outlined"}
            size="medium"
            sx={{ borderRadius: 46, px: 4 }}
          >
            Jobs
          </Button>
          <Button
            onClick={() => setTabIndex(1)}
            variant={tabIndex == 1 ? "contained" : "outlined"}
            size="medium"
            sx={{ borderRadius: 46 }}
          >
            Applied Jobs
          </Button>
        </Box>
      </Box>
      {tabIndex === 0 && <Jobs />}
      {tabIndex === 1 && <AppliedJobs />}
      {tabIndex === 2 && <MyConsultations />}
      {tabIndex === 3 && <ConsultationHistory />}
    </>
  );
};

export default JobsMain;
