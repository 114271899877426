import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Rating from "@mui/material/Rating";
import { useState } from "react";
import HTMLRenderer from "react-html-renderer";

import api from "../../api";
import doctoremail from "../../assets/images/doctoremail.png";
import doctorphone from "../../assets/images/doctorphone.png";
import fileimage from "../../assets/images/fileimage.png";
import staricon from "../../assets/images/staricon.png";
import getExperience from "../../utils/getExperience";
import SentSuccessfully from "./SentSuccessfully";

const ClinicDashModal = (props) => {
  const { open, handleClose, selectedProposal } = props;
  const [isLoading, setLoading] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [rejectOpen, setRejectOpen] = useState(false);

  const updateProposalStatus = async (id, status) => {
    try {
      setLoading(status);
      await api.updateProposalByProposalId({
        id: id,
        status: status,
      });
      setLoading("");
      if (status === "ACCEPTED") {
        setModalOpen(true);
        handleClose();
        return;
      }
      setRejectOpen(true);
    } catch (err) {
      setLoading("");
      console.log(err);
    }
  };

  const handleAproveModal = () => {
    updateProposalStatus(selectedProposal?.id, "ACCEPTED");
  };

  const handleCloseApprove = () => {
    setModalOpen(false);
    handleClose();
  };

  const handleRejectModal = () => {
    updateProposalStatus(selectedProposal?.id, "REJECTED");
  };

  const handleCloseReject = () => {
    setRejectOpen(false);
    handleClose();
  };

  const getStringInitials = (text) => {
    let textArr = text.trim().split(" ");
    let initials = "";
    textArr.map((item) => {
      initials = initials + item.charAt(0);
    });

    return initials.toUpperCase();
  };

  return (
    <>
      {modalOpen ? (
        <SentSuccessfully
          modal={modalOpen}
          handleCloseApprove={handleCloseApprove}
          value={"doctor"}
          book={true}
        />
      ) : rejectOpen ? (
        <SentSuccessfully
          modal={rejectOpen}
          cancel={true}
          reject={true}
          handleCloseApprove={handleCloseReject}
          value={"doctor"}
          book={true}
        />
      ) : (
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle
            sx={{ m: 0, p: 1, fontWeight: 600, fontSize: 20, color: "#F98247" }}
            id="customized-dialog-title"
          >
            {selectedProposal?.post?.testName || ""}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent
            sx={{ p: 1, display: "flex", flexDirection: "column", rowGap: 2 }}
          >
            <Grid display={"flex"} alignItems="center" gap={1}>
              {selectedProposal?.doctor?.imageUrl ? (
                <Box>
                  <img
                    src={selectedProposal?.doctor?.imageUrl}
                    style={{ width: 60, height: 60 }}
                    alt="_img"
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: 60,
                    width: 60,
                    backgroundColor: "#004E89",
                    borderRadius: "12px",
                  }}
                >
                  <Typography
                    sx={{ color: "#fff", fontSize: 24, fontWeight: 600 }}
                  >
                    {selectedProposal?.doctor?.name
                      ? getStringInitials(selectedProposal?.doctor.name)
                      : ""}
                  </Typography>
                </Box>
              )}

              <Grid>
                <Typography
                  sx={{
                    fontSize: 20,
                    fontWeight: 600,
                    color: "#1A202C",
                  }}
                >
                  {selectedProposal?.doctor?.name || ""}
                </Typography>
                <Box display="flex" alignItems={"center"}>
                  <Rating
                    name="half-rating"
                    readOnly
                    value={selectedProposal?.doctor?.rating || 0}
                    precision={0.5}
                    sx={{ fontSize: 14 }}
                  />
                  <Typography
                    sx={{
                      color: "#1D2343",
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                  >
                    {selectedProposal?.doctor?.rating || 0}
                  </Typography>
                  <Typography
                    sx={{
                      color: "rgba(10, 10, 10, 0.48)",
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                  >
                    ({selectedProposal?.doctor?.totalRatings || 0})
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Grid container rowGap={1}>
              <Grid
                item
                xs={12}
                sm={4}
                display={"flex"}
                alignItems={"center"}
                gap={1}
              >
                <img
                  src={doctorphone}
                  alt="_img"
                  style={{ width: 30, height: 30 }}
                />
                <Box>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: "400",
                      color: "#858585",
                    }}
                  >
                    Phone Number
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: "500",
                      color: "#303030",
                    }}
                  >
                    {selectedProposal?.doctor?.phoneNumber || "-"}
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
                display={"flex"}
                alignItems={"center"}
                gap={1}
              >
                <img
                  src={doctoremail}
                  style={{ width: 30, height: 30 }}
                  alt="_email"
                />
                <Box>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: "400",
                      color: "#858585",
                    }}
                  >
                    Email Address
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: "500",
                      color: "#303030",
                    }}
                  >
                    {selectedProposal?.doctor?.email || "-"}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Grid container rowGap={1}>
              <Grid
                item
                xs={12}
                sm={4}
                display={"flex"}
                alignItems={"center"}
                gap={1}
              >
                <img
                  src={doctorphone}
                  alt="_exp"
                  style={{ width: 30, height: 30 }}
                />
                <Box>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: "400",
                      color: "#858585",
                    }}
                  >
                    Graduation Year
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: "500",
                      color: "#303030",
                    }}
                  >
                    {selectedProposal?.doctorDetails?.passOutYear || "-"}
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
                display={"flex"}
                alignItems={"center"}
                gap={1}
              >
                <img
                  src={doctorphone}
                  style={{ width: 30, height: 30 }}
                  alt="Exp"
                />
                <Box>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: "400",
                      color: "#858585",
                    }}
                  >
                    Work Type
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: "500",
                      color: "#303030",
                    }}
                  >
                    {selectedProposal?.doctorDetails?.workType || "-"}
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}
                display={"flex"}
                alignItems={"center"}
                gap={1}
              >
                <img
                  src={doctorphone}
                  style={{ width: 30, height: 30 }}
                  alt="Exp"
                />
                <Box>
                  <Typography
                    sx={{ fontSize: 14, fontWeight: "400", color: "#858585" }}
                  >
                    Experience
                  </Typography>
                  <Typography
                    sx={{ fontSize: 14, fontWeight: "500", color: "#303030" }}
                  >
                    {getExperience(
                      selectedProposal?.doctorDetails?.experienceInMonths
                    )}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {selectedProposal?.doctorDetails?.specialistIn && (
              <Grid
                display={"flex"}
                alignItems={"center"}
                gap={0.5}
                flexWrap={"wrap"}
              >
                <Box display={"flex"} alignItems={"center"} gap={0.5}>
                  <img
                    src={staricon}
                    style={{ width: 14, height: 14 }}
                    alt="_staricon"
                  />
                  <Typography
                    sx={{ color: "#858585", fontWeight: "500", mt: "1px" }}
                  >
                    Specialist In:
                  </Typography>
                </Box>
                <Grid>
                  {selectedProposal?.doctorDetails?.specialistIn
                    ?.split(",")
                    .map((specialization, index) => (
                      <Chip
                        key={index}
                        label={specialization}
                        style={{ margin: "4px", fontWeight: 500 }}
                      />
                    ))}
                </Grid>
              </Grid>
            )}

            <Grid>
              <Typography sx={{ fontSize: 20, fontWeight: 600 }}>
                Job Description
              </Typography>
              <Grid
                item
                sx={{
                  minHeight: "80px",
                  maxHeight: "150px",
                  overflowY: "auto",
                }}
              >
                <Typography
                  sx={{ fontSize: 14, color: "rgba(118, 121, 128, 1)" }}
                >
                  <HTMLRenderer
                    html={
                      selectedProposal?.post?.caseDescription || "<p>...</p>"
                    }
                  />
                </Typography>
              </Grid>
            </Grid>

            <Grid>
              <Button
                variant="outlined"
                onClick={() =>
                  window.open(
                    selectedProposal?.doctorDetails?.certificateUrl,
                    "_blank"
                  )
                }
                size="large"
                sx={{
                  borderRadius: "25px",
                  border: "1px solid #EDEDED",
                  background: "white",
                  mt: 1,
                  color: "black",
                  fontWeight: 600,
                }}
              >
                <img
                  src={fileimage}
                  style={{ width: 24, height: 24, marginRight: 15 }}
                />
                Certificate.pdf
              </Button>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{
              justifyContent: {
                xs: "center",
                md: "flex-start",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
                gap: "10px",
              }}
            >
              <Button
                disabled={isLoading ? true : false}
                variant="contained"
                sx={{
                  backgroundColor: "#F98247",
                  borderRadius: "30px",
                  color: "white",
                  fontSize: 13,
                  fontWeight: 300,
                  "&:hover": { backgroundColor: "#F98247" },
                  width: "200px",
                }}
                onClick={handleAproveModal}
              >
                {isLoading === "ACCEPTED" ? (
                  <CircularProgress size={15} />
                ) : selectedProposal?.post?.requestType == "consultation" ? (
                  "Approve"
                ) : (
                  "Shortlist"
                )}
              </Button>
              <Button
                disabled={isLoading ? true : false}
                variant="contained"
                sx={{
                  backgroundColor: "#E91717",
                  borderRadius: "30px",
                  color: "white",
                  fontSize: 13,
                  fontWeight: 300,
                  "&:hover": { backgroundColor: "#E91717" },
                  width: "200px",
                }}
                onClick={handleRejectModal}
              >
                {isLoading === "REJECTED" ? (
                  <CircularProgress size={15} />
                ) : (
                  "Reject"
                )}
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
export default ClinicDashModal;
