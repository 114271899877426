export const MDSSpecialities = [
  "ENDODONTICS",
  "PROSTHODONTICS",
  "OMFS",
  "PEDODONTICS",
  "PERIODONTICS",
  "ORTHODONTICS",
  "OMR",
  "ORAL PATHOLOGY",
  "PUBLIC HEALTH DENTISTRY",
];

export const BDSSpecialities = [
  "RESTORATIONS",
  "SCALING",
  "RCT",
  "EXTRACTIONS",
  "IMPACTIONS",
  "CD/RPD/FPD",
];

export const workTypeOptions = ["FULL-TIME", "PART-TIME", "CONSULTATION"];

export const consultationProposalStatusList = [
  "PENDING",
  "ACCEPTED",
  "REJECTED",
];
export const jobProposalStatusList = ["PENDING", "SHORTLISTED", "REJECTED"];

export const statesList = [
  "Andhra Pradesh",
  "Arunachal Pradesh",
  "Assam",
  "Bihar",
  "Chhattisgarh",
  "Goa",
  "Gujarat",
  "Haryana",
  "Himachal Pradesh",
  "Jharkhand",
  "Karnataka",
  "Kerala",
  "Madhya Pradesh",
  "Maharashtra",
  "Manipur",
  "Meghalaya",
  "Mizoram",
  "Nagaland",
  "Odisha",
  "Punjab",
  "Rajasthan",
  "Sikkim",
  "Tamil Nadu",
  "Telangana",
  "Tripura",
  "Uttar Pradesh",
  "Uttarakhand",
  "West Bengal",
  "Andaman and Nicobar Islands",
  "Chandigarh",
  "Dadra and Nagar Haveli and Daman and Diu",
  "Lakshadweep",
  "Delhi",
  "Puducherry",
];
