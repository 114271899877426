import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Grid, InputAdornment, OutlinedInput, Paper, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import api from "../../api";
import sent from "../../assets/images/sent.png";
import uploadfileIcon from "../../assets/images/uploadfile.png";
import AdminClinics from "../../components/Table/AdminClinics";
import AdminClinicsPending from "../../components/Table/AdminClinicsPending";
import AdminClinicsRejected from "../../components/Table/AdminClinicsRejected";
import { styles } from "../../layouts/Styles";
import DoctorDetails from "./DoctorDetails";

const Clinics = () => {
  const [showWarning, setShowWarning] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [submitModal, setSubmitModal] = React.useState(false);
  const [clinics, setClinics] = useState([]);
  const [clinicEmailAlreadyExists, setClinicEmailExists] = useState(false);
  const [clinicPhoneAlreadyExists, setClinicPhoneExists] = useState(false);

  const [page, setPage] = useState(1);
  const [activeUserID, setActiveUserID] = useState("");

  const [overViewData, setOverViewData] = useState({});

  const [inputData, setInputData] = useState({
    name: "",
    website: "",
    clinicName: "",
    address: "",
    email: "",
    googleMapLocation: "",
    phoneNumber: "",
    licenseUrl: null,
    _role: 2,
  });
  const isReset = () => {
    setInputData((prev) => ({
      ...prev,
      name: "",
      website: "",
      clinicName: "",
      address: "",
      email: "",
      googleMapLocation: "",
      phoneNumber: "",
      licenseUrl: null,
      _role: 2,
    }));
  };
  const [isValidUrl, setIsValidUrl] = useState(true);

  const isValidUrlFormat = (url) => {
    // Regular expression to validate URL format
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlPattern.test(url);
  };
  const classes = styles();

  const getClinics = async (page, limit) => {
    try {
      const _role = 2;
      const status =
        tabIndex == 0 ? "APPROVED" : tabIndex == 1 ? "PENDING" : "REJECTED";
      const res = await api.getUsersByRoleAndStatus(_role, status, page, limit);
      if (res.data.success) {
        setClinics(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const overviewClinics = async () => {
    try {
      const res = await api.getOverviewClinicsAdmin();

      if (res.data.success) {
        console.log(res?.data?.data);
        // setClinics(res.data.data);
        setOverViewData(res?.data?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    overviewClinics();
  }, [tabIndex]);

  const handleInput = (e, id, inputField) => {
    const value = e.target.value;
    if (inputField === "website") {
      setInputData({ ...inputData, website: value });
      setIsValidUrl(isValidUrlFormat(value));
    }
    e.preventDefault();
    if (id == "licenseUrl") {
      setInputData({
        ...inputData,
        [id]: e.target.files[0],
      });
    } else {
      const { value } = e.target;
      setInputData({
        ...inputData,
        [id]: value,
      });
    }
  };
  const uploadFile = async (e) => {
    let fileName = e.target.files[0].name;
    let file = e.target.files[0];

    let awsSUrl = `https://godenty-backend-docs.s3.ap-south-2.amazonaws.com/docs/${fileName}`;

    let response = await fetch(awsSUrl, {
      method: "PUT",
      body: file,
    });

    if (response.ok) {
      setInputData({ ...inputData, licenseUrl: response?.url });
    }
  };

  const handleUploadButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const fileInputRef = React.createRef();

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    isReset();
  };
  const handleCloseSubmit = () => {
    setSubmitModal(false);
  };

  const isFormInvalid = () => {
    return (
      !inputData?.name ||
      !inputData?.website ||
      !inputData?.clinicName ||
      !inputData?.address ||
      !inputData?.phoneNumber ||
      inputData?.phoneNumber?.length !== 10 ||
      !inputData?.email ||
      !RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i).test(
        inputData?.email
      ) ||
      !inputData?.googleMapLocation
    );
  };

  const handleSubmitModal = async () => {
    setShowWarning(true);

    //check form validity
    if (isFormInvalid()) {
      return;
    }

    const res = await api.createClinic(inputData);
    if (res.data.success) {
      setSubmitModal(true);
      setShowWarning(false);
      handleClose();
    }
  };

  useEffect(() => {
    if (inputData.email === "sampleclinic@gmail.com") {
      setClinicEmailExists(true);
    } else {
      setClinicEmailExists(false);
    }
  }, [inputData.email]);

  useEffect(() => {
    if (inputData.phoneNumber === "9876543211") {
      setClinicPhoneExists(true);
    } else {
      setClinicPhoneExists(false);
    }
  }, [inputData.phoneNumber]);
  return (
    <>
      {!activeUserID ? (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "column",
                lg: "row",
                xl: "row",
              },
              gap: "15px",
              mt: 2,
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                  md: "row",
                  lg: "row",
                  xl: "row",
                },
                gap: "10px",
              }}
            >
              <Button
                variant={tabIndex == 0 ? "contained" : "outlined"}
                size="medium"
                sx={{ borderRadius: 46 }}
                onClick={() => {
                  setTabIndex(0);
                  setPage(1);
                }}
              >
                Clinics
              </Button>
              <Button
                variant={tabIndex == 1 ? "contained" : "outlined"}
                size="medium"
                sx={{ borderRadius: 46 }}
                onClick={() => {
                  setTabIndex(1);
                  setPage(1);
                }}
              >
                Pending Approval
              </Button>
              <Button
                variant={tabIndex == 2 ? "contained" : "outlined"}
                size="medium"
                sx={{ borderRadius: 46 }}
                onClick={() => {
                  setTabIndex(2);
                  setPage(1);
                }}
              >
                Rejected
              </Button>
            </Box>
            <Box
              sx={{
                maxWidth: { xs: "100%", sm: 300, md: 200, lg: 200, xl: 200 },
              }}
            >
              <Button
                variant="contained"
                size="medium"
                fullWidth
                sx={{ borderRadius: 46 }}
                startIcon={<AddIcon />}
                onClick={handleClickOpen}
              >
                Create Clinic
              </Button>
            </Box>
          </Box>

          {tabIndex === 0 && (
            <Box>
              <Typography
                variant="h3"
                sx={{ fontSize: "22px", my: 1 }}
              >
                Overview
              </Typography>
              <AdminClinics
                clinics={clinics?.users}
                getClinics={getClinics}
                total={clinics?.total}
                currentPage={clinics?.currentPage}
                totalPages={clinics?.totalPages}
                overViewData={overViewData}
                setActiveUserID={setActiveUserID}
                page={page}
                setPage={setPage}
              />
            </Box>
          )}
          {tabIndex === 1 && (
            <AdminClinicsPending
              clinics={clinics?.users}
              getClinics={getClinics}
              total={clinics?.total}
              currentPage={clinics?.currentPage}
              totalPages={clinics?.totalPages}
              setActiveUserID={setActiveUserID}
              page={page}
              setPage={setPage}
            />
          )}
          {tabIndex === 2 && (
            <AdminClinicsRejected
              clinics={clinics?.users}
              getClinics={getClinics}
              total={clinics?.total}
              currentPage={clinics?.currentPage}
              totalPages={clinics?.totalPages}
            />
          )}

          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle
              sx={{
                m: 0,
                p: 2,
                fontWeight: 600,
                fontSize: 24,
                color: "#303030",
              }}
              id="customized-dialog-title"
            >
              Create Clinic
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "black",
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent>
              <Grid container spacing={4} sx={{ pr: 2 }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid container spacing={1} justifyContent="space-between">
                    <Grid
                      item
                      display="flex"
                      flexDirection="column"
                      xs={12}
                      sm={5.5}
                      md={5.5}
                      lg={5.5}
                      xl={5.5}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#303030",
                          mb: 1,
                          fontSize: 16,
                          fontWeight: 400,
                        }}
                      >
                        Owner Name
                      </Typography>
                      <OutlinedInput
                        placeholder="Enter Owner Name"
                        inputProps={{ id: "name", maxLength: 40 }}
                        value={inputData.name}
                        onChange={(e) => {
                          handleInput(e, "name");
                        }}
                        sx={{
                          borderRadius: 2,
                          backgroundColor: "white",
                          "& .MuiOutlinedInput-input": {
                            borderRadius: 2,
                            border: "none",
                            outline: "none",
                            height: "15px",
                            fontSize: "16px",
                            fontWeight: "500px",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: 2,
                            border: "none",
                            //transition: "ease-in 0.2s",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            //border: `1px solid ${palette?.primary?.main}`,
                            //transition: "ease-in 0.2s",
                            border: "none",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            //border: `2px solid ${palette?.primary?.light}`,
                            //transition: "ease-in 0.2s",
                            border: "none",
                          },
                        }}
                      />
                      <FormHelperText
                        sx={{
                          height: 10,
                          visibility:
                            showWarning && !inputData.name
                              ? "visible"
                              : "hidden",
                        }}
                      >
                        Owner Name is required
                      </FormHelperText>
                    </Grid>
                    <Grid
                      item
                      display="flex"
                      flexDirection="column"
                      xs={12}
                      sm={5.5}
                      md={5.5}
                      lg={5.5}
                      xl={5.5}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#303030",
                          mb: 1,
                          fontSize: 16,
                          fontWeight: 400,
                        }}
                      >
                        Website
                      </Typography>
                      <OutlinedInput
                        placeholder="Enter Website Name"
                        inputProps={{ id: "website" }}
                        value={inputData.website}
                        onChange={(e) => {
                          handleInput(e, "website");
                        }}
                        sx={{
                          borderRadius: 2,
                          backgroundColor: "#FAFAFA",
                          "& .MuiOutlinedInput-input": {
                            borderRadius: 2,
                            border: "none",
                            outline: "none",
                            height: "15px",
                            fontSize: "16px",
                            fontWeight: "500px",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: 2,
                            border: "none",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                      />
                      {!isValidUrl && inputData.website.trim() !== "" && (
                        <p style={{ color: "red" }}>Please enter a valid URL</p>
                      )}

                      <FormHelperText
                        sx={{
                          height: 10,
                          visibility:
                            showWarning && !inputData.website
                              ? "visible"
                              : "hidden",
                        }}
                      >
                        Website is required
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid container spacing={1} justifyContent="space-between">
                    <Grid
                      item
                      display="flex"
                      flexDirection="column"
                      xs={12}
                      sm={5.5}
                      md={5.5}
                      lg={5.5}
                      xl={5.5}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#303030",
                          mb: 1,
                          fontSize: 16,
                          fontWeight: 400,
                        }}
                      >
                        Clinic Name
                      </Typography>
                      <OutlinedInput
                        placeholder="Enter Clinic Name"
                        inputProps={{ id: "clinicName", maxLength: 100 }}
                        value={inputData.clinicName}
                        onChange={(e) => {
                          handleInput(e, "clinicName");
                        }}
                        sx={{
                          borderRadius: 2,
                          backgroundColor: "white",
                          "& .MuiOutlinedInput-input": {
                            borderRadius: 2,
                            border: "none",
                            outline: "none",
                            height: "15px",
                            fontSize: "16px",
                            fontWeight: "500px",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: 2,
                            border: "none",
                            //transition: "ease-in 0.2s",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            //border: `1px solid ${palette?.primary?.main}`,
                            //transition: "ease-in 0.2s",
                            border: "none",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            //border: `2px solid ${palette?.primary?.light}`,
                            //transition: "ease-in 0.2s",
                            border: "none",
                          },
                        }}
                      />
                      <FormHelperText
                        sx={{
                          height: 10,
                          visibility:
                            showWarning && !inputData.clinicName
                              ? "visible"
                              : "hidden",
                        }}
                      >
                        Clinic Name is required
                      </FormHelperText>
                    </Grid>
                    <Grid
                      item
                      display="flex"
                      flexDirection="column"
                      xs={12}
                      sm={5.5}
                      md={5.5}
                      lg={5.5}
                      xl={5.5}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#303030",
                          mb: 1,
                          fontSize: 16,
                          fontWeight: 400,
                        }}
                      >
                        Location
                      </Typography>
                      <OutlinedInput
                        placeholder="Enter Location"
                        inputProps={{ id: "address", maxLength: 200 }}
                        value={inputData.address}
                        onChange={(e) => {
                          handleInput(e, "address");
                        }}
                        sx={{
                          borderRadius: 2,
                          backgroundColor: "white",
                          "& .MuiOutlinedInput-input": {
                            borderRadius: 2,
                            border: "none",
                            outline: "none",
                            height: "15px",
                            fontSize: "16px",
                            fontWeight: "500px",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: 2,
                            border: "none",
                            //transition: "ease-in 0.2s",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            // border: `1px solid ${palette?.primary?.main}`,
                            // transition: "ease-in 0.2s",
                            border: "none",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            // border: `2px solid ${palette?.primary?.light}`,
                            // transition: "ease-in 0.2s",
                            border: "none",
                          },
                        }}
                      />
                      <FormHelperText
                        sx={{
                          height: 10,
                          visibility:
                            showWarning && !inputData.address
                              ? "visible"
                              : "hidden",
                        }}
                      >
                        Location is required
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid container spacing={1} justifyContent="space-between">
                    <Grid
                      item
                      display="flex"
                      flexDirection="column"
                      xs={12}
                      sm={5.5}
                      md={5.5}
                      lg={5.5}
                      xl={5.5}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#303030",
                          mb: 1,
                          fontSize: 16,
                          fontWeight: 400,
                        }}
                      >
                        Email
                      </Typography>
                      <OutlinedInput
                        placeholder="Enter Email ID"
                        inputProps={{ id: "email" }}
                        value={inputData.email}
                        onChange={(e) => {
                          handleInput(e, "email");
                        }}
                        sx={{
                          borderRadius: 2,
                          backgroundColor: "white",
                          "& .MuiOutlinedInput-input": {
                            borderRadius: 2,
                            border: "none",
                            outline: "none",
                            height: "15px",
                            fontSize: "16px",
                            fontWeight: "500px",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: 2,
                            border: "none",
                            // transition: "ease-in 0.2s",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            // border: `1px solid ${palette?.primary?.main}`,
                            // transition: "ease-in 0.2s",
                            border: "none",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            // border: `2px solid ${palette?.primary?.light}`,
                            // transition: "ease-in 0.2s",
                            border: "none",
                          },
                        }}
                        onInput={(event) => {
                          const inputData = event.target.value;
                          if (
                            inputData !== "" &&
                            !/^[a-zA-Z!@#$%^&*()]+$/?.test(inputData)
                          ) {
                            event.target.value = inputData.replace(/\s/g, "");
                          }
                        }}
                      />
                      <FormHelperText
                        sx={{
                          height: 10,
                          visibility: !inputData?.email
                            ? "hidden"
                            : RegExp(
                                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
                              ).test(inputData?.email)
                            ? !clinicEmailAlreadyExists
                              ? "hidden"
                              : "visible"
                            : "visible",
                        }}
                      >
                        {clinicEmailAlreadyExists
                          ? "Email Already Exists"
                          : "Enter valid email"}
                      </FormHelperText>
                    </Grid>
                    <Grid
                      item
                      display="flex"
                      flexDirection="column"
                      xs={12}
                      sm={5.5}
                      md={5.5}
                      lg={5.5}
                      xl={5.5}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#303030",
                          mb: 1,
                          fontSize: 16,
                          fontWeight: 400,
                        }}
                      >
                        Google Map Location
                      </Typography>
                      <OutlinedInput
                        placeholder="https//googlemap.com"
                        inputProps={{ id: "googleMapLocation" }}
                        value={inputData.googleMapLocation}
                        onChange={(e) => {
                          handleInput(e, "googleMapLocation");
                        }}
                        sx={{
                          borderRadius: 2,
                          backgroundColor: "white",
                          "& .MuiOutlinedInput-input": {
                            borderRadius: 2,
                            border: "none",
                            outline: "none",
                            height: "15px",
                            fontSize: "16px",
                            fontWeight: "500px",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: 2,
                            border: "none",
                            //transition: "ease-in 0.2s",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            //border: `1px solid ${palette?.primary?.main}`,
                            //transition: "ease-in 0.2s",
                            border: "none",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            //border: `2px solid ${palette?.primary?.light}`,
                            //transition: "ease-in 0.2s",
                            border: "none",
                          },
                        }}
                      />
                      <FormHelperText
                        sx={{
                          height: 10,
                          visibility:
                            showWarning && !inputData.googleMapLocation
                              ? "visible"
                              : "hidden",
                        }}
                      >
                        Google Map Location is required
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Grid container spacing={1} justifyContent="space-between">
                    <Grid
                      item
                      display="flex"
                      flexDirection="column"
                      xs={12}
                      sm={5.5}
                      md={5.5}
                      lg={5.5}
                      xl={5.5}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#303030",
                          mb: 1,
                          fontSize: 16,
                          fontWeight: 400,
                        }}
                      >
                        Phone Number
                      </Typography>
                      <OutlinedInput
                        placeholder="Enter Mobile Number"
                        inputProps={{
                          id: "phoneNumber",
                          maxLength: 10,
                          inputMode: "numeric",
                          pattern: "[0-9]*",
                        }}
                        value={inputData.phoneNumber}
                        onChange={(e) => {
                          handleInput(e, "phoneNumber");
                        }}
                        sx={{
                          borderRadius: 2,
                          backgroundColor: "white",
                          "& .MuiOutlinedInput-input": {
                            borderRadius: 2,
                            border: "none",
                            outline: "none",
                            height: "15px",
                            fontSize: "16px",
                            fontWeight: "500px",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: 2,
                            border: "none",
                            //transition: "ease-in 0.2s",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            // border: `1px solid ${palette?.primary?.main}`,
                            // transition: "ease-in 0.2s",
                            border: "none",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            // border: `2px solid ${palette?.primary?.light}`,
                            // transition: "ease-in 0.2s",
                            border: "none",
                          },
                        }}
                        onInput={(e) => {
                          const target = e?.target;
                          target.value = e?.target?.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                      />
                      <FormHelperText
                        sx={{
                          height: 10,
                          visibility: !inputData?.phoneNumber
                            ? "hidden"
                            : inputData?.phoneNumber?.length === 10
                            ? !clinicPhoneAlreadyExists
                              ? "hidden"
                              : "visible"
                            : "visible",
                        }}
                      >
                        {clinicPhoneAlreadyExists
                          ? "Mobile number already existed"
                          : "Phone number must be 10 digits"}
                      </FormHelperText>
                    </Grid>
                    <Grid
                      item
                      display="flex"
                      flexDirection="column"
                      xs={12}
                      sm={5.5}
                      md={5.5}
                      lg={5.5}
                      xl={5.5}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#303030",
                          mb: 1,
                          fontSize: 16,
                          fontWeight: 400,
                        }}
                      >
                        License/ Rental Agreement
                      </Typography>
                      <OutlinedInput
                        inputProps={{ id: "licenseUrl" }}
                        value={inputData.licenseUrl ? inputData.licenseUrl : ""}
                        sx={{
                          borderRadius: 2,
                          backgroundColor: "#FAFAFA",
                          "& .MuiOutlinedInput-input": {
                            borderRadius: 2,
                            border: "none",
                            outline: "none",
                            height: "15px",
                            fontSize: "16px",
                            fontWeight: "500px",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderRadius: 2,
                            border: "none",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                        }}
                        placeholder="Upload Document"
                        endAdornment={
                          <InputAdornment position="end">
                            <img
                              src={uploadfileIcon}
                              alt="Upload Image"
                              style={{ cursor: "pointer" }}
                              onClick={handleUploadButtonClick}
                            />
                            <input
                              type="file"
                              style={{ display: "none" }}
                              onChange={uploadFile}
                              ref={fileInputRef}
                            />
                          </InputAdornment>
                        }
                      />
                      <FormHelperText
                        sx={{
                          height: 10,
                          visibility:
                            showWarning && !inputData.licenseUrl
                              ? "visible"
                              : "hidden",
                        }}
                      >
                        License is required
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ padding: 0 }}>
              <Grid
                container
                spacing={2}
                justifyContent={"center"}
                sx={{ mt: 1, mb: 1 }}
              >
                <Grid item xs={10} sm={6} md={6} lg={6} xl={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    size="medium"
                    sx={{
                      borderRadius: 24,
                      fontSize: "16px",
                      fontWeight: "500px",
                    }}
                    onClick={handleSubmitModal}
                    // disabled={
                    //   !inputData?.name ||
                    //   !inputData?.website ||
                    //   !inputData?.clinicName ||
                    //   !inputData?.address ||
                    //   !inputData?.phoneNumber ||
                    //   // inputData?.phoneNumber?.length !== 10 ||
                    //   !inputData?.email ||
                    //   // !RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i).test(
                    //   //   inputData?.email
                    //   // ) ||
                    //   !inputData?.googleMapLocation ||
                    //   !inputData?.licenseUrl
                    // }
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
          <Dialog
            onClose={handleCloseSubmit}
            aria-labelledby="customized-dialog-title"
            open={submitModal}
            fullWidth={true}
            // maxWidth="xs"
            sx={{
              "& .MuiDialog-paper": {
                // width: "672px",
                padding: "0px",
                borderRadius: "15px",
                height: "400px",
                width: "450px",
              },
            }}
          >
            <IconButton
              aria-label="close"
              onClick={handleCloseSubmit}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: "black",
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent className={classes.dialogContent}>
              <img
                src={sent}
                alt="Sent"
                style={{ width: "134px", height: "134px" }}
                className={classes.image}
              />
              <Typography
                align="center"
                sx={{
                  fontSize: "30px",
                  fontWeight: "600",
                  color: "#00A01B",
                  fontFamily: "Poppins",
                  marginTop: "5px",
                  padding: "0px",
                }}
              >
                Success
              </Typography>
              <Typography
                align="center"
                sx={{
                  // marginTop: "10px",
                  fontSize: "24px",
                  fontWeight: "400",
                  color: "black",
                  fontFamily: "Poppins",
                  margin: "0px",
                  padding: "0px",
                }}
              >
                The Clinic has been added Successfully.
              </Typography>
            </DialogContent>
          </Dialog>
        </>
      ) : (
        <DoctorDetails
          role={3}
          activeUserID={activeUserID}
          setActiveUserID={setActiveUserID}
        />
      )}
    </>
  );
};

export default Clinics;
