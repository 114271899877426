import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, DialogContent, DialogTitle, FormControl, Grid, MenuItem, Select, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import api from "../../api/index";
import clockicon from "../../assets/images/clockicon.png";
import doctorphone from "../../assets/images/doctorphone.png";
import location from "../../assets/images/location.png";
import staricon from "../../assets/images/staricon.png";
import { styles } from "../../layouts/Styles";
import { formatTimeSlots } from "../../utils/formatTimeSlots";
import { getPayType } from "../../utils/getPayType";
import getStringInitials from "../../utils/getStringInitials";
import SentSuccessfully from "./SentSuccessfully";

const DoctorMyBookings = (props) => {
  console.log("props: ", props);
  const [selectedOption, setSelectedOption] = useState("");
  const [done, setDone] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { currentClinic } = props;
  const Navigate = useNavigate();
  const classes = styles();
  const handleDropdownOpen = () => {
    setIsDropdownOpen(true);
  };

  const handleDropdownClose = () => {
    setIsDropdownOpen(false);
  };
  const handleCloseApprove = () => {
    setCancel(false);
    props.handleClose();
  };
  const handleOptionChange = async (event) => {
    let value = event.target.value;
    setSelectedOption(value);

    if (value == "done") {
      try {
        let response = await api.updateCabinBookingStatusDoctor({
          id: props?.currentClinic?.id || "",
          status: "COMPLETED",
          amount: props?.currentClinic?.cabinAvailability?.price || "",
        });

        if (response?.data?.success) {
          toast.success("Marked job done complete!");
          setDone(true);

          if (props.doctor) {
            Navigate("/doctor/payment", {
              state: { loc: props.dashboard ? "doctordashboard" : "bookchair" },
            });
          } else {
            Navigate("/clinic/payment", { state: { loc: "managebookings" } });
          }
        } else {
          toast.error("Failed to mark job done complete!");
        }
      } catch (e) {
        toast.error("Failed to mark job done complete!");
      }
    } else {
      try {
        let response = await api.updateCabinBookingStatusDoctor({
          id: props?.currentClinic?.id,
          status: "REJECTED",
          amount: props?.currentClinic?.cabinAvailability?.price || "",
        });
        if (response?.data?.success) {
          toast.success("Appointment cancelled");
          setCancel(true);
        } else {
          toast.error("Failed to cancel appointment!");
        }
      } catch (e) {
        toast.error("Failed to cancel appointment!");
      }
    }
  };

  return (
    <>
      {cancel ? (
        <SentSuccessfully
          modal={cancel}
          cancel={true}
          handleCloseApprove={handleCloseApprove}
          value={"proposal"}
        />
      ) : (
        // done ?
        // <ManageClinicPayment modal={done} handleCloseApprove={handlecloseDone} value={"proposal"}/>
        // :
        <Dialog
          onClose={props.handleClose}
          aria-labelledby="customized-dialog-title"
          open={props.open}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle
            sx={{ m: 0, p: 2, fontWeight: 600, fontSize: 20, color: "#303030" }}
            id="customized-dialog-title"
          >
            Details
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={props.handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Grid container alignItems="center">
              {currentClinic?.clinic?.imageUrl ? (
                <Box
                  item
                  className={classes.imageContainer}
                  sx={{
                    width: 60,
                    height: 60,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    overflow: "hidden",
                    borderRadius: "8px",
                  }}
                >
                  <img
                    src={currentClinic?.clinic?.imageUrl || ""}
                    alt="_img"
                    style={{
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    width: 60,
                    height: 60,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#004E89",
                    borderRadius: "12px",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: 24,
                      color: "#fff",
                    }}
                  >
                    {getStringInitials(
                      currentClinic?.clinicDetails?.clinicName || ""
                    )}
                  </Typography>
                </Box>
              )}

              <Grid item>
                <Box display="flex" alignItems="center">
                  <Box display="flex" flexDirection="column">
                    <Box
                      display="flex"
                      sx={{
                        flexDirection: {
                          xs: "column",
                          sm: "column",
                          md: "column",
                          lg: "row",
                          xl: "row",
                        },
                        mr: 1,
                        ml: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#004E89",
                          fontSize: "20px",
                          fontWeight: 600,
                          textTransform: "capitalize",
                        }}
                      >
                        {currentClinic?.clinicDetails?.clinicName.toLowerCase() ||
                          ""}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography
                        sx={{
                          color: "rgba(118, 121, 128, 1)",
                          fontSize: "16px",
                          fontWeight: 500,
                          mr: 1,
                          ml: 1,
                        }}
                      >
                        {currentClinic?.name}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
              {/* <Grid item>
                <Box
                  display="flex"
                  sx={{
                    alignItems: "center",
                  }}
                >
                  <Rating
                    name="half-rating"
                    readOnly
                    value={currentClinic?.clinic?.rating || 0}
                    precision={0.5}
                    sx={{ fontSize: 20 }}
                  />
                  <Typography
                    sx={{
                      color: "#1D2343",
                      fontSize: "18.14px",
                      fontWeight: 500,
                      px: 1,
                    }}
                  >
                    {currentClinic?.clinic?.rating || 0}
                  </Typography>
                  <Typography
                    sx={{
                      color: "rgba(10, 10, 10, 0.48)",
                      fontSize: "18.14px",
                      fontWeight: 500,
                    }}
                  >
                    ({currentClinic?.clinic?.totalRating || 0})
                  </Typography>
                </Box>
              </Grid> */}
            </Grid>
            <Grid container sx={{ my: 5 }}>
              <Grid
                item
                xs={3}
                sm={1}
                md={1}
                lg={1}
                xl={1}
                sx={{ pl: "2px", mr: 0 }}
              >
                <img
                  src={location}
                  style={{ width: 40, height: 40 }}
                  alt="loc"
                />
              </Grid>
              <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#858585" }}
                >
                  Location
                </Typography>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: "500",
                    color: "#303030",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(
                      currentClinic?.clinicDetails?.googleMapLocation || "",
                      "_blank"
                    );
                  }}
                >
                  {currentClinic?.clinicDetails?.address || ""}
                </Typography>
              </Grid>
              <Grid item xs={3} sm={1} sx={{ pl: "2px", mr: 0 }}>
                <img
                  src={clockicon}
                  style={{ width: 40, height: 40 }}
                  alt="clock"
                />
              </Grid>
              <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#858585" }}
                >
                  Date
                </Typography>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "500", color: "#303030" }}
                >
                  {currentClinic?.date
                    ? moment(currentClinic?.date).format("DD-MM-YYYY")
                    : "-"}
                </Typography>
              </Grid>

              <Grid item xs={3} sm={1} md={1} lg={1} xl={1} sx={{ mr: 0 }}>
                <img
                  src={doctorphone}
                  alt="phone"
                  style={{ width: 40, height: 40 }}
                />
              </Grid>
              <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "400", color: "#858585" }}
                >
                  Time Slot
                </Typography>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: "500", color: "#303030" }}
                >
                  {formatTimeSlots({
                    startTime: currentClinic?.startTime,
                    endTime: currentClinic?.startTime,
                  }) || ""}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} flexDirection="row">
              {/* <Grid item>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={staricon}
                    style={{ width: "16.67px", height: "16.67px" }}
                  />
                  <Typography
                    sx={{
                      color: "#F98247",
                      fontSize: "16px",
                      fontWeight: 600,
                      ml: 1,
                    }}
                  >
                    Cabins({currentClinic?.noOfCabinsBooked})
                  </Typography>
                </Box>
              </Grid> */}
              <Grid item>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={staricon}
                    style={{ width: "16.67px", height: "16.67px" }}
                  />
                  <Typography
                    sx={{
                      color: "#F98247",
                      fontSize: "16px",
                      fontWeight: 600,
                      ml: 1,
                    }}
                  >
                    {/* INR {parseInt(currentClinic?.cabinAvailability?.price)} */}
                    {getPayType({
                      requestType: currentClinic?.postDetails?.requestType,
                      minSalary: currentClinic?.postDetails?.minimumSalary,
                      maxSalary: currentClinic?.postDetails?.maximumSalary,
                      hourly: currentClinic?.postDetails?.hourly,
                    })}
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            <Grid container Spacing={1}>
              <Grid
                item
                xs={12}
                sx={{ mt: "1.5em" }}
              >
                <Typography sx={{ fontSize: "16px", color: "#303030" }}>
                  Order Status
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FormControl sx={{ minWidth: 320 }}>
                  <Select
                    value={selectedOption}
                    onChange={handleOptionChange}
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "white",
                      "& .MuiOutlinedInput-input": {
                        borderRadius: 2,
                        border: "none",
                        outline: "none",
                        height: "15px",
                        marginTop: "-0.2em",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        border: "none",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                    //   inputProps={{ id: "doctor_type" }}
                    //   value={inputData.doctor_type}
                    //   onChange={(e) => { handleInput(e, "doctor_type") }}
                    onFocus={(e) => e.currentTarget.classList.add("focused")}
                    onBlur={(e) => e.currentTarget.classList.remove("focused")}
                    displayEmpty
                    onOpen={handleDropdownOpen}
                    onClose={handleDropdownClose}
                  >
                    <MenuItem value="" style={{ display: "none" }}>
                      <em
                        style={{
                          color: "#C2C2C2",
                          fontStyle: "normal",
                          fontSize: 14,
                          fontWeight: 400,
                        }}
                      >
                        Select Status
                      </em>
                    </MenuItem>

                    <MenuItem value="done">
                      <Typography style={{ color: "green", fontWeight: "400" }}>
                        Job Done
                      </Typography>
                    </MenuItem>
                    <MenuItem value="cancel">
                      <Typography style={{ color: "red", fontWeight: "400" }}>
                        Appointment Cancel
                      </Typography>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                container
                spacing={1}
                // sx={{ mt: isDropdownOpen ? "4em" : "0em" }}
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}></Grid>
              </Grid>
            </Grid>
          </DialogContent>
          {/* <DialogActions sx={{ justifyContent: "flex-start", mt:"2em" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
              md: "row",
              lg: "row",
              xl: "row",
            },
            gap: "10px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#F98247",
              borderRadius: "30px",
              color: "white",
              fontSize: 13,
              fontWeight: 300,
              padding: "12px 50px 12px 50px",
              marginRight:"45px",
              marginLeft:"7px",
              "&:hover": { backgroundColor: "#F98247" },
              maxWidth:"300px"
            }}
            onClick={handleAproveModal}
          >
            Send Proposal
          </Button>
         
          </Box>
        </DialogActions> */}
        </Dialog>
      )}
    </>
  );
};
export default DoctorMyBookings;
