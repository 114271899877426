import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";

import cancelIcon from "../../assets/images/cancelicon.png";
import sent from "../../assets/images/sent.png";
import { styles } from "../../layouts/Styles";

const SentSuccessfully = (props) => {
  const classes = styles();

  return (
    <Dialog
      onClose={props.handleCloseApprove}
      aria-labelledby="customized-dialog-title"
      open={props.modal}
      sx={{
        "& .MuiDialog-paper": {
          padding: "0px",
          borderRadius: "15px",
          height: "50%",
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={props.handleCloseApprove}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "black",
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent
        className={classes.dialogContent}
        sx={{
          height: props.cancel && "350px !important",
          mt: props.cancel && "30px",
        }}
      >
        {props.cancel ? (
          // <Typography
          //   variant="h5"
          //   align="center"
          //   style={{ color: "red", fontSize: "40px" }}
          // >
          //   {props.reject ? "Reject" : "Cancel"}
          // </Typography>
          <></>
        ) : (
          <>
            <img
              src={sent}
              alt="Sent"
              style={{ width: "75px", height: "75px" }}
              className={classes.image}
            />
            <Typography
              align="center"
              sx={{
                fontSize: "24px",
                fontWeight: "600",
                color: "#F98247",
                fontFamily: "Poppins",
                marginTop: "5px",
                padding: "0px",
              }}
            >
              {props.withdraw ? "Withdrawn Successfully" : "Sent Successfully"}
            </Typography>
          </>
        )}

        {props.withdraw ? (
          <>
            <Typography
              align="center"
              className={classes.text}
              sx={{
                fontSize: "18px",
                fontWeight: "400",
                color: "#343434",
                fontFamily: "Poppins",
              }}
            >
              The proposal has been withdrawn
            </Typography>
          </>
        ) : props.reject ? (
          <>
            <img
              src={cancelIcon}
              alt="Cancelled"
              style={{ width: "75px", height: "75px" }}
              className={classes.image}
            />
            <Typography
              align="center"
              className={classes.text}
              sx={{
                fontSize: "18px",
                fontWeight: "400",
                color: "#343434",
                fontFamily: "Poppins",
              }}
            >
              The doctor has been rejected
            </Typography>
          </>
        ) : props.cancel ? (
          <>
            <img
              src={cancelIcon}
              alt="Cancelled"
              style={{ width: "75px", height: "75px" }}
              className={classes.image}
            />
            <Typography
              variant="h5"
              align="center"
              sx={{
                fontSize: "32px",
                fontWeight: "600",
                color: "#ED3838",
                fontFamily: "Poppins",
              }}
            >
              {props.reject ? "Reject" : "Cancelled"}
            </Typography>
            <Typography
              align="center"
              // className={classes.text}
              sx={{
                fontSize: "18px",
                fontWeight: "400",
                color: "#343434",
                fontFamily: "Poppins",
              }}
            >
              Your consultation has been cancelled
            </Typography>
          </>
        ) : (
          <>
            {/* <img
              src={sent}
              alt="Sent"
              style={{ width: "75px", height: "75px" }}
              className={classes.image}
            /> */}
            <Typography
              align="center"
              // className={classes.text}
              sx={{
                fontSize: "23px",
                fontWeight: "400",
                color: "#343434",
                fontFamily: "Poppins",
              }}
            >
              The {"application"} has been{" "}
              {props.book ? "booked" : props.post ? "created" : "sent"}{" "}
              successfully.
            </Typography>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SentSuccessfully;
