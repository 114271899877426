import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

import SearchBar from "../../components/searchbar";
import { styles } from "../../layouts/Styles";

const AdminClinics = ({
  clinics: initialClinics,
  total,
  currentPage,
  getClinics,
  overViewData,
  page = "",
  setPage = null,
  setActiveUserID = null,
}) => {
  const [searchInput, setSearchInput] = useState("");
  const [clinics, setClinics] = useState(initialClinics);
  const [filteredClinics, setFilteredClinics] = useState([]);

  const classes = styles();
  let clinicdata = [
    {
      id: 1,
      "clinic name": "John Doe",
      "owner name": "Owner Name",
      "phone number": 251111129,
      email: "sample@test.com",
      website: "Clinic Website",
      address: "Madhapur",
      status: "View",
    },
  ];

  const [limit, setLimit] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setLimit(event.target.value);
  };

  const columnsclinics = Object.keys(clinicdata[0]);

  useEffect(() => {
    getClinics(page, limit);
  }, [page, limit]);

  useEffect(() => {
    if (initialClinics) {
      setClinics(initialClinics);
    }
  }, [initialClinics]);

  useEffect(() => {
    if (clinics) {
      const filtered = clinics.filter((clinic) =>
        Object.values(clinic).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(searchInput.toLowerCase())
        )
      );
      setFilteredClinics(filtered);
    }
  }, [searchInput, clinics]);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3.3} lg={3} xl={2}>
          <Paper sx={{ p: "1.5em", borderRadius: 2 }}>
            <Typography
              variant="h6"
              sx={{
                color: "#161616",
                fontWeight: "500",
                fontSize: "14px",
                mb: 1.5,
              }}
            >
              Total Bookings
            </Typography>
            <Typography
              variant="h2"
              sx={{ color: "#161616", fontWeight: 600, fontSize: "24px" }}
            >
              {overViewData?.totalBooking || 0}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3.3} lg={3} xl={2}>
          <Paper sx={{ p: "1.5em", borderRadius: 2 }}>
            <Typography
              variant="h6"
              sx={{
                color: "#161616",
                fontWeight: "500",
                fontSize: "14px",
                mb: 1.5,
              }}
            >
              Total Jobs
            </Typography>
            <Typography
              variant="h2"
              sx={{ color: "#161616", fontWeight: 600, fontSize: "24px" }}
            >
              {/* {overViewData?.totalBooking || 0} */} 0
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3.3} lg={3} xl={2}>
          <Paper sx={{ p: "1.5em", borderRadius: 2 }}>
            <Typography
              variant="h6"
              sx={{
                color: "#161616",
                fontWeight: "500",
                fontSize: "14px",
                mb: 1.5,
              }}
            >
              Total Consultations
            </Typography>
            <Typography
              variant="h2"
              sx={{ color: "#161616", fontWeight: 600, fontSize: "24px" }}
            >
              {overViewData?.totalConsulting || 0}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3.3} lg={3} xl={2}>
          <Paper sx={{ p: "1.5em", borderRadius: 2 }}>
            <Typography
              variant="h6"
              sx={{
                color: "#161616",
                fontWeight: "500",
                fontSize: "14px",
                mb: 1.5,
              }}
            >
              Total Revenue
            </Typography>
            <Typography
              variant="h2"
              sx={{ color: "#161616", fontWeight: 600, fontSize: "24px" }}
            >
              {overViewData?.totalRevenue || 0}
            </Typography>
          </Paper>
        </Grid>
      </Grid>
      <Paper sx={{ p: 2, mt: 2, borderRadius: 2 }}>
        <Grid container alignItems={'center'} mb={2} justifyContent={'space-between'}>
          <Typography
            variant="h3"
            sx={{ fontSize: "22px", fontWeight: 500 }}
          >
            Clinics{" "}
            <span style={{ fontSize: "22px", fontWeight: 700 }}>
              ({total})
            </span>
          </Typography>
          <Grid item>
            <SearchBar
              searchInput={searchInput}
              setSearchInput={setSearchInput}
            />
          </Grid>
        </Grid>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columnsclinics.map((column) => (
                  <TableCell key={column} className={classes.tableCell}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredClinics.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={8} style={{ textAlign: "center" }}>
                    No data found
                  </TableCell>
                </TableRow>
              ) : (
                filteredClinics.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell className={classes.tableCellBody}>
                      {row?.id || "-"}
                    </TableCell>
                    <TableCell className={classes.tableCellBodyLink}>
                      <Link style={{textTransform: 'capitalize'}} to={`#`} onClick={() => setActiveUserID(row?.id)}>
                        {row?.userDetails?.clinicName?.toLowerCase() || "-"}
                      </Link>
                    </TableCell>
                    <TableCell className={classes.tableCellBody}>
                      {row?.name || "-"}
                    </TableCell>
                    <TableCell className={classes.tableCellBody}>
                      {row?.phoneNumber || "-"}
                    </TableCell>
                    <TableCell className={classes.tableCellBody}>
                      {row?.email || "-"}
                    </TableCell>
                    <TableCell className={classes.tableCellBody}>
                      {row?.userDetails?.website || "-"}
                    </TableCell>
                    <TableCell className={classes.tableCellBody}>
                      {row?.userDetails?.address || "-"}
                    </TableCell>
                    <TableCell className={classes.tableCellBody}>
                      <Button
                        variant="contained"
                        size="small"
                        sx={{
                          color: "#00AB11",
                          backgroundColor: "#D9F3DC",
                          borderRadius: 30,
                          fontSize: 14,
                          fontWeight: 500,
                          "&:hover": { backgroundColor: "#D9F3DC" },
                          cursor: "default",
                          width: "100px"
                        }}
                      >
                        Active
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={total}
          rowsPerPage={limit}
          page={currentPage - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
};

export default AdminClinics;
