import CloseIcon from "@mui/icons-material/Close";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import api from "../../api/index";
import cancelIcon from "../../assets/images/cancelicon.png";
import doctoremail from "../../assets/images/doctoremail.png";
import doctorphone from "../../assets/images/doctorphone.png";
import doctorprofile from "../../assets/images/doctorprofile.png";
import fileimage from "../../assets/images/fileimage.png";
import location from "../../assets/images/location.png";
import ownericon from "../../assets/images/ownericon.png";
import sent from "../../assets/images/sent.png";
import website from "../../assets/images/website.png";
import { styles } from "../../layouts/Styles";
import getStringInitials from "../../utils/getStringInitials";

const PendingClinics = (props) => {
  const { page, setPage } = props;
  const classes = styles();
  const [rejection_reason, setReason] = React.useState("");
  const [selectedClinic, setSelectedClinic] = React.useState(null);
  const [approveModal, setApproveModal] = React.useState(false);
  const [rejectModal, setRejectModal] = React.useState(false);
  const [submitReject, setsubmitReject] = React.useState(false);
  const [clinicOpen, setClinicOpen] = React.useState(false);

  const changeUserStatus = async (id, status, reason) => {
    const res = await api.changeUserStatus(status, id, reason);
    if (res.data.success) {
      props.getPendingClinics(page, limit);
      setSelectedClinic(null);
      if (!reason) {
        handleApproveModal();
      }
    }
  };

  const handleClinicOpen = (obj) => {
    setClinicOpen(true);
    setSelectedClinic(obj);
  };
  const handleClinicClose = () => {
    setClinicOpen(false);
  };
  const handleCloseApprove = () => {
    setApproveModal(false);
  };
  const handleApproveModal = () => {
    setApproveModal(true);
    handleClinicClose();
  };
  const handleRejectModal = () => {
    setRejectModal(true);

    handleClinicClose();
  };
  const handleCloseReject = () => {
    setRejectModal(false);
    setReason("");
  };
  const handleOpensubmitRejectModal = async () => {
    await changeUserStatus(selectedClinic.id, "REJECTED", rejection_reason);
    setsubmitReject(true);
    handleCloseReject();
  };
  const handleClosesubmitReject = () => {
    setsubmitReject(false);
  };

  const handleReason = (e) => {
    setReason(e.target.value);
  };

  let documentFileName = "certificate.pdf";

  let clinicdata = [
    {
      id: 1,
      "owner name": "John Doe",
      "phone number": 251111129,
      email: "sample@test.com",
      website: "Clinic Website",
      address: "Madhapur",
      "profie status": "Approved",
      status: "View",
    },
  ];
  const getColorStyle = (isApproved) => {
    isApproved = isApproved;

    switch (isApproved) {
      case 0:
        return "pending";
      case 1:
        return "credited";
      default:
        return "tableCellBody";
    }
  };
  const columnsclinic = Object.keys(clinicdata[0]);
  // const [page, setPage] = useState(1);

  const [limit, setLimit] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setLimit(event.target.value);
  };
  useEffect(() => {
    props.getPendingClinics(page, limit);
  }, [page, limit]);

  return (
    <>
      <Paper sx={{ p: 2, mt: 2, borderRadius: "8px" }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {columnsclinic.map((column) => (
                  <TableCell key={column} className={classes.tableCell}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props?.pendingClinics?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.tableCellBody}>
                    {row?.userDetails?.id || "-"}
                  </TableCell>
                  <TableCell className={classes.tableCellBodyLink}>
                    <Link
                      to={`#`}
                      onClick={() => props.setActiveUserID(row.id)}
                      style={{textTransform: 'capitalize'}}
                    >
                      {row?.name?.toLowerCase() || "-"}
                    </Link>
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {row?.phoneNumber || "-"}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {row?.email || "-"}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {row?.userDetails?.website || "-"}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {row?.userDetails?.address || "-"}
                  </TableCell>
                  <TableCell
                    className={classes[getColorStyle(row["isApproved"])]}
                    sx={{
                      fontSize: "13px !important",
                    }}
                  >
                    {row?.isApproved == "1" ? "Completed" : "Pending"}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    <Button
                      onClick={() => {
                        handleClinicOpen(row);
                      }}
                      variant="contained"
                      size="small"
                      sx={{
                        fontSize: 13,
                        backgroundColor: "#F98247",
                        borderRadius: 30,
                        px: 2,
                        "&:hover": { backgroundColor: "#F98247" },
                      }}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={props?.total}
          rowsPerPage={limit}
          page={props?.currentPage - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Dialog
        onClose={handleClinicClose}
        aria-labelledby="customized-dialog-title"
        open={clinicOpen}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          sx={{ m: 0, p: 1, fontWeight: 600, fontSize: 20, color: "#303030" }}
          id="customized-dialog-title"
        >
          Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClinicClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ p: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Grid display={"flex"} alignItems="center" gap={1}>
            {selectedClinic?.imageUrl ? (
              <Box>
                <img
                  src={selectedClinic?.imageUrl}
                  style={{ width: 60, height: 60 }}
                  alt="_img"
                />
              </Box>
            ) : (
              <Box
                sx={{
                  width: 60,
                  height: 60,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#004E89",
                  borderRadius: "12px",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontWeight: 600,
                    fontSize: 22,
                  }}
                >
                  {getStringInitials(
                    selectedClinic?.userDetails?.clinicName || "-"
                  )}
                </Typography>
              </Box>
            )}

            <Typography
              sx={{ fontSize: "20px", fontWeight: "600", color: "#1A202C" }}
            >
              {selectedClinic?.userDetails?.clinicName || "-"}
            </Typography>
          </Grid>

          <Grid container rowGap={1}>
            <Grid
              item
              xs={12}
              sm={4}
              display={"flex"}
              alignItems={"center"}
              gap={0.5}
            >
              <img
                src={ownericon}
                alt="owner"
                style={{ width: 30, height: 30 }}
              />
              <Box>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "400", color: "#858585" }}
                >
                  Owner Name
                </Typography>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "500", color: "#303030" }}
                >
                  {selectedClinic?.name || "-"}
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              display={"flex"}
              alignItems={"center"}
              gap={0.5}
            >
              <img
                src={doctorphone}
                style={{ width: 30, height: 30 }}
                alt="Phone"
              />
              <Box>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "400", color: "#858585" }}
                >
                  Phone Number
                </Typography>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "500", color: "#303030" }}
                >
                  {selectedClinic?.phoneNumber || "-"}
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              display={"flex"}
              alignItems={"center"}
              gap={0.5}
            >
              <img
                src={doctoremail}
                style={{ width: 30, height: 30 }}
                alt="Email"
              />
              <Box>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "400", color: "#858585" }}
                >
                  Email Address
                </Typography>
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#303030",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {selectedClinic?.email || "-"}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container rowGap={1}>
            <Grid
              item
              xs={12}
              sm={4}
              display={"flex"}
              alignItems={"center"}
              gap={0.5}
            >
              <img src={website} alt="Web" style={{ width: 30, height: 30 }} />
              <Box>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "400", color: "#858585" }}
                >
                  Website Name
                </Typography>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "500", color: "#303030" }}
                >
                  {selectedClinic?.userDetails?.website || "-"}
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              display={"flex"}
              alignItems={"center"}
              gap={0.5}
            >
              <img src={location} style={{ width: 30, height: 30 }} alt="Loc" />
              <Box>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "400", color: "#858585" }}
                >
                  Address
                </Typography>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "500", color: "#303030" }}
                >
                  {selectedClinic?.userDetails?.address || "-"}
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              display={"flex"}
              alignItems={"center"}
              gap={0.5}
            >
              <img src={location} style={{ width: 30, height: 30 }} alt="Loc" />
              <Box>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "400", color: "#858585" }}
                >
                  Google Map Location
                </Typography>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "500", color: "#303030" }}
                >
                  {selectedClinic?.userDetails?.googleMapLocation || "-"}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid container rowGap={1}>
            <Grid
              item
              xs={12}
              sm={4}
              display={"flex"}
              alignItems={"center"}
              gap={0.5}
            >
              <img
                src={ownericon}
                style={{ width: 30, height: 30 }}
                alt="Email"
              />
              <Box>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "400", color: "#858585" }}
                >
                  Registration No.
                </Typography>
                <Typography
                  sx={{ fontSize: 14, fontWeight: "500", color: "#303030" }}
                >
                  {selectedClinic?.stateCouncilNo || "-"}
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              display={"flex"}
              alignItems={"center"}
              gap={0.5}
            >
              <img
                src={ownericon}
                style={{ width: 30, height: 30 }}
                alt="Email"
              />
            <Box>
              <Typography
                sx={{ fontSize: 14, fontWeight: "400", color: "#858585" }}
              >
                Dental Council
              </Typography>
              <Typography
                sx={{ fontSize: 14, fontWeight: "500", color: "#303030" }}
              >
                {selectedClinic?.state || "-"}
              </Typography>
            </Box>
            </Grid>

          </Grid>

          <Grid
            display={"flex"}
            flexWrap={"wrap"}
            alignItems={"center"}
            gap={1}
          >
            {selectedClinic?.userDetails?.licenseUrl ? (
              <Button
                variant="outlined"
                size="large"
                sx={{
                  borderRadius: "25px",
                  border: "1px solid #EDEDED",
                  background: "white",
                  color: "black",
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={fileimage}
                  style={{ width: 24, height: 24, marginRight: 4 }}
                />
                {documentFileName}
              </Button>
            ) : (
              <Typography
                sx={{ color: "#858585", fontSize: "14px", fontWeight: 500 }}
              >
                No license available
              </Typography>
            )}

            {selectedClinic?.userDetails?.licenseUrl ? (
              <Link
                style={{
                  // backgroundColor: "#F98247",
                  borderRadius: "30px",
                  color: "#000",
                  border: "1px solid #EDEDED",
                  width: "60px",
                  height: "45px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                to={`${selectedClinic?.userDetails?.licenseUrl || ""}`}
                target="_blank"
                download
              >
                <DownloadRoundedIcon />
              </Link>
            ) : (
              ""
            )}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-start" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "row",
                md: "row",
                lg: "row",
                xl: "row",
              },
              gap: "10px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#F98247",
                borderRadius: "30px",
                color: "white",
                fontSize: 13,
                fontWeight: 300,
                padding: "12px 50px 12px 50px",
                marginRight: "45px",
                marginLeft: "7px",
                "&:hover": { backgroundColor: "#F98247" },
                maxWidth: "150px",
              }}
              onClick={() => {
                changeUserStatus(selectedClinic.id, "APPROVED");
              }}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "#E91717",
                borderRadius: "30px",
                color: "white",
                fontSize: 13,
                fontWeight: 300,
                padding: "12px 50px 12px 50px",
                "&:hover": { backgroundColor: "#E91717" },
                maxWidth: "150px",
                marginLeft: "7px",
              }}
              onClick={handleRejectModal}
            >
              Reject
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={handleCloseApprove}
        aria-labelledby="customized-dialog-title"
        open={approveModal}
        fullWidth={true}
        sx={{
          "& .MuiDialog-paper": {
            padding: "0px",
            borderRadius: "15px",
            height: "400px",
            width: "450px",
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleCloseApprove}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className={classes.dialogContent}>
          <img
            src={sent}
            alt="Sent"
            style={{ width: "134px", height: "134px" }}
            className={classes.image}
          />
          <Typography
            align="center"
            sx={{
              fontSize: "30px",
              fontWeight: "600",
              color: "#00A01B",
              fontFamily: "Poppins",
              marginTop: "5px",
              padding: "0px",
            }}
          >
            Success
          </Typography>
          <Typography
            align="center"
            sx={{
              fontSize: "24px",
              fontWeight: "400",
              color: "black",
              fontFamily: "Poppins",
              margin: "0px",
              padding: "0px",
            }}
          >
            The Clinic has been added Successfully.
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={handleCloseReject}
        aria-labelledby="customized-dialog-title"
        open={rejectModal}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, fontWeight: 600, fontSize: 20, color: "#303030" }}
          id="customized-dialog-title"
        >
          Enter the reason
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseReject}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xl={12} md={12} xs={12} sm={12}>
              <Typography variant="caption1">Description</Typography>
            </Grid>

            <Grid item xl={12} md={12} xs={12} sm={12}>
              <TextField
                id="outlined-multiline-flexible"
                label="Type Here"
                multiline
                rows={4}
                variant="outlined"
                className={classes.bgColorTextField}
                fullWidth
                value={rejection_reason}
                onChange={handleReason}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "flex-start" }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#F98247",
              borderRadius: "30px",
              color: "white",
              fontSize: 13,
              fontWeight: 300,
              padding: "12px 50px 12px 50px",
              marginTop: "38px",
              marginLeft: "8px",
              "&:hover": { backgroundColor: "#F98247" },
            }}
            onClick={handleOpensubmitRejectModal}
            disabled={!rejection_reason}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        onClose={handleClosesubmitReject}
        aria-labelledby="customized-dialog-title"
        open={submitReject}
        fullWidth={true}
        sx={{
          "& .MuiDialog-paper": {
            padding: "0px",
            borderRadius: "15px",
            height: "300px",
            width: "450px",
            margin: "0px",
          },
          "& .MuiDialogContent-root": {
            margin: "0px",
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClosesubmitReject}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent
          sx={{
            height: "350px !important",
            mt: "100px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={cancelIcon}
            alt="Cancelled"
            style={{ width: "134px", height: "134px" }}
            className={classes.image}
          />
          <Typography
            variant="h5"
            align="center"
            style={{ color: "red", fontSize: "40px" }}
          >
            Cancelled
          </Typography>
          <Typography
            align="center"
            style={{
              fontSize: "20px",
              fontWeight: "400",
              color: "black",
            }}
          >
            The Clinic has been Cancelled.
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PendingClinics;
