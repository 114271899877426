import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React from "react";

const PasswordDoneModal = (props) => {
  return (
    <>
      <Dialog
        onClose={() => {
          window.location.href = `${process.env.REACT_APP_FRONTEND_BASE_URL}/admin/login`;
        }}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        maxWidth="sm"
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            window.location.href = `${process.env.REACT_APP_FRONTEND_BASE_URL}/admin/login`;
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography
                sx={{ fontSize: "24px", fontWeight: 600, color: "#161616" }}
              >
                Saved Successful
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Typography
                sx={{ fontSize: "14px", fontWeight: 400, color: "#161616" }}
              >
                You going to logout in few seconds
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid item>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#F98247",
                  borderRadius: "30px",
                  color: "white",
                  fontSize: 13,
                  fontWeight: 300,
                  padding: "12px 50px 12px 50px",
                  marginRight: "45px",
                  marginLeft: "4em",
                  "&:hover": { backgroundColor: "#F98247" },
                  maxWidth: "150px",
                }}
                onClick={() => {
                  window.location.href = `${process.env.REACT_APP_FRONTEND_BASE_URL}/admin/login`;
                }}
              >
                Done
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default PasswordDoneModal;
