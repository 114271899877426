import { Box, Button, Grid, Paper, Typography } from "@mui/material";

import clockicon from "../../assets/images/clockicon.png";
import doctorphone from "../../assets/images/doctorphone.png";
import location from "../../assets/images/location.png";
import staricon from "../../assets/images/staricon.png";
import { formatTimeSlots } from "../../utils/formatTimeSlots";
import { getPayType } from "../../utils/getPayType";
import getStringInitials from "../../utils/getStringInitials";

const ConsultationDetailsCard = ({
  consultation = {},
  index = "",
  getDate = null,
  handleOpen = null,
}) => {
  return (
    <Paper sx={{ borderRadius: "8px", my: 1 }} xl={12} key={index}>
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-between",
          p: 0.5,
        }}
      >
        <Grid display={"flex"} flexDirection={"column"} gap={1}>
          <Box display={"flex"} alignItems={"center"} gap={1}>
            {consultation?.clinicOwner?.imageUrl ? (
              <Box
                sx={{
                  height: 45,
                  width: 45,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  overflow: "hidden",
                  borderRadius: "8px",
                }}
              >
                <img
                  src={consultation?.clinicOwner?.imageUrl}
                  alt="_img"
                  style={{
                    objectFit: "cover",
                  }}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  height: 45,
                  width: 45,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  overflow: "hidden",
                  borderRadius: "8px",
                  backgroundColor: "#004E89",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "20px",
                    fontWeight: 600,
                  }}
                >
                  {getStringInitials(consultation?.clinicDetails?.clinicName) || "-"}
                </Typography>
              </Box>
            )}

            <Typography
              sx={{
                color: "#004E89",
                fontSize: 18,
                fontWeight: 600,
              }}
            >
              {" "}
              {consultation?.clinicDetails?.clinicName || "N-A"}
            </Typography>
          </Box>

          <Grid
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <img
                src={doctorphone}
                style={{ width: "18px", height: "18px" }}
              />
              <Typography
                sx={{
                  color: "#1A202C",
                  fontSize: 13,
                  fontWeight: 500,
                }}
              >
                {consultation?.clinicOwner?.phoneNumber}{" "}
              </Typography>
            </Box>

            {consultation?.locationUrl && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                <img src={location} style={{ width: "18px", height: "18px" }} />
                <Typography
                  title={consultation?.clinicDetails?.address?.toLowerCase() || "-"}
                  noWrap
                  sx={{
                    width: 120,
                    color: "#1A202C",
                    fontSize: "13px",
                    fontWeight: 500,
                    textTransform: "capitalize",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open(consultation?.clinicDetails?.googleMapLocation, "_blank");
                  }}
                >
                  {consultation?.clinicDetails?.address?.toLowerCase() || "-"}{" "}
                </Typography>
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <img src={clockicon} style={{ width: "18px", height: "18px" }} />
              <Typography
                sx={{
                  color: "#1A202C",
                  fontSize: 13,
                  fontWeight: 500,
                }}
              >
                {getDate(consultation?.date)},{" "}
                {formatTimeSlots({
                  startTime: consultation?.startTime,
                  endTime: consultation?.endTime,
                })}{" "}
              </Typography>
            </Box>
          </Grid>

          <Grid container alignItems="center" gap={2}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              <img src={staricon} style={{ width: 15, height: 15 }} />
              <Typography
                sx={{
                  color: "#F98247",
                  fontSize: "13px",
                  fontWeight: 600,
                }}
              >
                {getPayType({
                  requestType: consultation?.requestType,
                  minSalary: consultation?.minimumSalary,
                  maxSalary: consultation?.maximumSalary,
                  hourly: consultation?.hourly,
                })}
              </Typography>
            </Box>

            {consultation?.skills && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                <img
                  src={staricon}
                  style={{ width: "16.67px", height: "16.67px" }}
                />
                <Typography
                  title={consultation?.skills
                    ?.split(",")
                    .join(", ")
                    .toLowerCase()}
                  sx={{
                    color: "#F98247",
                    fontSize: "13px",
                    fontWeight: 600,
                    textTransform: "capitalize",
                  }}
                >
                  {consultation?.skills?.split(",").join(", ").toLowerCase()}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>

        <Grid
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              handleOpen(consultation);
            }}
            sx={{
              fontSize: "13px",
              fontWeight: 600,
              backgroundColor: "#E78756",
              borderRadius: 30,
              width: "100px",
              "&:hover": { backgroundColor: "#E78756" },
            }}
          >
            {" "}
            View{" "}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ConsultationDetailsCard;
