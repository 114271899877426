import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import React, { Fragment } from "react";
import Sidebar from "./Sidebar";
import Sidebar1 from "./Sidebar1";
import NavBar from "./NavBar";

const Navigation = ({ children, role, designation }) => {
  const { breakpoints } = useTheme();
  const mathUpMd = useMediaQuery(breakpoints?.up("lg"));

  return (
    <Fragment>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ width: "100%", display: "flex", overflow: "hidden" }}>
          <NavBar role={role} designation={designation} />
          {/* <Sidebar role={role} /> */}
          <Sidebar1 role={role} designation={designation} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              width: `calc(100% - ${mathUpMd ? "330px" : "0px"})`,
              pt: 1,
              pb: 1,
              /* pr:1,
              pl:1 */
            }}
          >
            <Box sx={{ height: 80 }} />
            <Container maxWidth={"xl"}>{children}</Container>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

export default Navigation;
