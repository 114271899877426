import CloseIcon from "@mui/icons-material/Close";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

import doctoremail from "../../assets/images/doctoremail.png";
import doctorphone from "../../assets/images/doctorphone.png";
import doctorprofile from "../../assets/images/doctorprofile.png";
import fileimage from "../../assets/images/fileimage.png";
import location from "../../assets/images/location.png";
import ownericon from "../../assets/images/ownericon.png";
import website from "../../assets/images/website.png";
import DocumentViewer from "../../components/DocumentViewer/DocView";
import { styles } from "../../layouts/Styles";
import api from "../../api/index";

const AdminClinicsRejected = ({
  clinics,
  total,
  currentPage,
  getClinics,
  totalPages,
}) => {
  const {
    palette: { grey },
  } = useTheme();

  const classes = styles();

  const [clinicOpen, setClinicOpen] = React.useState(false);
  const [selectedClinic, setSelectedClinic] = React.useState(null);
  const [approveModal, setApproveModal] = React.useState(false);

  const handleClinicOpen = (obj) => {
    setSelectedClinic(obj);
    setClinicOpen(true);
  };
  const handleClinicClose = () => {
    setClinicOpen(false);
  };

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(1);
    setLimit(event.target.value);
  };
  const handleApproveModal = () => {
    setApproveModal(true);
    handleClinicClose();
  };

  const changeUserStatus = async (id, status, reason) => {
    const res = await api.changeUserStatus(status, id, reason);
    if (res.data.success) {
      setSelectedClinic(null);
      getClinics(page, limit);
      if (!reason) {
        handleApproveModal();
      }
    }
  };

  const documentFileUrl =
    "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf";
  const documentFileName = "Liscense.pdf";

  let clinicsdata = [
    {
      id: 1,
      "clinic name": "John Doe",
      "phone number": 251111129,
      email: "sample@test.com",
      website: "Clinic Website",
      address: "Madhapur",
      status: "View",
    },
  ];

  const columnsclinic = Object.keys(clinicsdata[0]);
  useEffect(() => {
    getClinics(page, limit);
  }, [page, limit]);

  return (
    <Fragment>
      <Paper sx={{ p: "2em", mt: 6, borderRadius: 2 }}>
        <Typography
          variant="h3"
          sx={{ fontSize: "22px", fontWeight: 500, mt: 1, mb: 2 }}
        >
          Clinic List{" "}
          <span style={{ fontSize: "22px", fontWeight: 700, mt: 1, mb: 2 }}>
            ({total})
          </span>
        </Typography>

        <TableContainer>
          <Table style={{ width: "1200px" }}>
            <TableHead>
              <TableRow>
                {columnsclinic.map((column) => (
                  <TableCell key={column} className={classes.tableCell}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {clinics?.map((row, index) => (
                // id: 2,
                // "clinic name": "John Doe",
                // "phone number": 251111129,
                // email: "sample@test.com",
                // website: "Clinic Website",
                // address: "Madhapur",
                // status: "View",
                <TableRow key={index}>
                  <TableCell className={classes.tableCellBody}>
                    {row?.id}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {/* <Link to={`/clinic/clinicDetails/${row?.id}`}> */}
                    {row?.userDetails?.clinicName || "-"}
                    {/* </Link> */}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {row?.phoneNumber}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {row?.email}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {row?.userDetails?.website || "-"}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    {row?.userDetails?.address || "-"}
                  </TableCell>
                  <TableCell className={classes.tableCellBody}>
                    <Button
                      onClick={() => {
                        handleClinicOpen(row);
                      }}
                      variant="contained"
                      size="medium"
                      sx={{
                        backgroundColor: "#F98247",
                        borderRadius: 30,
                        padding: "8px 46px 8px 46px",
                        "&:hover": { backgroundColor: "#F98247" },
                      }}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={total}
          rowsPerPage={limit}
          page={currentPage - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Dialog
        onClose={handleClinicClose}
        aria-labelledby="customized-dialog-title"
        open={clinicOpen}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          sx={{ m: 0, p: 2, fontWeight: 600, fontSize: 20, color: "#303030" }}
          id="customized-dialog-title"
        >
          Details
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClinicClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid container spacing={3} alignItems="center">
            <Grid item className={classes.imageContainer}>
              <img
                src={doctorprofile}
                style={{ width: "84px", height: "84px" }}
              />
              <Typography variant="h6" className={classes.textOverlay}>
                {selectedClinic?.userDetails?.clinicName?.[0].toUpperCase()}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                sx={{ fontSize: "20px", fontWeight: "600", color: "#1A202C" }}
              >
                {selectedClinic?.userDetails?.clinicName}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "500", color: "red" }}
              >
                Rejected
              </Typography>
            </Grid>
            <Grid item>
              <Button
                onClick={() => {
                  changeUserStatus(selectedClinic.id, "APPROVED");
                }}
                variant="contained"
                size="medium"
                sx={{
                  color: "#00AB11",
                  backgroundColor: "#D9F3DC",
                  borderRadius: 30,
                  fontSize: 14,
                  fontWeight: 500,
                  padding: "10px 60px 10px 60px",
                  "&:hover": { backgroundColor: "#D9F3DC" },
                }}
              >
                Approve
              </Button>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: "40px" }}>
            <Grid item xs={3} sm={1} md={1} lg={1} xl={1} sx={{ mr: 0 }}>
              <img
                src={ownericon}
                alt="owner"
                style={{ width: 40, height: 40 }}
              />
            </Grid>
            <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "400", color: "#858585" }}
              >
                Owner Name
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "500", color: "#303030" }}
              >
                {selectedClinic?.name}
              </Typography>
            </Grid>

            <Grid
              item
              xs={3}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              sx={{ pl: "2px", mr: 0 }}
            >
              <img
                src={doctorphone}
                style={{ width: 40, height: 40 }}
                alt="Phone"
              />
            </Grid>
            <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "400", color: "#858585" }}
              >
                Phone Number
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "500", color: "#303030" }}
              >
                {selectedClinic?.phoneNumber}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              sx={{ pl: "2px", mr: 0 }}
            >
              <img
                src={doctoremail}
                style={{ width: 40, height: 40 }}
                alt="Email"
              />
            </Grid>
            <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "400", color: "#858585" }}
              >
                Email Address
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "500", color: "#303030" }}
              >
                {selectedClinic?.email}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ mt: "30px" }}>
            <Grid item xs={3} sm={1} md={1} lg={1} xl={1} sx={{ mr: 0 }}>
              <img src={website} alt="Web" style={{ width: 40, height: 40 }} />
            </Grid>
            <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "400", color: "#858585" }}
              >
                Website Name
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "500", color: "#303030" }}
              >
                {selectedClinic?.userDetails?.website}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              sx={{ pl: "2px", mr: 0 }}
            >
              <img src={location} style={{ width: 40, height: 40 }} alt="Loc" />
            </Grid>
            <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "400", color: "#858585" }}
              >
                Address
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "500", color: "#303030" }}
              >
                {selectedClinic?.userDetails?.address}
              </Typography>
            </Grid>
            <Grid
              item
              xs={3}
              sm={1}
              md={1}
              lg={1}
              xl={1}
              sx={{ pl: "2px", mr: 0 }}
            >
              <img src={location} style={{ width: 40, height: 40 }} alt="Loc" />
            </Grid>
            <Grid item xs={9} sm={5} md={3} lg={3} xl={3}>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "400", color: "#858585" }}
              >
                Google Map Location
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "500", color: "#303030" }}
              >
                {selectedClinic?.userDetails?.googleMapLocation}
              </Typography>
            </Grid>
          </Grid>
          {/* <Grid container spacing={1}> */}
          <Grid item>
            <Typography
              variant="h3"
              sx={{ fontSize: "20px", fontWeight: 600, mt: "2em" }}
            >
              Reason
            </Typography>
          </Grid>
          <Grid item style={{ marginTop: 14 }}>
            <Typography
              // onClick={() => console.log(selectedClinic)}
              sx={{
                fontSize: "16px",
                fontWeight: 400,
                color: "rgba(118, 121, 128, 1)",
                maxHeight: 300,
                border: "1px solid #000",
                borderRadius: "8px",
                p: 1,
                overflowY: "auto",
              }}
            >
              {selectedClinic?.reason ||
                " Lorem ipsum dolor sit amet consectetur. Amet arcu aliquam at egestas facilisis id vitae ullamcorper. Habitant susci Case Description dolor sit amet consectetur"}
            </Typography>
          </Grid>
          {/* </Grid> */}
          <Grid
            display={"flex"}
            flexWrap={"wrap"}
            alignItems={"center"}
            gap={1}
            mt={1}
          >
            {selectedClinic?.userDetails?.licenseUrl ? (
              <Button
                variant="outlined"
                // onClick={handleOpen}
                size="large"
                sx={{
                  borderRadius: "25px",
                  border: "1px solid #EDEDED",
                  background: "white",
                  color: "black",
                  fontWeight: 600,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={fileimage}
                  style={{ width: 24, height: 24, marginRight: 4 }}
                />
                {documentFileName}
              </Button>
            ) : (
              <Typography
                sx={{ color: "#858585", fontSize: "14px", fontWeight: 500 }}
              >
                No license available
              </Typography>
            )}

            {selectedClinic?.userDetails?.licenseUrl ? (
              <Link
                style={{
                  // backgroundColor: "#F98247",
                  borderRadius: "30px",
                  color: "#000",
                  border: "1px solid #EDEDED",
                  width: "60px",
                  height: "45px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                to={`${selectedClinic?.userDetails?.licenseUrl || ""}`}
                target="_blank"
                download
              >
                <DownloadRoundedIcon />
              </Link>
            ) : (
              ""
            )}
          </Grid>

          {/* <DocumentViewer file={documentFileUrl} fileName={documentFileName} /> */}
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default AdminClinicsRejected;
