import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Button, CircularProgress, FormControl, Grid, IconButton, ListItemText, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import { InputAdornment } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import Radio from "@mui/material/Radio";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import api from "../../api/index";
import leftarrowicon from "../../assets/images/leftarrowicon.png";
import rocketimg from "../../assets/images/rocket.png";
import { styles } from "../../layouts/Styles";

const EditSlot = () => {
  const [nav, setNav] = useState(false);
  const Navigate = useNavigate();
  const { state } = useLocation();
  const availableSlots = state?.item;
  const [timeslot, setTimeSlot] = useState("");
  const [selectedPrice, setSelectedPrice] = useState("");
  const [startTime, setStartTime] = useState("");
  const [activeId, setActiveId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const [isLoading, setLoading] = useState(false);

  const [sections, setSections] = useState([
    {
      noOfCabins: 0,
      price: selectedPrice,
      startTime: "10:00:00",
      endTime: "11:00:00",
    },
  ]);

  const classes = styles();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        overflowY: "auto",
      },
    },
    autoFocus: false,
  };

  const handleChangeTime = (e) => {
    setTimeSlot(e.target.value);
  };

  const handleBack = () => {
    setNav(true);
  };

  // const handleCommonDataChange = ({ target: { name, value } }) => {
  //   setCommonData({ ...commonData, [name]: value });
  // };
  // const handleClickOpen = () => {
  //   setOpenDialog(true);
  // };

  // const handleClose = () => {
  //   setOpenDialog(false);
  // };

  const handleFocus = () => {
    if (startTime === "") {
      setStartTime(startTime);
    }
  };
  // const handleCloseBook = () => {
  //   setModalOpen(false);
  //   setNav(true);
  // };

  const handleChange = ({ target: { name, value } }, index, action) => {
    if (action) {
      let arr = sections.map((item, i) =>
        i == index
          ? {
              ...item,
              [name]:
                action == "inc"
                  ? item[name] + 1
                  : item[name] > 1
                  ? item[name] - 1
                  : item[name],
            }
          : item
      );
      setSections(arr);
      return;
    }

    let arr = sections.map((item, i) =>
      i == index ? { ...item, [name]: value } : item
    );
    setSections(arr);
  };

  const updateCabinAvailability = async () => {
    setLoading(true);
    try {
      const { data } = await api.updateCabinAvailability({
        id: activeId,
        args: sections[0],
      });
      setLoading(false);
      if (data.success) {
        setModalOpen(true);
      } else {
        toast.error("Failed to update availibility");
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const renderSections = () => {
    return sections.map((item, index) => (
      <Grid
        xs={12}
        sx={{
          mt: "2em",
        }}
        key={index}
      >
        <Grid container>
          <Grid xs={12} sm={12} md={3}>
            <Typography
              sx={{
                color: "rgba(48, 48, 48, 1)",
                fontSize: "16px",
                fontWeight: 400,
                mb: 1,
              }}
            >
              From
            </Typography>
            <div
              style={{
                display: "flex",
              }}
            >
              <TextField
                name="startTime"
                type="time"
                value={item.startTime}
                // onChange={handleStartTimeChange}
                onChange={(e) => handleChange(e, index)}
                onFocus={handleFocus}
                inputProps={{
                  step: 300,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  "& .MuiOutlinedInput-input": {
                    outline: "none",
                    height: "23px",
                    width: "100vw",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {},
                }}
              />
            </div>
          </Grid>

          <Grid sx={{ paddingLeft: "20px" }} xs={12} sm={12} md={3}>
            <Typography
              sx={{
                color: "rgba(48, 48, 48, 1)",
                fontSize: "16px",
                fontWeight: 400,
                mb: 1,
              }}
            >
              To
            </Typography>
            <div
              style={{
                display: "flex",
              }}
            >
              <TextField
                // label="Select Time"
                name="endTime"
                type="time"
                value={item.endTime}
                // onChange={handleEndTimeChange}
                onChange={(e) => handleChange(e, index)}
                onFocus={handleFocus}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  // "& input[type='time']::-webkit-calendar-picker-indicator": {
                  //   display: "none",
                  // },
                  "& .MuiOutlinedInput-input": {
                    outline: "none",
                    height: "23px",
                    width: "100vw",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {},
                }}
              />
            </div>
          </Grid>

          <Grid sx={{ paddingLeft: "20px" }} xs={12} sm={12} md={3}>
            <Typography
              sx={{
                color: "rgba(48, 48, 48, 1)",
                fontSize: "16px",
                fontWeight: 400,
                mb: 1,
              }}
            >
              Cabins
            </Typography>
            <TextField
              type="number"
              name="noOfCabins"
              value={item.noOfCabins}
              fullWidth
              // onChange={(e) => setCabinValues(e.target.value)}
              // onChange={(e) => handleChange(e, index)}
              sx={{
                "& .MuiInputBase-input": {
                  textAlign: "center",
                  height: 21,
                },
              }}
              InputProps={{
                readOnly: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton
                      onClick={(e) =>
                        handleChange(
                          { target: { name: "noOfCabins" } },
                          index,
                          "dec"
                        )
                      }
                    >
                      <RemoveIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={(e) => {
                        if (item.noOfCabins > 4) {
                          toast.warn(
                            "Number of cabins cannot be greater than 5.",
                            {
                              toastId: "max-cabin-warn-toast",
                            }
                          );
                          return;
                        }
                        handleChange(
                          { target: { name: "noOfCabins" } },
                          index,
                          "inc"
                        );
                      }}
                    >
                      <AddIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid sx={{ paddingLeft: "20px" }} xs={12} sm={12} md={3}>
            <Typography
              variant="h6"
              sx={{
                color: "rgba(48, 48, 48, 1)",
                fontSize: "16px",
                fontWeight: 400,
                mb: 1,
              }}
            >
              Price Per Hour
            </Typography>
            <OutlinedInput
              name={"price"}
              value={item.price}
              onChange={(e) => handleChange(e, index)}
              onFocus={handleFocus}
              placeholder="Enter Price"
              fullWidth
              sx={{
                "& .MuiOutlinedInput-input": {
                  outline: "none",
                  height: "21px",
                  width: "100%",
                },
                "& .MuiOutlinedInput-notchedOutline": {},
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    ));
  };

  useEffect(() => {
    if (availableSlots.length > 0 && activeId) {
      const selectedSlot = availableSlots.find((item) => item.id == activeId);
      setSelectedPrice(selectedSlot.price);

      setSections([
        {
          noOfCabins: selectedSlot.noOfCabins,
          price: selectedSlot.price,
          startTime: selectedSlot.startTime,
          endTime: selectedSlot.endTime,
        },
      ]);
    }
  }, [activeId, availableSlots]);

  return (
    <>
      {nav ? (
        Navigate("/clinic/managebookings")
      ) : (
        <>
          <Grid container sx={{ mb: "2em" }}>
            <Grid item>
              <img
                onClick={handleBack}
                src={leftarrowicon}
                style={{ width: "24px", height: "24px" }}
                alt="leftarrow"
              />
            </Grid>
            <Grid item sx={{ mt: "0.1em", ml: "1em" }}>
              <Typography
                variant="body"
                sx={{
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "black",
                }}
              >
                Back
              </Typography>
            </Grid>
          </Grid>

          <Typography variant="h3" sx={{ fontSize: "23px", fontWeight: 600 }}>
            Details
          </Typography>

          <Paper
            sx={{
              width: {
                xs: "100%",
                md: "40%",
              },
              p: "2em",
              borderRadius: 1.5,
              mt: 3,
              pb: "3em",
              minHeight: "fit-content",
            }}
          >
            <Grid
              container
              sx={{
                justifyContent: {
                  xs: "center",
                  md: "space-between",
                },
              }}
            >
              <Grid item xs={12} md={12}>
                <Box display="flex" flexDirection="column">
                  <Typography
                    sx={{
                      color: "rgba(48, 48, 48, 1)",
                      fontSize: "16px",
                      fontWeight: 400,
                      mb: 1,
                    }}
                  >
                    Select Time Slots
                  </Typography>
                  <FormControl>
                    <Select
                      displayEmpty
                      value={timeslot}
                      onChange={handleChangeTime}
                      input={<OutlinedInput />}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return (
                            <em
                              style={{
                                color: "#C2C2C2",
                                fontStyle: "normal",
                                fontSize: 14,
                                fontWeight: 500,
                              }}
                            >
                              Select Time Slots
                            </em>
                          );
                        }
                        return selected;
                      }}
                      MenuProps={MenuProps}
                      // onOpen={handleDropdownOpen}
                      // onClose={handleDropdownClose}
                    >
                      {availableSlots?.map((item, index) => (
                        <MenuItem
                          key={index}
                          value={item?.id}
                          className={classes.selectedMenuItem}
                          onClick={() => setActiveId(item?.id)}
                          sx={{
                            "& .MuiTypography-root": {
                              color: "black",
                            },
                          }}
                        >
                          <Radio
                            checked={timeslot == item?.id}
                            sx={{
                              color: "#C2C2C2",
                              "&.Mui-checked": { color: "#F98247" },
                            }}
                          />
                          <ListItemText
                            primary={`${item?.startTime} - ${item?.endTime}`}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Paper>

          {activeId && (
            <Paper
              sx={{
                p: "2em",
                borderRadius: 1.5,
                mt: 3,
                pb: "3em",
                width: "100%",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontSize: "30px",
                  fontWeight: 600,
                  mb: 4,
                  color: "#111111",
                }}
              >
                Update Availability
              </Typography>

              {renderSections()}

              <Button
                variant="contained"
                disabled={isLoading}
                sx={{
                  mt: "3rem",
                  backgroundColor: "#F98247",
                  padding: "30px",
                  fontSize: "16px",
                  borderRadius: "15px",
                  "&:hover": { backgroundColor: "#F98247" },
                  width: "300px"
                }}
                startIcon={
                  <img
                    src={rocketimg}
                    alt="Icon"
                    style={{ marginRight: "0px" }}
                  />
                }
                onClick={updateCabinAvailability}
              >
                {!isLoading ? (
                  "Update Availability"
                ) : (
                  <CircularProgress color="inherit" size={20} />
                )}
              </Button>
            </Paper>
          )}
        </>
      )}
      {/* {modalOpen ? (
        <Dialog
          onClose={handleCloseBook}
          aria-labelledby="customized-dialog-title"
          sx={{
            "& .MuiDialog-paper": {
              padding: "0px",
              borderRadius: "15px",
              height: "50%",
            },
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseBook}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>

          <DialogContent
            className={classes.dialogContent}
            sx={{
              height: "300px",
              mt: "30px",
            }}
          >
            <img
              src={sent}
              alt="Sent"
              style={{ width: "75px", height: "75px" }}
              className={classes.image}
            />
            <Typography
              align="center"
              sx={{
                fontSize: "24px",
                fontWeight: "600",
                color: "#F98247",
                fontFamily: "Poppins",
                marginTop: "5px",
                padding: "0px",
              }}
            >
              Slot has been updated succesfully!
            </Typography>
          </DialogContent>
        </Dialog>
      ) : null} */}
    </>
  );
};

export default EditSlot;
