import SearchIcon from "@mui/icons-material/Search";
import { Box, Grid, InputAdornment, LinearProgress, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import api from "../../api";
import { setActivePost } from "../../store/reducer/Clinic";
import ClinicDashModal from "../Modals/ClinicDashModal";
import ProposalDetailsCard from "./ProposalDetailsCard";
import ProposalFilter from "./ProposalFilter";

const Proposals = () => {
  const [open, setOpen] = React.useState(false);
  const [isLoading, setLoading] = useState(true);
  const [proposals, setProposals] = useState([]);
  const [isACtiveCase, setIsActiveCase] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const dispatch = useDispatch();

  let activeCase = useSelector((store) => store.clinic.activePost);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getPostByCaseId = async () => {
    try {
      const { data } = await api.getAllClinicPostsById(activeCase?.id);
      setLoading(false);
      if (!data?.success) {
        toast.error(data?.message || "Failed to load proposals.");
        return;
      }

      setProposals(data?.data?.allProposal || []);
      dispatch(setActivePost({}));
    } catch (error) {
      console.error("Error fetching proposals:", error);
      setLoading(false);
    }
  };

  const [filterData, setFilterData] = useState({
    status: "",
    startDate: "",
    endDate: "",
    type: "",
  });

  const searchPostsOfClinic = async () => {
    setLoading(true);
    try {
      let payload = {
        ...filterData,
        searchQuery: searchQuery,
      };
      const { data } = await api.searchProposalsOfClinic(payload);
      setLoading(false);
      if (!data?.success) {
        return;
      }
      setProposals(data?.data?.allProposal || []);
      console.log(data.data.allProposal);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!activeCase?.id) {
      return;
    }
    setSearchQuery(activeCase?.caseName || "-");
    getPostByCaseId();
    setIsActiveCase(true);
  }, [activeCase]);

  //delay api call on search key change
  useEffect(() => {
    if (isACtiveCase) {
      setIsActiveCase(false);
      return;
    }
    const getData = setTimeout(() => {
      searchPostsOfClinic();
    }, 1500);

    return () => clearTimeout(getData);
  }, [searchQuery]);

  useEffect(() => {
    if (activeCase?.id) {
      return;
    }
    searchPostsOfClinic();
  }, [filterData.endDate, filterData.status, filterData.type]);

  return (
    <>
      {open ? (
        <ClinicDashModal open={open} handleClose={handleClose} data={{}} />
      ) : null}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "1em",
        }}
      >
        <Typography variant="h3" sx={{ fontSize: "22px", mt: 1.5 }}>
          Proposals
        </Typography>
        <Grid display={"flex"} alignItems={"center"}>
          <TextField
            id="search"
            placeholder="Case Title"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}
            sx={{
              width: "200px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            style={{ color: "#F98247" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ marginRight: "8px" }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <ProposalFilter setFilterData={setFilterData} />
        </Grid>
      </Box>

      {isLoading && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}

      <Grid display={"flex"} flexWrap={"wrap"} gap={1}>
        {proposals?.length
          ? proposals.map((item, index) => (
              <ProposalDetailsCard data={item} index={index} />
            ))
          : !isLoading
          ? "No records founds."
          : ""}
      </Grid>
    </>
  );
};

export default Proposals;
