import { makeStyles } from "@mui/styles";

export const styles = makeStyles((theme) => {
  return {
    paper: {
      width: "100%",
      marginBottom: theme?.spacing(2),
    },
    tableCell: {
      fontWeight: "500",
      color: theme?.palette.common.gray, // Gray color for the header
      fontFamily: "Poppins",
      fontSize: "12px",
      fontStyle: "normal",
      lineHeight: "normal",
      textTransform: "uppercase",
      textAlign: "center",
      backgroundColor: "#EDEDED",
      padding: "10px",
    },
    tableCellStatus: {
      fontWeight: "500",
      color: theme?.palette.common.gray, // Gray color for the header
      fontFamily: "Poppins",
      fontSize: "12px",
      fontStyle: "normal",
      lineHeight: "normal",
      textTransform: "uppercase",
      textAlign: "start",
      backgroundColor: "#EDEDED",
      padding: "10px",
    },
    OverviewMenu: {
      border: "1px solid #C2C2C2",
      display: "flex",
      padding: "10px 16px",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      // gap: "8px",
      height: "3.7em",
      borderRadius: "12px",
      cursor: "pointer",
      width: "300px",
      backgroundColor: "#F9F9F9",
    },
    OverviewMenuSmall: {
      border: "1px solid #C2C2C2",
      display: "flex",
      padding: "10px 16px",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      // gap: "8px",
      height: "3.7em",
      borderRadius: "12px",
      cursor: "pointer",
      width: "200px",
      backgroundColor: "#F9F9F9",
    },
    tableCellBody: {
      color: theme?.palette.common.blackLight,
      fontFamily: "Poppins",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "normal",
      // borderBottom: "none",
      textAlign: "start",
      padding: "10px",
    },
    tableCellBodyLink: {
      color: "#004E89",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "normal",
      // borderBottom: "none",
      textAlign: "start",
      padding: "10px",
    },
    activeCell: {
      color: "white",
      display: "flex",
      justifyContent: "center",
      padding: "8px 46px 8px 46px",
      alignItems: "center",
      textAlign: "center",
      marginTop: "10px",
      borderRadius: "30px",
      backgroundColor: "#E78756",
      fontSize: "14px",
      fontWeight: "400",
      //   fontFamily:'Plus Jakarta Sans'
    },
    active: {
      color: theme?.palette.error.main,
      display: "flex",
      justifyContent: "flex-start",
      padding: "6px 14px",
      alignItems: "flex-start",
      textAlign: "start",
      marginTop: "10px",
      borderRadius: "6px",
      backgroundColor: "rgba(229, 29, 29, 0.15)",
      textTransform: "capitalize",
    },
    credited: {
      color: "#00A01B",
      fontWeight: 500,
      fontSize: 14,
      // display: "flex",
      // justifyContent: "flex-start",
      // fontFamily: "Poppins",
      // border: "none",
    },
    debited: {
      color: "#ED3838",
      fontWeight: 500,
      fontSize: "16px",
      display: "flex",
      justifyContent: "center",
      fontFamily: "Poppins",
      border: "none",
    },
    active: {
      color: "#00A01B",
      fontWeight: 500,
      fontSize: "16px",
      display: "flex",
      justifyContent: "center",
      fontFamily: "Poppins",
      border: "none",
    },
    inActive: {
      color: "#ED3838",
      fontWeight: 500,
      fontSize: "16px",
      display: "flex",
      justifyContent: "center",
      fontFamily: "Poppins",
      border: "none",
    },
    pending: {
      color: "#F98247",
      fontWeight: 500,
      fontSize: 14,
      // display: "flex",
      // justifyContent: "flex-start",
      // fontFamily: "Poppins",
      // // border: "none",
    },
    bgColorTextField: {
      "& .MuiInputBase-input": {
        backgroundColor: "white",
      },
    },
    imageContainer: {
      position: "relative",
      display: "inline-block",
    },
    textOverlay: {
      position: "absolute",
      top: "57%",
      left: "60%",
      fontSize: "34px",
      fontWeight: 500,
      transform: "translate(-50%, -50%)",
      padding: theme.spacing(2),
      color: "white", // Customize text color
      //background: 'rgba(0, 0, 0, 0.5)', // Customize background color and opacity
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    image: {
      maxWidth: "100%",
      maxHeight: "100%",
    },
    text: {
      marginTop: "16px",
    },
    selectedMenuItem: {
      height: "30px",
    },
  };
});
