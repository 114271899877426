import { Box, Button, Modal, Typography } from "@mui/material";
import React, { useState } from "react";

import fileimage from "../../assets/images/fileimage.png";

const DocumentViewer = ({ file, fileName }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    // setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleOpen}
        size="large"
        sx={{
          borderRadius: "25px",
          border: "1px solid #EDEDED",
          background: "white",
          mt: 1,
          color: "black",
          fontWeight: 600,
        }}
      >
        <img
          src={fileimage}
          style={{ width: 24, height: 24, marginRight: 15 }}
        />
        {fileName}
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600, // Adjust the width as needed
            //bgcolor: 'background.paper',

            //boxShadow: 24,
            p: 4,
            //borderRadius: "25px",
            border: "1px solid #EDEDED",
            background: "white",
          }}
        >
          <Typography variant="h6" component="div" gutterBottom>
            {fileName}
          </Typography>
          {/* <FileViewer
              fileType="pdf" // Specify the file type or leave it empty for automatic detection
              filePath={file} // Pass the URL or file path of the document
            /> */}
        </Box>
      </Modal>
    </div>
  );
};

export default DocumentViewer;
