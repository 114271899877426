import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Grid, OutlinedInput, Typography, useTheme } from "@mui/material";
import moment from "moment";

const WorkExpDetailsCard = ({
  data = {},
  index = "",
  isEdit = false,
  handleExpChange = null,
  deleteExpRow = null,
}) => {
  const { palette } = useTheme();

  return (
    <Grid
      item
      key={index}
      display={"flex"}
      container
      spacing={1}
      sx={{
        backgroundColor: "#F8F8F8",
        p: 2,
        borderRadius: "12px",
        my: 1,
        position: "relative",
      }}
    >
      {isEdit && (
        <div
          style={{
            position: "absolute",
            top: 4,
            right: 4,
            cursor: "pointer",
          }}
          onClick={() => {
            deleteExpRow(index);
          }}
        >
          <RemoveCircleOutlineIcon
            sx={{
              color: "red !important",
            }}
            fontSize="small"
          />
        </div>
      )}

      <Grid xs={12} sm={6} lg={3} item display="flex" flexDirection="column">
        <Typography
          variant="h6"
          sx={{
            color: "#303030",
            mb: 1,
            fontSize: 14,
            fontWeight: 400,
          }}
        >
          Clinic Name
        </Typography>
        <OutlinedInput
          readOnly={!isEdit}
          name={"clinicName"}
          value={data?.clinicName || ""}
          onChange={({ target: { name, value } }) =>
            handleExpChange(name, value, index)
          }
          sx={{
            borderRadius: 2,
            backgroundColor: "white",
            "& .MuiOutlinedInput-input": {
              borderRadius: 2,
              outline: "none",
              height: "15px",
              backgroundColor: "#fff",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: 2,
              transition: "ease-in 0.2s",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: `1px solid ${palette?.primary?.main}`,
              transition: "ease-in 0.2s",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: `2px solid ${palette?.primary?.light}`,
              transition: "ease-in 0.2s",
            },
          }}
        />
      </Grid>
      <Grid xs={12} sm={6} lg={3} item display="flex" flexDirection="column">
        <Typography
          variant="h6"
          sx={{
            color: "#303030",
            mb: 1,
            fontSize: 14,
            fontWeight: 400,
          }}
        >
          Job Title
        </Typography>
        <OutlinedInput
          readOnly={!isEdit}
          name={"jobTitle"}
          value={data?.jobTitle || ""}
          onChange={({ target: { name, value } }) =>
            handleExpChange(name, value, index)
          }
          sx={{
            borderRadius: 2,
            backgroundColor: "white",
            "& .MuiOutlinedInput-input": {
              borderRadius: 2,
              outline: "none",
              height: "15px",
              backgroundColor: "#fff",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: 2,
              transition: "ease-in 0.2s",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: `1px solid ${palette?.primary?.main}`,
              transition: "ease-in 0.2s",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: `2px solid ${palette?.primary?.light}`,
              transition: "ease-in 0.2s",
            },
          }}
        />
      </Grid>
      <Grid xs={12} sm={6} lg={3} item display="flex" flexDirection="column">
        <Typography
          variant="h6"
          sx={{
            color: "#303030",
            mb: 1,
            fontSize: 14,
            fontWeight: 400,
          }}
        >
          Start Date
        </Typography>
        <input
          type="date"
          name="startFrom"
          disabled={!isEdit}
          value={moment(data?.startFrom).format("yyyy-MM-DD")}
          max={moment(new Date()).format("YYYY-MM-DD")}
          onChange={({ target: { name, value } }) => {
            handleExpChange(name, value, index);
          }}
          style={{
            height: 45,
            borderRadius: "20px",
            border: `0`,
            padding: "0 8px",
          }}
        />

        {/* <OutlinedInput
            type="date"
            readOnly={!isEdit}
            name={"startFrom"}
            value={
              data?.startFrom
                ? moment(data?.startFrom).format("yyyy-MM-DD")
                : "-"
            }
            onChange={({ target: { name, value } }) =>
              handleExpChange(name, value, index)
            }
            sx={{
              borderRadius: 2,
              backgroundColor: "white",
              "& .MuiOutlinedInput-input": {
                borderRadius: 2,
                outline: "none",
                height: "15px",
                backgroundColor: "#fff",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 2,
                transition: "ease-in 0.2s",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: `1px solid ${palette?.primary?.main}`,
                transition: "ease-in 0.2s",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: `2px solid ${palette?.primary?.light}`,
                transition: "ease-in 0.2s",
              },
            }}
          /> */}
      </Grid>
      <Grid xs={12} sm={6} lg={3} item display="flex" flexDirection="column">
        <Typography
          variant="h6"
          sx={{
            color: "#303030",
            mb: 1,
            fontSize: 14,
            fontWeight: 400,
          }}
        >
          End Date
        </Typography>
        <input
          type="date"
          name="upto"
          disabled={!isEdit}
          value={moment(data?.upto).format("yyyy-MM-DD")}
          min={moment(data?.startFrom).format("yyyy-MM-DD")}
          max={moment(new Date()).format("YYYY-MM-DD")}
          onChange={({ target: { name, value } }) =>
            handleExpChange(name, value, index)
          }
          style={{
            height: 45,
            borderRadius: "20px",
            padding: "0 8px",
            border: "0",
          }}
        />
        {/* <OutlinedInput
            type="date"
            readOnly={!isEdit}
            name={"upto"}
            value={data?.upto ? moment(data?.upto).format("yyyy-MM-DD") : "-"}
            onChange={({ target: { name, value } }) =>
              handleExpChange(name, value, index)
            }
            sx={{
              borderRadius: 2,
              backgroundColor: "white",
              "& .MuiOutlinedInput-input": {
                borderRadius: 2,
                outline: "none",
                height: "15px",
                backgroundColor: "#fff",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: 2,
                transition: "ease-in 0.2s",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: `1px solid ${palette?.primary?.main}`,
                transition: "ease-in 0.2s",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: `2px solid ${palette?.primary?.light}`,
                transition: "ease-in 0.2s",
              },
            }}
          /> */}
      </Grid>
    </Grid>
  );
};

export default WorkExpDetailsCard;
