import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import BookAClinic from "../../components/bookachair/BookAClinic";
import MyBookings from "../../components/bookachair/MyBookings";
import BookingHistory from "../../components/bookachair/BookingHistory";

const BookChair = () => {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <>
      {/* <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "row",
            md: "row",
            lg: "row",
            xl: "row",
          },
          gap: "15px",
        }}
        justifyContent="space-between"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
              md: "row",
              lg: "row",
              xl: "row",
            },
            gap: "10px",
          }}
        >
          <Button
            onClick={() => setTabIndex(0)}
            variant={tabIndex == 0 ? "contained" : "outlined"}
            size="large"
            sx={{ borderRadius: 46 }}
          >
            Book a Clinic
          </Button>
          <Button
            onClick={() => setTabIndex(1)}
            variant={tabIndex == 1 ? "contained" : "outlined"}
            size="large"
            sx={{ borderRadius: 46 }}
          >
            My Bookings
          </Button>
          <Button
            onClick={() => setTabIndex(2)}
            variant={tabIndex == 2 ? "contained" : "outlined"}
            size="large"
            sx={{ borderRadius: 46 }}
          >
            Booking History
          </Button>
        </Box>
      </Box>
      {tabIndex === 0 && <BookAClinic />}
      {tabIndex === 1 && <MyBookings />}
      {tabIndex === 2 && <BookingHistory />} */}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "80vh" }}
      >
        <h1
          style={{
            color: "#1A202C",
            fontWeight: 600,
            fontSize: 100,
            textAlign: "center",
            margin: "0px",
            padding: "0px",
            lineHeight: "90px",
          }}
        >
          Coming
          <br /> Soon!
        </h1>
      </Box>
    </>
  );
};

export default BookChair;
