import { Box, Grid, LinearProgress, Paper, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";

import api from "../../api/index";
import overviewicon from "../../assets/images/overviewicon.png";
import DoctorDashModal from "../../components/Modals/DoctorDashModal";
import DoctorMyBookings from "../../components/Modals/DoctorMyBookings";
import ConsultationDetailsCard from "./ConsultationDetailsCard";

const DoctorDashboard = () => {
  const [isPostsLoading, setPostsLoading] = useState(true);
  const [consultations, setConsultations] = useState([]);
  const [upcomingBookings, setUpcomingBookings] = useState([]);
  const [open, setOpen] = useState(null);
  const [openView, setOpenView] = useState(false);
  const [dashboardData, setDashboardData] = useState();

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [activeBooking, setActiveBooking] = useState({});

  const getDate = (dateString) => {
    let dateObj = new Date(dateString);
    let day = dateObj.getDate();
    const monthName = dateObj.toLocaleString("en-US", { month: "short" });
    return `${day} ${monthName}`;
  };

  const handleOpen = (obj) => {
    setOpen(obj);
  };
  const handleClose = () => {
    setOpen(null);
  };
  const handleOpenView = (data) => {
    setActiveBooking(data);
    setOpenView(true);
  };
  const handleCloseView = () => {
    setOpenView(false);
  };

  const getDoctorBookingByDate = async () => {
    let payload = {
      startDate: moment(fromDate || new Date()).format("YYYY-MM-DD"),
      endDate: toDate ? moment(toDate).format("YYYY-MM-DD") : "",
    };
    const res = await api.getDoctorBookingByDate(payload);
    if (res.data.success) {
      setUpcomingBookings(res.data.data.upcomingBookingDetails);
    }
  };

  const getData = async () => {
    try {
      const currentDate = new Date();
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      const res = await api.getDoctorOverview();
      const res2 = await api.getDoctorBookingByDate(formattedDate);
      if (res.data.success) {
        setDashboardData(res.data.data);
      }
      if (res2.data.success) {
        setUpcomingBookings(res2.data.data.upcomingBookingDetails);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getDoctorBookingByDate();
  }, [toDate]);

  useEffect(() => {
    if (localStorage.getItem("auth")) {
      getData();
    }
  }, []);

  useEffect(() => {
    const getAllClinicPosts = async () => {
      await api
        .getAllPosts()
        .then((response) => {
          if (response?.data?.success) {
            setConsultations(response?.data?.data?.allClinicPosts || []);
          }
          setPostsLoading(false);
        })
        .catch((error) => {
          setPostsLoading(false);
          console.log(error);
        })
        .finally(() => {
          setPostsLoading(false);
        });
    };
    getAllClinicPosts();
  }, []);

  return (
    <>
      <DoctorDashModal
        open={open}
        handleClose={handleClose}
        getDate={getDate}
      />
      {openView ? (
        <DoctorMyBookings
          open={openView}
          handleClose={handleCloseView}
          doctor={true}
          dashboard={true}
          currentClinic={activeBooking}
        />
      ) : null}

      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={7} lg={7.5} xl={8}>
          <Typography
            variant="h3"
            sx={{
              fontSize: "22px",
              mt: 1,
              mb: 1,
              fontWeight: 600,
              color: "#004E89",
            }}
          >
            Overview
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} sx={{ borderRadius: "16px" }}>
              <Paper sx={{ p: 2, borderRadius: "16px" }}>
                <Box sx={{ mb: "0.5em" }}>
                  <img src={overviewicon} style={{ width: 44, height: 44 }} />
                </Box>
                <Typography
                  variant="h6"
                  sx={{ fontSize: "14px", fontWeight: 500 }}
                >
                  Total Clinics
                </Typography>
                <Typography
                  variant="h2"
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    fontSize: 30,
                    lineHeight: "30px",
                  }}
                >
                  {dashboardData?.totalClinic || "0"}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <Paper sx={{ p: 2, borderRadius: "16px" }}>
                <Box sx={{ mb: "0.5em" }}>
                  <img src={overviewicon} style={{ width: 44, height: 44 }} />
                </Box>
                <Typography variant="h6">Active Clinics</Typography>
                <Typography
                  variant="h2"
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    fontSize: 30,
                    lineHeight: "30px",
                  }}
                >
                  {dashboardData?.activeClinics || "0"}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <Paper sx={{ p: 2, borderRadius: "16px" }}>
                <Box sx={{ mb: "0.5em" }}>
                  <img src={overviewicon} style={{ width: 44, height: 44 }} />
                </Box>
                <Typography variant="h6">Total Jobs</Typography>
                <Typography
                  variant="h2"
                  sx={{
                    color: "black",
                    fontWeight: 500,
                    fontSize: 30,
                    lineHeight: "30px",
                  }}
                >
                  {dashboardData?.consultationRequest || "0"}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Typography variant="h3" sx={{ fontSize: "22px", mt: 2 }}>
            Consultation
          </Typography>

          {isPostsLoading ? (
            <Box>
              <LinearProgress />
            </Box>
          ) : consultations?.length ? (
            consultations?.map((consultation, index) => (
              <ConsultationDetailsCard
                consultation={consultation}
                index={index}
                getDate={getDate}
                handleOpen={handleOpen}
              />
            ))
          ) : (
            <Typography textAlign={"center"}>No records found.</Typography>
          )}
        </Grid>
        {/* <Grid item xs={12} sm={12} md={5} lg={4.5} xl={4} mt={1.5}>
          <Paper sx={{ mt: 5, ml: 1 }}>
            <Box sx={{ mb: 2 }}>
              <Typography
                variant="h3"
                sx={{
                  fontSize: "18px",
                  fontWeight: 600,
                }}
              >
                Upcoming Bookings
              </Typography>
              <Divider sx={{ mt: "0.5em" }} />
              <Calendar setFromDate={setFromDate} setToDate={setToDate} />
            </Box>
            <Box mb={1}>
              {isPostsLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <CircularProgress size={25} />
                </Box>
              ) : upcomingBookings?.length > 0 ? (
                upcomingBookings?.map((booking, index) => (
                  <UpcomingPostDetailsCard
                    booking={booking}
                    index={index}
                    handleOpenView={handleOpenView}
                  />
                ))
              ) : (
                <Typography textAlign={"center"}>No records found.</Typography>
              )}
            </Box>
          </Paper>
        </Grid> */}
      </Grid>
    </>
  );
};

export default DoctorDashboard;
