import React from "react";
import {
  Box,
  ButtonBase,
  Container,
  Typography,
  Drawer,
  useTheme,
  Tooltip,
} from "@mui/material";
import godentylogo from "../../assets/images/godentylogo.svg";
import logout from "../../assets/images/logout.svg";
import { useNavigate } from "react-router-dom";
import { navigation } from "../../utils/getSideBarRoutes";
import { setLogout } from "../../store/reducer/AuthHelper";
import { useDispatch } from "react-redux";

function DrawerComponent({ role }) {
  const {
    breakpoints,
    palette: { primary, common, text },
    shape: { borderRadius },
  } = useTheme();

  const dispatch = useDispatch();
  const Navigate = useNavigate();
  return (
    <Drawer
      variant={"permanent"}
      sx={{
        width: 300,
        border: "none",
        backgroundColor: common.white,
        "& .MuiDrawer-paper": {
          backgroundColor: common.white,
          boxSizing: "border-box",
          width: 300,
          border: "none",
          boxShadow: "none",
          overflowY: "hidden",
          overflowX: "hidden",
          display: "flex",
          alignItems: "center",
        },
      }}
    >
      <Box sx={{ mt: 5, mb: 6 }}>
        <img
          src={godentylogo}
          style={{ width: "106px", height: "57.01px" }}
          alt="logo"
        />
      </Box>

      <Box
        sx={{
          overflow: "auto",
          height: "91%",
          width: 300,
          borderRadius: "8px",
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          {navigation(role)
            ?.filter((item) => item?.visible === true)
            ?.map((value, index) => {
              return (
                <ButtonBase
                  key={index}
                  onClick={() => Navigate(value?.navigator)}
                  sx={{
                    width: 288,
                    alignItems: "center",
                    justifyContent: "flex-start",
                    gap: "1rem",
                    p: "1rem",
                    transition: "ease-in-out 0.1s",
                    borderRadius: `${borderRadius / 2}px`,
                    backgroundColor: value.isActive.includes(
                      window?.location?.pathname
                    )
                      ? "#E78756"
                      : "",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <img
                      alt={value?.icon}
                      src={
                        value.isActive.includes(window?.location?.pathname)
                          ? value.activeicon
                          : value.inactiveicon
                      }
                      style={{ width: "22px" }}
                    />
                    <Typography
                      //  variant="h6"
                      sx={{
                        color: value.isActive.includes(
                          window?.location?.pathname
                        )
                          ? "white"
                          : "#004E89",
                        fontSize: "16px",
                        fontWeight: 500,
                        lineHeight: "24px",
                        marginLeft: "4px",
                      }}
                    >
                      {value?.name}
                    </Typography>
                  </div>
                </ButtonBase>
              );
            })}
        </Container>
      </Box>

      <Box>
        <ButtonBase
          onClick={() => {
            dispatch(setLogout());
            let logout_role = localStorage.getItem("role");
            localStorage.removeItem("auth");
            localStorage.removeItem("role");
            localStorage.removeItem("isLoggedIn");
            if (logout_role != 1) {
              window.location.href =
                process.env.NEXT_PUBLIC_LANDING_FRONTEND_BASE_URL;
            } else {
              window.location.href = process.env.REACT_APP_FRONTEND_BASE_URL;
            }
          }}
          sx={{
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "20px",
            width: 288,
            borderRadius: 24,
            height: 64,
          }}
        >
          <img src={logout} style={{ width: 24, height: 24 }} />
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 16,
              color: "#004E89",
              marginLeft: 3,
            }}
          >
            Logout
          </Typography>
          {/* <ChevronRightRounded /> */}
        </ButtonBase>
      </Box>
    </Drawer>
  );
}

export default DrawerComponent;
