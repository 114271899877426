import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  OutlinedInput,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import api from "../../api";
import profileicon from "../../assets/images/avatar-placeholder.png";
import editicon from "../../assets/images/editicon.png";

const EditSettings = () => {
  const Navigate = useNavigate();
  const [formData, setFormData] = useState({
    userId: "",
    name: "",
    phoneNumber: "",
    email: "",
    imageUrl: "",
  });
  const { palette } = useTheme();
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const addFormData = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const uploadImg = async (e) => {
    let fileName = e.target.files[0].name;
    let file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png"];

    if (!allowedTypes.includes(file.type)) {
      toast.error("Please select a JPG, JPEG or PNG file.");
      return;
    }
    let awsSUrl = `https://godenty-backend-docs.s3.ap-south-2.amazonaws.com/docs/${fileName}`;

    let response = await fetch(awsSUrl, {
      method: "PUT",
      body: file,
    });

    if (response.ok) {
      setFormData({ ...formData, imageUrl: response?.url });
    }
  };

  const updateProfile = async () => {
    try {
      if (emailError || phoneNumberError) {
        toast.error("Please Enter valid email or phone number.");
        return;
      }
      let payload = formData;

      delete payload["userId"];

      const { data } = await api.updateProfile(payload);
      if (!data?.success) {
        return;
      }
      Navigate("/settings");
    } catch (err) {
      console.log(err);
    }
  };

  const getProfile = async () => {
    await api
      .getProfile()
      .then((res) => {
        if (!res?.data?.success) {
          return;
        }
        if (res.data.success) {
          setFormData({
            userId: res?.data?.data?.user?.id || "",
            name: res?.data?.data?.user?.name || "",
            phoneNumber: res?.data?.data?.user?.phoneNumber || "",
            email: res?.data?.data?.user?.email || "",
            imageUrl: res?.data?.data?.user?.imageUrl || "",
          });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };

  useEffect(() => {
    getProfile();
  }, []);

  const handlePhoneNumberChange = (e) => {
    const phoneNumber = e.target.value;
    setFormData((prev) => ({
      ...prev,
      phoneNumber,
    }));
    // Check if phone number is exactly 10 digits
    if (phoneNumber.length !== 10) {
      setPhoneNumberError(true);
    } else {
      setPhoneNumberError(false);
    }
  };
  const handleEmailChange = (e) => {
    const email = e.target.value;
    setFormData((prev) => ({
      ...prev,
      email,
    }));
    // Email validation using regex
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setEmailError(!emailPattern.test(email));
  };
  return (
    <>
      <Grid container spacing={1} sx={{ mt: 2 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography
            sx={{ fontSize: "24px", fontWeight: 600, color: "#303030" }}
          >
            Profile
          </Typography>
        </Grid>
      </Grid>

      <Paper sx={{ p: "1.8em", borderRadius: 2, mt: 3, maxWidth: "1090px" }}>
        <Box
          sx={{
            position: "relative",
            width: "100px",
          }}
        >
          <Box
            sx={{
              mb: 3,
              width: "100px",
              height: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "100%",
              overflow: "hidden",
            }}
          >
            <img
              src={formData.imageUrl || profileicon}
              style={{ width: 100, height: "inherit", objectFit: "cover" }}
            />
          </Box>
          <Box>
            <label id="avatar-inp-label" htmlFor="avatar-inp">
              <img
                src={editicon}
                style={{
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  width: 34,
                  height: 34,
                }}
              />
            </label>
            <TextField
              sx={{
                display: "none",
              }}
              id="avatar-inp"
              type="file"
              accept=".jpg, .jpeg, .png"
              onChange={uploadImg}
            />
          </Box>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={1} justifyContent="space-between">
              {/* <Grid
                item
                display="flex"
                flexDirection="column"
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={5.5}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#303030",
                    mb: 1,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  User Id
                </Typography>
                <OutlinedInput
                  name={"userId"}
                  readOnly
                  value={formData?.userId}
                  sx={{
                    borderRadius: 2,
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-input": {
                      borderRadius: 2,
                      // border: "none",
                      outline: "none",
                      height: "15px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      transition: "ease-in 0.2s",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: `1px solid ${palette?.primary?.main}`,
                      transition: "ease-in 0.2s",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: `2px solid ${palette?.primary?.light}`,
                      transition: "ease-in 0.2s",
                    },
                  }}
                />
              </Grid> */}
               <Grid
                item
                display="flex"
                flexDirection="column"
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={5.5}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#303030",
                    mb: 1,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Email
                </Typography>
                <TextField
                  name={"email"}
                  type="email"
                  onChange={handleEmailChange}
                  value={formData?.email}
                  error={emailError} // Apply error state to TextField
                  helperText={emailError ? "Invalid email format" : ""}
                  inputProps={{ maxLength: 40 }}
                  sx={{
                    borderRadius: 2,
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-input": {
                      borderRadius: 2,
                      outline: "none",
                      height: "15px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      transition: "ease-in 0.2s",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: `1px solid ${palette?.primary?.main}`,
                      transition: "ease-in 0.2s",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: `2px solid ${palette?.primary?.light}`,
                      transition: "ease-in 0.2s",
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                display="flex"
                flexDirection="column"
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={5.5}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#303030",
                    mb: 1,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Name
                </Typography>
                <OutlinedInput
                  name={"name"}
                  onChange={addFormData}
                  value={formData?.name}
                  inputProps={{ maxLength: 40 }}
                  sx={{
                    borderRadius: 2,
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-input": {
                      borderRadius: 2,
                      // border: "none",
                      outline: "none",
                      height: "15px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      transition: "ease-in 0.2s",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: `1px solid ${palette?.primary?.main}`,
                      transition: "ease-in 0.2s",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: `2px solid ${palette?.primary?.light}`,
                      transition: "ease-in 0.2s",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={1} justifyContent="space-between">
             
              <Grid
                item
                display="flex"
                flexDirection="column"
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={5.5}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#303030",
                    mb: 1,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Phone Number
                </Typography>
                {/* <OutlinedInput
                  inputProps={{
                    maxLength: 10,
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                  name={"phoneNumber"}
                  value={formData?.phoneNumber}
                  onChange={addFormData}
                  // type="number"
                  onInput={(e) => {
                    const target = e?.target;
                    target.value = e?.target?.value.replace(/[^0-9]/g, "");
                  }}
                  sx={{
                    borderRadius: 2,
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-input": {
                      borderRadius: 2,
                      // border: "none",
                      outline: "none",
                      height: "15px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      transition: "ease-in 0.2s",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: `1px solid ${palette?.primary?.main}`,
                      transition: "ease-in 0.2s",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: `2px solid ${palette?.primary?.light}`,
                      transition: "ease-in 0.2s",
                    },
                  }}
                /> */}
                <TextField
                  name={"phoneNumber"}
                  value={formData?.phoneNumber}
                  inputProps={{
                    maxLength: 10,
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                  onInput={(e) => {
                    const target = e?.target;
                    target.value = e?.target?.value.replace(/[^0-9]/g, "");
                  }}
                  onChange={handlePhoneNumberChange}
                  error={phoneNumberError}
                  helperText={
                    phoneNumberError ? "Phone number must be 10 digits" : ""
                  }
                  sx={{
                    borderRadius: 2,
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-input": {
                      borderRadius: 2,
                      outline: "none",
                      height: "15px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      transition: "ease-in 0.2s",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: `1px solid ${palette?.primary?.main}`,
                      transition: "ease-in 0.2s",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: `2px solid ${palette?.primary?.light}`,
                      transition: "ease-in 0.2s",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid
              container
              spacing={1}
              justifyContent="space-between"
              sx={{ mt: "1em" }}
            >
              <Grid
                item
                display="flex"
                flexDirection="column"
                xs={12}
                sm={4}
                md={4}
                lg={4}
                xl={4}
              >
                <Button
                  variant="contained"
                  size="small"
                  fullWidth={false}
                  onClick={updateProfile}
                  sx={{
                    width: 120,
                    height: 50,
                    borderRadius: 2,
                    padding: "18px 30px 18px 30px",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Save
                </Button>
              </Grid>
              <Grid
                item
                display="flex"
                flexDirection="column"
                xs={12}
                sm={8}
                md={8}
                lg={8}
                xl={8}
              >
                <Link
                  to="/settings"
                  style={{
                    color: "#FF7676",
                    fontWeight: 600,
                    fontSize: 16,
                    cursor: "pointer",
                    marginLeft: "1em",
                    marginTop: "1em",
                    fontFamily: "poppins",
                  }}
                >
                  Cancel
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default EditSettings;
