import { Box, Button } from "@mui/material";
import React, { useState } from "react";

import ConsultationHistory from "../../components/consultation/ConsultationHistory";
import ConsultationReq from "../../components/consultation/ConsultationReq";
import MyConsultations from "../../components/consultation/MyConsultations";
import SentProposals from "../../components/consultation/SentProposals";

const Consultation = () => {
  const [tabIndex, setTabIndex] = useState(0);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
            lg: "row",
            xl: "row",
          },

          gap: "15px",
        }}
        justifyContent="space-between"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            gap: 1,
          }}
        >
          <Button
            onClick={() => setTabIndex(0)}
            variant={tabIndex == 0 ? "contained" : "outlined"}
            size="medium"
            sx={{ borderRadius: 46 }}
          >
            New Requests
          </Button>
          <Button
            onClick={() => setTabIndex(1)}
            variant={tabIndex == 1 ? "contained" : "outlined"}
            size="medium"
            sx={{ borderRadius: 46 }}
          >
            Proposals
          </Button>
          <Button
            onClick={() => setTabIndex(2)}
            variant={tabIndex == 2 ? "contained" : "outlined"}
            size="medium"
            sx={{ borderRadius: 46 }}
          >
            Consultations
          </Button>
          <Button
            onClick={() => setTabIndex(3)}
            variant={tabIndex == 3 ? "contained" : "outlined"}
            size="medium"
            sx={{ borderRadius: 46 }}
          >
            History
          </Button>
        </Box>
      </Box>
      {tabIndex === 0 && <ConsultationReq />}
      {tabIndex === 1 && <SentProposals />}
      {tabIndex === 2 && <MyConsultations />}
      {tabIndex === 3 && <ConsultationHistory />}
    </>
  );
};

export default Consultation;
