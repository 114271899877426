import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useState } from "react";
import HTMLRenderer from "react-html-renderer";

import api from "../../api/index";
import clockicon from "../../assets/images/clockicon.png";
import doctorphone from "../../assets/images/doctorphone.png";
import location from "../../assets/images/location.png";
import staricon from "../../assets/images/staricon.png";
import { formatTimeSlots } from "../../utils/formatTimeSlots";
import { getPayType } from "../../utils/getPayType";
import getStringInitials from "../../utils/getStringInitials";
import SentSuccessfully from "./SentSuccessfully";

const DoctorDashModal = ({ handleClose, open, getDate }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const handleCloseApprove = () => {
    setModalOpen(false);
    handleClose();
  };

  const handleSendProposal = async () => {
    setLoading(true);
    try {
      const res = await api.createProposal({ _post: open?.id });
      setLoading(false);
      if (res?.data?.success) {
        setModalOpen(true);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <>
      {modalOpen ? (
        <SentSuccessfully
          modal={modalOpen}
          handleCloseApprove={handleCloseApprove}
          value={"proposal"}
        />
      ) : (
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={Boolean(open)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle
            sx={{ m: 0, p: 2, fontWeight: 600, fontSize: 20, color: "#F98247" }}
            id="customized-dialog-title"
          >
            {open?.caseName || "-"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ p: 1.5 }}>
            <Grid display={"flex"} flexDirection={"column"} gap={3}>
              <Grid item display={"flex"} gap={1} alignItems="center">
                {open?.clinicOwner?.imageUrl ? (
                  <Box
                    sx={{
                      height: 60,
                      width: 60,
                      overflow: "hidden",
                      borderRadius: "8px",
                    }}
                  >
                    <img
                      src={open?.clinicOwner?.imageUrl}
                      style={{ width: "100%", objectFit: "cover" }}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      height: 60,
                      width: 60,
                      overflow: "hidden",
                      borderRadius: "8px",
                      backgroundColor: "#004E89",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#fff",
                        fontSize: 22,
                        fontWeight: 600,
                      }}
                    >
                      {getStringInitials(
                        open?.clinicDetails?.clinicName || "-"
                      )}
                    </Typography>
                  </Box>
                )}
                <Box
                  display="flex"
                  sx={{
                    flexDirection: {
                      xs: "column",
                      md: "column",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      color: "#004E89",
                      fontSize: 18,
                      fontWeight: 600,
                    }}
                  >
                    {open?.clinicDetails?.clinicName}
                  </Typography>
                </Box>
              </Grid>

              <Grid container justifyContent={"space-between"} rowGap={2}>
                <Grid display={"flex"} alignItems={"center"} gap={1}>
                  <img
                    src={doctorphone}
                    alt="phone"
                    style={{ width: 30, height: 30 }}
                  />
                  <Box>
                    <Typography
                      sx={{ fontSize: 14, fontWeight: "400", color: "#858585" }}
                    >
                      Contact no
                    </Typography>
                    <Typography
                      sx={{ fontSize: 14, fontWeight: "500", color: "#303030" }}
                    >
                      {open?.clinicOwner?.phoneNumber}
                    </Typography>
                  </Box>
                </Grid>

                <Grid display={"flex"} alignItems={"center"} gap={1}>
                  <img
                    src={location}
                    style={{ width: 30, height: 30 }}
                    alt="loc"
                  />
                  <Box>
                    <Typography
                      sx={{ fontSize: 14, fontWeight: "400", color: "#858585" }}
                    >
                      Location
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 14,
                        fontWeight: "500",
                        color: "#303030",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.open(
                          open?.clinicDetails?.googleMapLocation,
                          "_blank"
                        );
                      }}
                    >
                      {open?.clinicDetails?.address}
                    </Typography>
                  </Box>
                </Grid>

                <Grid display={"flex"} alignItems={"center"} gap={1}>
                  <img
                    src={clockicon}
                    style={{ width: 30, height: 30 }}
                    alt="clock"
                  />
                  <Box>
                    <Typography
                      sx={{ fontSize: 14, fontWeight: "400", color: "#858585" }}
                    >
                      Date & Time
                    </Typography>
                    <Typography
                      sx={{ fontSize: 14, fontWeight: "500", color: "#303030" }}
                    >
                      {getDate(open?.date)},{" "}
                      {formatTimeSlots({
                        startTime: open?.startTime,
                        endTime: open?.endTime,
                      })}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                flexDirection="row"
                alignItems="center"
              >
                <Grid
                  item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img src={staricon} style={{ width: 13, height: 13 }} />
                  <Typography
                    sx={{
                      color: "#F98247",
                      fontSize: 13,
                      fontWeight: 600,
                      ml: 1,
                    }}
                  >
                    {open?.requestType}
                  </Typography>
                </Grid>

                <Grid
                  item
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img src={staricon} style={{ width: 13, height: 13 }} />
                  <Typography
                    sx={{
                      color: "#F98247",
                      fontSize: 13,
                      fontWeight: 600,
                      ml: 1,
                    }}
                  >
                    {getPayType({
                      requestType: open?.requestType,
                      minSalary: open?.minimumSalary,
                      maxSalary: open?.maximumSalary,
                      hourly: open?.hourly,
                    })}
                  </Typography>
                </Grid>

                <Grid item>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {open?.skills && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 0.5,
                        }}
                      >
                        <img src={staricon} style={{ width: 13, height: 13 }} />
                        <Typography
                          title={open?.skills
                            ?.split(",")
                            .join(", ")
                            .toLowerCase()}
                          sx={{
                            color: "#F98247",
                            fontSize: "13px",
                            fontWeight: 600,
                            textTransform: "capitalize",
                          }}
                        >
                          {open?.skills?.split(",").join(", ").toLowerCase()}
                        </Typography>
                      </Box>
                    )}{" "}
                  </Box>
                </Grid>
              </Grid>

              <Grid>
                <Typography variant="h3" sx={{ fontSize: "20px" }}>
                  Case Location
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    color: "rgba(118, 121, 128, 1)",
                    textTransform: "capitalize",
                    cursor: "pointer",
                  }}
                  onClick={() => window.open(open?.locationUrl, "_blank")}
                >
                  {open?.locationName?.toLowerCase() || "-"}
                </Typography>
              </Grid>

              <Grid container spacing={1} flexDirection="column">
                <Grid item>
                  <Typography variant="h3" sx={{ fontSize: "20px" }}>
                    Case Description
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: "rgba(118, 121, 128, 1)",
                      minHeight: "50px",
                      maxHeight: "150px",
                      overflowY: "auto",
                    }}
                  >
                    <HTMLRenderer
                      html={open?.caseDescription || "<p>...</p>"}
                    />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions
            sx={{
              justifyContent: {
                xs: "center",
                md: "flex-start",
              },
              mt: 1,
            }}
          >
            <Button
              variant="contained"
              size="small"
              sx={{
                backgroundColor: "#F98247",
                borderRadius: "30px",
                color: "white",
                fontSize: 13,
                fontWeight: 300,
                minWidth: "160px",
                "&:hover": { backgroundColor: "#F98247" },
              }}
              disabled={isLoading}
              onClick={handleSendProposal}
            >
              {isLoading ? <CircularProgress size={15} /> : "Send Proposal"}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};
export default DoctorDashModal;
