import { CircularProgress } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import api from "../../api";
import goDentyLogo from "../../assets/images/godentyImage.png";
import { emailValidation } from "../../components/validations/validations";
import { setLogin } from "../../store/reducer/AuthHelper";
import ForgotPasswordComponent1 from "./ForgotPasswordComponent1";

const AdminLogin = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [inputErrors, setInputErrors] = useState({ email: "", password: "" });
  const [isLoading, setLoading] = useState(false);

  const [showForgetPasswordComponent, setForgetPasswordComponent] =
    useState(false);
  const [errormsg, setErrorMsg] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const getLogin = async (e) => {
    e.preventDefault();
    if (!formData.email || !formData.password) {
      setInputErrors({
        email: formData.email ? "" : "Please enter a valid email",
        password: formData.password ? "" : "Please enter a password",
      });
      return;
    }
    const emailValidationResp = await emailValidation(formData.email);
    if (!emailValidationResp) {
      setInputErrors({
        ...inputErrors,
        email: "Invalid email",
      });
      return;
    }
    const params = {
      email: formData.email,
      password: formData.password,
    };
    try {
      setLoading(true);
      const res = await api.adminLogin(params);
      setLoading(false);
      if (res.data.success) {
        localStorage.setItem("auth", JSON.stringify(res.data.data.token));
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("role", res?.data?.data?.userData?._role);
        dispatch(setLogin({ isAuth: true }));
        setFormData({});
      } else {
        console.error(
          "Error during SignUp:",
          res.data.message || "Unknown error"
        );
        setErrorMsg({
          ...errormsg,
          email: res.data.message || "Unknown error",
        });
      }
    } catch (error) {
      setLoading(false);
      if (error.response && error.response.status === 404) {
        const errorMessage = error.response.data.message;
        setErrorMsg({ ...errormsg, email: errorMessage });
      }
      if (error.response && error.response.status === 400) {
        const errormessage = error.response.data.message;
        setPasswordError({ ...inputErrors, password: errormessage });
      }
      console.error("Error during SignUp:", error.message || "Unknown error");
    }
  };

  const addFormData = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setInputErrors({ ...inputErrors, [name]: "" });
    if (name === "email") {
      setErrorMsg({ ...errormsg, email: "" });
    }
    if (name === "password") {
      setPasswordError({ ...passwordError, password: "" });
    }
  };

  return (
    <div style={{ height: "100vh", width: "100vw", display: "flex" }}>
      <div className="SignUpPage-leftSidecontainer">
        <Link to="/" style={{ textAlign: "center" }}>
          <img src={goDentyLogo} alt="goDentyLogo" style={{ width: "35%" }} />
        </Link>
        <h3 style={{ color: "white", marginTop: "auto" }}>
          Unlock dental career possibilities with us – where hiring meets
          convenience.
        </h3>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          overflow: "auto",
        }}
      >
        {!showForgetPasswordComponent ? (
          <form onSubmit={getLogin} className="signUp-formcontainer">
            <h1 style={{ textAlign: "center" }}>Login</h1>
            <h4>👋 Welcome to Godenty </h4>
            <p style={{ color: "rgba(190, 190, 190, 1)" }}>
              Access your account by logging in.
            </p>
            <input
              style={{ marginTop: "15px" }}
              className="signUp-formcontainer-input"
              name="email"
              type="text"
              onChange={addFormData}
              value={formData.email || ""}
              placeholder="Enter Registered Email  "
            />
            {inputErrors.email && (
              <span className="errorText">{inputErrors.email}</span>
            )}
            {errormsg.email && (
              <span className="errorText">{errormsg.email}</span>
            )}
            <input
              style={{ marginTop: "15px" }}
              className="signUp-formcontainer-input"
              type="password"
              name="password"
              value={formData.password || ""}
              onChange={addFormData}
              placeholder="Enter Password  "
            />
            {inputErrors.password && (
              <span className="errorText">{inputErrors.password}</span>
            )}
            {passwordError.password && (
              <span className="errorText">{passwordError.password}</span>
            )}
            <button
              disabled={isLoading}
              type="submit"
              style={{
                width: "100%",
                borderRadius: "12px",
                cursor: "pointer",
                border: "none",
                backgroundColor: "rgba(0, 121, 191, 1)",
                color: "white",
                marginTop: "15px",
                marginBottom: "15px",
                height: "45px",
              }}
            >
              {isLoading ? (
                <CircularProgress sx={{ color: "#fff" }} size={20} />
              ) : (
                "Login"
              )}
            </button>
            <div
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "end",
                flexWrap: "wrap",
                alignItems: "center",
                fontWeight: "500",
              }}
            >
              <span
                style={{
                  color: "rgba(55, 159, 255, 1)",
                  cursor: "pointer",
                  textAlign: "center",
                }}
                onClick={() => setForgetPasswordComponent(true)}
              >
                Forgot password ?
              </span>
            </div>
          </form>
        ) : (
          <ForgotPasswordComponent1
            setForgetPasswordComponent={setForgetPasswordComponent}
          />
        )}
      </div>
    </div>
  );
};

export default AdminLogin;
