import { configureStore } from "@reduxjs/toolkit";
import AuthHelper from "./reducer/AuthHelper";
import Doctor from "./reducer/Doctor";
import Clinic from "./reducer/Clinic";

export const store = configureStore({
  reducer: {
    authhelper: AuthHelper,
    doctor: Doctor,
    clinic: Clinic
  },
});
