import moment from "moment";
export const formatTimeSlots = (slot) => {
  let str;
  str = `${
    slot?.startTime
      ? moment(slot?.startTime, "hh:mm:ss").format("hh:mm A")
      : "-"
  } : ${
    slot?.endTime ? moment(slot?.endTime, "hh:mm:ss").format("hh:mm A") : "-"
  }`;
  return str;
};
