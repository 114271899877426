import React, { Fragment, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  ButtonBase,
  Container,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme,
  Hidden,
  IconButton,
  createTheme,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import godentylogo from "../../assets/images/godentylogo.svg";
import logout from "../../assets/images/logout.svg";
import { useDispatch } from "react-redux";
import { setLogout } from "../../store/reducer/AuthHelper";
import Navbar from "./NavBar";
import DrawerComponent from "./DrawerComponent";
import { navigation } from "../../utils/getSideBarRoutes";

const Sidebar1 = ({ role, designation }) => {
  const dispatch = useDispatch();

  const params = useParams();
  const Navigate = useNavigate();
  const [openSidebar, setOpenSideBar] = useState(false);
  const handleSidebarOpen = () => {
    setOpenSideBar(true);
  };

  const handleSidebarClose = () => {
    setOpenSideBar(false);
  };
  const {
    breakpoints,
    palette: { primary, common, text },
    shape: { borderRadius },
  } = useTheme();

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1000,
        lg: 1200,
        xl: 1536,
      },
    },
  });

  const lg = useMediaQuery(theme.breakpoints.up("lg"));
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const xs = useMediaQuery(theme.breakpoints.only("xs"));

  const getDrawerWidth = () => {
    if (lg) return "40%";
    if (md) return "40%";
    if (sm) return "50%";
    if (xs) return "80%";
    return "50%";
  };

  return (
    <Fragment>
      {!md ? (
        <>
          <Navbar
            role={role}
            onSidebarOpen={handleSidebarOpen}
            designation={designation}
          />
          <SwipeableDrawer
            open={openSidebar}
            onOpen={handleSidebarOpen}
            onClose={handleSidebarClose}
            disableBackdropTransition={!md}
            disableDiscovery={!md}
            PaperProps={{
              style: {
                width: getDrawerWidth(),
                //width: "100%",
              },
            }}
          >
            <Hidden mdUp>
              <IconButton color="black" onClick={handleSidebarClose}>
                <CloseIcon />
              </IconButton>
            </Hidden>
            <Box
              sx={{ mt: 5, mb: 6, display: "flex", justifyContent: "center" }}
            >
              <img
                src={godentylogo}
                style={{ width: "106px", height: "57.01px" }}
                alt="logo"
              />
            </Box>
            <Box sx={{ overflow: "auto", height: "91%" }}>
              <Container
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                {navigation(role, designation)
                  ?.filter((item) => item?.visible === true)
                  ?.map((value, index) => {
                    return (
                      <ButtonBase
                        key={index}
                        onClick={() => {
                          setOpenSideBar(false);
                          Navigate(value?.navigator);
                        }}
                        onKeyDown={handleSidebarClose}
                        sx={{
                          width: 288,
                          alignItems: "center",
                          justifyContent: "flex-start",
                          gap: "10px",
                          p: "12px",
                          transition: "ease-in-out 0.1s",
                          borderRadius: `${borderRadius / 2}px`,
                          backgroundColor: value.isActive.includes(
                            window?.location?.pathname
                          )
                            ? "#E78756"
                            : "",
                        }}
                      >
                        <img
                          alt={value?.icon}
                          src={
                            value.isActive.includes(window?.location?.pathname)
                              ? value.activeicon
                              : value.inactiveicon
                          }
                          style={{ width: "22px" }}
                        />
                        <Typography
                          variant="h6"
                          sx={{
                            color: value.isActive.includes(
                              window?.location?.pathname
                            )
                              ? "white"
                              : "#004E89",
                          }}
                        >
                          {value?.name}
                        </Typography>
                      </ButtonBase>
                    );
                  })}
              </Container>
            </Box>
            <Box>
              <ButtonBase
                onClick={() => {
                  dispatch(setLogout());
                  localStorage.removeItem("auth");
                  localStorage.removeItem("role");
                  localStorage.removeItem("isLoggedIn");
                }}
                sx={{
                  justifyContent: "flex-start",
                  alignItems: "center",
                  padding: "20px",
                  width: 288,
                  borderRadius: 24,
                  height: 64,
                }}
              >
                <img src={logout} style={{ width: 24, height: 24 }} />
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 16,
                    color: "#004E89",
                    marginLeft: 3,
                  }}
                >
                  Logout
                </Typography>
              </ButtonBase>
            </Box>
          </SwipeableDrawer>
        </>
      ) : (
        <DrawerComponent role={role} /> //This is the drawer component with logout for mobile views
      )}
    </Fragment>
  );
};

export default Sidebar1;
