import { Box, Button, Chip, Divider, Grid, Typography } from "@mui/material";
import moment from "moment";
import React from "react";

import markerPin from "../../assets/images/marker-pin.png";
import { getPayType } from "../../utils/getPayType";
import getStringInitials from "../../utils/getStringInitials";

const MyProposalDetailsCard = ({
  consultation = {},
  handleOpen = null,
  index = "",
}) => {
  return (
    <>
      {consultation?.id && (
        <Grid
          key={index}
          sx={{
            py: 1.5,
            px: 1.5,
            display: "flex",
            gap: 1,
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#fff",
            borderRadius: "8px",
          }}
        >
          <Grid
            display={"flex"}
            alignItems={"center"}
            gap={1}
            flexWrap={"wrap"}
          >
            {consultation?.clinic?.imageUrl ? (
              <Box display={"flex"} alignItems={"center"}>
                <img
                  src={consultation?.clinic?.imageUrl}
                  style={{
                    height: "46px",
                    width: "46px",
                    borderRadius: "5px",
                    objectFit: "cover",
                  }}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  width: 46,
                  height: 46,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#004E89",
                  borderRadius: "5px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: 20,
                    color: "#fff",
                  }}
                >
                  {getStringInitials(
                    consultation?.postDetails?.requestType == "CONSULTATION"
                      ? consultation?.postDetails?.caseName || "-"
                      : consultation?.postDetails?.jobTitle || "-"
                  ) || "-"}
                </Typography>
              </Box>
            )}

            <Box>
              <Box>
                <Typography
                  noWrap
                  sx={{
                    color: "#004E89",
                    fontSize: "18px",
                    fontWeight: 600,
                    fontFamily: "Poppins",
                    width: "130px",
                  }}
                  title={consultation?.postDetails?.caseName || "-"}
                >
                  {consultation?.postDetails?.caseName}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    color: "21.885px",
                    fontSize: "9.708px",
                    fontWeight: 400,
                  }}
                >
                  {moment(consultation?.postDetails?.date).format(
                    "DD MMM, YYYY"
                  )}
                </Typography>
              </Box>
              <Box
                onClick={() => {
                  if (consultation?.postDetails?.locationUrl) {
                    window.open(
                      consultation?.postDetails?.locationUrl,
                      "_blank"
                    );
                  }
                }}
              >
                <Typography
                  sx={{
                    color: "#1A202C",
                    fontSize: "13px",
                    fontWeight: 600,
                    display: "flex",
                    alignItems: "center",
                    color: "#E78756",
                    cursor: "pointer",
                  }}
                >
                  <img src={markerPin} height={15} />
                  Direction
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                },
                gap: 0.5,
                position: "relative",
              }}
            >
              <Box>
                <Chip
                  sx={{
                    borderRadius: "3.43px",
                    border: "none",
                    backgroundColor: "rgba(248, 248, 248, 1)",
                    minWidth: "140px",
                  }}
                  label={
                    <Typography
                      sx={{
                        color: "#F98247",
                        fontSize: "12px",
                        fontWeight: "600",
                        fontFamily: "Poppins",
                      }}
                    >
                      {consultation?.postDetails?.requestType}
                    </Typography>
                  }
                />
              </Box>
              <Box>
                <Chip
                  sx={{
                    borderRadius: "3.43px",
                    border: "none",
                    backgroundColor: "rgba(248, 248, 248, 1)",
                    width: "140px",
                  }}
                  title={getPayType({
                    requestType: consultation?.postDetails?.requestType,
                    minSalary: consultation?.postDetails?.minimumSalary,
                    maxSalary: consultation?.postDetails?.maximumSalary,
                    hourly: consultation?.postDetails?.hourly,
                  })}
                  label={
                    <Typography
                      sx={{
                        color: "#F98247",
                        fontSize: "12px",
                        fontWeight: "600",
                        fontFamily: "Poppins",
                      }}
                      noWrap
                    >
                      {getPayType({
                        requestType: consultation?.postDetails?.requestType,
                        minSalary: consultation?.postDetails?.minimumSalary,
                        maxSalary: consultation?.postDetails?.maximumSalary,
                        hourly: consultation?.postDetails?.hourly,
                      })}
                    </Typography>
                  }
                />
              </Box>
            </Box>
          </Grid>
          <Divider
            color={"#F98247"}
            width={"0.47px"}
            orientation="vertical"
            variant="middle"
            flexItem
            sx={{ mx: 1 }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
            }}
          >
            <Button
              size="small"
              sx={{
                fontSize: "13px",
                fontWeight: 600,
                color: "#fff",
                backgroundColor: "#E78756",
                borderRadius: "8px",
                "&:hover": { backgroundColor: "#E78756" },
              }}
              onClick={() => {
                handleOpen(consultation);
              }}
            >
              View
            </Button>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default MyProposalDetailsCard;
