import { Box, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import React, { useEffect, useState } from "react";

import api from "../../api";
import DateRangeCalendar from "../../components/calendar/DateRangeCalendar";
import { styles } from "../../layouts/Styles";

const ClinicTransaction = () => {
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [transactions, setTransactions] = useState([]);

  const setStartDate = (d) => {
    setSelectedStartDate(
      moment(d?.$d, "ddd MMM DD YYYY HH:mm:ss [GMT]Z").format("YYYY-MM-DD")
    );
  };

  const getTransactions = async () => {
    await api
      .getTransactions(selectedStartDate, selectedEndDate)
      .then((res) => {
        if (res?.data?.success) {
          // setDoctorCount(res?.data?.data?.totalPendingDoctors);
          console.log("Line 34", res?.data);
          setTransactions(res?.data?.data);
        }
      });
  };

  useEffect(() => {
    // console.log('Line 44 CALLED')
    getTransactions();
  }, [selectedEndDate]);

  const setEndDate = (d) => {
    setSelectedEndDate(
      moment(d?.$d, "ddd MMM DD YYYY HH:mm:ss [GMT]Z").format("YYYY-MM-DD")
    );
  };

  const {
    palette: { grey },
  } = useTheme();
  const classes = styles();

  let transactiondata = [
    {
      id: 1,
      Name: "Martin Joy",
      Amount: "₹2000",
      "Date & Time": "3Jan, 10:00AM",
      status: "Credited",
    },
    {
      id: 1,
      Name: "Martin Joy",
      Amount: "₹2000",
      "Date & Time": "3Jan, 10:00AM",
      status: "Debited",
    },
  ];
  const getColorStyle = (status) => {
    status = status?.toLowerCase();

    switch (status) {
      case "credited":
        return "credited";
      case "debited":
        return "debited";
      default:
        return "tableCellBody";
    }
  };

  const columns = Object.keys(transactiondata[0]);

  const CustomizedStaticDatePicker = styled(DatePicker)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
      width: 320,
    },
  }));
  const DownArrowIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path d="M7 10l5 5 5-5z" />
    </svg>
  );

  const UpArrowIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <path d="M7 14l5-5 5 5z" />
    </svg>
  );
  return (
    <>
      <Grid Container>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{
              flexDirection: {
                xs: "column",
                sm: "row",
                md: "row",
                lg: "row",
                xl: "row",
              },
              alignItems: {
                xs: "start",
                sm: "center",
                md: "center",
                lg: "center",
                xl: "center",
              },
            }}
          >
            <Box>
              <Typography
                variant="h3"
                sx={{ fontSize: "22px", mt: "1em", mb: "1em" }}
              >
                Transaction
              </Typography>
            </Box>
            <Box>
              <DateRangeCalendar
                setStartDate={setStartDate}
                setEndDate={setEndDate}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper sx={{ p: "1.5em", mt: 2 }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column} className={classes.tableCell}>
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions?.length ? (
                    transactions.map((row, index) => (
                      <TableRow key={index}>
                        <TableCell className={classes.tableCellBody}>
                          {row?.id}
                        </TableCell>
                        <TableCell
                          className={[classes.tableCellBody]}
                          style={{ color: "#004E89" }}
                        >
                          {/* {row?.userDetails?.name?.charAt(0).toUpperCase() +
                            row?.userDetails?.name?.slice(1)} */}
                            {row?.userDetails?.name || '-'}
                        </TableCell>
                        <TableCell className={classes.tableCellBody}>
                          ₹ {row?.amount}
                        </TableCell>
                        <TableCell className={classes.tableCellBody}>
                          {moment(row?.proposalDetails?.date).format(
                            "DD MMM, h:mmA"
                          )}
                        </TableCell>
                        <TableCell
                          className={classes[getColorStyle(row["status"])]}
                          style={{
                            color:
                              localStorage.getItem("userId") ===
                              row?.userDetails?._receiver
                                ? "#00A01B"
                                : "#ED3838",
                          }}
                        >
                          {localStorage.getItem("userId") ===
                          row?.userDetails?._receiver
                            ? "Credited"
                            : "Debited"}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        <Typography variant="h6" sx={{ fontSize: "22px" }}>
                          No Transactions Found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ClinicTransaction;
