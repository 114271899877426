import { Box, Button, Grid, OutlinedInput, Paper, TextField, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import api from "../../api";
import AvatarIcon from "../../assets/images/avatar-placeholder.png";
import editicon from "../../assets/images/editicon.png";
import DocumentViewer from "../../components/DocumentViewer/DocView";

const EditSettingsClinic = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    clinicName: "",
    phoneNumber: "",
    website: "",
    address: "",
    googleMapLocation: "",
    stateCouncilNo: ""
  });

  const [profileURL, setProfileURL] = useState("");
  const [websiteUrlError, setWebsiteUrlError] = useState("");
  const [locationUrlError, setLocationUrlError] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const { palette } = useTheme();
  const Navigate = useNavigate();
  const addFormData = (e) => {
    const { name, value } = e.target;
    if (name === "phoneNumber") {
      const isValidPhoneNumber = /^[0-9]{10}$/.test(value);

      setPhoneNumberError(isValidPhoneNumber ? "" : "Invalid phone number");
    }
    if (name === "website") {
      // const expectedUrl = "www.godenty.com";
      // const isValidUrl = value === expectedUrl;
      const isValidUrl =
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/.test(
          value
        );

      setWebsiteUrlError(isValidUrl ? "" : "Invalid website URL");
    }
    if (name === "googleMapLocation") {
      // const expectedUrl = "www.godenty.com";
      // const isValidUrl = value === expectedUrl;
      const isValidLocationUrl =
        /^https?:\/\/(www\.)?(maps\.app\.goo\.gl|google\.com\/maps)\/.*$/.test(
          value
        );

      setLocationUrlError(
        isValidLocationUrl ? "" : "Invalid google map location URL"
      );
    }

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const documentFileUrl =
    "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf";
  let documentFileName = "Certificate.pdf";

  const getProfileData = async () => {
    try {
      const { data } = await api.getProfile();
      if (!data.success) {
        return;
      }

      let info = data?.data?.user || {};

      setFormData({
        ...formData,
        name: info?.name || "",
        email: info?.email || "",
        clinicName: info?.userDetails?.clinicName || "",
        phoneNumber: info?.phoneNumber || "",
        website: info?.userDetails?.website || "",
        address: info?.userDetails?.address || "",
        googleMapLocation: info?.userDetails?.googleMapLocation || "",
        stateCouncilNo: info?.stateCouncilNo || ''
      });

      setProfileURL(info?.imageUrl);
    } catch (err) {
      console.log(err);
    }
  };

  const updateProfile = async () => {
    try {
      const { data } = await api.updateProfile({
        ...formData,
        imageUrl: profileURL,
      });
      if (!data?.success) {
        return;
      }
      Navigate("/clinic/settings");
    } catch (err) {
      console.log(err);
    }
  };

  const uploadImg = async (e) => {
    // let fileName = e.target.files[0].name;
    // let file = e.target.files[0];
    const file = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png"];

    if (!allowedTypes.includes(file.type)) {
      toast.error("Please select a JPG, JPEG or PNG file.");
      return;
    }

    const fileName = file.name;

    let awsSUrl = `https://godenty-backend-docs.s3.ap-south-2.amazonaws.com/docs/${fileName}`;

    let response = await fetch(awsSUrl, {
      method: "PUT",
      body: file,
    });

    if (response.ok) {
      setProfileURL(response?.url);
    } else {
      toast.error("Failed to upload image.");
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);
  return (
    <>
      <Paper sx={{ p: "1.8em", borderRadius: 2, mt: 3, maxWidth: "1090px" }}>
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: 600,
            color: "#303030",
            mb: "1em",
          }}
        >
          Profile
        </Typography>
        <div
          style={{
            position: "relative",
            width: "100px",
          }}
        >
          <Box
            sx={{
              mb: 3,
              position: "relative",
              width: "100px",
              height: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "100%",
              overflow: "hidden",
              border: "2px solid #004E89",
            }}
          >
            <img
              src={profileURL || AvatarIcon}
              width={"100%"}
              style={{
                height: "fit-content",
              }}
              alt="_profile"
            />

            {/* <label id="avatar-inp-label" htmlFor="avatar-inp">
            <img
              src={editicon}
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: 34,
                height: 34,
              }}
              alt="_edit_icon"
            />
          </label>

          <TextField
            sx={{
              display: "none",
            }}
            id="avatar-inp"
            type="file"
            onChange={uploadImg}
          /> */}
          </Box>
          <label id="avatar-inp-label" htmlFor="avatar-inp">
            <img
              src={editicon}
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                width: 34,
                height: 34,
              }}
              alt="_edit_icon"
            />
          </label>

          <TextField
            sx={{
              display: "none",
            }}
            id="avatar-inp"
            type="file"
            onChange={uploadImg}
          />
        </div>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={1} justifyContent="space-between">
              <Grid
                item
                display="flex"
                flexDirection="column"
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={5.5}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#303030",
                    mb: 1,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Owner Name
                </Typography>
                <OutlinedInput
                  name={"name"}
                  onChange={addFormData}
                  value={formData?.name}
                  inputProps={{
                    maxLength: 40,
                  }}
                  accept=".jpg,.jpeg, .png" // Accept only jpg and png files
                  sx={{
                    borderRadius: 2,
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-input": {
                      borderRadius: 2,
                      // border: "none",
                      outline: "none",
                      height: "15px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      transition: "ease-in 0.2s",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: `1px solid ${palette?.primary?.main}`,
                      transition: "ease-in 0.2s",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: `2px solid ${palette?.primary?.light}`,
                      transition: "ease-in 0.2s",
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                display="flex"
                flexDirection="column"
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={5.5}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#303030",
                    mb: 1,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Email Id
                </Typography>
                <OutlinedInput
                  // readOnly
                  name={"email"}
                  type="email"
                  onChange={addFormData}
                  value={formData?.email}
                  sx={{
                    borderRadius: 2,
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-input": {
                      borderRadius: 2,
                      // border: "none",
                      outline: "none",
                      height: "15px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      transition: "ease-in 0.2s",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: `1px solid ${palette?.primary?.main}`,
                      transition: "ease-in 0.2s",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: `2px solid ${palette?.primary?.light}`,
                      transition: "ease-in 0.2s",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={1} justifyContent="space-between">
              <Grid
                item
                display="flex"
                flexDirection="column"
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={5.5}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#303030",
                    mb: 1,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Clinic Name
                </Typography>
                <OutlinedInput
                  // readOnly
                  name={"clinicName"}
                  onChange={addFormData}
                  value={formData?.clinicName}
                  sx={{
                    borderRadius: 2,
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-input": {
                      borderRadius: 2,
                      // border: "none",
                      outline: "none",
                      height: "15px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      transition: "ease-in 0.2s",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: `1px solid ${palette?.primary?.main}`,
                      transition: "ease-in 0.2s",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: `2px solid ${palette?.primary?.light}`,
                      transition: "ease-in 0.2s",
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                display="flex"
                flexDirection="column"
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={5.5}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#303030",
                    mb: 1,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Phone Number
                </Typography>
                <OutlinedInput
                  inputProps={{
                    maxLength: 10,
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                  name={"phoneNumber"}
                  value={formData?.phoneNumber}
                  onChange={addFormData}
                  onInput={(e) => {
                    const target = e?.target;
                    target.value = e?.target?.value.replace(/[^0-9]/g, "");
                  }}
                  sx={{
                    borderRadius: 2,
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-input": {
                      borderRadius: 2,
                      // border: "none",
                      outline: "none",
                      height: "15px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      transition: "ease-in 0.2s",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: `1px solid ${palette?.primary?.main}`,
                      transition: "ease-in 0.2s",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: `2px solid ${palette?.primary?.light}`,
                      transition: "ease-in 0.2s",
                    },
                  }}
                />
                {phoneNumberError && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ mt: 1, fontSize: 12 }}
                  >
                    {phoneNumberError}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={1} justifyContent="space-between">
              <Grid
                item
                display="flex"
                flexDirection="column"
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={5.5}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#303030",
                    mb: 1,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Website
                </Typography>
                <OutlinedInput
                  name={"website"}
                  value={formData?.website}
                  onChange={addFormData}
                  sx={{
                    borderRadius: 2,
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-input": {
                      borderRadius: 2,
                      // border: "none",
                      outline: "none",
                      height: "15px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      transition: "ease-in 0.2s",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: `1px solid ${palette?.primary?.main}`,
                      transition: "ease-in 0.2s",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: `2px solid ${palette?.primary?.light}`,
                      transition: "ease-in 0.2s",
                    },
                  }}
                />
                {websiteUrlError && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ mt: 1, fontSize: 12 }}
                  >
                    {websiteUrlError}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                display="flex"
                flexDirection="column"
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={5.5}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#303030",
                    mb: 1,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Address
                </Typography>
                <OutlinedInput
                  // readOnly
                  name={"address"}
                  value={formData?.address}
                  onChange={addFormData}
                  sx={{
                    borderRadius: 2,
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-input": {
                      borderRadius: 2,
                      // border: "none",
                      outline: "none",
                      height: "15px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      transition: "ease-in 0.2s",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: `1px solid ${palette?.primary?.main}`,
                      transition: "ease-in 0.2s",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: `2px solid ${palette?.primary?.light}`,
                      transition: "ease-in 0.2s",
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={1} justifyContent="space-between" >
              <Grid item display={"flex"} justifyContent={'space-between'} gap={3}>
                <Grid
                  item
                  display="flex"
                  flexDirection="column"
                  xs={12}
                  sm={6}
                  xl={5.5}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "#303030",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    Licence/Rental Agreement
                  </Typography>
                  <DocumentViewer
                    file={documentFileUrl}
                    fileName={documentFileName}
                  />
                </Grid>
                <Grid>
                  <Typography
                    variant="h6"
                    noWrap
                    sx={{
                      color: "#303030",
                      fontSize: 14,
                      fontWeight: 400,
                    }}
                  >
                    State Council Registration Number
                  </Typography>
                  <OutlinedInput
                    fullWidth
                    name={"stateCouncilNo"}
                    value={formData?.stateCouncilNo}
                    onChange={addFormData}
                    sx={{
                      borderRadius: 2,
                      backgroundColor: "white",
                      "& .MuiOutlinedInput-input": {
                        borderRadius: 2,
                        outline: "none",
                        height: "15px",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderRadius: 2,
                        transition: "ease-in 0.2s",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: `1px solid ${palette?.primary?.main}`,
                        transition: "ease-in 0.2s",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: `2px solid ${palette?.primary?.light}`,
                        transition: "ease-in 0.2s",
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <Grid
                item
                display="flex"
                flexDirection="column"
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={5.5}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#303030",
                    mb: 1,
                    fontSize: 14,
                    fontWeight: 400,
                  }}
                >
                  Google Map Location
                </Typography>
                <OutlinedInput
                  name={"googleMapLocation"}
                  onChange={addFormData}
                  value={formData?.googleMapLocation}
                  sx={{
                    borderRadius: 2,
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-input": {
                      borderRadius: 2,
                      // border: "none",
                      outline: "none",
                      height: "15px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      transition: "ease-in 0.2s",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: `1px solid ${palette?.primary?.main}`,
                      transition: "ease-in 0.2s",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: `2px solid ${palette?.primary?.light}`,
                      transition: "ease-in 0.2s",
                    },
                  }}
                />
                {locationUrlError && (
                  <Typography
                    variant="body2"
                    color="error"
                    sx={{ mt: 1, fontSize: 12 }}
                  >
                    {locationUrlError}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={1} justifyContent="space-between">
              <Grid
                item
                display="flex"
                flexDirection="column"
                xs={1}
                sm={1}
                md={1}
                lg={1}
                xl={1}
              >
                <Button
                  variant="contained"
                  size="small"
                  fullWidth={false}
                  onClick={updateProfile}
                  sx={{
                    width: 120,
                    height: 40,
                    borderRadius: 24,
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  Save
                </Button>
              </Grid>
              <Grid item xs={12} sm={8} md={8} lg={8} xl={8} sx={{ mt: "1em" }}>
                <Link
                  to="/clinic/settings"
                  style={{
                    color: "#FF7676",
                    fontWeight: 600,
                    fontSize: 16,
                    cursor: "pointer",
                    marginLeft: "1em",
                  }}
                >
                  Cancel
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default EditSettingsClinic;
