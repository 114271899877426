import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, Button, CircularProgress, Grid, Hidden, IconButton, Typography, useTheme } from "@mui/material";
import Fade from "@mui/material/Fade";
import Popper from "@mui/material/Popper";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";

import api from "../../api";
import notificationicon from "../../assets/images/notificationicon.png";
import getStringInitials from "../../utils/getStringInitials";

const Navbar = (props) => {
  const { classes, onSidebarOpen, ...rest } = props;
  const [open, setOpen] = React.useState(false);

  const [isLoading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setOpen(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
    localStorage.getItem("role") == 1
      ? getAdminNotifications()
      : getUserNotifications();
  };

  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "transition-popper" : undefined;
  const {
    breakpoints,
    palette: { common },
  } = useTheme();

  const [userNotifications, setUserNotifications] = useState([]);
  const [userData, setUserData] = useState({});
  const popperRef = useRef(null);

  const getProfile = async () => {
    try {
      const res = await api?.getProfile();
      if (res?.data?.success) {
        setUserData(res?.data?.data?.user);
        localStorage.setItem("userId", res?.data?.data?.user?.id);
        if (res?.data?.data?.user?._role == 3) {
          localStorage.setItem(
            "designation",
            res?.data?.data?.user?.designation
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getUserNotifications = async () => {
    setLoading(true);
    await api
      .getUserNotifications()
      .then((res) => {
        setLoading(false);
        if (res?.data?.success) {
          setUserNotifications(res?.data?.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getAdminNotifications = async () => {
    setLoading(true);
    await api
      .getAdminNotifications()
      .then((res) => {
        setLoading(false);
        if (res?.data?.success) {
          setUserNotifications(res?.data?.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const clearNotifications = async () => {
    try {
      const { data } = await api.putUserNotifications();
      if (data?.success) {
        localStorage.getItem("role") == 1
          ? getAdminNotifications()
          : getUserNotifications();
        setOpen(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popperRef.current && !popperRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    if (open) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open]);

  useEffect(() => {
    getProfile();
    localStorage.getItem("role") == 1
      ? getAdminNotifications()
      : getUserNotifications();
  }, []);

  return (
    <AppBar
      {...rest}
      elevation={0}
      sx={{
        backgroundColor: common.white,
        border: "none",
        height: 78,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
          <Hidden mdUp>
            <IconButton color="black" onClick={onSidebarOpen}>
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            marginRight: "3rem",
          }}
        >
          <Box
            sx={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "24px",
            }}
          >
            <img
              src={notificationicon}
              style={{ width: "22px", cursor: "pointer" }}
              onClick={handleClick}
              alt="Notification Icon"
            />
            {userNotifications?.newNotificationCount > 0 ? (
              <Box
                sx={{
                  position: "absolute",
                  // top: "0.02px",
                  // left: "10.05px",
                  top: "-5px",
                  right: "-5px",
                  backgroundColor: "#004E89",
                  color: "white",
                  borderRadius: "100%",
                  width: "15px",
                  height: "15px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "8.5px",
                  fontWeight: 500,
                }}
              >
                {userNotifications?.newNotificationCount}
              </Box>
            ) : (
              <></>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              marginLeft: "1em",
            }}
          >
            <Typography sx={{ fontSize: "16px", fontWeight: 700 }}>
              {userData?.name || "User Name"}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "1em",
              width: "48px",
              height: "48px",
              borderRadius: "100%",
              backgroundColor: "#004E89",
              overflow: "hidden",
            }}
          >
            {userData?.imageUrl ? (
              <img
                src={userData?.imageUrl}
                alt="_profile"
                style={{ width: "48px", height: "48px" }}
              />
            ) : (
              <Typography
                sx={{
                  color: "#fff",
                  fontWeight: 500,
                  fontSize: 18,
                }}
              >
                {userData?.name ? getStringInitials(userData?.name) : ""}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
        onClose={handleClose}
        sx={{ zIndex: 999999 }}
        ref={popperRef}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Box
              sx={{
                boxShadow: "3px 3px 5px 3px rgba(0, 0, 0, 0.2)",
                py: 1.5,
                px: "4px",
                m: 2,
                bgcolor: "background.paper",
                borderRadius: "12px",
                width: "300px",
              }}
            >
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "600",
                  justifyContent: "space-between",
                  color: "#004E89",
                  fontSize: 15,
                  padding: "0 4px",
                }}
              >
                Notifications ({userNotifications?.notification?.length || 0})
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ fontSize: 12, p: 0, height: 20, width: 40 }}
                  onClick={clearNotifications}
                >
                  Clear
                </Button>
              </p>
              <Grid
                sx={{
                  mt: 1,
                  maxHeight: 481,
                  overflow: "auto",
                  scrollbarWidth: "none",
                  // borderRadius: "8px",
                  "&:-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                {isLoading ? (
                  <Box width={"100%"} textAlign={"center"}>
                    <CircularProgress size={15} />
                  </Box>
                ) : userNotifications?.notification.length ? (
                  userNotifications?.notification?.map((item) => (
                    <div
                      style={{
                        backgroundColor: "#F1F1F1",
                        paddingTop: "12px",
                        paddingRight: "16px",
                        paddingBottom: "12px",
                        paddingLeft: "16px",
                        marginBottom: "6px",
                        borderRadius: 12.57,
                      }}
                    >
                      <div style={{}}>
                        <p
                          style={{
                            display: "flex",
                            fontWeight: "600",
                            justifyContent: "space-between",
                            color: item?.notificationType
                              ? "#F98247"
                              : "#F1F1F1",
                            fontSize: 14,
                          }}
                        >
                          {item?.notificationType
                            ? item?.notificationType
                            : "cdsacsdcd"}
                          <span
                            style={{
                              fontWeight: "500",
                              color: "#303030A1",
                              fontSize: 10,
                            }}
                          >
                            {moment(item?.createdAt).format("hh:mm A")}
                          </span>
                        </p>
                      </div>
                      <p
                        style={{
                          display: "flex",
                          fontWeight: "300",
                          justifyContent: "space-between",
                          color: "#222222",
                          fontSize: 12,
                        }}
                      >
                        {item.notificationText}
                      </p>
                    </div>
                  ))
                ) : (
                  <Typography width={"100%"} textAlign={"center"}>
                    No new notifications
                  </Typography>
                )}
              </Grid>
            </Box>
          </Fade>
        )}
      </Popper>
    </AppBar>
  );
};

export default Navbar;
