import SearchIcon from "@mui/icons-material/Search";
import { Grid, InputAdornment, LinearProgress, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import api from "../../api";
import SentSuccessfully from "../Modals/SentSuccessfully";
import JobDetailsCard from "./JobDetailsCard";
import JobDetailsModal from "./JobDetailsModal";

const Jobs = () => {
  const [openModal, setOpenModal] = React.useState(false);

  const [activeJob, setActiveJob] = useState(null);

  const [clinicInfo, steClinicInfo] = useState({});

  const [isLoading, setLoading] = useState(false);

  const [jobs, setJobs] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");

  const handleClose = () => {
    setActiveJob(null);
  };

  const getJobs = async () => {
    setLoading(true);
    try {
      let payload = `?queryString=${searchQuery}`;
      const { data } = await api.getJobs(payload);
      setLoading(false);
      setJobs(data?.data?.allClinicPosts || []);
      steClinicInfo(data?.data?.allClinicPosts.clinicDetails || {});
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const handleCloseApprove = () => {
    setOpenModal(false);
  };

  //delay api call on search key change
  useEffect(() => {
    const getData = setTimeout(() => {
      getJobs();
    }, 1500);

    return () => clearTimeout(getData);
  }, [searchQuery]);

  useEffect(() => {
    getJobs();
  }, []);

  return (
    <>
      <Grid
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h3" mt={1} mb={0.5} sx={{ fontSize: "22px" }}>
          Jobs
        </Typography>
        <Grid display={"flex"} alignItems={"center"}>
          <TextField
            id="search"
            placeholder="Search by location"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={({ target: { value } }) => setSearchQuery(value)}
            sx={{
              width: "200px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            style={{ color: "#F98247" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ marginRight: "8px" }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      {isLoading && (
        <LinearProgress
          sx={{
            mb: 1,
          }}
        />
      )}

      {!isLoading && (
        <Grid display="flex" flexWrap="wrap" gap={1} mt={1}>
          {jobs.length ? (
            jobs?.map((job, index) => (
              <JobDetailsCard
                job={job}
                index={index}
                setActiveJob={setActiveJob}
              />
            ))
          ) : (
            <Typography sx={{ width: "100%" }} textAlign={"center"}>
              No records found.
            </Typography>
          )}
        </Grid>
      )}
      {activeJob?.id && (
        <JobDetailsModal
          open={activeJob}
          handleClose={handleClose}
          clinicInfo={clinicInfo}
          setOpenModal={setOpenModal}
        />
      )}

      <SentSuccessfully
        modal={openModal}
        handleCloseApprove={handleCloseApprove}
      />
    </>
  );
};

export default Jobs;
