import {
  Box,
  Grid,
  MenuItem,
  OutlinedInput,
  Popper,
  Select,
} from "@mui/material";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { BiFilterAlt } from "react-icons/bi";
import { MdOutlineFilterAltOff } from "react-icons/md";

import DateRangeCalendar from "../../components/calendar/DateRangeCalendar";
import {
  consultationProposalStatusList,
  jobProposalStatusList,
  workTypeOptions,
} from "../../constants";

const ProposalFilter = ({ setFilterData = null }) => {
  const [anchorElFilter, setAnchorElFilter] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [workTypes, setWorkTypes] = useState([]);
  const [selectedStatusList, setSelectedStatusList] = useState([]);

  const handleClickFilter = (event) => {
    setAnchorElFilter(anchorElFilter ? null : event.currentTarget);
  };

  const isOpen = Boolean(anchorElFilter);
  const id = isOpen ? "simple-popper" : undefined;

  const popperRef = useRef(null);

  const handleWorkTypeChange = ({ target: { value } }) => {
    setWorkTypes(typeof value === "string" ? value.split(",") : value);
    setSelectedStatusList([]);
  };

  const handleStatusTypeChange = ({ target: { value } }) => {
    setSelectedStatusList(typeof value === "string" ? value.split(",") : value);
  };

  const getProposalStatusList = () => {
    if (workTypes.includes("CONSULTATION")) {
      if (workTypes.includes("FULL-TIME") || workTypes.includes("PART-TIME")) {
        return [
          ...new Set([
            ...consultationProposalStatusList,
            ...jobProposalStatusList,
          ]),
        ];
      }
      return consultationProposalStatusList;
    }
    if (workTypes.includes("FULL-TIME") || workTypes.includes("PART-TIME")) {
      return jobProposalStatusList;
    }
    return [
      ...new Set([...consultationProposalStatusList, ...jobProposalStatusList]),
    ];
  };

  const formatDate = (date) => {
    return date ? new Intl.DateTimeFormat("en-US").format(date) : "";
  };

  useEffect(() => {
    setFilterData({
      status: selectedStatusList,
      startDate: startDate
        ? moment(formatDate(startDate), "MM/DD/YYYY").format("YYYY-MM-DD")
        : "",
      endDate: endDate
        ? moment(formatDate(endDate), "MM/DD/YYYY").format("YYYY-MM-DD")
        : "",
      type: workTypes,
    });
  }, [startDate, endDate, selectedStatusList, workTypes]);

  return (
    <Box>
      <div
        style={{
          border: "2px solid #004E89",
          height: 38,
          width: 38,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "12px",
          marginLeft: 4,
          cursor: 'pointer'
        }}
        aria-describedby={id}
        type="button"
        onClick={handleClickFilter}
      >
        {!isOpen ? (
          <BiFilterAlt size={16} />
        ) : (
          <MdOutlineFilterAltOff size={18} />
        )}
      </div>
      <Popper id={id} open={isOpen} anchorEl={anchorElFilter} ref={popperRef}>
        <Box
          sx={{
            boxShadow: 3,
            p: 1.5,
            bgcolor: "background.paper",
            // maxHeight: 481,
            overflow: "auto",
            borderRadius: "8px",
            "&:-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <Grid display={"flex"} flexDirection={"column"} gap={1}>
            <Grid>
              <DateRangeCalendar
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                disablePast={true}
              />
            </Grid>

            <Grid>
              <Select
                fullWidth
                displayEmpty
                input={<OutlinedInput />}
                multiple
                value={workTypes}
                onChange={handleWorkTypeChange}
                renderValue={(selected) =>
                  selected.length ? (
                    <em
                      style={{
                        color: "#000",
                        fontStyle: "normal",
                        fontSize: 12,
                        fontWeight: 500,
                      }}
                    >
                      {selected.join(", ")}
                    </em>
                  ) : (
                    <em
                      style={{
                        color: "#808690",
                        fontStyle: "normal",
                        fontSize: 12,
                        fontWeight: 500,
                      }}
                    >
                      Type
                    </em>
                  )
                }
              >
                {workTypeOptions.map((item) => (
                  <MenuItem sx={{m: 0.5}} key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid>
              <Select
                fullWidth
                multiple
                displayEmpty
                input={<OutlinedInput />}
                value={selectedStatusList}
                onChange={handleStatusTypeChange}
                renderValue={(selected) =>
                  selected.length ? (
                    <em
                      style={{
                        color: "#000",
                        fontStyle: "normal",
                        fontSize: 12,
                        fontWeight: 500,
                      }}
                    >
                      {selected.join(", ")}
                    </em>
                  ) : (
                    <em
                      style={{
                        color: "#808690",
                        fontStyle: "normal",
                        fontSize: 12,
                        fontWeight: 500,
                      }}
                    >
                      Status
                    </em>
                  )
                }
              >
                {getProposalStatusList().map((item) => (
                  <MenuItem sx={{m: 0.5}} key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </Box>
      </Popper>
    </Box>
  );
};

export default ProposalFilter;
