import CloseIcon from "@mui/icons-material/Close";
import { Box, Chip, Dialog, DialogContent, DialogTitle, Grid, MenuItem, Select, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useState } from "react";
import HTMLRenderer from "react-html-renderer";

import api from "../../api";
import clockicon from "../../assets/images/clockicon.png";
import doctorphone from "../../assets/images/doctorphone.png";
import location from "../../assets/images/location.png";
import staricon from "../../assets/images/staricon.png";
import getStringInitials from "../../utils/getStringInitials";
import RatingModal from "./RatingModal";
import SentSuccessfully from "./SentSuccessfully";

const ConsultationModal = ({ handleClose, open, isHistory = false }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [done, setDone] = useState(false);
  const [cancel, setCancel] = useState(false);

  const [isDisabled, setDisabled] = useState(false);

  const getDate = (dateString) => {
    let dateObj = new Date(dateString);
    let day = dateObj.getDate();
    const monthName = dateObj.toLocaleString("en-US", { month: "short" });
    return `${day} ${monthName}`;
  };

  const handleCloseApprove = () => {
    setCancel(false);
    handleClose();
  };
  const handlecloseDone = () => {
    setDone(false);
    handleClose();
  };

  const updateAppointmentStatus = async (status) => {
    setDisabled(true);
    try {
      const { data } = await api.updateMyConsultationStatus(open?.id, status);
      setDisabled(false);
      if (!data?.success) {
        return;
      }

      if (status == "completed") {
        setDone(true);
        return;
      }
      if (status == "cancelled") {
        setCancel(true);
      }
    } catch (err) {
      setDisabled(false);
      console.log(err);
    }
  };

  const handleOptionChange = (event) => {
    let value = event.target.value;
    setSelectedOption(value);

    if (value == "done") {
      updateAppointmentStatus("completed");
    } else {
      updateAppointmentStatus("cancelled");
    }
  };

  return (
    <>
      {cancel ? (
        <SentSuccessfully
          modal={cancel}
          cancel={true}
          handleCloseApprove={handleCloseApprove}
          value={"proposal"}
        />
      ) : done ? (
        <RatingModal
          modal={done}
          handleCloseApprove={handlecloseDone}
          value={"proposal"}
          data={open}
          isDoctor={true}
        />
      ) : (
        <Dialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle
            sx={{ px: 1, fontWeight: 600, fontSize: 20, color: "#F98247" }}
            id="customized-dialog-title"
          >
            {open?.postDetails?.testName || "Test Name"}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: "black",
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent
            sx={{ p: 1, display: "flex", flexDirection: "column", rowGap: 3 }}
          >
            <Grid container alignItems="center" gap={1}>
              <Grid
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                {open.clinic.imageUrl ? (
                  <img
                    src={open.clinic.imageUrl}
                    style={{
                      width: 60,
                      height: 60,
                      borderRadius: "12px",
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      width: 60,
                      height: 60,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#004E89",
                      borderRadius: "12px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: 20,
                        color: "#fff",
                      }}
                    >
                      {getStringInitials(
                        open?.clinicDetails?.clinicName || "-"
                      )}
                    </Typography>
                  </Box>
                )}
              </Grid>
              <Box
                display="flex"
                sx={{
                  flexDirection: {
                    xs: "column",
                    lg: "row",
                  },
                }}
              >
                <Typography
                  sx={{
                    color: "#004E89",
                    fontSize: "20px",
                    fontWeight: 600,
                  }}
                >
                  {open?.clinicDetails?.clinicName || "-"}
                </Typography>
              </Box>
            </Grid>

            <Grid
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              flexWrap={"wrap"}
              rowGap={1}
            >
              <Grid display={"flex"} alignItems={"center"} gap={1}>
                <img
                  src={doctorphone}
                  alt="phone"
                  style={{ width: 30, height: 30 }}
                />
                <Grid>
                  <Typography
                    sx={{ fontSize: 14, fontWeight: "400", color: "#858585" }}
                  >
                    Contact no
                  </Typography>
                  <Typography
                    sx={{ fontSize: 14, fontWeight: "500", color: "#303030" }}
                  >
                    {open?.clinic?.phoneNumber}
                  </Typography>
                </Grid>
              </Grid>

              <Grid display={"flex"} alignItems={"center"} gap={1}>
                <img
                  src={location}
                  style={{ width: 30, height: 30 }}
                  alt="loc"
                />
                <Grid>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: "400",
                      color: "#858585",
                    }}
                  >
                    Location
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: "500",
                      color: "#303030",
                    }}
                  >
                    {open?.clinicDetails?.address || "-"}
                  </Typography>
                </Grid>
              </Grid>

              <Grid display={"flex"} alignItems={"center"} gap={1}>
                <img
                  src={clockicon}
                  style={{ width: 30, height: 30 }}
                  alt="clock"
                />
                <Grid>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: "400",
                      color: "#858585",
                    }}
                  >
                    Date & Time
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: "500",
                      color: "#303030",
                    }}
                  >
                    {getDate(open?.postDetails?.date)},
                    {open?.postDetails?.timeSlots}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid>
              {open?.postDetails?.requestType ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 0.5,
                  }}
                >
                  <img
                    src={staricon}
                    alt="_img"
                    style={{ width: 14, height: 14 }}
                  />
                  <Typography
                    sx={{
                      color: "#F98247",
                      fontSize: 14,
                      fontWeight: 600,
                      mt: "2px",
                    }}
                  >
                    {open?.postDetails?.requestType || ""}
                  </Typography>
                </Box>
              ) : (
                ""
              )}

              {open?.postDetails?.price ? (
                <Grid item>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <img src={staricon} style={{ width: 14, height: 14 }} />
                    <Typography
                      sx={{
                        color: "#F98247",
                        fontSize: "16px",
                        fontWeight: 600,
                        ml: 1,
                      }}
                    >
                      Rs.{open?.postDetails?.price} (Per Hour)
                    </Typography>
                  </Box>
                </Grid>
              ) : (
                ""
              )}

              {open?.postDetails?.specilistIn ? (
                <Grid item>
                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Grid container sx={{ mt: "-0.5em" }}>
                      <Grid item sx={{ mt: "0.7em" }}>
                        <img src={staricon} style={{ width: 14, height: 14 }} />
                      </Grid>
                      <Grid item>
                        <Chip
                          label={open?.postDetails?.specilistIn}
                          style={{ margin: "4px" }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              ) : (
                ""
              )}
            </Grid>

            {/* <Grid container spacing={1} sx={{ mt: "0.5em" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography variant="h3" sx={{ fontSize: "20px", mt: "0.5em" }}>
                  Case Location
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Typography
                  sx={{ fontSize: "16px", color: "rgba(118, 121, 128, 1)" }}
                >
                  {open?.clinicDetails?.googleMapLocation || "-"}
                </Typography>
              </Grid>
            </Grid> */}

            <Grid>
              <Typography variant="h3" sx={{ fontSize: 20 }}>
                Case Description
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  color: "rgba(118, 121, 128, 1)",
                  minHeight: 60,
                  maxHeight: 200,
                  overflowY: "auto",
                }}
              >
                <HTMLRenderer
                  html={open?.postDetails?.caseDescription || "<p>...</p>"}
                />
              </Typography>
            </Grid>

            {!isHistory && (
              <Grid>
                <Typography sx={{ fontSize: "16px", color: "#303030" }}>
                  Order Status
                </Typography>
                <Select
                  disabled={isDisabled}
                  value={selectedOption}
                  onChange={handleOptionChange}
                  sx={{
                    minWidth: "200px",
                    mt: 1,
                    borderRadius: "8px",
                    backgroundColor: "white",
                    "& .MuiOutlinedInput-input": {
                      borderRadius: 2,
                      border: "none",
                      outline: "none",
                      height: "15px",
                      marginTop: "-0.2em",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderRadius: 2,
                      border: "none",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                  }}
                  onFocus={(e) => e.currentTarget.classList.add("focused")}
                  onBlur={(e) => e.currentTarget.classList.remove("focused")}
                  displayEmpty
                  // onOpen={handleDropdownOpen}
                  // onClose={handleDropdownClose}
                >
                  <MenuItem value="" style={{ display: "none" }}>
                    <em
                      style={{
                        color: "#C2C2C2",
                        fontStyle: "normal",
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      Select Status
                    </em>
                  </MenuItem>

                  <MenuItem value="done">
                    <Typography style={{ color: "green", fontWeight: "400" }}>
                      Job Done
                    </Typography>
                  </MenuItem>
                  <MenuItem value="cancel">
                    <Typography style={{ color: "red", fontWeight: "400" }}>
                      Appointment Cancel
                    </Typography>
                  </MenuItem>
                </Select>
              </Grid>
            )}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
export default ConsultationModal;
