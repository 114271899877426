import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggedIn: null,
};

export const AuthHelper = createSlice({
  name: "AuthHelper",
  initialState,
  reducers: {
    setLogin: (state, payload) => {
      state.isLoggedIn = payload?.payload?.isAuth;
    },
    setLogout: (state) => {
      state.isLoggedIn = false;
    },
  },
});

export const { setLogout, setLogin } = AuthHelper.actions;

export default AuthHelper.reducer;
