import SearchIcon from "@mui/icons-material/Search";
import { Grid, InputAdornment, LinearProgress, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import api from "../../api/index";
import CardDetails from "../CardDetails";
import DoctorConsultationReq from "../Modals/DoctorConsultationReq";

const ConsultationReq = () => {
  const [open, setOpen] = useState(false);
  const [consultations, setConsultations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const getAllClinicPosts = async () => {
    setLoading(true);

    let payload = `?queryString=${searchQuery}`;
    await api
      .getAllPosts(payload)
      .then((response) => {
        if (response?.data?.success) {
          setConsultations(response?.data?.data?.allClinicPosts);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllClinicPosts();
  }, []);

  //delay api call on search key change
  useEffect(() => {
    const getData = setTimeout(() => {
      getAllClinicPosts();
    }, 1500);

    return () => clearTimeout(getData);
  }, [searchQuery]);

  return (
    <>
      <DoctorConsultationReq open={open} handleClose={handleClose} />

      <Grid
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        mt={2}
      >
        <Typography variant="h3" mt={1} mb={0.5} sx={{ fontSize: "22px" }}>
          New Request
        </Typography>
        <Grid display={"flex"} alignItems={"center"}>
          <TextField
            id="search"
            placeholder="Search by location"
            variant="outlined"
            size="small"
            value={searchQuery}
            onChange={({ target: { value } }) => setSearchQuery(value)}
            sx={{
              width: "200px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            style={{ color: "#F98247" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" sx={{ marginRight: "8px" }}>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      {loading && (
        <LinearProgress
          sx={{
            my: 1,
          }}
        />
      )}

      <Grid display="flex" flexDirection="row" flexWrap="wrap" gap={1} mt={1}>
        {consultations.length ? (
          consultations?.map((consultation, index) => (
            <CardDetails consultation={consultation} index={index} />
          ))
        ) : (
          <Typography>No records found.</Typography>
        )}
      </Grid>
    </>
  );
};

export default ConsultationReq;
